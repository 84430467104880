import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Master/Channels/AddEditForm";
import { Checkbox } from "antd";
import getPermittedColumns from "Utils/getPermittedColumns";


const apiUrl = "/master/rest/channels";
const headerTitle = "Channels";
const editPermission = "channels:edit";
const deletePermission = "channels:delete";
const addPermission = "channels:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, row) => {
            return <Checkbox disabled checked={row.status}></Checkbox>
        }
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const ChannelsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;;
}

export default ChannelsPage;