import React, { useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Flex, Skeleton, TimePicker } from 'antd';
import { useSelector } from 'react-redux';
import Halls from './Halls';
import dayjs from 'dayjs';

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const getItems = (panelStyle, theatre, index) => [
  {
    key: '1',
    label: theatre.title,
    children: <Halls halls={theatre.halls} />,
    style: panelStyle,
  }
];

const getDummyItems = (panelStyle) => [
  {
    key: '1',
    label: <Skeleton.Button active block={true} />,
    children: <Skeleton.Button active block={true} />,
    style: panelStyle,
  }
];

const Theatres = () => {

  const theatres = useSelector(store => store.shows?.selectedProgramData?.theatres);

  const panelStyle = {
    marginBottom: 30,
  };

  const onChange = (e) => {
    console.log(e);
  }

  return (
    <div>
      {
        !theatres || !Array.isArray(theatres) ?
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition={'start'}
            items={getDummyItems(panelStyle)}
          /> :
          <>
            {
              theatres.map((theatre, i) => {

                return <Collapse
                  key={i}
                  bordered={true}
                  defaultActiveKey={['1']}
                  onChange={onChange}
                  expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  expandIconPosition={'start'}
                  items={getItems(panelStyle, theatre, i)}
                />
              })
            }
          </>
      }

    </div>
  );
};
export default Theatres;