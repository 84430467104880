import { useNotificationContext } from 'Config/NotificationsContext';
import React, { useEffect, useState } from 'react'
import { fetchAllDistributorsList, fetchLanguagesList, fetchProgramTypeList, fetchSeatTypeList } from 'Api/commonApis';
import { Button, Col, Flex, Form, Input, Row, Select } from 'antd';
import { asyncStatuses } from 'Redux/enums';
import { useSelector } from 'react-redux';
import { validateTwoDecimalNumber } from 'Utils/formValidators';
import ProgramTypeSurchargeForm from 'Components/Common/Shows/ProgramTypeSurchargeForm';

const AdditionalShowRelatedDetails = ({
    form,
    onSave,
    formatTypeOptions, setFormatTypeOptions,
    languagesOptions, setLanguagesOptions,
    distributorOptions, setDistributorsOptions,
    newlyCreatedShows, setNewlyCreatedShows
}) => {

    const [selectedFormatType, setSelectedFormatType] = useState("");

    const [seatTypes, setSeatTypes] = useState(null);

    const openNotification = useNotificationContext();
    const saveShowsDataRequestStatus = useSelector(store => store.shows.saveShowsDataRequestStatus);

    useEffect(() => {
        // const fetchFormatTypesOptions = async () => {
        //     try {
        //         const list = await fetchShowFormatTypeChoices();
        //         if (Array.isArray(list)) {
        //             const options = list.map(l => ({ value: l[0], label: l[0] }));
        //             setFormatTypeOptions(options);
        //         } else {
        //             throw new Error("Fetch FormatTypes did not return a list");
        //         }
        //     } catch (err) {
        //         console.log({ err })
        //         if (err.message) {
        //             openNotification("Format Type Options", err.message, "error");
        //         } else {
        //             openNotification("Format Type Options", "Something went wrong while fetching user options", "error")
        //         }
        //     }
        // }
        const fetchSeatTypesList = async () => {
            try {
                const list = await fetchSeatTypeList();
                console.log({ list });
                if (Array.isArray(list)) {
                    setSeatTypes(list);
                } else {
                    throw new Error("Fetch SeatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Seat Type Options", err.message, "error");
                } else {
                    openNotification("Seat Type Options", "Something went wrong while fetching format type options", "error")
                }
            }
        }
        const fetchFormatTypesOptions = async () => {
            try {
                const list = await fetchProgramTypeList();
                console.log({ list });
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title, surcharge: l.surcharge }));
                    setFormatTypeOptions(options);
                } else {
                    throw new Error("Fetch FormatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Format Type Options", err.message, "error");
                } else {
                    openNotification("Format Type Options", "Something went wrong while fetching format type options", "error")
                }
            }
        }
        const fetchLanguagesOptions = async () => {
            try {
                const list = await fetchLanguagesList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title }))
                    setLanguagesOptions(options);
                } else {
                    throw new Error("Fetch Languages did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Language Options", err.message, "error");
                } else {
                    openNotification("Language Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        const fetchDistributorsOptions = async () => {
            try {
                const list = await fetchAllDistributorsList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.name }))
                    setDistributorsOptions(options);
                } else {
                    throw new Error("Fetch Distributors did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Distributor Options", err.message, "error");
                } else {
                    openNotification("Distributor Options", "Something went wrong while fetching distributor options", "error")
                }
            }
        }
        fetchFormatTypesOptions();
        fetchLanguagesOptions();
        fetchDistributorsOptions();
        fetchSeatTypesList();
    }, [openNotification, setDistributorsOptions, setFormatTypeOptions, setLanguagesOptions]);

    const formatTypeSelected = (selectedFormat, fullData) => {
        console.log(fullData)
        setSelectedFormatType(fullData);
    }

    return (
        <Form requiredMark={true} onFinish={onSave} form={form}>
            <Row>
                <Col sm={8}>
                    <Form.Item
                        className="d-flex flex-column"
                        name="format_type"
                        label="Format Type"
                        rules={[
                            {
                                required: true,
                                message: "Format Type is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(formatTypeOptions) ? false : true}
                            options={formatTypeOptions}
                            onSelect={formatTypeSelected}
                            optionRender={(option) => {
                                return <Flex justify='space-between'>
                                    <div>{option?.data?.label}</div>
                                    <div><small style={{ color: "gray" }}>MUR {option?.data?.surcharge}</small></div>
                                </Flex>
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item
                        name="language"
                        label="Language"
                        rules={[
                            {
                                required: true,
                                message: "Language is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(languagesOptions) ? false : true}
                            options={languagesOptions}
                        />
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item
                        name="distributor"
                        label="Distributor"
                        rules={[
                            {
                                required: true,
                                message: "Distributor is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(distributorOptions) ? false : true}
                            options={distributorOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <ProgramTypeSurchargeForm selectedFormatType={selectedFormatType} />
            <Flex>
                <Button
                    // onClick={saveData}
                    htmlType="submit"
                    size='large' 
                    type='primary' 
                    loading={saveShowsDataRequestStatus === asyncStatuses.LOADING}
                    disabled={!newlyCreatedShows || Object.keys(newlyCreatedShows || {}).length === 0}
                >
                    Save
                </Button>
            </Flex>
        </Form>
    )
}

export default AdditionalShowRelatedDetails