import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useNotificationContext } from 'Config/NotificationsContext';
import axios from 'axios';
import { useSelector } from 'react-redux';

const { Option } = Select;

const EmployeeFilterForReports = ({ employeeFilterVal, setEmployeeFilterVal }) => {

    const openNotification = useNotificationContext();

    const [filterValues, setFilterValues] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [employeeName, setEmployeeName] = useState(null);

    const user = useSelector(store => store.auth.user);

    useEffect(() => {
        const hasAdminRole = user?.roles?.find?.(role => role.role_code === "ADMIN");
        if (hasAdminRole) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [user]);
    console.log({ user, isAdmin });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/theatre/theatre-users-list/');
                setFilterValues(response.data);
                setEmployeeFilterVal('none');
            } catch (e) {
                console.error(e);
                openNotification("Couldn't fetch Theatres", e?.message, "error");
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!isAdmin && Array.isArray(filterValues)) {
            const currentUserId = user?.id;
            if (currentUserId) {
                const userInTheatreUsers = filterValues?.find?.(fv => fv.user === currentUserId);
                if (userInTheatreUsers) {
                    setShowDropDown(false);
                    setEmployeeFilterVal(userInTheatreUsers.id);
                    setEmployeeName(userInTheatreUsers?.username)
                } else {
                    setShowDropDown(false);
                    setEmployeeFilterVal("NOT_ALLOWED");
                }
            }
        } else if (isAdmin) {
            setShowDropDown(true);
        }
    }, [isAdmin, filterValues, user]);

    const onFilterSelect = (v) => {
        setEmployeeFilterVal(v);
    }

    return (
        <>
            <Flex justify='flex-start' align='center' gap={5}>
                {
                    showDropDown ? <>
                        <label>Employee</label>
                        <Select
                            style={{ width: "150px" }}
                            placeholder="Choose a Theatre to Filter"
                            loading={Array.isArray(filterValues) ? false : true}
                            onSelect={onFilterSelect}
                            value={employeeFilterVal}
                        >
                            <Option key={'none'} value={'none'}>all</Option>
                            {
                                filterValues?.map?.((afv, ind) => {
                                    return <Option key={afv.id} value={afv.id}>{afv?.username}</Option>
                                })
                            }
                        </Select>
                    </> : employeeName ? <Flex vertical gap={3}>
                        <small><strong>Cashier</strong></small>
                        <p style={{margin: 0}}>{employeeName}</p>
                    </Flex> : <></>
                }
            </Flex>
        </>
    )
}

export default EmployeeFilterForReports;