import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { asyncStatuses } from "Redux/enums";
import axios from "axios";

export const fetchTheatresListAsync = createAsyncThunk("theatres/list", async (filter, thunkAPI) => {
    try {
        let response = null
        if (filter) {
            response = await axios.get('/theatre/theatres-list/' + filter);
        } else {
            response = await axios.get('/theatre/theatres-list/');
        }
        console.log(response);
        return response.data;
    } catch (err) {
        if (err.response?.data?.msg){
            return thunkAPI.rejectWithValue({msg: err.response.data.msg});
        }
        return thunkAPI.rejectWithValue(err)
    }
});

export const fetchTheatreScheduleAsync = createAsyncThunk("theatre/schedule", async (data, thunkAPI) => {
    try {
        const { theatre } = data;
        const response = await axios.get("/theatre/schedule/?theatre="+theatre);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        if (err.response?.data?.msg){
            return thunkAPI.rejectWithValue({msg: err.response.data.msg});
        }
        return thunkAPI.rejectWithValue({ msg: "Error while fetching schedule" });
    }
})

const initialState = {
    theatreList: [],
    theatreListState: null,
    count: 0,
    isViewScheduleDrawerVisible: false,
    scheduleDataRetrieveStatus: null,
    scheduleDataRetrieveErrorMsg: null,
    scheduleData: null,
}

const theatreSlice = createSlice({
    name: "theatre",
    initialState: initialState,
    reducers: {
        resetTheatreSlice: (state, action) => {
            return initialState
        },
        hideViewScheduleDrawer(state) {
            state.isViewScheduleDrawerVisible = false;
        },
        showViewScheduleDrawer(state) {
            state.isViewScheduleDrawerVisible = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTheatresListAsync.pending, (state) => {
            state.theatreListState = asyncStatuses.LOADING;
        });
        builder.addCase(fetchTheatresListAsync.fulfilled, (state, action) => {
            state.theatreListState = asyncStatuses.SUCCESS;
            state.theatreList = action.payload;
            state.count = action.payload.length;
        });
        builder.addCase(fetchTheatresListAsync.rejected, (state) => {
            state.theatreList = [];
            state.count = 0;
            state.theatreListState = asyncStatuses.FAILED;
        });

        builder.addCase(fetchTheatreScheduleAsync.pending, (state) => {
            state.scheduleDataRetrieveStatus = asyncStatuses.LOADING;
            state.scheduleData = null;
            state.scheduleDataRetrieveErrorMsg = null;
        });
        builder.addCase(fetchTheatreScheduleAsync.rejected, (state, action) => {
            state.scheduleDataRetrieveStatus = asyncStatuses.FAILED;
            state.scheduleData = null;
            if (action.payload.msg) {
                state.scheduleDataRetrieveErrorMsg = action.payload.msg;
            } else {
                state.scheduleDataRetrieveErrorMsg = null;
            }
        });
        builder.addCase(fetchTheatreScheduleAsync.fulfilled, (state, action) => {
            state.scheduleDataRetrieveStatus = asyncStatuses.SUCCESS;
            state.scheduleData = action.payload;
            state.scheduleDataRetrieveErrorMsg = null;
        })
    }
});

export const { resetTheatreSlice, hideViewScheduleDrawer, showViewScheduleDrawer } = theatreSlice.actions;
export default theatreSlice.reducer;