import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { DatePicker, Flex, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from './ExportXlsx';
const { RangePicker } = DatePicker;

const API_URL = "/reports/pre-sales-report/";

const tableColumns = [
    {
        title: 'Movie',
        dataIndex: 'movie',
        key: 'movie',
        sorter: (a, b) => a.movie - b.movie,
    },
    {
        title: 'Release Date',
        dataIndex: 'releaseDate',
        key: 'releaseDate',
        sorter: (a, b) => {
            const dateA = dayjs(a.releaseDate);
            const dateB = dayjs(b.releaseDate);
            return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
        },
        align: "right"
    },
    {
        title: 'Occupancy',
        dataIndex: 'occupancy',
        key: 'occupancy',
        render: (text) => text + " %",
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.occupancy - b.occupancy,
        align: "right"
    },
    {
        title: 'Total Tickets Sold',
        dataIndex: 'totalTicketsSold',
        key: 'totalTicketsSold',
        sorter: (a, b) => a.totalTicketsSold - b.totalTicketsSold,
        align: "right"
    },
    {
        title: 'Total Tickets Available',
        dataIndex: 'totalTicketsAvailable',
        key: 'totalTicketsAvailable',
        sorter: (a, b) => a.totalTicketsAvailable - b.totalTicketsAvailable,
        align: "right"
    },
    {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (text) => getFormattedAmount(text),
        sorter: (a, b) => a.totalAmount - b.totalAmount,
        align: "right",
    },
    {
        title: 'Sales Channel Performance',
        children: [
            {
                title: 'POS',
                dataIndex: 'pos',
                key: 'pos',
                render: (_, row) => {
                    let val = row?.salesChannelPerformance?.POS?.percent;
                    if (val) {
                        val += " %"
                        return val;
                    } else {
                        return "-"
                    }
                },
                sorter: (a, b) => a?.salesChannelPerformance?.POS?.percent - b?.salesChannelPerformance?.POS?.percent,
                align: "center",
            },
            {
                title: 'KIOSK',
                dataIndex: 'KIOSK',
                key: 'KIOSK',
                render: (_, row) => {
                    let val = row?.salesChannelPerformance?.KIOSK?.percent;
                    if (val) {
                        val += " %"
                        return val;
                    } else {
                        return "-"
                    }
                },
                sorter: (a, b) => a?.salesChannelPerformance?.KIOSK?.percent - b?.salesChannelPerformance?.KIOSK?.percent,
                align: "right",
            },
            {
                title: 'WEBSITE',
                dataIndex: 'WEB',
                key: 'WEB',
                render: (_, row) => {
                    let val = row?.salesChannelPerformance?.WEB?.percent
                    if (val) {
                        val += " %"
                        return val;
                    } else {
                        return "-"
                    }
                },
                sorter: (a, b) => a?.salesChannelPerformance?.WEB?.percent - b?.salesChannelPerformance?.WEB?.percent,
                align: "right",
            },
            {
                title: 'APP',
                dataIndex: 'APP',
                key: 'APP',
                render: (_, row) => {
                    let val = row?.salesChannelPerformance?.APP?.percent;
                    if (val) {
                        val += " %"
                        return val;
                    } else {
                        return "-"
                    }
                },
                sorter: (a, b) => a?.salesChannelPerformance?.APP?.percent - b?.salesChannelPerformance?.APP?.percent,
                align: "right",
            },
        ],
    },
]

const initialDates = dayjs();

const PreSalesReport = () => {

    const [data, setData] = useState([]);
    // const [theatreFilterVal, setTheatreFilterVal] = useState("");
    // const [startDate, setStartDate] = useState(initialDates);
    // const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            // if (!theatreFilterVal) {
            //     return;
            // }
            try {
                setLoading(true);
                const payload = {}
                // payload['theatre'] = theatreFilterVal;
                // payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                // payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data);
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Movie Analysis Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, []);
    // }, [startDate, endDate, theatreFilterVal]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{}}
                    reportName={'presales_report'}
                    reportTitleForNotification={'Pre-Sales Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Flex justify='space-between' style={{ width: "100%" }}>
                    <ReportTitle title={`Pre-Sales Report`} />
                    {/* <Flex gap={15}>
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex> */}
                </Flex>

                <Flex>
                    <Table
                        columns={tableColumns}
                        bordered
                        dataSource={data}
                        size='small'
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        loading={loading}
                        scroll={{
                            y: "65vh",
                            x: "100%"
                        }}
                        pagination={false}
                    />
                </Flex>
            </Flex>
        </>
    )
}

export default PreSalesReport