import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { RELATIVE_DATE_OPTIONS, getStartAndEndDate } from 'Utils/getStartAndEndDate';
import { DatePicker, Flex, Segmented, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
const { Option } = Select;

const initialDates = dayjs();

const SelectDateForReports = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate
}) => {

    const [selectedDateFilter, setSelectedDateFilter] = useState(RELATIVE_DATE_OPTIONS.TODAY);

    const openNotification = useNotificationContext();

    const modifyDates = (value) => {
        console.log({ value })
        setStartDate(value[0]);
        setEndDate(value[1])
    }

    useEffect(() => {
        const generateDates = async () => {
            try {
                if (selectedDateFilter !== RELATIVE_DATE_OPTIONS.CUSTOM) {
                    const { start_date, end_date } = getStartAndEndDate(selectedDateFilter);
                    // console.log({ start_date, end_date });
                    setStartDate(dayjs(start_date), SELECTED_DATE_FORMAT);
                    setEndDate(dayjs(end_date), SELECTED_DATE_FORMAT);
                }
            } catch (e) {
                console.error(e);
            }
        }
        generateDates();
    }, [selectedDateFilter]);

    return (
        <Flex align='center' gap={5}>
            <label htmlFor="">Date</label>
            <Select
                style={{ width: "150px" }}
                placeholder="Date"
                value={selectedDateFilter}
                onSelect={(v)=>setSelectedDateFilter(v)}
            >
                {
                    Object.values(RELATIVE_DATE_OPTIONS)?.map?.((afv, ind) => {
                        return <Option key={ind} value={afv}>{afv}</Option>
                    })
                }
            </Select>
            {
                selectedDateFilter === RELATIVE_DATE_OPTIONS.CUSTOM ?
                    <DatePicker.RangePicker defaultValue={[initialDates, initialDates]} onChange={modifyDates} style={{ width: "max-content" }} /> :
                    <></>
            }
        </Flex>
    )
}

export default SelectDateForReports