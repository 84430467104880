import { Button, Card, Flex, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import ReportTitle from './ReportTitle'
import axios from 'axios';
import dayjs from 'dayjs';

const AtAGlance = ({ openNotification, openShowReportsList }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('/reports/at-a-glance-data/', { start_date: dayjs().format('YYYY-MM-DD') });
                setData(response.data);
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", "Error while fetching at a glance data", "error");
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Flex vertical gap={5} style={{ margin: "1rem 0rem", marginTop: 0, paddingRight: 5 }}>
                <ReportTitle title={`At A Glance (Today)`} />
                <Flex gap={20}>
                    <Box title={'Number of Transactions with food'} value={data?.['transactions_with_food'] || '0'} loading={loading} />
                    <Box title={'Number of Transactions with tickets'} value={data?.['transactions_with_tickets'] || '0'} loading={loading} />
                    <Box title={'Number of Transactions with food & tickets'} value={data?.['transactions_with_tickets_and_food'] || '0'} loading={loading} />
                    <Box title={'Items sold per customer'} value={data?.['items_sold_per_customer'] || '0'} loading={loading} />
                    <Box title={'Items sold per transaction'} value={data?.['items_sold_per_transaction'] || '0'} loading={loading} />
                    <Box title={'Number of Tickets Sold'} value={data?.['number_of_tickets_sold'] || '0'} loading={loading} />
                </Flex>
                {/* <Flex gap={5}>
                    <Box title={'items sold per customer'} value={data?.['items_sold_per_customer'] || '0'} loading={loading} />
                    <Box title={'items sold per transaction'} value={data?.['items_sold_per_transaction'] || '0'} loading={loading} />
                    <Box title={'Number of Tickets Sold'} value={data?.['number_of_tickets_sold'] || '0'} loading={loading} />
                </Flex> */}
            </Flex>
        </>
    )
}

const Box = ({ title, value, loading }) => {
    return (
        <Flex
            flex={1}
            vertical
            justify="space-between"
            style={{
                backgroundColor: "rgb(220, 220, 220)", // Light background color
                padding: "20px",
                borderRadius: "0px", // Sharp corners
                minWidth: "200px", // Ensures a consistent size
                textAlign: "center",
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth hover effect
            }}
            gap={20}
            onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#ffe6e6"; // Lighter background on hover
                e.currentTarget.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.2)"; // Slightly bigger shadow on hover
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "rgb(220, 220, 220)"; // Reset background color
                e.currentTarget.style.boxShadow = "0px 0px 0px 0px"; // Reset shadow
            }}
        >
            <small style={{ fontSize: "14px", fontWeight: "600", color: "#595959" }}>
                {title}
            </small>
            {
                <h1 style={{ margin: 0, fontSize: "36px", fontWeight: "700", color: "#262626" }}>
                    {loading ? <Skeleton active paragraph={{ rows: 0 }} /> : value}
                </h1>
            }
        </Flex>
    );
}


export default AtAGlance