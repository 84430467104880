import SeatSvg20 from "./SeatSvg20";
import { BOOKED_SEAT_COLOR, LOCKED_SEAT_COLOR, NO_EMPTY_SEAT_MESSAGE } from "./utils";
import { useNotificationContext } from "Config/NotificationsContext";

const SeatDesign = ({ row, column, allSeats, seat, totalSeatsSelected, defaultColor, color, bookedSeats, setBookedSeats }) => {

    const openNotification = useNotificationContext();
    const seat_status = bookedSeats.filter(bookedSeat => bookedSeat.id === seat.id);
    const availableSeats = allSeats.flat().filter(x => x.status === "AVAILABLE");
    let next_seat = column;
    // alert(availableSeats.length);
    // console.log(allSeats);

    return (
        <div
            style={{
                margin: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                cursor: seat?.status === null || seat?.status === "BOOKED" || seat?.status === "LOCKED" ? "" : "pointer"
            }}
            onClick={() => {
                if (seat?.status !== null && seat?.status !== "BOOKED" && seat?.status !== "LOCKED") {
                    if (!bookedSeats.find(item => item.id === seat.id)) {
                        setBookedSeats([...bookedSeats, seat]);
                    } else {
                        setBookedSeats(bookedSeats.filter(item => item.id !== seat.id));
                    }
                }

            }}
        >
            <SeatSvg20
                color={
                    seat?.status === "BOOKED" ? BOOKED_SEAT_COLOR :
                        seat?.status === "LOCKED" ? LOCKED_SEAT_COLOR :
                            !seat?.status ? "#fff" :
                                (seat_status.length > 0 ? color : defaultColor)
                }
            />
            {/* <div className={`user-select-none position-absolute pb-2 fs-6 ${seat?.status === "BOOKED" || seat?.status === "LOCKED" ? "text-white" : ""}`}>
                {seat?.display_label}
                </div> */}
        </div>
    )
}

export default SeatDesign;