import axios from "axios";
import { PAYMENT_METHOD } from "Pages/Dashboard/Orders/SelectSeats";

function constructMessage(response) {
    let message = '';

    for (const [field, errors] of Object.entries(response)) {
        const fieldName = field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        errors?.forEach?.(error => {
            message += `${fieldName}: ${error}\n`;
        });
    }

    return message.trim(); // To remove the trailing newline character
}

export const fetchClientProgram = async (clientProgramId,) => {
    try {
        const response = await axios.get(`/program/rest/client-programs/${clientProgramId}/`);
        if (response) {
            console.log("Client Program Response", response);
            return response;
        }
    } catch (err) {
        console.log("Client Programs Error", err);
        return err;
    }
};

export const fetchShow = async (showId) => {
    try {
        const response = await axios.get(`/program/rest/shows/${showId}/`);
        if (response) {
            console.log("Show Response", response);
            return response;
        }
    } catch (err) {
        console.log("Show Error", err);
        return err;
    }
};

export const fetchShowSeats = async (showId) => {
    try {
        const response = await axios.post(`/program/rest/shows/${showId}/seats/`, { "channel_code": "WEB" });
        if (response) {
            console.log("Show seats Response", response);
            return response;
        }
    } catch (err) {
        console.log("Show seats Error", err);
        return err;
    }
};

export const fetchShowSeatTypes = async (showId) => {
    try {
        const response = await axios.post(`/program/rest/shows/${showId}/seat_types/`);
        if (response) {
            console.log("Seat types Response", response);
            return response;
        }
    } catch (err) {
        console.log("Seat types Error", err);
        return err;
    }
};

export const fetchActivePOSSessions = async (showId, openNotification) => {
    try {
        const response = await axios.post(`/theatre/rest/cashier_sessions/active_pos_sessions/`, { "show_id": showId });
        if (response) {
            console.log("Seat types Response", response);
            return response;
        }
    } catch (err) {
        console.log("Seat types Error", err);
        openNotification("Couldn't get active POS Sessions", "Error in fetching active sessions.", "error");
        return false;
    }
};

// export const fetchConvenienceFee = async (theatre_id, openNotification) => {
//     try {
//         const response = await axios.get(`/user/rest/channel-settings/convenience_fee/?theatre_id=${theatre_id}&channel_code=POS`);
//         if (response) {
//             console.log("Fetch Convenience Fee Response", response);
//             return response;
//         }
//     } catch (err) {
//         console.error(err);
//         if (err?.response?.data?.msg) {
//             openNotification("Error while fetching convenience fee", err?.response?.data?.msg, "error");
//         } else if (err?.response?.data && typeof err?.response?.data === "object") {
//             openNotification("Error while fetching convenience fee", constructMessage(err?.response?.data), "error");
//         } else {
//             openNotification("Error while fetching convenience fee", err?.message, "error");
//         }
//         return false;
//     }
// }


/////////////// -------- CATEGORY & FOOD -------- ///////////////

export const fetchCategories = async (theatreId) => {
    try {
        const response = await axios.get(`/food/categories/?theatre=${theatreId}`);
        if (response) {
            console.log("Category Response", response);
            return response;
        }
    } catch (err) {
        console.log("Category Error", err);
        return err;
    }
};

export const fetchFoodOfCategory = async (theatreId, categoryId) => {
    try {
        // let search_params = ""
        // if (categoryId !== "") {
        //   search_params += `?category=${categoryId}`
        // } else {
        //   search_params += `?category__theatre=${theatreId}`
        // }
        const response = await axios.post(`/food/foods/get_food_by_category/`, {
            theatre_id: theatreId,
            category: categoryId,
        });
        if (response) {
            console.log("Food Response", response);
            return response;
        }
    } catch (err) {
        console.log("Food Error", err);
        return err;
    }
};


export const getTaxes = async (theatreId, openNotification) => {
    try {
        const response = await axios.post(`/theatre/rest/theatretax/by_theatre/`, { "theatre_id": theatreId });
        if (response.status === 200) {
            console.log("Lock seats Response", response);
            return response.data;
        }
    } catch (err) {
        openNotification("Couldn't get taxes", "Some of the seats you selected are already booked! Please select different seats.", "error");
        return false;
    }
};


export const lockSeats = async (data, openNotification) => {
    try {
        const response = await axios.post(`/program/lock_seats/`, data);
        if (response.status === 200) {
            console.log("Lock seats Response", response);
            return true;
        }
    } catch (err) {
        openNotification("Seat lock failed!", "Some of the seats you selected are already booked! Please select different seats.", "error");
        return false;
    }
};

export const unlockSeats = async (data, openNotification) => {
    try {
        const response = await axios.post(`/program/unlock_seats/`, data);
        if (response.status === 200) {
            console.log("Lock seats Response", response);
            return true;
        }
    } catch (err) {
        openNotification("Seats Unlock Failed", "Some of the seats you selected are already booked! Please select different seats.", "error");
        return false;
    }
};


export const checkSeatCouponValidity = async (data, openNotification) => {
    try {
        const response = await axios.post(`/coupon/rest/seat-coupons/check_validity/`, data);
        if (response.status === 200) {
            openNotification("MUR " + response.data.value + " Discount applied!", null, "success");
            return response;
        }
    } catch (err) {
        if (Array.isArray(err?.response?.data?.msg)) {
            openNotification("Failed to apply coupon", err?.response?.data?.msg[0], "error");
        } else {
            openNotification("Failed to apply coupon", err?.response?.data?.msg, "error");
        }
        return err;
    }
};


export const checkFoodCouponValidity = async (data, openNotification) => {
    try {
        const response = await axios.post(`/coupon/rest/food-coupons/check_validity/`, data);
        if (response.status === 200) {
            openNotification("MUR " + response.data.value + " Discount applied!", null, "success");
            return response;
        }
    } catch (err) {
        if (Array.isArray(err?.response?.data?.msg)) {
            openNotification(null, err?.response?.data?.msg[0], "error");
        } else {
            openNotification(null, err?.response?.data?.msg || err?.message, "error");
        }
    }
};


export const createOrder = async (data, openNotification) => {
    try {
        const response = await axios.post(`/order/rest/orders/create_order/`, data);
        if (response.status === 200) {
            // openNotification("Order Created", null, "success");
            return response.data;
        }
    } catch (err) {
        if (Array.isArray(err?.response?.data?.msg)) {
            openNotification(null, err?.response?.data?.msg[0], "error");
        } else if (err?.response?.data && typeof err?.response?.data === "object") {
            openNotification(null, constructMessage(err?.response?.data), "error");
        } else {
            openNotification(null, err?.message, "error");
        }
    }
};

export const fetchConvenienceFee = async (theatre_id, openNotification) => {
    try {
        const response = await axios.get(`/user/rest/channel-settings/convenience_fee/?channel_code=POS&theatre_id=${theatre_id}`);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        if (err?.response?.data?.msg) {
            openNotification("Error while fetching convenience fee", err?.response?.data?.msg, "error");
        } else if (err?.response?.data && typeof err?.response?.data === "object") {
            openNotification("Error while fetching convenience fee", constructMessage(err?.response?.data), "error");
        } else {
            openNotification("Error while fetching convenience fee", err?.message, "error");
        }
    }
}

export const fetchTheatreURLFromShowId = async (show_id) => {
    try {
        const response = await axios.post(
            `/program/rest/shows/theatre_details/`, { show_id: show_id }
        );
        return response;
    } catch (er) {
        console.error(er);
        if (er?.response?.data) {
            return er.response.data;
        } else {
            return er?.message;
        }
    }
}

export const getPaymentInfo = async (order_id, payment_id, theatre_id) => {
    try {
        const response = await axios.post(
            `/order/rest/payments/get_cash_payment_info/`,
            { order_id, payment_id, theatre_id }
        );
        return response.data;
    } catch (er) {
        console.error(er);
        if (er?.response?.data) {
            return er.response.data;
        } else {
            return er?.message;
        }
    }
};

export const confirmCashPayment = async (payment_id, cash_credit, change_returned, theatre_id) => {
    try {
        const response = await axios.post(
            `/order/rest/payments/${payment_id}/confirm_cash_payment/`,
            { cash_credit, change_returned, theatre_id, payment_method: PAYMENT_METHOD.CASH }
        );
        return response.data;
    } catch (er) {
        console.error(er);
        if (er?.response?.data) {
            if (er.response.data?.msg) throw new Error(er.response.data.msg);
            throw new Error(er.response.data);
        } else {
            throw new Error(er?.message);
        }
    }
};