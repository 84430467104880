

import { Flex } from 'antd'
import React from 'react'
import { Navigate } from 'react-router'

const HomePage = () => {

  return <Navigate to={"/dashboard"} />

  return (
    <Flex vertical align='center' justify='center' style={{height: '100%'}}>
        <h1 >Cineboxx Admin Portal</h1>
        <p>Please, use navigation menus on left to navigate</p>
    </Flex>
  )
}

export default HomePage