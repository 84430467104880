import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount, getFormattedNumber } from 'Utils/textFormat';
import { Button, DatePicker, Flex, Table, Typography } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from './ExportXlsx';
import ExportPdf from './ExportPdf';
const { RangePicker } = DatePicker;
const { Text } = Typography;

const API_URL = "/reports/food-analysis-report/";

const tableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        align: "right",
        sorter: (a, b) => a.quantity - b.quantity,
    },
    {
        title: 'Type of Item',
        dataIndex: 'type',
        key: 'type',
        align: "center",
        sorter: (a, b) => a.type - b.type,
    },
    {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        defaultSortOrder: 'descend',
        key: 'totalAmount',
        align: "right",
        render: (text) => getFormattedAmount(text),
        sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
        title: 'Discount',
        dataIndex: 'discount',
        key: 'discount',
        align: "right",
        render: (text) => getFormattedAmount(text),
        sorter: (a, b) => a.discount - b.discount,
    },
]

const initialDates = dayjs();

const FoodAnalysisReport = () => {

    const [data, setData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['theatre'] = theatreFilterVal;
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data);
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Failed to fetch Food Analysis Report data.", ` ${e.response?.data?.message || e.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [startDate, endDate, theatreFilterVal]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        theatre: theatreFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'food_analysis_report'}
                    reportTitleForNotification={'Food Analysis'}
                />
                {/* <ExportPdf report_name='Food Analysis Report'/> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10} id='report-content'>
                <Flex justify='space-between' style={{ width: "100%" }}>
                    <ReportTitle title={`Food Analysis Report`} />
                    <Flex gap={15}>
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex>
                    <Table
                        columns={tableColumns}
                        bordered
                        dataSource={data}
                        size='small'
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        loading={loading}
                        scroll={{
                            y: "65vh",
                            x: "100%"
                        }}
                        pagination={false}
                        summary={pageData => {
                            let _totalQty = 0;
                            let _totalAmount = 0;
                            let _totalDiscount = 0;
                            console.log({ pageData })
                            pageData.forEach(({ totalAmount, discount, quantity }) => {
                                _totalAmount += totalAmount;
                                _totalQty += quantity;
                                _totalDiscount += discount;
                            });

                            if (!isNaN(_totalQty)){
                                _totalQty = _totalQty.toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 2})
                            }

                            return (
                                <>
                                    <Table.Summary.Row >
                                        <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell className='table-summary-cell' align='right'>
                                            {_totalQty}
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                        <Table.Summary.Cell className='table-summary-cell' align='right'>
                                            <Text>{getFormattedAmount(_totalAmount)}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell className='table-summary-cell' align='right'>
                                            <Text>{getFormattedAmount(_totalDiscount)}</Text>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    />
                </Flex>
            </Flex>
        </>
    )
}

export default FoodAnalysisReport