import { Flex } from 'antd'
import Title from 'antd/es/typography/Title';
import React from 'react';
import { dummyProfile } from 'Assets';

const IMAGE_BASE_URL_TMDB = "https://media.themoviedb.org/t/p/w66_and_h66_face/";

const CrewCastCard = ({data}) => {
  return (
    <Flex gap={10}>
        <Flex style={{borderRadius: 10, width: 66, height: 66}}>
            {
                data.profile_path ? 
                    <img src={IMAGE_BASE_URL_TMDB+data.profile_path} style={{borderRadius: "inherit"}}/> : 
                    <img src={dummyProfile} style={{borderRadius: "inherit", backgroundColor: "#dbdbdb"}}/>
            }
            
        </Flex>
        <Flex vertical align='start' justify='center'>
            <span><strong>{data.name}</strong></span>
            <span>{data.character || data.job}</span>
        </Flex>
    </Flex>
  )
}

export default CrewCastCard;