import { Routes, Route, Outlet } from "react-router-dom";

import CitiesPage from "Pages/Dashboard/Master/Cities/CitiesPage";
import DashboardLayout from "../Components/Layouts/Dashboard";
import GenresPage from "Pages/Dashboard/Master/Genres/GenresPage";
import CountriesPage from "Pages/Dashboard/Master/Countries/CountriesPage";
import ChannelsPage from "Pages/Dashboard/Master/Channels/ChannelsPage";
import LanguagesPage from "Pages/Dashboard/Master/Languages/LanguagesPage";
import ProgramTypesPage from "Pages/Dashboard/Clients/ProgramTypes/ProgramTypesPage";
import SettingsPage from "Pages/Dashboard/Clients/Settings/SettingsPage";
import ShowsPage from "Pages/Dashboard/Shows/ShowsPage";
import StatesPage from "Pages/Dashboard/Master/States/StatesPage";
// import TerritoriesPage from "Pages/Dashboard/Master/Territories/TerritoriesPage";
import FacilitiesPage from "Pages/Dashboard/Master/Facilities/FacilitiesPage";
import CertificationsPage from "Pages/Dashboard/Clients/Certifications/CertificationsPage";
import PageNotFound from "Pages/PageNotFound";
import UsersPage from "Pages/Dashboard/PlatformUsers/UsersPage";
import ClientPage from "Pages/Dashboard/Clients/ClientPage";
import DistributorsPage from "Pages/Dashboard/Distributors/DistributorsPage";
import TheatresPage from "Pages/Dashboard/Theatres/TheatresPage";
import TheatreUsersPage from "Pages/Dashboard/Theatres/TheatreUsersPage";
import TheatreHallsPage from "Pages/Dashboard/Theatres/TheatreHallsPage";
import TheatreChannellsPage from "Pages/Dashboard/Theatres/TheatreChannellsPage";

import VerifyTheatrePage from "Pages/Dashboard/Theatres/VerifyTheatrePage";
// import TheatreHallLayoutPage from "Pages/Dashboard/Theatres/Hall/BulkModeSingleMode/TheatreHallLayoutPage";
import TheatreHallLayoutPage from "Pages/Dashboard/Theatres/Hall/TheatreHallLayoutPage";
import TheatreSeatTypesPage from "Pages/Dashboard/Theatres/TheatreSeatTypesPage";
import AllTheatreUsersPage from "Pages/Dashboard/Theatres/AllTheatreUsersPage";
import AllTheatreChannelsPage from "Pages/Dashboard/Theatres/AllTheatreChannelsPage";
import AllTheatreHallsPage from "Pages/Dashboard/Theatres/AllTheatreHallsPage";
import AllClientSeatTypesPage from "Pages/Dashboard/Theatres/AllClientSeatTypesPage";
import AllTheatreSeatTypesPage from "Pages/Dashboard/Theatres/AllTheatreSeatTypesPage";
// import ProgramsPage from "Pages/Dashboard/Shows/ProgramsPage";
import ClientProgramsPage from "Pages/Dashboard/Shows/ClientProgramsPage";
import FoodPage from "Pages/Dashboard/Food/FoodPage";
import CategoriesPage from "Pages/Dashboard/Food/CategoriesPage";
// import DashboardPage from "Pages/Dashboard/DashboardPage";
import DashboardOutlet from "Pages/Dashboard/Reports/DashoardOutlet";
import RolesPage from "Pages/Dashboard/PlatformUsers/RolesPage";
import PermissionsPage from "Pages/Dashboard/PlatformUsers/PermissionsPage";
import { useSelector } from "react-redux";
import NoAccess from "./Access/NoAccess";
import AccessControl from "./Access/AccessControl";
import HomePage from "Pages/HomePage";

import TempCyberSource from "Pages/Dashboard/PG/TempCyberSource";
import TempAbsaSpark from "Pages/Dashboard/PG/TempAbsaSpark";
import FailurePage from "Pages/Payments/FailurePage";
import SuccessPage from "Pages/Payments/SuccessPage";
import OrderPage from "Pages/Dashboard/Orders/OrdersPage";
import FoodOrdersPage from "Pages/Dashboard/Orders/FoodOrdersPage";
import PaymentsPage from "Pages/Dashboard/Orders/PaymentsPage";
import RefundsPage from "Pages/Dashboard/Orders/RefundsPage";
import ShowPriceLayoutPage from "Pages/Dashboard/Shows/ShowPriceLayoutPage";
import FoodCouponsPage from "Pages/Dashboard/Coupons/FoodCouponsPage";
import SeatCouponsPage from "Pages/Dashboard/Coupons/SeatCouponsPage";
// import PaymentForm from "Pages/Dashboard/PG/PaymentForm";
import Hello from "Pages/Dashboard/PG/temp";
import AllTheatreTaxPage from "Pages/Dashboard/Theatres/AllTheatreTaxPage";
import TheatreTaxPage from "Pages/Dashboard/Theatres/TheatreTaxPage";
import SelectSeats from "Pages/Dashboard/Orders/SelectSeats";
import DashboardPage from "Pages/Dashboard/Reports/DashboardPage";
import MovieAnalysisReport from "Pages/Dashboard/Reports/MovieAnalysisReport";
import FoodAnalysisReport from "Pages/Dashboard/Reports/FoodAnalysisReport";
import HallSeatHeatmap from "Pages/Dashboard/Reports/HallSeatHeatmap";
import TheatrePerformanceComparisonReport from "Pages/Dashboard/Reports/TheatrePerformanceComparisonReport";
import TempMips from "Pages/Dashboard/PG/TempMips";
import PreSalesReport from "Pages/Dashboard/Reports/PreSalesReport";
import InventoryPage from "Pages/Dashboard/Food/InventoryPage";
import DailyPerformanceReport from "Pages/Dashboard/Reports/DailyPerformanceReport";
import DistributorReport from "Pages/Dashboard/Reports/DistributorReport";
import LoyaltyUsageDataReport from "Pages/Dashboard/Reports/LoyaltyUsageDataReport";
import UserOnboardingReport from "Pages/Dashboard/Reports/UserOnboardingReport";
import TheatreDevicesPage from "Pages/Dashboard/Theatres/TheatreDevicesPage";
import POSSessionsPage from "Pages/Dashboard/Theatres/POSSessionsPage";
import ClientSettingsPage from "Pages/Dashboard/Clients/Settings/ClientSettingsPage";
import TempMyT from "Pages/Dashboard/PG/TempMyT";
import AuditPage from "Pages/Audit/AuditPage";
import CashRegisterSeatAndFoodReport from "Pages/Dashboard/Reports/CashRegisterClosingReport/SeatsAndFood";
import CashRegisterFoodOnlyPosReport from "Pages/Dashboard/Reports/CashRegisterClosingReport/FoodOnlyPos";
import NotificationsPage from "Pages/Notifications/NotificationsPage";
import SalesReconciliationReport from "Pages/Dashboard/Reports/SalesReconcilliationReport";
import MoviePerHallReport from "Pages/Dashboard/Reports/MoviePerHall/MoviePerHallReport";
import FilmWiseReport from "Pages/Dashboard/Reports/FilmWise/FilmWiseReport";


const DashboardRoutes = () => {

    const user = useSelector(store => store.auth.user);

    return (
        <DashboardLayout>
            <Routes>
                <Route path="" element={<HomePage />} />
                <Route path="master" element={<Outlet />}>
                    <Route path="cities" element={
                        <AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["cities:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="cities:view" />
                            )}
                        >
                            <CitiesPage />
                        </AccessControl>} />
                    <Route path="genres" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["genres:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="genres:view" />
                        )}
                    >
                        <GenresPage />
                    </AccessControl>} />
                    <Route path="channels" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["channels:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="channels:view" />
                        )}
                    >
                        <ChannelsPage />
                    </AccessControl>} />
                    <Route path="languages" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["languages:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="languages:view" />
                        )}
                    >
                        <LanguagesPage />
                    </AccessControl>} />
                    <Route path="facilities" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["facilities:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="facilities:view" />
                        )}
                    >
                        <FacilitiesPage />
                    </AccessControl>} />
                    <Route path="states" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["states:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="states:view" />
                        )}
                    >
                        <StatesPage />
                    </AccessControl>} />
                    <Route path="countries" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["countries:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="countries:view" />
                        )}
                    >
                        <CountriesPage />
                    </AccessControl>} />
                    {/* <Route path="territories" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["territories:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="territories:view" />
                        )}
                    >
                        <TerritoriesPage />
                    </AccessControl>} /> */}
                </Route>
                <Route path="book-show/:movieId/seats/:showId" element={<SelectSeats />}> </Route>
                <Route path="shows" element={<Outlet />} >
                    <Route path="" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["shows:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="shows:view" />
                        )}
                    >
                        <ShowsPage />
                    </AccessControl>} />
                    <Route path="programs" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["programs:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="programs:view" />
                        )}
                    >
                        <ClientProgramsPage />
                    </AccessControl>} />
                    <Route path=":showId/pricelayout"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["shows:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="shows:view" />
                            )}
                        >
                            <ShowPriceLayoutPage />
                        </AccessControl>}
                    />
                </Route>
                <Route path="order" element={<Outlet />} >
                    <Route path="orders" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["order:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="order:view" />
                        )}
                    >
                        <OrderPage />
                    </AccessControl>} />
                    <Route path="food-orders" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["order:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="order:view" />
                        )}
                    >
                        <FoodOrdersPage />
                    </AccessControl>} />
                    <Route path="payments" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["order:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="order:view" />
                        )}
                    >
                        <PaymentsPage />
                    </AccessControl>} />
                    <Route path="refunds" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["order:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="order:view" />
                        )}
                    >
                        <RefundsPage />
                    </AccessControl>} />
                </Route>
                <Route path="food" element={<Outlet />} >
                    <Route path="food" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["food:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="food:view" />
                        )}
                    >
                        <FoodPage />
                    </AccessControl>} />
                    {/* <Route path="inventories" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["inventory:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="inventory:view" />
                        )}
                    >
                        <InventoryPage />
                    </AccessControl>} /> */}
                    <Route path="categories" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["food_categories:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="food_categories:view" />
                        )}
                    >
                        <CategoriesPage />
                    </AccessControl>} />
                </Route>
                <Route path="coupon" element={<Outlet />} >
                    <Route path="food" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["food_coupons:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="food_coupons:view" />
                        )}
                    >
                        <FoodCouponsPage />
                    </AccessControl>} />
                    <Route path="seat" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["seat_coupons:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="seat_coupons:view" />
                        )}
                    >
                        <SeatCouponsPage />
                    </AccessControl>} />
                </Route>
                <Route path="clients" element={<Outlet />}>
                    <Route path="clients"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["clients:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="clients:view" />
                            )}
                        >
                            <ClientPage />
                        </AccessControl>}
                    />
                    <Route path="distributors"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["distributors:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="distributors:view" />
                            )}
                        >
                            <DistributorsPage />
                        </AccessControl>}
                    />
                    <Route path="seat-types"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["seat-type:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="seat-type:view" />
                            )}
                        >
                            <AllClientSeatTypesPage />
                        </AccessControl>}
                    />
                    <Route path="certifications" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["certifications:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="certifications:view" />
                        )}
                    >
                        <CertificationsPage />
                    </AccessControl>} />
                    <Route path="program-types" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["program-types:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="program-types:view" />
                        )}
                    >
                        <ProgramTypesPage />
                    </AccessControl>} />
                    <Route path="audit"
                        element={
                            <AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["dashboard:view"]}
                                renderNoAccess={
                                    () => {
                                        <NoAccess permissionNeeded="dashboard:view" />
                                    }
                                }
                            >
                                <AuditPage />
                            </AccessControl>
                        } />
                    {/* <Route path="settings" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["settings:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="settings:view" />
                        )}
                    >
                        <SettingsPage />
                    </AccessControl>} /> */}
                    <Route path=":id" element={<Outlet />}>
                        <Route path="" element={<PageNotFound />} />
                        <Route path="settings"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["settings:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="settings:view" />
                                )}
                            >
                                <ClientSettingsPage />
                            </AccessControl>}
                        />
                    </Route>
                </Route>
                <Route path="platform-users" element={<Outlet />}>
                    <Route path="users"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["users:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="users:view" />
                            )}
                        >
                            <UsersPage />
                        </AccessControl>}
                    />
                    <Route path="roles"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["roles:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="roles:view" />
                            )}
                        >
                            <RolesPage />
                        </AccessControl>}
                    />
                    <Route path="permissions"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["permissions:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="permissions:view" />
                            )}
                        >
                            <PermissionsPage />
                        </AccessControl>}
                    />
                </Route>
                <Route path="theatres" element={<Outlet />}>
                    <Route path=""
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["theatre:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="theatre:view" />
                            )}
                        >
                            <TheatresPage />
                        </AccessControl>}
                    />
                    <Route path="users"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["theatreuser:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="theatreuser:view" />
                            )}
                        >
                            <AllTheatreUsersPage />
                        </AccessControl>}
                    />
                    <Route path="channels"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["theatrechannel:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="theatrechannel:view" />
                            )}
                        >
                            <AllTheatreChannelsPage />
                        </AccessControl>}
                    />
                    <Route path="halls"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["theatrehall:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="theatrehall:view" />
                            )}
                        >
                            <AllTheatreHallsPage />
                        </AccessControl>}
                    />
                    <Route path="seat-types"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["seat-type:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="seat-type:view" />
                            )}
                        >
                            <AllTheatreSeatTypesPage />
                        </AccessControl>}
                    />
                    <Route path="tax"
                        element={<AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={["theatretax:view"]}
                            renderNoAccess={() => (
                                <NoAccess permissionNeeded="theatretax:view" />
                            )}
                        >
                            <AllTheatreTaxPage />
                        </AccessControl>}
                    />
                    <Route path=":id" element={<Outlet />}>
                        <Route path="" element={<VerifyTheatrePage />} />
                        <Route path="users"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["theatreuser:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="theatreuser:view" />
                                )}
                            >
                                <VerifyTheatrePage ><TheatreUsersPage /></VerifyTheatrePage>
                            </AccessControl>}
                        />
                        <Route path="tax"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["theatretax:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="theatretax:view" />
                                )}
                            >
                                <VerifyTheatrePage ><TheatreTaxPage /></VerifyTheatrePage>
                            </AccessControl>}
                        />
                        <Route path="halls" element={<Outlet />}>
                            <Route path=""
                                element={<AccessControl
                                    userPermissions={user.user_permissions}
                                    allowedPermissions={["theatrehall:view"]}
                                    renderNoAccess={() => (
                                        <NoAccess permissionNeeded="theatrehall:view" />
                                    )}
                                >
                                    <VerifyTheatrePage ><TheatreHallsPage /></VerifyTheatrePage>
                                </AccessControl>}
                            />
                            <Route path=":hall_id/layout"
                                element={<AccessControl
                                    userPermissions={user.user_permissions}
                                    allowedPermissions={["theatrehall:view"]}
                                    renderNoAccess={() => (
                                        <NoAccess permissionNeeded="theatrehall:view" />
                                    )}
                                >
                                    <TheatreHallLayoutPage />
                                </AccessControl>}
                            />
                        </Route>
                        <Route path="channels"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["theatrechannel:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="theatrechannel:view" />
                                )}
                            >
                                <VerifyTheatrePage >
                                    <TheatreChannellsPage />
                                </VerifyTheatrePage>
                            </AccessControl>}
                        />
                        <Route path="seat-types"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["seat-type:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="seat-type:view" />
                                )}
                            >
                                <VerifyTheatrePage >
                                    <TheatreSeatTypesPage />
                                </VerifyTheatrePage>
                            </AccessControl>}
                        />
                        <Route path="devices"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["theatredevices:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="theatredevices:view" />
                                )}
                            >
                                <VerifyTheatrePage ><TheatreDevicesPage /></VerifyTheatrePage>
                            </AccessControl>}
                        />
                        <Route path="pos_sessions"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["possessions:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="possessions:view" />
                                )}
                            >
                                <VerifyTheatrePage ><POSSessionsPage /></VerifyTheatrePage>
                            </AccessControl>}
                        />
                        <Route path="inventories"
                            element={<AccessControl
                                userPermissions={user.user_permissions}
                                allowedPermissions={["inventory:view"]}
                                renderNoAccess={() => (
                                    <NoAccess permissionNeeded="inventory:view" />
                                )}
                            >
                                <VerifyTheatrePage ><InventoryPage /></VerifyTheatrePage>
                            </AccessControl>}
                        />
                    </Route>
                </Route>
                <Route path="notifications"
                    element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["notifications:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="notifications:view" />
                        )}
                    >
                        <NotificationsPage />
                    </AccessControl>}>
                </Route>
                <Route path="dashboard"
                    element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["dashboard:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="dashboard:view" />
                        )}
                    >
                        <DashboardOutlet />
                    </AccessControl>}>
                    <Route path="" element={<DashboardPage />} />
                </Route>
                <Route path="reports" element={<Outlet />}>
                    <Route path="movie-analysis" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_movie_analysis:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_movie_analysis:view" />
                        )}
                    >
                        <MovieAnalysisReport />
                    </AccessControl>} />
                    <Route path="food-analysis" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_food_analysis:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_food_analysis:view" />
                        )}
                    >
                        <FoodAnalysisReport />
                    </AccessControl>} />
                    <Route path="hall-seat-heatmap" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_hall_seat_heatmap:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_hall_seat_heatmap:view" />
                        )}
                    >
                        <HallSeatHeatmap />
                    </AccessControl>} />
                    <Route path="theatre-performance-comparison-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_theatre_performance_comparison:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_theatre_performance_comparison:view" />
                        )}
                    >
                        <TheatrePerformanceComparisonReport />
                    </AccessControl>} />
                    <Route path="pre-sales-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_pre_sales:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_pre_sales:view" />
                        )}
                    >
                        <PreSalesReport />
                    </AccessControl>} />
                    <Route path="daily-performance-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_daily_performance:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_daily_performance:view" />
                        )}
                    >
                        <DailyPerformanceReport />
                    </AccessControl>} />
                    <Route path="distributor-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_distributor:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_distributor:view" />
                        )}
                    >
                        <DistributorReport />
                    </AccessControl>} />
                    <Route path="loyalty-usage-data-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_user_onboarding:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_user_onboarding:view" />
                        )}
                    >
                        <LoyaltyUsageDataReport />
                    </AccessControl>} />
                    <Route path="sales-reconciliation-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_loyalty_usage_data:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_loyalty_usage_data:view" />
                        )}
                    >
                        <SalesReconciliationReport />
                    </AccessControl>} />
                    <Route path="user-onboarding-report" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_sales_reconciliation:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_sales_reconciliation:view" />
                        )}
                    >
                        <UserOnboardingReport />
                    </AccessControl>} />
                    <Route path="cash-register-seat-and-food" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_cash_register_seat_and_food:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_cash_register_seat_and_food:view" />
                        )}
                    >
                        <CashRegisterSeatAndFoodReport />
                    </AccessControl>} />
                    <Route path="cash-register-food-only-pos" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_cash_register_food_only_pos:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_cash_register_food_only_pos:view" />
                        )}
                    >
                        <CashRegisterFoodOnlyPosReport />
                    </AccessControl>} />
                    <Route path="movie-per-hall" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_movie_per_hall:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_movie_per_hall:view" />
                        )}
                    >
                        <MoviePerHallReport />
                    </AccessControl>} />
                    <Route path="film-wise" element={<AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["reports_film_wise:view"]}
                        renderNoAccess={() => (
                            <NoAccess permissionNeeded="reports_film_wise:view" />
                        )}
                    >
                        <FilmWiseReport />
                    </AccessControl>} />
                </Route>
                <Route path="temp_payment_cybersource" element={<AccessControl
                    userPermissions={user.user_permissions}
                    allowedPermissions={["developer:view"]}
                    renderNoAccess={() => (
                        <NoAccess permissionNeeded="developer:view" />
                    )}
                >
                    <TempCyberSource />
                </AccessControl>} />
                <Route path="temp_payment_absa_spark" element={<AccessControl
                    userPermissions={user.user_permissions}
                    allowedPermissions={["developer:view"]}
                    renderNoAccess={() => (
                        <NoAccess permissionNeeded="developer:view" />
                    )}
                >
                    <TempAbsaSpark />
                </AccessControl>} />
                <Route path="temp_payment_mips" element={<AccessControl
                    userPermissions={user.user_permissions}
                    allowedPermissions={["developer:view"]}
                    renderNoAccess={() => (
                        <NoAccess permissionNeeded="developer:view" />
                    )}
                >
                    <TempMips />
                </AccessControl>} />
                <Route path="temp_payment_myt" element={<AccessControl
                    userPermissions={user.user_permissions}
                    allowedPermissions={["developer:view"]}
                    renderNoAccess={() => (
                        <NoAccess permissionNeeded="developer:view" />
                    )}
                >
                    <TempMyT />
                </AccessControl>} />
                <Route path="temp" element={<AccessControl
                    userPermissions={user.user_permissions}
                    allowedPermissions={["developer:view"]}
                    renderNoAccess={() => (
                        <NoAccess permissionNeeded="developer:view" />
                    )}
                >
                    <Hello />
                </AccessControl>} />
                {/* <Route path="pf" element={<AccessControl
                    userPermissions={user.user_permissions}
                    allowedPermissions={["developer:view"]}
                    renderNoAccess={() => (
                        <NoAccess permissionNeeded="developer:view" />
                    )}
                >
                    <PaymentForm />
                </AccessControl>} /> */}
                <Route path="payment/status" element={<Outlet />}>
                    <Route path="failure" element={<FailurePage />} />
                    <Route path="success" element={<SuccessPage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </DashboardLayout>
    );
}

export default DashboardRoutes;