import { useNotificationContext } from 'Config/NotificationsContext';
import { FormModesEnum } from 'Redux/enums';
import { Flex, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { HiExclamationTriangle } from "react-icons/hi2";
import { useSelector } from 'react-redux';


export const ALL_AVAILABLE_VALUES = {
    CALCULATING: "CALCULATING",
    API_ERROR: "API_ERROR",
    ALL_PRESENT: "ALL_PRESENT",
    DATA_NOT_AVAILABLE: "DATA_NOT_AVAILABLE"
}

const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
} 

const getFieldNamesString = (fn=[]) => {
    let string = "";
    const len = fn.length;

    fn.forEach((f, i) => {
        if (i === len-1 && len > 1) {
            string += " and " + capitalize(f);
        } else if (i > 0 ) {
            string += ", " + capitalize(f);
        } else {
            string += capitalize(f);
        }
    });

    return string;
}


const CheckIfPrequisitesAreAvailable = ({
    children,
    thisModel = "",
    requiredData = []
}) => {

    const currentFormMode = useSelector(store => store.master?.currentFormMode);

    const [allAvailable, setAllAvailable] = useState(ALL_AVAILABLE_VALUES.CALCULATING);
    const [fieldNames, setFieldNames] = useState([]);

    useEffect(() => {
        setFieldNames([]);
        const determineAllAvailable = () => {
            if (currentFormMode !== FormModesEnum.ADD) {
                setAllAvailable(ALL_AVAILABLE_VALUES.ALL_PRESENT);
            }

            if (Array.isArray(requiredData) && requiredData.length === 0) {
                setAllAvailable(ALL_AVAILABLE_VALUES.ALL_PRESENT);
            }
            else if (Array.isArray(requiredData)) {

                let nulls = requiredData.filter(v => v?.data === null);
                if (nulls.length > 0) {
                    setAllAvailable(ALL_AVAILABLE_VALUES.CALCULATING);
                    return;
                }

                let errors = requiredData.filter(v => v?.data === ALL_AVAILABLE_VALUES.API_ERROR);
                if (errors.length > 0) {
                    setAllAvailable(ALL_AVAILABLE_VALUES.API_ERROR);
                    for (let d of requiredData){
                        let data = d.data;
                        if (data === ALL_AVAILABLE_VALUES.API_ERROR){
                            setFieldNames(prev => [...prev, d.field]);
                        }
                    }
                    return;
                }

                let lengthsOfAll = requiredData.map(v => v?.data?.length);
                let zeroDataFields = lengthsOfAll.filter(v => v === 0);

                if (zeroDataFields.length > 0) {
                    setAllAvailable(ALL_AVAILABLE_VALUES.DATA_NOT_AVAILABLE);
                    for (let d of requiredData){
                        let data = d.data?.length;
                        if (data === 0){
                            setFieldNames(prev => [...prev, d.field]);
                        }
                    }
                    return;
                }

                setAllAvailable(ALL_AVAILABLE_VALUES.ALL_PRESENT);
            }
        }
        determineAllAvailable();
    }, [requiredData]);

    if (allAvailable === ALL_AVAILABLE_VALUES.ALL_PRESENT) {
        return (
            <>
                {children}
            </>
        )
    }

    const getUI = () => {
        let UI = null;

        if (allAvailable === ALL_AVAILABLE_VALUES.CALCULATING) {
            UI = <Flex vertical justify='center' align='center' gap={10}>
                <Spin />
                <small style={{ textAlign: "center" }}>Fetching data ...</small>
            </Flex>;
        } else if (allAvailable === ALL_AVAILABLE_VALUES.DATA_NOT_AVAILABLE) {
            UI = <Flex vertical justify='center' align='center' gap={10}>
                <HiExclamationTriangle size={50} />
                <p style={{ textAlign: "center" }}>
                    {thisModel} are not available.
                    <br />
                    You need to add {getFieldNamesString(fieldNames)} to add {thisModel}.
                </p>
                <p style={{ textAlign: "center" }}></p>
            </Flex>
        } else if (allAvailable === ALL_AVAILABLE_VALUES.API_ERROR) {
            UI = <Flex vertical justify='center' align='center' gap={10}>
                <small style={{ textAlign: "center" }}>Unable to determine if pre-requisite data is available.</small>
            </Flex>
        } else if (allAvailable === ALL_AVAILABLE_VALUES.ALL_PRESENT) {
            UI = <Flex vertical justify='center' align='center' gap={10}>
                <small style={{ textAlign: "center" }}>You can add data.</small>
            </Flex>
        }

        return UI;
    }

    return (
        <>
            <Flex justify='center' align='center' style={{ width: "100%", height: "100%" }}>
                {getUI()}
            </Flex>
        </>
    );

}

export default CheckIfPrequisitesAreAvailable;