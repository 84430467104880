import React from 'react'

const Hello = () => {

    const doStuff = () => {
        const formPromise = window.fiserv.commercehub.createPaymentForm({
            data: {
                environment: "CERT",
                supportedCardBrands: [],
                fields: {},
                contextualCssClassNames: {
                    valid: "validCssClass",
                    invalid: "invalidCssClass",
                },
                font: {},
                css: {},
            },
            hooks: {},
        });

        console.log(formPromise)
    }

    return (
        <div>
            <iframe 
                src='https://serveo.serveo.net/temp'
                novalidate="novalidate"
                name="myIframe" 
                width="1000"
                height="600">
            </iframe>

            <div span={12} style={{ display: "flex", justifyContent: "center" }}>
                <button type='submit' onClick={doStuff}>commerce hub</button>
            </div>
        </div>
    )
}

export default Hello