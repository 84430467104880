

import { START_TIME_FORMAT, addAShowTime, removeAShowTime } from 'Redux/ShowReducer/showSlices';
import { Tag, notification } from 'antd'
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TimeTagTooltip from './TimeTagTooltip';

const SELECTED_TAG_COLOR = '#108ee9';
const NOT_SELECTED_TAG_COLOR = 'geekblue';
const DISABLED_TAG_COLOR = 'gray';

const getShowsOfDate = ({ allShowsData, selectedDate }) => {
  let keysInShowsData = Object.keys(allShowsData);
  if (keysInShowsData.length > 0) {
    if (keysInShowsData.includes(selectedDate)) {
      let showsOfThisDate = JSON.parse(JSON.stringify(allShowsData[selectedDate]));
      return showsOfThisDate;
    }
  }
  return null;
}

const findIndexOfThisShowInShowsData = ({ allShowsData, selectedDate, hall, time, selectedProgramData }) => {
  try {
    let showsOfThisDate = getShowsOfDate({ selectedDate, allShowsData });

    if (!showsOfThisDate) {
      return [-1, null];
    }

    let show = {}
    let index = showsOfThisDate.findIndex(a => {
      if (
        a.hall_id === hall.id
        && a.time === time
        && a.date === selectedDate
        && a.client_program_id === selectedProgramData.id
      ) {
        show = { ...a };
        return true;
      } else {
        return false;
      }
    });
    return [index, show];

  } catch (err) {
    console.error(err);
    return [-1, null];
  }
}

const getShowsOfAHallOnADate = ({ allShowsData, selectedDate, hall }) => {
  if (selectedDate in allShowsData) {
    let allShowsOfThisDate = allShowsData[selectedDate];
    let showsOfThisHall = allShowsOfThisDate?.filter?.(show => show.hall_id === hall.id);
    showsOfThisHall.sort((a, b) => a.time < b.time ? -1 : 1);

    return showsOfThisHall;
  } else {
    return [];
  }
}

const shouldThisTimeBeDisabled = ({ time = "", lastTagTime="", allShowsData = {}, selectedDate = "", hall = {}, selectedProgramData = {} }) => {

  if (!time || !lastTagTime || typeof allShowsData !== "object" || !selectedDate || typeof hall !== "object" || typeof selectedProgramData !== "object") {
    console.error("Invalid Data", { time, lastTagTime, allShowsData, selectedDate, hall });
    return [false, null];
  }

  let showsOfThisHall = getShowsOfAHallOnADate({ allShowsData, selectedDate, hall });

  if (showsOfThisHall.length === 0) {
    return [false, null];
  } else {
    let thisTagTime = dayjs(time, START_TIME_FORMAT);
    let _lastTagTime = dayjs(lastTagTime, START_TIME_FORMAT);

    let shouldBeDisabled = false;
    let _show = null;
    for (let show of showsOfThisHall) {
      let show_time = dayjs(show.time, START_TIME_FORMAT);
      let thisShowEndTime = show_time.add(show.runtime, 'minutes');
      if (thisTagTime >= show_time && (thisTagTime <= thisShowEndTime)) {
        // console.table({thisTagTime: thisTagTime.toString(), show_time: show_time.toString(), thisShowEndTime: thisShowEndTime.toString(), _lastTagTime: _lastTagTime.toString()})
        shouldBeDisabled = true;
        _show = show;
        break;
      }
    }
    return [shouldBeDisabled, _show];
  }
}

const isThisTimeBooked = ({ selectedDate, allShowsData, hall, time, selectedProgramData }) => {
  let [indexOfThisShowInShowsData] = findIndexOfThisShowInShowsData({ selectedDate, allShowsData, hall, time, selectedProgramData });
  if (indexOfThisShowInShowsData !== -1) {
    return [true, indexOfThisShowInShowsData];
  } else {
    return [false, indexOfThisShowInShowsData];
  }
}

const findIfRuntimeIsFeasible = ({ time = "", allShowsData = {}, selectedDate = "", hall = {}, selectedProgramData = {} }) => {
  // need to check if there is any show which is before the runtime

  if (!time || typeof allShowsData !== "object" || !selectedDate || typeof hall !== "object" || typeof selectedProgramData !== "object") {
    console.error("Invalid Data", { time, allShowsData, selectedDate, hall });
    return false;
  }

  let showsOfThisHall = getShowsOfAHallOnADate({ allShowsData, selectedDate, hall });

  if (showsOfThisHall.length === 0) {
    return [true, null];
  }

  let new_show_time_start = dayjs(time, START_TIME_FORMAT);
  let new_show_time_end = new_show_time_start.add(selectedProgramData.runtime, 'minute');

  let runtimeFeasible = true;
  let error = null;
  for (let show of showsOfThisHall) {
    let show_time_start = dayjs(show.time, START_TIME_FORMAT);
    let show_time_end = show_time_start.add(show.runtime, 'minute');

    let isFeasible = true;
    if (new_show_time_start < show_time_start) {
      if (new_show_time_end >= show_time_start) {
        isFeasible = false;
      }
    } else if (new_show_time_start === show_time_start) {
      isFeasible = false;
    } else if (new_show_time_start > show_time_start) {
      if (new_show_time_start <= show_time_end) {
        isFeasible = false;
      }
    }

    if (!isFeasible) {
      runtimeFeasible = false;
      error = "Runtime of this movie does not allow for this slot to be booked."
      break;
    }
  }

  return [runtimeFeasible, error];
}

const TimeTag = ({ time, hall, lastTagTime }) => {

  const dispatch = useDispatch();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [color, setColor] = useState(NOT_SELECTED_TAG_COLOR);
  const [thisShow, setThisShow ] = useState(null);
  const allShowsData = useSelector(store => store.shows.allShowsData);
  const selectedDate = useSelector(store => store.shows.selectedDate);
  const selectedProgramData = useSelector(store => store.shows.selectedProgramData);
  const showsDataUpdatesCounter = useSelector(store => store.shows.showsDataUpdatesCounter);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  useEffect(() => {
    // to update color of tag
    let [indexOfThisShow, show] = findIndexOfThisShowInShowsData({ selectedDate, allShowsData, hall, time, selectedProgramData });
    let [shouldBeDisabled, _show] = shouldThisTimeBeDisabled({ time, lastTagTime, hall, selectedDate, selectedProgramData, allShowsData });
    if (shouldBeDisabled) {
      let [thisTimeIsBooked, indexOfThisShow] = isThisTimeBooked({ selectedDate, allShowsData, hall, time, selectedProgramData });
      if (!thisTimeIsBooked) {
        setColor(DISABLED_TAG_COLOR);
      } else {
        if ("id" in _show) {
          setColor(DISABLED_TAG_COLOR);
        } else {
          setColor(SELECTED_TAG_COLOR);
        }
      }      
      setThisShow(_show);
    } else {
      if (indexOfThisShow === -1) {
        setColor(NOT_SELECTED_TAG_COLOR);
        setThisShow(null);
      } else {
        if ("id" in show) { // to not allow editing if it is already stored in database
          setColor(DISABLED_TAG_COLOR);
        } else {
          setColor(SELECTED_TAG_COLOR);
        }
        setThisShow(show);
      }
    }
  }, [allShowsData, showsDataUpdatesCounter]);

  const addShow = () => {
    dispatch(addAShowTime({ hall_id: hall.id, time: time, date: selectedDate, client_program_id: selectedProgramData?.id, runtime: selectedProgramData?.runtime, client_program_title: selectedProgramData?.title }));
  }

  const removeShow = (index) => {
    dispatch(removeAShowTime({ date: selectedDate, index }))
  }

  const onTagClick = () => {
    let [thisTimeIsBooked, indexOfThisShow] = isThisTimeBooked({ selectedDate, allShowsData, hall, time, selectedProgramData });
    if (thisTimeIsBooked) {
      removeShow(indexOfThisShow);
    } else {
      let [isRuntimeFeasibleHere, error] = findIfRuntimeIsFeasible({ time, selectedProgramData, allShowsData, selectedDate, hall });
      if (isRuntimeFeasibleHere) {
        addShow();
      } else {
        openNotification("Error", error, "error");
      }
    }
  }

  return (<>
    {contextHolder}
    {
      color === NOT_SELECTED_TAG_COLOR
        ?
        <Tag
          style={{ margin: 0, cursor: "pointer", width: 47 }}
          color={color}
          onClick={onTagClick}
        >
          {time}
        </Tag>
        :
        <TimeTagTooltip thisShow={thisShow}>
          <Tag
            style={{ margin: 0, cursor: color === DISABLED_TAG_COLOR ? "not-allowed" : "pointer", width: 47 }}
            color={color}
            onClick={color === DISABLED_TAG_COLOR ? null : onTagClick}
          >
            {time}
          </Tag>
        </TimeTagTooltip>
    }

  </>
  );
}

export default TimeTag