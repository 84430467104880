import React, { useEffect, useState } from 'react'
import { Button, Drawer, Flex, Tabs, notification, DatePicker, Space } from 'antd'
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { changeHeaderTitle } from 'Redux/UtilitiesReducer/utilitySlice';
import { useNotificationContext } from 'Config/NotificationsContext';
import ErrorBoundary from 'Components/ErrorBoundary';
import PieChart from 'Components/Charts/PieChart';
import GaugeChart from 'Components/Charts/GaugeChart';
import TopSalesComponent from 'Components/Dashboard/DashboardPage/TopSalesComponent';
import AtAGlance from 'Components/Dashboard/AtAGlance';
const { RangePicker } = DatePicker;

const defaultPieData = [
  { type: 'Box Office', value: 27 },
  { type: 'Food', value: 25 },
  { type: 'Pre-Sale', value: 18 },
];

const defaultGaugeData = {
  target: 120,
  total: 400,
  name: 'score',
  thresholds: [100, 200, 400],
};

const commonBorderStyle = {
  border: "1px solid gray",
  borderRadius: 5,
};

const initialDates = dayjs();

const DashboardPage = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(initialDates);
  const [endDate, setEndDate] = useState(initialDates);
  const openNotification = useNotificationContext();

  const modifyDates = (value) => {
    console.log({ value });
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  useEffect(() => {
    dispatch(changeHeaderTitle("Dashboard"));

    return () => dispatch(changeHeaderTitle("Cineboxx"));
  }, [dispatch]);

  return (
    <>
      <ErrorBoundary>
        <div style={{ height: "100%", width: "100%", overflow: "hidden", paddingLeft: 10, position: "relative" }}>
          <Space direction='vertical' style={{ height: "95%", width: "100%", overflow: "auto", paddingBottom: 30 }}>
            <AtAGlance openNotification={openNotification} />
            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              <GaugeChart
                defaultData={defaultGaugeData}
                title={`Occupancy`}
                style={commonBorderStyle}
                openNotification={openNotification}
                url={`reports/occupancy-data/`}
              />
              <TopSalesComponent openNotification={openNotification} style={{ ...commonBorderStyle, flex: 1 }} />
            </div>

            <Space
              direction='vertical'
              style={{
                border: "0px solid",
                borderRadius: 0,
                transition: "all 0.3s ease",
              }}
            >
              <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                <RangePicker defaultValue={[initialDates, initialDates]} onChange={modifyDates} />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", width: "100%" }}>
                <PieChart
                  defaultData={defaultPieData}
                  openNotification={openNotification}
                  title="Sales"
                  url={`/reports/sales-data/`}
                  payload={{ start_date: startDate.format("YYYY-MM-DD"), end_date: endDate.format("YYYY-MM-DD") }}
                />
                <PieChart
                  defaultData={defaultPieData}
                  openNotification={openNotification}
                  title="Transactions"
                  url={`/reports/transactions-data/`}
                  payload={{ start_date: startDate.format("YYYY-MM-DD"), end_date: endDate.format("YYYY-MM-DD") }}
                />
                <PieChart
                  defaultData={defaultPieData}
                  openNotification={openNotification}
                  title="Payment Channels"
                  url={`/reports/payment-channels-data/`}
                  payload={{ start_date: startDate.format("YYYY-MM-DD"), end_date: endDate.format("YYYY-MM-DD") }}
                />
                <PieChart
                  defaultData={defaultPieData}
                  openNotification={openNotification}
                  title="Sales Channels"
                  url={`/reports/sales-channels-data/`}
                  payload={{ start_date: startDate.format("YYYY-MM-DD"), end_date: endDate.format("YYYY-MM-DD") }}
                />
              </div>
            </Space>
          </Space>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default DashboardPage;
