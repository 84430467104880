import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Master/Facilities/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/master/rest/facilities";
const headerTitle = "Facilities";
const editPermission = "facilities:edit";
const deletePermission = "facilities:delete";
const addPermission = "facilities:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const FacilitiesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default FacilitiesPage;