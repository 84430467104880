import { fetchModelNames, fetchUserList } from 'Api/commonApis';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { Flex, Input, Select, Table, Button, Space, Spin, Tooltip, } from 'antd';
import Text from 'antd/es/typography/Text';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import lodash from "lodash";
import Title from 'antd/es/typography/Title';
import moment from 'moment-timezone';

const columns = [
    {
        title: '#',
        key: 'index',
        width: "80px",
        render: (text, record, index) => index + 1,
    },
    {
        title: 'Model name',
        dataIndex: 'model_name',
        key: 'model_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: "Record",
        dataIndex: "model_id",
        key: "model_id",
        render: (text, row) => {
            if (row?.id_str) {
                return <Tooltip align="center" placement='top' title={text}>
                    {row?.id_str}
                </Tooltip>
            } else {
                return text;
            }
        }
    },
    {
        title: 'User',
        dataIndex: 'history_user',
        key: 'history_user',
        render: (text, row) => <Space direction="vertical" style={{ gap: 0 }}>
            <span>{row?.history_user?.first_name} {row?.history_user?.last_name}</span>
            <span>{row?.updated_at ? moment(row.updated_at).format("DD MMM yyyy, hh:mm a") : ""}</span>
        </Space>
    },
    // ...getCommonColumns({created: false, updated: true, action: false})
]

const initialDates = dayjs();

const AuditPage = () => {

    const [data, setData] = useState([]);
    const dataRef = useRef([]);
    const [paginationData, setPaginationData] = useState(null);
    const paginationDataRef = useRef(null);

    const abortControllerRef = useRef(null);

    const [modelNameOptions, setModelNameOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const [searchVal, setSearchVal] = useState("");
    const searchValRef = useRef(null);

    const [filteredModelNames, setFilteredModelNames] = useState([]);
    const filteredModelNamesRef = useRef([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const filteredUsersRef = useRef([]);

    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {

        const handleScroll = (e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= (e.target.clientHeight);
            // console.log({ bottom, scrollHeight: e.target.scrollHeight, scrollTop: e.target.scrollTop, clientHeight: e.target.clientHeight, minus: (e.target.scrollHeight - e.target.scrollTop) })
            if (bottom) {
                fetchData(1, true)
            }
        };

        const antTableBody = document.querySelector('.ant-table-body');
        if (antTableBody) {
            antTableBody.addEventListener('scroll', handleScroll);
        }

        return () => antTableBody.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const fetchModelNameOptions = async () => {
            try {
                const response = await fetchModelNames();
                setModelNameOptions(response);
            } catch (err) {
                setModelNameOptions([]);
            }
        }

        const fetchUsers = async () => {
            try {
                const response = await fetchUserList();
                setUserOptions(response);
            } catch (err) {
                setUserOptions([]);
            }
        }

        fetchModelNameOptions();
        fetchUsers();
        fetchData(1, false);
    }, []);

    const fetchData = async (page = 1, nextPage = false) => {
        try {
            let p = page;
            if (nextPage) {
                p = paginationDataRef.current?.nextPage;
                console.log({ paginationData: paginationDataRef.current, p })
            }
            console.log({ p, nextPage, page })
            if (p === null && nextPage === true) return;
            setLoading(true);
            console.log({ page, nextPage });
            const payload = {}
            payload['search'] = searchValRef.current;
            payload['model_name'] = filteredModelNamesRef.current?.map?.(r => r.value);
            payload['history_user'] = filteredUsersRef.current?.map?.(r => r.value);
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            const newAbortController = new AbortController();
            abortControllerRef.current = newAbortController;
            const response = await axios.post(`/utilities/audit_data/?page=${p}`, payload, { signal: newAbortController.signal });
            console.log({ response });
            if (response?.data?.results) {
                const nextPage = response.data.next?.split?.("page=")[1] || null;
                console.log({ nextPage, page });
                if (p === 1) {
                    setData(response.data?.results);
                    dataRef.current = response.data?.results;
                } else {
                    let newData = [...dataRef.current, ...response.data.results];
                    setData(newData);
                    dataRef.current = newData;
                }
                let newPaginationData = {
                    count: response?.data?.count,
                    next: response?.data?.next,
                    nextPage: response.data?.next ? response.data.next.split?.("page=")[1] : null
                }
                setPaginationData(newPaginationData);
                paginationDataRef.current = newPaginationData;
            } else {
                setData([]);
                dataRef.current = [];
                setPaginationData(null);
                paginationDataRef.current = null;
                openNotification("Data Format Unrecognized", null, "error");
            }
            setLoading(false);
        } catch (e) {
            let msg = `${e.response?.data?.message || e.message || ''}`;
            console.log({ msg })
            if (msg !== "canceled") openNotification("Failed to fetch Food Analysis Report data.", msg, "error");
            console.error(e);
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log({ paginationData })
        paginationDataRef.current = paginationData;
    }, [paginationData]);

    // useEffect(() => {
    //     fetchData();
    // }, [filteredModelNames, filteredUsers, searchVal]);

    const onModelNameSelect = function (value, option) {
        console.log({ value, option });
        setFilteredModelNames(prev => {
            let r = lodash.cloneDeep(prev);
            if (Array.isArray(r)) {
                r.push(option);
            } else {
                r = []
                r.push(option);
            }
            filteredModelNamesRef.current = r;
            return r;
        })
    }
    const onModelNameDeSelect = function (value, option) {
        console.log({ value, option });
        let ss = lodash.cloneDeep(filteredModelNames);
        if (Array.isArray(ss)) {
            // 
        } else {
            ss = [];
        }
        ss = ss.filter(r => r.value !== value);
        setFilteredModelNames(ss);
        filteredModelNamesRef.current = ss;
    }

    const onUserSelect = function (value, option) {
        console.log({ value, option });
        setFilteredUsers(prev => {
            let r = lodash.cloneDeep(prev);
            if (Array.isArray(r)) {
                r.push(option);
            } else {
                r = []
                r.push(option);
            }
            filteredUsersRef.current = r;
            return r;
        })
    }
    const onUserDeSelect = function (value, option) {
        console.log({ value, option });
        let ss = lodash.cloneDeep(filteredUsers);
        if (Array.isArray(ss)) {
            // 
        } else {
            ss = [];
        }
        ss = ss.filter(r => r.value !== value);
        setFilteredUsers(ss);
        filteredUsersRef.current = ss;
    }

    const onSearch = (e) => {
        console.log({ e });
        if (e) {
            setSearchVal(e);
            searchValRef.current = e;
            fetchData(1, false);
        } else {
            setSearchVal("");
            searchValRef.current = "";
            fetchData(1, false);
        }
    }


    return (
        <>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Title style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }} level={5}>
                    Showing {(Array.isArray(data) && data.length) || 0} of {paginationData?.count || 0}
                </Title>
                <Flex justify='space-between' style={{ width: "100%" }}>
                    <Flex gap={15}>
                        <Flex justify='flex-start' align='center' gap={5}>
                            <label>Model</label>
                            <Select
                                mode="multiple"
                                style={{ width: "250px" }}
                                placeholder="Choose a Theatre to Filter"
                                loading={Array.isArray(filteredModelNames) ? false : true}
                                onSelect={onModelNameSelect}
                                onDeselect={onModelNameDeSelect}
                                value={filteredModelNames}
                                maxTagCount="responsive"
                            >
                                {
                                    modelNameOptions?.map?.((afv, ind) => {
                                        return <Select.Option key={afv.id} value={afv.id}>{afv?.value}</Select.Option>
                                    })
                                }
                            </Select>
                        </Flex>
                        <Flex justify='flex-start' align='center' gap={5}>
                            <label>User</label>
                            <Select
                                mode="multiple"
                                style={{ width: "250px" }}
                                placeholder="Choose a Theatre to Filter"
                                loading={Array.isArray(userOptions) ? false : true}
                                onSelect={onUserSelect}
                                onDeselect={onUserDeSelect}
                                value={filteredUsers}
                                maxTagCount="responsive"
                            >
                                {
                                    userOptions?.map?.((afv, ind) => {
                                        return <Select.Option key={afv.id} value={afv.id}>{`${afv?.first_name} ${afv?.last_name} (${afv.username})`}</Select.Option>
                                    })
                                }
                            </Select>
                        </Flex>
                        <Flex align='center' justify='center'>
                            <Button onClick={() => fetchData(1, false)} type='primary'>Apply Filters</Button>
                        </Flex>
                        <Flex justify='flex-start' align='center' gap={5}>
                            <Input.Search
                                placeholder="Search"
                                allowClear
                                onSearch={onSearch}
                                defaultValue={searchVal}
                                enterButton
                                style={{ maxWidth: 200 }}
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <Flex>
                    <Table
                        columns={columns}
                        bordered
                        dataSource={data}
                        // size='small'
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        loading={loading && data.length === 0}
                        scroll={{
                            y: "65vh",
                            x: "100%"
                        }}
                        pagination={false}
                    />
                    <Flex vertical justify='center' align='center' flex={1} style={{ width: "90%", height: '6vh', position: 'absolute', bottom: 20 }}>
                        {/* <CustomPagination /> */}
                        {loading && data.length > 0 && <>
                            <Flex gap={10} style={{ width: 150, height: 50, backgroundColor: "white", borderRadius: 10, boxShadow: "1px 2px 15px #d1d1d1" }} justify='center' align='center'>
                                <Spin size="small" />
                                <Text style={{ color: "black" }}>Loading...</Text>
                            </Flex>
                        </>}
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default AuditPage;
