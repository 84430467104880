import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { DatePicker, Flex, Table, Typography } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from './ExportXlsx';
const { RangePicker } = DatePicker;
const { Text } = Typography;

const API_URL = "/reports/daily-performance-report/";

const movieTableColumns = [
    {
        title: 'Name',
        dataIndex: 'movie',
        key: 'movie',
        sorter: (a, b) => a.movie - b.movie,
    },
    {
        title: 'Occupancy',
        dataIndex: 'occupancy',
        key: 'occupancy',
        render: (text) => text + " %",
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.occupancy - b.occupancy,
        align: "center",
    },
    {
        title: 'Total Tickets Sold',
        dataIndex: 'totalTicketsSold',
        key: 'totalTicketsSold',
        sorter: (a, b) => a.totalTicketsSold - b.totalTicketsSold,
        align: "center",
    },
    {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (text) => getFormattedAmount(text),
        sorter: (a, b) => a.totalAmount - b.totalAmount,
        align: "center",
    },
]

const foodTableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        align: "center",
        sorter: (a, b) => a.quantity - b.quantity,
    },
    {
        title: 'Type of Item',
        dataIndex: 'type',
        key: 'type',
        align: "center",
        sorter: (a, b) => a.type - b.type,
    },
    {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        defaultSortOrder: 'descend',
        key: 'totalAmount',
        align: "center",
        render: (text) => getFormattedAmount(text),
        sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
]

const initialDates = dayjs();

const DailyPerformanceReport = () => {

    const [movieData, setMovieData] = useState([]);
    const [foodData, setFoodData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['theatre'] = theatreFilterVal;
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) {
                    setFoodData(response.data?.data?.food?.map((a, index) => ({ ...a, key: index })));
                    setMovieData(response.data?.data?.movie?.map((a, index) => ({ ...a, key: index })));
                }
                else {
                    setFoodData([]);
                    setMovieData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Daily Performance Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [startDate, endDate, theatreFilterVal]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        theatre: theatreFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'daily_performance_report'}
                    reportTitleForNotification={'Daily Performance Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Flex justify='space-between' style={{ width: "100%", marginTop: 20 }}>
                    <ReportTitle title={`Daily Performance Report`} />
                    <Flex gap={15}>
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex gap={10}>
                    <Flex vertical flex={1}>
                        <h3>Movie</h3>
                        <Table
                            columns={movieTableColumns}
                            bordered
                            dataSource={movieData}
                            size='small'
                            scrollToFirstRowOnChange
                            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                            loading={loading}
                            scroll={{
                                y: "60vh",
                                x: "100%"
                            }}
                            pagination={false}
                            summary={pageData => {
                                let _totalTicketsSold = 0;
                                let _totalAmount = 0;
                                let _occupancy = 0;
                                console.log({ pageData })
                                pageData.forEach(({ totalAmount, totalTicketsSold, occupancy }) => {
                                    _totalAmount += totalAmount;
                                    _totalTicketsSold += totalTicketsSold;
                                    _occupancy += occupancy;
                                });

                                _occupancy = (_occupancy / pageData.length).toFixed(2);

                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell className='table-summary-cell' align='center'>
                                                <small style={{ color: "gray", marginRight: 5 }}>(Avg)</small>
                                                {_occupancy} %
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className='table-summary-cell' align='center'>
                                                <Text>{_totalTicketsSold}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className='table-summary-cell' align='center'>
                                                <Text>{getFormattedAmount(_totalAmount)}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Flex>
                    <Flex vertical flex={1}>
                        <h3>Food</h3>
                        <Table
                            columns={foodTableColumns}
                            bordered
                            dataSource={foodData}
                            size='small'
                            scrollToFirstRowOnChange
                            stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                            loading={loading}
                            scroll={{
                                y: "60vh",
                                x: "100%"
                            }}
                            pagination={false}
                            summary={pageData => {
                                let _quantity = 0;
                                let _totalAmount = 0;
                                pageData.forEach(({ totalAmount, quantity }) => {
                                    _totalAmount += totalAmount;
                                    _quantity += quantity;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row >
                                            <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell className='table-summary-cell' align='center'>
                                                <Text>{_quantity}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                            <Table.Summary.Cell className='table-summary-cell' align='center'>
                                                <Text>{getFormattedAmount(_totalAmount)}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default DailyPerformanceReport