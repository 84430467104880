import React, { useEffect } from 'react';
import { Button, Form, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS){
      form.resetFields();
  }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  }

  const onFinish = (val) => {
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please enter title',
            },
          ]}
        >
          <Input placeholder="Please enter title" />
        </Form.Item>
        <Form.Item
          name="iso_639_1"
          label="ISO 639 1"
          rules={[
            {
              required: true,
              message: 'Please enter ISO 639 1 Code',
            },
            {
              len: 2,
              message: 'It should be of exactly 2 Characters'
            }
          ]}
        >
          <Input placeholder="Please enter ISO 639 1 Code" />
        </Form.Item>
        <Form.Item
          name="in_language"
          label="In Language"
          rules={[
            {
              required: true,
              message: 'Please enter In Language',
            },
          ]}
        >
          <Input placeholder="Please enter In Language" />
        </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;