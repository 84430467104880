export const asyncStatuses = {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED"
}

export const ThemeEnum = {
    LIGHT: "light",
    DARK: "dark"
}

export const FormModesEnum = {
    ADD: "ADD",
    EDIT: "EDIT",
    VIEW: "VIEW",
    NONE: "NONE",
}

// const DARK_COLOR = "#001529"
export const DARK_COLOR = "black"
export const LIGHT_COLOR = "white"