import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchUserList } from 'Api/commonApis';
import lodash from "lodash";

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(()=>{
        if (currentFormMode === FormModesEnum.ADD){
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        console.log({ fetchARecord, currentRecordData });
        let initValues = lodash.cloneDeep(currentRecordData);
        // console.log({initValues})
        // initValues.user = initValues.user || "-";
        // initValues.show = `${initValues.show_data?.client_program_data?.title} (${initValues.show_data?.date} ${initValues.show_data?.start_time?.slice?.(0, 5)})`
        // initValues.theatre = `${initValues.theatre_data?.title}`;
        // initValues.hall = `${initValues.show_data?.hall_data?.hall_no}`;
        // initValues.bill_to = JSON.stringify(initValues.bill_to) === "null" ? "-" : JSON.stringify(initValues.bill_to);
        // console.log({initValues})
        form.setFieldsValue(initValues);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
    let url = apiUrl?.split?.("?")[0];
    // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
    dispatch(editARecord({ apiUrl:url , id: currentEditViewFormId, data: val }));
  }

    const onFinish = (val) => {
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Form.Item
                    name="id"
                    label="Id"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="payment_status"
                    label="Payment Status"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="payment_method"
                    label="Payment Method"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="total"
                    label="Total"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="order"
                    label="Order"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="food_order"
                    label="Food Order"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="change_returned"
                    label="Change Returned"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter value',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Submit
                                </Button>
                        }
                    </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                        {
                            editRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Updating
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
        </>
    );
};
export default AddEditForm;