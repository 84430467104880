import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useNotificationContext } from 'Config/NotificationsContext';
import axios from 'axios';
import { fetchHallsList } from 'Api/commonApis';

const { Option } = Select;

const HallFilterForReports = ({ theatreFilterVal, hallFilterVal, setHallFilterVal }) => {

    const openNotification = useNotificationContext();
    const [filterValues, setFilterValues] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let search_param = theatreFilterVal ? `?theatre=${theatreFilterVal}` : ""
                const response = await fetchHallsList(search_param);
                console.log(response);
                setFilterValues(response);
                if (Array.isArray(response) && response.length > 0) {
                    setHallFilterVal(response[0].id);
                }
            } catch (e) {
                console.error(e);
                openNotification("Couldn't fetch Theatres", e?.message, "error");
                setFilterValues([]);
            }
        }
        if (theatreFilterVal) fetchData(); else setFilterValues([]);
    }, [theatreFilterVal]);

    const onFilterSelect = (v) => {
        setHallFilterVal(v);
    }

    return (
        <>
            <Flex justify='flex-start' align='center' gap={5}>
                <label>Hall</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Hall to Filter"
                    loading={Array.isArray(filterValues) ? false : true}
                    onSelect={onFilterSelect}
                    value={hallFilterVal}
                >
                    {
                        filterValues?.map?.((afv, ind) => {
                            return <Option key={afv.id} value={afv.id}>{afv?.hall_no}</Option>
                        })
                    }
                </Select>
            </Flex>
        </>
    )
}

export default HallFilterForReports;