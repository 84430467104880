import { Flex, Table } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

const tableColumns = [
    {
        title: 'Coupon Name',
        dataIndex: 'title',
        key: 'title',
        align: "center",
    },
    {
        title: 'Used At',
        dataIndex: 'used_at',
        key: 'used_at',
    },
    {
        title: 'Used By',
        dataIndex: 'booked_by',
        key: 'booked_by',
    },
    {
        title: 'Used On',
        dataIndex: 'used_on',
        key: 'used_on',
    },
]

const UsedCouponReport = ({ record, cashier }) => {
    const rawData = record?.no_tickets_sold?.CASH?.coupon_data;

    let data = rawData?.map?.((row, index) => {
        let thisRow = {};

        thisRow['title'] = row?.coupon?.title;
        thisRow['used_at'] = moment(row?.created_at).format("MMM DD, YYYY HH:mm");
        thisRow['booked_by'] = cashier || row?.booked_by;
        thisRow['used_on'] = row?.used_on;
        
        return thisRow;
    });
    return (
        <Flex vertical gap={5} style={{backgroundColor: "#fff", padding: 5}}>
            <h3>Used Coupon History</h3>
            <Table
                columns={tableColumns}
                bordered
                dataSource={data}
                size='small'
                scrollToFirstRowOnChange
                stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                scroll={{
                    y: "59vh",
                    x: "100%"
                }}
                pagination={false}
            />
        </Flex>
    )
}

export default UsedCouponReport