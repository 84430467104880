import React, { useState } from 'react'
import { Flex, Select } from 'antd';
import { NOTIFICATION_TYPES } from 'Components/Forms/Notifications/AddEditForm';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';

const { Option } = Select;

const notification_types = Object.keys(NOTIFICATION_TYPES).map(key => NOTIFICATION_TYPES[key])

const NotificationTypeFilter = ({ openNotification, apiUrl, extraApiParamsState, setExtraApiParamsState, field }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [showNotificationTypeFilter, setShowNotificationTypeFilter] = useState(true);
    const [filterValues, setFilterValues] = useState(notification_types);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const [disableFilter, setDisableFilter] = useState(false);

    const onNotificationTypeSelect = (v) => {
        // console.log({ v });
        if (v === "all") {
            setSelectedFilter("all");
        } else {
            setSelectedFilter(v);
        }
        setSearchParams(params => {
            params.set(searchParamsEnum.PAGE, 1);
        });
        setExtraApiParamsState((p) => {
            if (v === "all") {
                let s = p && JSON.parse(JSON.stringify(p));
                if (!s) {
                    s = {}
                }
                if (field in s) {
                    delete s[field]
                }
                return s;
            } else {
                const s = {
                    ...p,
                    [field]: v === "all" ? null : v
                }
                // console.log({ s, v });
                return s;
            }
        })
    }

    if (!showNotificationTypeFilter){
        return <></>;
    }

    return (
        <>
            <Flex align='center' justify='center' gap={5}>
                <label>NotificationType</label>
                <Select
                    style={{ width: "250px"}}
                    placeholder="Choose a Client to Filter"
                    disabled={disableFilter}
                    loading={Array.isArray(filterValues) ? false : true}
                    value={!selectedFilter ? "all" : selectedFilter}
                    onSelect={onNotificationTypeSelect}
                >
                    {
                        filterValues?.length > 1 ? <>
                            <Option key={"all"} value="all">All</Option>
                            {
                                filterValues?.map((afv, ind) => {
                                    return <Option key={afv.value} value={afv.value}>
                                            <span>{afv.title}</span>
                                    </Option>
                                })
                            }
                        </> : filterValues?.length === 1 ? <Option key={filterValues[0]?.value} value={filterValues[0]?.value}>{filterValues[0]?.title}</Option> : <></>
                    }
                </Select>
            </Flex>
        </>
    )
}

export default NotificationTypeFilter;