import { landscapePlaceholder } from 'Assets'
import { Col, Flex, Form, Row, Upload, Image, Spin } from 'antd'
import React from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNotificationContext } from 'Config/NotificationsContext';
import { FormModesEnum } from 'Redux/enums';

const PosterImage = ({ setImageBase64, setNewDefaultImage, imageBase64}) => {

    const openNotification = useNotificationContext();

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    const getFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const handleImageUpload = (file) => {
        console.log(file.file)
        try {
            // Convert the image to base64
            getBase64(file.file, (base64) => {
                setImageBase64(base64);
            });
            setNewDefaultImage(file.file);
            // openNotification(`${file.file.name} file uploaded successfully`, null, "success");
        } catch (err) {
            console.log(err)
            // openNotification(`${file.file.name} file upload failed.`, null, "error");
        }
    };


    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    label="Poster"
                    name="poster_image"
                    valuePropName='fileList'
                    getValueFromEvent={getFile}
                    rules={[{ required: false, message: 'Please upload a poster!' }]}
                >
                    <Flex gap={20}>
                        <Flex>
                            <Upload
                                // action={handleImageUpload}
                                beforeUpload={() => false} // Prevent automatic upload
                                listType="picture-card"
                                fileList={[]}
                                showUploadList={false}
                                style={{ display: "flex", justifyContent: "start", width: "fit-content", height: 750 }}
                                onChange={handleImageUpload}
                                accept='image/png, image/jpeg, image/jpg, .webp'
                            >
                                {
                                    imageBase64 ? (
                                        <img
                                            src={imageBase64}
                                            alt="Preview"
                                            style={{ height: '100%', width: '100%', objectFit: "contain" }}
                                        />
                                    ) : (
                                        <div>
                                            <UploadOutlined />
                                            <div style={{ marginTop: 8 }}>
                                                {currentFormMode === FormModesEnum.ADD ? "Upload" : "Change"}
                                            </div>
                                        </div>
                                    )
                                }
                            </Upload>
                        </Flex>
                        {
                            currentRecordData.poster_image && !imageBase64 ?
                                <Flex justify='center' align='center'>
                                    <Image
                                        src={currentRecordData.poster_image}
                                        alt='Preview'
                                        style={{ maxWidth: 100 }}
                                        fallback={landscapePlaceholder}
                                        loading={<Spin size='sm' />}
                                    />
                                </Flex> : <></>
                        }
                    </Flex>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default PosterImage