import { DatePicker, Divider, Drawer, Flex, notification, Spin } from 'antd'
import { useNotificationContext } from 'Config/NotificationsContext';
import dayjs from 'dayjs';
import ExportPdfServer from 'Pages/Dashboard/Reports/ExportPdfServer';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { asyncStatuses } from 'Redux/enums';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { hideViewScheduleDrawer } from 'Redux/TheatreReducer/theatreSlice';

const initialDates = dayjs()

const ViewScheduleDrawer = () => {

    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const theatre = useSelector(store => store.theatre.scheduleData?.theatre);

    const scheduleDataRetrieveStatus = useSelector(store => store.theatre.scheduleDataRetrieveStatus);
    const scheduleDataRetrieveErrorMsg = useSelector(store => store.theatre.scheduleDataRetrieveErrorMsg);
    const isViewScheduleDrawerVisible = useSelector(store => store.theatre?.isViewScheduleDrawerVisible);

    const openNotification = useNotificationContext();

    const modifyDates = (value) => {
        console.log({ value })
        setStartDate(value[0]);
        setEndDate(value[1])
    }

    useEffect(() => {
        if (scheduleDataRetrieveStatus === asyncStatuses.FAILED) {
            openNotification("Error occurred", scheduleDataRetrieveErrorMsg, "error");
        } else {
        }
    }, [scheduleDataRetrieveErrorMsg, scheduleDataRetrieveStatus, openNotification]);


    const dispatch = useDispatch();

    const closeThisDrawer = () => {
        dispatch(hideViewScheduleDrawer());
    }

    return (
        <>
            <Drawer
                title={`View Schedule`}
                width={"40vw"}
                style={{
                    minWidth: "40vw"
                }}
                onClose={closeThisDrawer}
                open={isViewScheduleDrawerVisible}
                styles={{
                    body: {
                        padding: 0,
                        paddingBottom: 20,
                    },
                }}
            >
                <Flex
                    vertical
                    gap={20}
                    style={{ overflow: "auto", padding: 20 }}
                >
                    <Flex gap={10} justify='space-between'>
                        <DatePicker.RangePicker
                            defaultValue={[initialDates, initialDates]}
                            onChange={modifyDates}
                            style={{ width: "max-content" }}
                        />
                        <ExportPdfServer
                            apiUrl={`/reports/display-schedule/export/`}
                            getPayload={() => ({
                                start_date: startDate.format(SELECTED_DATE_FORMAT),
                                end_date: endDate.format(SELECTED_DATE_FORMAT),
                                theatre_id: theatre?.id,
                            })}
                            reportName={'schedule_report'}
                            reportTitleForNotification={'Movie Per Hall Report'}
                        />
                    </Flex>
                    <Divider
                        plain
                        variant="dashed"
                        style={{
                            borderColor: 'gray',
                            margin: 0,
                            marginTop: 10
                        }}
                    >
                        
                    </Divider>
                    <Flex justify='center' align='start' style={{ width: "100%", minHeight: "30vh" }}>
                        {
                            scheduleDataRetrieveStatus === asyncStatuses.LOADING ?
                                <Spin /> : scheduleDataRetrieveStatus === asyncStatuses.FAILED ?
                                    <Flex flex={1} justify='center' align='center' vertical style={{ color: "red" }}>
                                        <p>Error Occurred</p>
                                        <p>{scheduleDataRetrieveErrorMsg}</p>
                                    </Flex> :
                                    <TabularFormat />
                        }
                    </Flex>
                </Flex>
            </Drawer>
        </>
    )
}

export default ViewScheduleDrawer;

const TabularFormat = () => {
    const scheduleData = useSelector(store => store.theatre.scheduleData?.shows);
    const theatre = useSelector(store => store.theatre.scheduleData?.theatre);

    const dates = Object.keys(scheduleData)?.sort();

    const getFormattedTitle = useCallback((date) => {
        let d = "";
        d = dayjs(date).format('dddd, DD MMM YYYY')
        d += ` (${scheduleData[date].length} ${scheduleData[date].length > 1 ? 'shows' : 'show'})`
        return d;
    }, [scheduleData])

    return (
        <Flex vertical flex={1} style={{ width: "100%" }}>
            <h2 style={{ marginTop: 5, marginBottom: 0 }}>{theatre?.title}</h2>
            {
                (!scheduleData || (Object.keys(scheduleData)?.length === 0)) ? <em>No shows scheduled</em> : <>
                    {
                        dates?.map?.((date, index) => (
                            <Flex vertical key={index}>
                                <h3 style={{ marginTop: 15, marginBottom: 15 }}>{getFormattedTitle(date)}</h3>
                                <table>
                                    <thead>
                                        <tr style={{ backgroundColor: "#c5c5c5" }}>
                                            <th style={{ paddingTop: 5, paddingBottom: 5, width: "20%", textAlign: "start" }}>Time</th>
                                            <th style={{ paddingTop: 5, paddingBottom: 5, width: "60%", textAlign: "start" }}>Movie</th>
                                            <th style={{ paddingTop: 5, paddingBottom: 5, width: "20%", textAlign: "start" }}>Hall</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            scheduleData[date]?.map?.((show, ind) => (
                                                <tr key={show?.id}>
                                                    <td style={{ paddingTop: 5, paddingBottom: 5, width: "20%" }}>
                                                        {show.start_time?.slice?.(0, 5)}
                                                    </td>
                                                    <td style={{ paddingTop: 5, paddingBottom: 5, width: "60%" }}>
                                                        {show.client_program?.title}
                                                    </td>
                                                    <td style={{ paddingTop: 5, paddingBottom: 5, width: "20%" }}>
                                                        {show.hall?.hall_no}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Flex>
                        ))
                    }
                </>
            }
        </Flex>
    )

}

const ToggleFormat = () => {

    const scheduleData = useSelector(store => store.theatre.scheduleData);

    return (
        <>
            <>
                {Object.keys(scheduleData).map((date) => (
                    <div key={date}>
                        <h2>Date: {date}</h2>
                        {Object.keys(scheduleData[date]).map((theatreId) => (
                            <div key={theatreId}>
                                <h3>Theatre ID: {theatreId}</h3>
                                {Object.keys(scheduleData[date][theatreId]).map((hall, hallIndex) => (
                                    <div key={hall}>
                                        <h4>Hall: {hall}</h4>
                                        {
                                            scheduleData[date][theatreId][hall]?.map?.((show, ind) => (
                                                <p>{show?.start_time} - {show?.client_program?.title}</p>
                                            ))
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </>
        </>
    )
}