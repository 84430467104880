import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Checkbox, Row, Col, Radio, Space } from 'antd';
// import apiUrls from 'Utils/apiUrls';
import { LoadingOutlined } from "@ant-design/icons"
import { validatePositiveInteger } from 'Utils/formValidators';
import { useNotificationContext } from 'Config/NotificationsContext';
import { useParams } from 'react-router';
import axios from 'axios';
import lodash from "lodash";

const SSL_TLS_VALUES = {
    SSL: "SSL",
    TLS: "TLS"
}

const ClientSettingsForm = ({ saveUrl, data, clientOptions, setData }) => {
    const params = useParams();
    const [form] = Form.useForm();
    const openNotification = useNotificationContext();
    const [loading, setLoading] = useState(false);
    console.log({ data, saveUrl })

    useEffect(() => {
        const fetchARecord = () => { }
        fetchARecord();
    }, []);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let dataCopy = lodash.cloneDeep(data);
        if (data)
            dataCopy.SSL_TLS = dataCopy.smtp_use_ssl ? SSL_TLS_VALUES.SSL : dataCopy.smtp_use_tls ? SSL_TLS_VALUES.TLS : null;
        else
            dataCopy = {
                smtp_port: 587
            }

        if (!dataCopy.mips_iframe_path) dataCopy.mips_iframe_path = '/api/load_payment_zone';
        if (!dataCopy.smtp_admin_email_title) dataCopy.smtp_admin_email_title = 'MCINE';
        form.setFieldsValue(dataCopy);
    }, [data]);

    const onFinish = async (val) => {
        val.password_confirmation = !!val.password_confirmation;
        console.log({ "Add Val": val });
        if (val.SSL_TLS) {
            val.smtp_use_ssl = val.SSL_TLS === SSL_TLS_VALUES.SSL ? true : false;
            val.smtp_use_tls = val.SSL_TLS === SSL_TLS_VALUES.TLS ? true : false;
        }
        setLoading(true);
        try {
            let method = "post";
            let url = saveUrl;
            if (data) {
                method = "patch";
                if (saveUrl.includes("?")) {
                    url = saveUrl.split("?")[0];
                    if (url[url.length - 1] === "/") {
                        // as expected
                    } else {
                        url += "/";
                    }
                }
                url = url + `${data.id}/`
            }
            console.log({ method, data, url })
            const response = await axios[method](url, val);
            console.log(response);
            setData(response.data);
            openNotification("Saved Successfully", null, "success");
        } catch (err) {
            console.error(err);
            openNotification("Error while saving data", null, "error");
        }
        setLoading(false);
    }


    const sslTlsRadio = ({ target: { value } }) => {
        console.log({ value });
    }

    return (
        <>
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                style={{ width: "100%" }}
            >
                <Row gutter={10}>
                    <Col>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter title',
                                },
                            ]}
                        >
                            <Input placeholder="Please enter title" />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    params.id ?
                        <Form.Item
                            hidden
                            name="client"
                            initialValue={params?.id}
                        >
                            <Input />
                        </Form.Item> :
                        <Form.Item
                            name="client"
                            label="Client"
                            rules={[
                                {
                                    required: true,
                                    message: "Client is required"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: 200,
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                loading={Array.isArray(clientOptions) ? false : true}
                                options={clientOptions}
                            />
                        </Form.Item>
                }
                <Row gutter={10} align="middle">
                    <Col span={24}>
                        <h4>SMTP Settings</h4>
                    </Col>
                    <Col>
                        <Form.Item
                            name="smtp_host"
                            label="Host"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter SMTP Host" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="smtp_username"
                            label="Username"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Username',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter SMTP Username" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="smtp_password"
                            label="Password"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Password',
                                // },
                            ]}
                        >
                            <Input.Password placeholder="Please enter SMTP Password" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="smtp_port"
                            label="Port"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Port',
                                // },
                                { validator: validatePositiveInteger },
                            ]}
                        >
                            <Input type="number" placeholder="Please enter SMTP Port" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="smtp_admin_email"
                            label="Admin Email"
                            tooltip="Email address from which the email will be sent"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Username',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter SMTP Admin Email" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="smtp_admin_email_title"
                            label="Admin Email Title"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Username',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter SMTP Admin Email Title" />
                        </Form.Item>
                    </Col>
                    <Col>
                        {/* <Form.Item
                            // hidden
                            name="smtp_use_tls"
                            valuePropName="checked"
                            style={{ marginBottom: 5 }}
                        >
                            <Checkbox>Use TLS?</Checkbox>
                        </Form.Item>
                        <Form.Item
                            hidden
                            name="smtp_use_ssl"
                            valuePropName="checked"
                        >
                            <Checkbox>Use SSL?</Checkbox>
                        </Form.Item> */}
                        <Form.Item
                            name={`SSL_TLS`}
                        >
                            <Radio.Group onChange={sslTlsRadio}>
                                <Space direction='vertical'>
                                    <Radio value={SSL_TLS_VALUES.SSL}>Use SSL</Radio>
                                    <Radio value={SSL_TLS_VALUES.TLS}>Use TLS</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            name="contact_email"
                            label="Contact Email"
                            tooltip="To what email should the system send contact us requests?"
                        >
                            <Input type='email' placeholder="Please enter Contact Email" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10} align="middle">
                    <Col span={24}>
                        <h4>SMS Settings</h4>
                    </Col>
                    <Col>
                        <Form.Item
                            name="sms_url"
                            label="SMS URL"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMS URL',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter SMS URL" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="sms_username"
                            label="SMS Username"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMS Username',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter SMS Username" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="sms_password"
                            label="SMS Password"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMS Password',
                                // },
                            ]}
                        >
                            <Input.Password placeholder="Please enter SMS Password" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="password_confirmation"
                            valuePropName="checked"
                        >
                            <Checkbox>Password Confirmation?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10} align="start">
                    <Col span={24}>
                        <h4>My.T Money Payment Gateway Settings</h4>
                    </Col>
                    <Col>
                        <Form.Item
                            name="myt_app_id"
                            label="App ID"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter App Id" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="myt_api_key"
                            label="API Key"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter API Key" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="myt_backend_base_url"
                            label="Backend Base Url"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Your Backend's Base Url" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="myt_public_key"
                            label="Public Key"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input.TextArea placeholder="Please enter Public Key" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10} align="start">
                    <Col span={24}>
                        <h4>MIPS Payment Gateway Settings</h4>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_base_url"
                            label="Base URL"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Base Url" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_id_merchant"
                            label="Merchant ID"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Merchant ID" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_id_entity"
                            label="Entity ID"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Entity ID" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_id_operator"
                            label="Operator ID"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Operator ID" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_operator_password"
                            label="Operator Password"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input.Password placeholder="Please enter Auth Password" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_auth_username"
                            label="Auth Username"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Auth Username" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_auth_password"
                            label="Auth Password"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input.Password placeholder="Please enter Auth Password" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_frontend_url"
                            label="Your Website's URL"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Where user will be redirected after payment" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="mips_iframe_path"
                            label="Iframe Path"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMTP Host',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Iframe Path" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10} align="middle">
                    <Col span={24}>
                        <h4>Google reCaptcha to contact form</h4>
                    </Col>
                    <Col>
                        <Form.Item
                            name="recaptcha_site_key"
                            label="reCaptcha Site Key"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMS URL',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter reCaptcha Site Key" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="recaptcha_secret_key"
                            label="reCaptcha Secret Key"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMS URL',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter reCaptcha Secret Key" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10} align="middle">
                    <Col span={24}>
                        <h4>Social links</h4>
                    </Col>
                    <Col>
                        <Form.Item
                            name="instagram"
                            label="Instagram Account URL"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter Instagram Account URL',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter reCaptcha Site Key" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="tiktok"
                            label="Tiktok Account URL"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter Tiktok Account URL',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Tiktok Account URL" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="facebook"
                            label="Facebook Account URL"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter SMS URL',
                                // },
                            ]}
                        >
                            <Input placeholder="Please enter Facebook Account URL" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10} align="middle">
                    <Col span={24}>
                        <h4>Booking Related Settings</h4>
                    </Col>
                    <Col>
                        <Form.Item
                            name="allow_booking_before"
                            label="Block Booking Before (mins)"
                            // rules={[
                            // ]}
                        >
                            <Input type="number" placeholder="Please enter Allow Booking Before in minutes" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    {
                        loading ?
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Saving
                            </Button> :
                            <Button type="primary" htmlType="submit" >
                                Save
                            </Button>
                    }
                </Form.Item>

            </Form >
        </>
    );
};
export default ClientSettingsForm;