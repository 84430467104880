import React, { useEffect, useState } from 'react';
import {
    UserOutlined,
    ContainerOutlined,
    DesktopOutlined,
    DatabaseOutlined,
    PieChartOutlined,
    ReconciliationOutlined,
} from '@ant-design/icons';
import lodash from "lodash";
import { Flex, Image, Menu, Space, Tooltip } from 'antd';
import Sider from 'antd/es/layout/Sider';
import Title from 'antd/es/typography/Title';
import { useSelector } from 'react-redux';
import { DARK_COLOR, LIGHT_COLOR, ThemeEnum } from 'Redux/enums';
import { Link, useLocation } from 'react-router-dom';
import { IoFastFoodOutline } from "react-icons/io5";
import { BsDistributeHorizontal } from "react-icons/bs";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { RiCoupon2Line } from "react-icons/ri";
import { RiMovie2Line } from "react-icons/ri";
import { SiGoogleclassroom } from "react-icons/si";
import { MdNotificationsActive } from "react-icons/md";
import { cinebox_logo_full, cinebox_logo_full_for_dark, cinebox_logo_full_for_light } from 'Assets';

function getItem(label, key, icon, linkTo, children, type, allowedPermissions = []) {

    if (!key) {
        key = linkTo || label.replace(" ", "").toLowerCase();
    }

    const itemContent = linkTo ? (
        <>
            {
                label.length > 24 ? <Tooltip placement="right" title={label}>
                    <Link to={linkTo}>{label}</Link>
                </Tooltip> : <Link to={linkTo}>{label}</Link>
            }
        </>
    ) : (
        label
    );

    return {
        key,
        icon,
        children,
        label: itemContent,
        type,
        allowedPermissions,
    };
}

function removeAllowedPermissionsFromProp(item) {
    if ("allowedPermissions" in item) {
        delete item.allowedPermissions;
    }
    return item;
}

function checkAllowedPermissions(userPermissions) {

    let new_items = [];
    console.log({ all_items });
    const copyOfAllItems = lodash.cloneDeep(all_items);
    console.log({ copyOfAllItems })

    for (let item of copyOfAllItems) {
        if (!item.children) {
            // for no children / no drop down
            let allPermissionsPresentIniItem = item.allowedPermissions.filter(i => userPermissions?.includes(i)).length === item.allowedPermissions.length;
            if (allPermissionsPresentIniItem) {
                let new_item = removeAllowedPermissionsFromProp(item);
                new_items.push(new_item);
            }
        } else {
            // create an array of all the permissions
            let allPermissionsInsideDropdown = []
            item.children.forEach(ite => {
                ite.allowedPermissions.forEach(p => {
                    allPermissionsInsideDropdown.push(p);
                })
            });

            // determine if this dropdown should be shown
            let showDropdown = false;
            for (let alp of allPermissionsInsideDropdown) {
                if (userPermissions?.includes(alp)) {
                    showDropdown = true;
                    break;
                }
            }

            // find the children that are to be shown
            // let dropdownItem = { ...item };
            let dropdownItem = removeAllowedPermissionsFromProp(item);
            if (showDropdown) {
                let newChildren = [];
                for (let child of dropdownItem.children) {
                    let allPermissionsPresentIniItem = child.allowedPermissions.filter(i => userPermissions?.includes(i)).length === child.allowedPermissions.length;
                    if (allPermissionsPresentIniItem) {
                        let new_item = removeAllowedPermissionsFromProp(child);
                        newChildren.push(new_item);
                    }
                }
                if (newChildren.length > 0) {
                    dropdownItem.children = newChildren;
                    new_items.push(dropdownItem);
                }
            }
        }
    }

    return new_items;

}

const all_items = [
    getItem('Dashboard', null, <PieChartOutlined />, "/dashboard", null, null, ["dashboard:view"]),
    getItem('Order', null, <MdOutlineShoppingCartCheckout />, null, [
        getItem('Orders', null, null, "/order/orders", null, null, ["order:view"]),
        getItem('Food Orders', null, null, "/order/food-orders", null, null, ["order:view"]),
        getItem('Payments', null, null, "/order/payments", null, null, ["order:view"]),
        getItem('Refunds', null, null, "/order/refunds", null, null, ["order:view"]),
    ]),
    getItem('Food', null, <IoFastFoodOutline />, null, [
        getItem('Category', null, null, "/food/categories", null, null, ["food_categories:view"]),
        getItem('Food', null, null, "/food/food", null, null, ["food:view"]),
        // getItem('Inventory', null, null, "/food/inventories", null, null, ["inventory:view"]),
    ]),
    getItem('Coupons', null, <RiCoupon2Line />, null, [
        getItem('Food Coupon', null, null, "/coupon/food", null, null, ["food_coupons:view"]),
        getItem('Seat Coupon', null, null, "/coupon/seat", null, null, ["seat_coupons:view"]),
    ]),
    getItem('Manage Theatres', null, <SiGoogleclassroom />, "/theatres", null, null, ["theatre:view"]),
    // getItem('Theatre', "theatres", <SiGoogleclassroom />, null, [
    //     getItem('Theatre', "", null, "/theatres", null, null, ["theatre:view"]),
    //     getItem('Users', null, null, "/theatres/users", null, null, ["theatreuser:view"]),
    //     getItem('Channels', null, null, "/theatres/channels", null, null, ["theatrechannel:view"]),
    //     // getItem('Seat Types', null, null, "/theatres/seat-types", null, null, ["seat-type:view"]),
    //     getItem('Halls', null, null, "/theatres/halls", null, null, ["theatrehall:view"]),
    //     getItem('Tax', null, null, "/theatres/tax", null, null, ["theatretax:view"]),
    // ], null, null, ["theatre:view"]),
    getItem('Shows', null, <RiMovie2Line />, null, [
        getItem('Shows', null, null, "/shows", null, null, ["shows:view"]),
        getItem('Programs', null, null, "/shows/programs", null, null, ["programs:view"]),
    ]),
    getItem('Reports', "reports", <ReconciliationOutlined />, null, [
        // getItem('At A Glance (Today)', null, null, "/dashboard", null, null, ["order:view"]),
        getItem('Movie Analysis', null, null, "/reports/movie-analysis/", null, null, ["reports_movie_analysis:view"]),
        getItem('Food Analysis', null, null, "/reports/food-analysis/", null, null, ["reports_food_analysis:view"]),
        getItem("Hall Seat Heatmap", null, null, "/reports/hall-seat-heatmap/", null, null, ["reports_hall_seat_heatmap:view"]),
        getItem("Theatre Performance Comparison Report", null, null, "/reports/theatre-performance-comparison-report/", null, null, ["reports_theatre_performance_comparison:view"]),
        getItem("Pre-Sales Report", null, null, "/reports/pre-sales-report/", null, null, ["reports_pre_sales:view"]),
        getItem("Daily Performance Report", null, null, "/reports/daily-performance-report/", null, null, ["reports_daily_performance:view"]),
        getItem("Distributor Report", null, null, "/reports/distributor-report/", null, null, ["reports_distributor:view"]),
        getItem("User Onboarding Report", null, null, "/reports/user-onboarding-report/", null, null, ["reports_user_onboarding:view"]),
        getItem("Loyalty/Usage Data Report", null, null, "/reports/loyalty-usage-data-report/", null, null, ["reports_loyalty_usage_data:view"]),
        getItem("Sales Reconciliation Report", null, null, "/reports/sales-reconciliation-report/", null, null, ["reports_sales_reconciliation:view"]),
        getItem('Cash Register - Seat And Food', null, null, "/reports/cash-register-seat-and-food/", null, null, ["reports_cash_register_seat_and_food:view"]),
        // getItem('Cash Register - Food Only POS', null, null, "/dashboard/cash-register-food-only-pos/", null, null, ["reports_cash_register_food_only_pos:view"]),
        getItem('Movie Per Hall', null, null, "/reports/movie-per-hall/", null, null, ["reports_movie_per_hall:view"]),
        getItem('Film Wise Report', null, null, "/reports/film-wise/", null, null, ["reports_film_wise:view"]),
    ]),
    getItem('Notifications', null, <MdNotificationsActive />, "/notifications", null, null, ["notifications:view"]),
    getItem('Master', 'master', <DatabaseOutlined />, null, [
        getItem('Channels', null, null, "/master/channels", null, null, ["channels:view"]),
        getItem('Languages', null, null, "/master/languages", null, null, ["languages:view"]),
        getItem('Cities', null, null, "/master/cities", null, null, ["cities:view"]),
        getItem('States', null, null, "/master/states", null, null, ["states:view"]),
        getItem('Countries', null, null, "/master/countries", null, null, ["countries:view"]),
    ]),
    getItem('Clients', "clients", <BsDistributeHorizontal />, null, [
        getItem('Clients', null, null, "/clients/clients", null, null, ["clients:view"]),
        getItem('Distributor', null, null, "/clients/distributors", null, null, ["distributors:view"]),
        getItem('Seat Types', null, null, "/clients/seat-types", null, null, ["seat-type:view"]),
        getItem('Certifications', null, null, "/clients/certifications", null, null, ["certifications:view"]),
        getItem('Program Types', null, null, "/clients/program-types", null, null, ["program-types:view"]),
        getItem('Audit', null, null, "/clients/audit", null, null, ["audit:view"]),
        // getItem('Convenience Fee Settings', null, null, "/clients/settings", null, null, ["settings:view"]),
    ]),
    getItem('Platform Users', "platform-users", <UserOutlined />, null, [
        getItem('Users', null, null, "/platform-users/users", null, null, ["users:view"]),
        getItem('Permissions', null, null, "/platform-users/permissions", null, null, ["permissions:view"]),
        getItem('Roles', null, null, "/platform-users/roles", null, null, ["roles:view"]),
    ]),
];

const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
        items2.forEach((item) => {
            if (item.key) {
                key[item.key] = level;
            }
            if (item.children) {
                func(item.children, level + 1);
            }
        });
    };
    func(items1);
    return key;
};

const SideBar = ({ trigger, collapsible, collapsed }) => {
    const location = useLocation();
    const { pathname } = location;
    const currentTheme = useSelector(store => store.utilities.theme);

    const [defaultOpenKeyState, setDefaultOpenKey] = useState([]);
    const [defaultSelectedKeyState, setDefaultSelectedKey] = useState([]);

    const user = useSelector(state => state.auth.user)
    // console.log({ all_items })
    const filtered_items = checkAllowedPermissions(user.user_permissions);
    console.log({ filtered_items });
    const levelKeys = getLevelKeys(filtered_items);

    const [stateOpenKeys, setStateOpenKeys] = useState(defaultOpenKeyState);
    const onOpenChange = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        console.log({ currentOpenKey });
        // open
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys
                .filter((key) => key !== currentOpenKey)
                .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
            setStateOpenKeys(
                openKeys
                    // remove repeat key
                    .filter((_, index) => index !== repeatIndex)
                    // remove current level all child
                    .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
            );
        } else {
            // close
            setStateOpenKeys(openKeys);
        }
    };

    useEffect(() => {
        setStateOpenKeys(defaultOpenKeyState);
    }, [defaultOpenKeyState]);

    useEffect(() => {
        let defaultOpenKey = [];
        if (pathname?.includes("master")) {
            defaultOpenKey = ["master"];
        } else if (pathname?.includes("clients")) {
            defaultOpenKey = ["clients"];
        } else if (pathname?.includes("platform")) {
            defaultOpenKey = ["platform-users"];
        } else if (pathname?.includes("theatres")) {
            defaultOpenKey = ["theatres"];
        } else if (pathname?.includes("shows")) {
            defaultOpenKey = ["shows"];
        } else if (pathname?.includes("coupon")) {
            defaultOpenKey = ["coupons"];
        } else if (pathname?.includes("reports") && pathname.split("/").length > 2) {
            defaultOpenKey = ["reports"];
        } else if (pathname?.includes("order")) {
            defaultOpenKey = ["order"];
        } else if (pathname?.includes("food")) {
            defaultOpenKey = ["food"];
        } else {
            defaultOpenKey = ["dashboard"]
        }
        setDefaultOpenKey(defaultOpenKey);
        // console.log({ defaultOpenKey });

        if (pathname?.includes("theatres")) {
            // console.log({ pathname })
            setDefaultSelectedKey("/theatres");
            // if (pathname?.includes("users")) {
            //     setDefaultSelectedKey("/theatres/users");
            // } else if (pathname?.includes("channels")) {
            //     setDefaultSelectedKey("/theatres/channels");
            // } else if (pathname?.includes("halls")) {
            //     setDefaultSelectedKey("/theatres/halls");
            // } else if (pathname?.includes("seat-types")) {
            //     setDefaultSelectedKey("/theatres/seat-types");
            // } else if (pathname?.includes("tax")) {
            //     setDefaultSelectedKey("/theatres/tax");
            // } else {
            //     setDefaultSelectedKey("/theatres");
            // }
        } else {
            setDefaultSelectedKey(pathname);
        }
    }, [pathname]);


    return (
        <Sider
            width={240}
            theme={currentTheme}
            trigger={trigger}
            collapsible={collapsible}
            collapsed={collapsed}
            style={{
                height: "100%",
                overflow: "hidden",
                backgroundColor: currentTheme === ThemeEnum.DARK ? DARK_COLOR : LIGHT_COLOR,
            }}
        >
            <Flex vertical justify='center' align='center'
            style={{ width: '100%', height: "60px" }}>
                {/* <Title level={collapsed ? 5 : 3} type='secondary'
                    style={{ color: currentTheme === ThemeEnum.DARK ? "#f5382c" : "#f5382c", margin: "1rem 0rem", fontSize: collapsed ? 12 : 32 }}>
                    CINEBOXX
                </Title> */}
                {
                    currentTheme === ThemeEnum.DARK ?
                        <img src={cinebox_logo_full_for_dark} style={{width: "80%"}}/> :
                        <img src={cinebox_logo_full_for_light} style={{width: "80%"}} />
                }
            </Flex>
            {
                Array.isArray(defaultOpenKeyState) && defaultOpenKeyState.length > 0 ?
                    <Menu
                        defaultSelectedKeys={[defaultSelectedKeyState]}
                        selectedKeys={defaultSelectedKeyState}
                        // defaultOpenKeys={defaultOpenKeyState}
                        mode="inline"
                        theme={currentTheme}
                        items={filtered_items}
                        openKeys={stateOpenKeys}
                        onOpenChange={onOpenChange}
                        style={{
                            height: "calc(100% - 60px)",
                            overflow: "auto",
                        }}
                    /> : <></>
            }
        </Sider>
    );
};
export default SideBar;