import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/Users/AddEditForm";
import { Link } from "react-router-dom";
import { Button, Checkbox, Flex } from "antd";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/theatre/rest/theatreuser";
const headerTitle = "Theatre Users";
const editPermission = "theatreuser:edit";
const deletePermission = "theatreuser:delete";
const addPermission = "theatreuser:add";

const columns = [
    // {
    //     title: 'User',
    //     dataIndex: 'user',
    //     key: 'user',
    //     render: (text, row) => {
    //         // console.log({text, row});
    //         return row?.user_data?.username
    //     }
    // },
    {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Is Super User?',
        dataIndex: 'is_superuser',
        key: 'is_admin',
        render: (text, row) => {
            return <Checkbox disabled checked={row.is_admin}></Checkbox>
        }
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const AllTheatreUsersPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            headerTitle={headerTitle}
            filters={[allowedFiltersEnum.CLIENT.theatre__client, allowedFiltersEnum.THEATRE.theatre]}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default AllTheatreUsersPage;