import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useNotificationContext } from 'Config/NotificationsContext';
import axios from 'axios';

const { Option } = Select;

const ClientProgramFilterForReports = ({ clientProgramFilterVal, setClientProgramFilterVal, start_date, end_date }) => {

    const openNotification = useNotificationContext();
    const [filterValues, setFilterValues] = useState(null);
    const [loadingFilterValues, setLoadingFilterValues] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingFilterValues(true);
                const response = await axios.get(`/program/client-programs-list/?start_date=${start_date}&end_date=${end_date}`);
                // console.log(response);
                setFilterValues(response.data);
                // if (Array.isArray(response.data) && response.data.length > 0) {
                //     setClientProgramFilterVal(response.data[0].id);
                // }
                setClientProgramFilterVal("all");
            } catch (e) {
                console.error(e);
                openNotification("Couldn't fetch Theatres", e?.message, "error");
            } finally {
                setLoadingFilterValues(false);
            }
        }
        if (start_date && end_date) fetchData();
    }, [openNotification, setClientProgramFilterVal, start_date, end_date]);

    const onFilterSelect = (v) => {
        setClientProgramFilterVal(v);
    }

    return (
        <>
            <Flex justify='flex-start' align='center' gap={5}>
                <label>Program</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Theatre to Filter"
                    loading={loadingFilterValues}
                    onSelect={onFilterSelect}
                    value={clientProgramFilterVal}
                >
                    <Option key={"all"} value={"all"}>All</Option>
                    {
                        filterValues?.map?.((afv, ind) => {
                            return <Option key={afv.id} value={afv.id}>
                                {afv?.title}
                                {/* <Flex vertical>
                                    <span style={{whiteSpace: "normal"}}>{afv?.title}</span>
                                    <small>{afv?.client_data?.title}</small>
                                </Flex> */}
                            </Option>
                        })
                    }
                </Select>
            </Flex>
        </>
    )
}

export default ClientProgramFilterForReports;