import CustomTable from "Components/CustomTable/CustomTable";
// import { useDispatch } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, ACTION_COLUMN_KEY } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/AddEditForm";
import { Link, useParams } from "react-router-dom";
import { Button, Flex } from "antd";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import ViewScheduleButton from "./ViewScheduleButton";
import ViewScheduleDrawer from "Components/ViewSchedule/ViewScheduleDrawer";
import TheatreSync from "Components/Theatre/TheatreSync";
import { SettingOutlined } from "@ant-design/icons"
import { useState } from "react";
import { useNotificationContext } from "Config/NotificationsContext";
import axios from "axios";
import AccessControl from "Router/Access/AccessControl";

const apiUrl = "/theatre/rest/theatre";
const headerTitle = "Theatres";
const editPermission = "theatre:edit";
const deletePermission = "theatre:delete";
const addPermission = "theatre:add";

const DownloadSettings = ({ row }) => {
    const [loading, setLoading] = useState(false);
    const openNotification = useNotificationContext();

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/theatre/rest/theatre/${row?.id}/generate_files/`, {
                responseType: 'blob',
            });

            // Try to parse the response as JSON
            const responseData = response.data;
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const json = JSON.parse(reader.result);
                    // Handle JSON response here
                    console.log('JSON response:', json);
                    openNotification('Error occurred', json.msg, "error");
                } catch (e) {
                    // If the parsing fails, it means the response is not JSON
                    const url = window.URL.createObjectURL(responseData);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${row?.title?.replaceAll?.(" ", "_")}_settings.zip`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            };
            reader.readAsText(responseData);
        } catch (error) {
            console.error('Error downloading the file', error);
            openNotification('An error occurred while downloading the file.', null, "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            type="default"
            shape="circle"
            icon={<SettingOutlined spin={loading} />}
            size="small"
            onClick={handleDownload}
            disabled={loading}
        />
    );
};

const TheatreInfoComponent = ({ row, index, text }) => {
    const user = useSelector(store => store.auth.user);

    return <Flex vertical style={{ gap: 5 }}>
        <Flex wrap="wrap" gap={5}>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["theatreuser:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/users`}>
                    <Button size="small">
                        Users
                    </Button>
                </Link>
            </AccessControl>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["theatretax:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/tax`}>
                    <Button size="small">
                        Tax
                    </Button>
                </Link>
            </AccessControl>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["theatrehall:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/halls`}>
                    <Button size="small">
                        Halls
                    </Button>
                </Link>
            </AccessControl>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["theatrechannel:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/channels`}>
                    <Button size="small">
                        Channels
                    </Button>
                </Link>
            </AccessControl>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["theatredevices:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/devices`}>
                    <Button size="small">
                        Devices
                    </Button>
                </Link>
            </AccessControl>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["possessions:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/pos_sessions`}>
                    <Button size="small">
                        POS Sessions
                    </Button>
                </Link>
            </AccessControl>
            <AccessControl
                userPermissions={user.user_permissions}
                allowedPermissions={["inventory:view"]}
                renderNoAccess={() => (
                    <></>
                )}
            >
                <Link to={`/theatres/${row.id}/inventories`}>
                    <Button size="small">
                        Inventory
                    </Button>
                </Link>
            </AccessControl>
        </Flex>
    </Flex>
}

const getColumns = (user_permissions) => {

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // render: (text) => <a>{text}</a>,
        },
        {
            title: 'Host URL',
            dataIndex: 'host_url',
            key: 'host_url',
            // render: (text) => <a>{text}</a>,
        },
        {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            render: (text, row) => {
                // console.log({text, row});
                return row?.client_data?.title
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text, row) => {
                // console.log({text, row});
                let title = "";
                if (row?.address) title += row?.address;
                if (row?.city_data?.title) title = title + ", " + row?.city_data?.title;
                if (row?.state_data?.title) title = title + ", " + row?.state_data?.title;
                if (row?.country_data?.title) title = title + ", " + row?.country_data?.title;
                return title;
            }
        },
    ];

    let hasAnyTheatreInfoPermission = false;
    let theatreInfoPermissions = ["theatreuser:view", "theatretax:view", "theatrehall:view", "theatrechannel:view", "theatredevices:view", "possessions:view", "inventory:view"]

    for (let perm of theatreInfoPermissions) {
        // console.log("hasAnyTheatreInfoPermission ", perm)
        if (user_permissions?.includes?.(perm)) {
            hasAnyTheatreInfoPermission = true;
            // console.log("hasAnyTheatreInfoPermission ", perm, user_permissions)
            break;
        }
    }

    if (hasAnyTheatreInfoPermission) {
        columns.push({
            title: 'Theatre Info',
            key: 'view-theatre-info',
            fixed: 'right',
            width: "35%",
            render: (text, row, index) => <TheatreInfoComponent row={row} index={index} text={text} />
        });
    }
    if (user_permissions?.includes("theatresync:view")) {
        columns.push(
            {
                title: 'Sync',
                key: 'sync-operation',
                width: 120,
                render: (text, row, index) => {
                    return <TheatreSync text={text} row={row} index={index} />
                },
            }
        );
    }
    if (user_permissions?.includes("theatres_schedule:view")) {
        columns.push({
            title: 'Schedule',
            key: 'view-schedule',
            width: 120,
            render: (text, row, index) => {
                return <ViewScheduleButton text={text} row={row} index={index} apiUrl={apiUrl} ClientProgramApiUrl={""} />
            }
        });
    }

    columns.push({
        title: 'Action',
        key: ACTION_COLUMN_KEY,
        fixed: 'right',
        width: 220,
        render: (text, row, index) => {
            return (<Flex style={{ gap: 5 }}>
                <AccessControl
                    userPermissions={user_permissions}
                    allowedPermissions={["theatres_config:download"]}
                    renderNoAccess={() => (
                        <></>
                    )}
                >
                    <DownloadSettings row={row} />
                </AccessControl>

                <RenderActionButtons
                    text={text}
                    row={row}
                    index={index}
                    apiUrl={apiUrl}
                    editPermission={editPermission}
                    deletePermission={deletePermission} />
            </Flex>);
        },
    });

    return columns;
}

const TheatresPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    const columns = getColumns(userPermissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);


    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            filters={[allowedFiltersEnum.CLIENT.client]}
            addPermissionNames={[addPermission]}
        />
        <ViewScheduleDrawer />
    </>;
}

export default TheatresPage;