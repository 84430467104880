import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { asyncStatuses } from "Redux/enums";
import axios from "axios";

export const MAX_DAYS_ALLOWED_TO_CREATE_SHOWS_INCLUDING_TODAY = 15;
export const START_TIME_FORMAT = 'HH:mm';
export const TIME_DIFFERENCE_FORMAT = 'mm';
export const SELECTED_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

export const fetchClientProgramDataAsync = createAsyncThunk("clientProgram/retrieve", async ({ id }, thunkAPI) => {
    try {
        const response = await axios.get(`/program/rest/client-programs/${id}/`);
        return response.data;
    } catch (err) {
        console.error(err);
        return thunkAPI.rejectWithValue({ msg: "Something went wrong while retreiving program data" });
    }
});

export const saveShowsAsync = createAsyncThunk("clientProgram/saveShows", async ({ data }, thunkAPI) => {
    try {
        const response = await axios.post(`/program/bulk-update-shows/`, data);
        return response.data;
    } catch (err) {
        console.error(err);
        if (err?.response?.data?.responseMsg){
            return thunkAPI.rejectWithValue({msg: err.response.data.responseMsg});
        }
        if (err?.code && err?.message){
            return thunkAPI.rejectWithValue({msg: err.message});
        }
        return thunkAPI.rejectWithValue({});
    }
});

export const fetchUserClientsListAsync = createAsyncThunk("clients/list", async (_, thunkAPI) => {
    try {
        const response = await axios.get('/user/clients-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(err)
    }
});


const initialState = {
    selectedProgramData: null,
    isCreateShowDrawerVisible: false,
    retrieveStatus: null,
    retrieveErrorMsg: null,
    startTime: '08:00', //START_TIME_FORMAT
    timeDifference: '15', //TIME_DIFFERENCE_FORMAT
    selectedDate: '', //SELECTED_DATE_FORMAT
    allShowsData: {}, // Format: {'Date in SELECTED_DATE_FORMAT':{}},
    showsDataUpdatesCounter: 0, // this is so that all other time slots are updated
    saveShowsDataRequestStatus: null,
    saveShowsDataRequestError: null,
    userClientsListRequestStatus: null,
    userClientsListRequestError: null,
    userClientsList: [],
    selectedClient: '',
}

const showsSlice = createSlice({
    name: "showsCreations",
    initialState: initialState,
    reducers: {
        setSelectedProgramData(state, action) {
            state.selectedProgramData = action.payload;
        },
        hideCreateShowDrawer(state) {
            state.isCreateShowDrawerVisible = false;
        },
        showCreateShowDrawer(state) {
            state.isCreateShowDrawerVisible = true;
        },
        resetRetrieveStatus(state) {
            state.retrieveStatus = null;
            state.retrieveErrorMsg = null;
        },
        setStartTime(state, action) {
            state.startTime = action.payload;
        },
        setTimeDifference(state, action) {
            state.timeDifference = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        resetShowsData(state) {
            state.allShowsData = {};
            state.saveShowsDataRequestError = null;
            state.saveShowsDataRequestStatus = null;
        },
        addAShowTime(state, { payload: { hall_id, time, date, client_program_id, runtime } }) {
            if (date in state.allShowsData) {
                state.allShowsData[date].push({ hall_id, time, date, client_program_id, runtime });
            } else {
                state.allShowsData[date] = [{ hall_id, time, date, client_program_id, runtime }];
            }
            state.showsDataUpdatesCounter = state.showsDataUpdatesCounter + 1;
        },
        removeAShowTime(state, { payload: { date, index } }) {
            if (state.allShowsData[date].length === 1) {
                delete state.allShowsData[date];
            } else {
                let allShowsOfThisDate = JSON.parse(JSON.stringify(state.allShowsData[date]));
                state.allShowsData[date] = allShowsOfThisDate.filter((d, i) => i !== index);
            }
            state.showsDataUpdatesCounter = state.showsDataUpdatesCounter + 1;
        },
        setSelectedClient(state, action) {
            console.log(action)
            state.selectedClient = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientProgramDataAsync.pending, (state, action) => {
            state.retrieveStatus = asyncStatuses.LOADING;
            state.selectedProgramData = null;
            state.retrieveErrorMsg = null;
        });
        builder.addCase(fetchClientProgramDataAsync.rejected, (state, action) => {
            state.retrieveStatus = asyncStatuses.FAILED;
            state.selectedProgramData = null;
            if (action.payload?.msg) {
                state.retrieveErrorMsg = action.payload.msg;
            }
        });
        builder.addCase(fetchClientProgramDataAsync.fulfilled, (state, action) => {
            state.retrieveErrorMsg = null;
            state.selectedProgramData = action.payload;
            state.retrieveStatus = asyncStatuses.SUCCESS;
            state.allShowsData = action.payload?.allShowsData;
        });

        // 

        builder.addCase(saveShowsAsync.pending, (state) => {
            state.saveShowsDataRequestStatus = asyncStatuses.LOADING;
        });
        builder.addCase(saveShowsAsync.rejected, (state, action) => {
            state.saveShowsDataRequestStatus = asyncStatuses.FAILED;
            if (action.payload?.msg){
                state.saveShowsDataRequestError = action.payload.msg;
            }
        });
        builder.addCase(saveShowsAsync.fulfilled, (state, action) => {
            state.saveShowsDataRequestStatus = asyncStatuses.SUCCESS;
        });

        builder.addCase(fetchUserClientsListAsync.pending, (state) => {
            state.userClientsListRequestStatus = asyncStatuses.LOADING;
        });
        builder.addCase(fetchUserClientsListAsync.fulfilled, (state, action) => {
            state.userClientsListRequestStatus = asyncStatuses.SUCCESS;
            state.userClientsList = action.payload;
            state.count = action.payload.length;
        });
        builder.addCase(fetchUserClientsListAsync.rejected, (state, action) => {
            state.userClientsList = [];
            state.userClientsListRequestStatus = asyncStatuses.FAILED;
        })
    }
});

export const {
    setSelectedProgramData,
    hideCreateShowDrawer,
    showCreateShowDrawer,
    resetRetrieveStatus,
    setStartTime,
    setTimeDifference,
    setSelectedDate,
    addAShowTime,
    removeAShowTime,
    resetShowsData,
    setSelectedClient
} = showsSlice.actions;
export default showsSlice.reducer;