import { Button, Flex, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import styles from "./ClientSettingsPage.module.css";
import ClientSettingsForm from 'Components/Forms/Clients/Settings/ClientSettingsForm';
import { useNotificationContext } from 'Config/NotificationsContext';
import axios from 'axios';
import { RedoOutlined } from "@ant-design/icons";
import { fetchChannelsList, fetchClientList, fetchConvenienceFeeTypeChoices } from 'Api/commonApis';
import ClientChannelSettingsForm from 'Components/Forms/Clients/Settings/ClientChannelSettingsForm';
import { useParams } from 'react-router';

export const STATUS_VALUES = {
    LOADING: "LOADING",
    DATA_PRESENT: "DATA_PRESENT",
    NO_DATA_PRESENT: "NO_DATA_PRESENT",
    LOADING_FAILED: "LOADING_FAILED"
}

const Settings = ({ name, FormComponent, apiUrl, defaultChannel, clientOptions, convenienceFeeTypeOptions }) => {

    const openNotification = useNotificationContext();

    const [status, setStatus] = useState(STATUS_VALUES.LOADING);
    const [data, setData] = useState(null);
    const [createClicked, setCreateClicked] = useState(false);

    const create = () => setCreateClicked(true);

    const fetchARecord = useCallback(async () => {
        try {
            if (!apiUrl) new Error("No Api URL");
            const response = await axios.get(apiUrl);
            console.log(response);
            if (response?.data?.count === 1) {
                setData(response.data?.results?.[0]);
                setStatus(STATUS_VALUES.DATA_PRESENT);
            } else {
                setStatus(STATUS_VALUES.NO_DATA_PRESENT);
            }
        } catch (err) {
            console.log(err);
            openNotification("Failed to load data", null, "error");
            setStatus(STATUS_VALUES.LOADING_FAILED);
        }
    }, [])

    useEffect(() => {
        fetchARecord();
    }, []);

    const retry = () => {
        fetchARecord();
    }

    let render = null;

    if (status === STATUS_VALUES.NO_DATA_PRESENT) {
        render = <Flex justify='center' align='center' className={`${styles['settings-form']}`}>
            {
                createClicked ?
                    <FormComponent
                        data={data}
                        setData={setData}
                        saveUrl={apiUrl}
                        defaultChannel={defaultChannel}
                        clientOptions={clientOptions}
                        convenienceFeeTypeOptions={convenienceFeeTypeOptions} /> :
                    <Button onClick={create}> Create {name} </Button>
            }
        </Flex>;
    } else if (status === STATUS_VALUES.DATA_PRESENT) {
        render = <Flex justify='start' align='center' className={`${styles['settings-form']}`}>
            <FormComponent
                data={data}
                setData={setData}
                saveUrl={apiUrl}
                clientOptions={clientOptions}
                convenienceFeeTypeOptions={convenienceFeeTypeOptions}
            />
        </Flex>;
    } else if (status === STATUS_VALUES.LOADING_FAILED) {
        render = <Flex justify='center' align='center' className={`${styles['settings-form']}`} style={{ flexDirection: "column" }}>
            <p>Failed to Load</p>
            <Button icon={<RedoOutlined />} iconPosition='start' onClick={retry}>Retry</Button>
        </Flex>;
    } else {
        render = <Flex justify='center' align='center' className={`${styles['settings-form']}`}>
            <Spin />
        </Flex>;
    }

    return (
        <Flex className={`${styles['settings-container']}`}>
            <h3 style={{color: "#f5382c"}}>{name}</h3>
            {render}
        </Flex>
    )
}

const ClientSettingsPage = () => {

    const openNotification = useNotificationContext();
    const params = useParams();
    const { id: client_id } = params;

    const [channelOptions, setChannelOptions] = useState(null);
    const [clientOptions, setClientOptions] = useState(null);
    const [convenienceFeeTypeOptions, setConvenienceFeeTypeOptions] = useState(null);

    useEffect(() => {
        const fetchConvenienceFeeTypeOptions = async () => {
            try {
                const obj = await fetchConvenienceFeeTypeChoices();
                console.log({obj})
                if (typeof obj === "object") {
                    const options = [];
                    for (const key in obj) {
                        options.push({
                            value: key,
                            label: obj[key]
                        })
                    }
                    console.log({options})
                    setConvenienceFeeTypeOptions(options);
                } else {
                    throw new Error("Fetch ConvenienceFeeTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("ConvenienceFeeType Options", err.message, "error");
                } else {
                    openNotification("ConvenienceFeeType Options", "Something went wrong while fetching convenienceFeeType options", "error")
                }
            }
        }
        fetchConvenienceFeeTypeOptions();
    }, []);


    const [channelIds, setChannelIds] = useState({
        pos: "",
        web: "",
        app: "",
        kiosk: ""
    });

    useEffect(() => {
        const fetchChannelOptions = async () => {
            try {
                const list = await fetchChannelsList();
                if (Array.isArray(list)) {
                    setChannelOptions(list);
                } else {
                    throw new Error("Fetch Channels did not return a list");
                }
            } catch (err) {
                console.log({ err });
                setChannelOptions([]);
                if (err.message) {
                    openNotification("Channel Options", err.message, "error");
                } else {
                    openNotification("Channel Options", "Something went wrong while fetching channel options", "error")
                }
            }
        }
        fetchChannelOptions();

        const fetcClientOptions = async () => {
            try {
                const list = await fetchClientList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.title }))
                        setClientOptions(options);
                    } else {
                        openNotification("No Clients Found", "Please enter clients first.", "error");
                    }
                } else {
                    throw new Error("Fetch Clients did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Client Options", err.message, "error");
                } else {
                    openNotification("Client Options", "Something went wrong while fetching client options", "error")
                }
            }
        }
        fetcClientOptions();
    }, []);

    useEffect(() => {
        if (!channelOptions) return

        let web_id = "";
        let pos_id = "";
        let app_id = "";
        let kiosk_id = "";

        try {
            if (channelOptions && Array.isArray(channelOptions)) {
                web_id = channelOptions?.find(a => a.code?.toLowerCase() === "web").id;
                pos_id = channelOptions?.find(a => a.code?.toLowerCase() === "pos").id;
                app_id = channelOptions?.find(a => a.code?.toLowerCase() === "app").id;
                kiosk_id = channelOptions?.find(a => a.code?.toLowerCase() === "kiosk").id;

                setChannelIds({
                    web: web_id,
                    pos: pos_id,
                    app: app_id,
                    kiosk: kiosk_id
                });
            } else {
                throw new Error("Channel data not present or not as expected");
            }
        } catch (err) {
            let desc = null;
            if (typeof err === "string") {
                desc = err;
            }
            console.error(err);
            openNotification("Error while processing channels", desc, "error");
        }
    }, [channelOptions]);


    return (
        <Flex gap={20} className={`${styles['container']}`}>
            <Settings
                name={`Client Settings`}
                FormComponent={ClientSettingsForm}
                apiUrl={`/user/rest/settings/?client=${client_id}`}
                clientOptions={clientOptions}
            />
            {
                channelIds.web && convenienceFeeTypeOptions && <Settings
                    name={`Web Channel Settings`}
                    FormComponent={ClientChannelSettingsForm}
                    apiUrl={`/user/rest/channel-settings/?client=${client_id}&channel=${channelIds.web}`}
                    defaultChannel={channelIds.web}
                    clientOptions={clientOptions}
                    convenienceFeeTypeOptions={convenienceFeeTypeOptions}
                />
            }
            {
                channelIds.pos && convenienceFeeTypeOptions && <Settings
                    name={`POS Channel Settings`}
                    FormComponent={ClientChannelSettingsForm}
                    apiUrl={`/user/rest/channel-settings/?client=${client_id}&channel=${channelIds.pos}`}
                    defaultChannel={channelIds.pos}
                    clientOptions={clientOptions}
                    convenienceFeeTypeOptions={convenienceFeeTypeOptions}
                />
            }
            {
                channelIds.app && convenienceFeeTypeOptions && <Settings
                    name={`App Channel Settings`}
                    FormComponent={ClientChannelSettingsForm}
                    apiUrl={`/user/rest/channel-settings/?client=${client_id}&channel=${channelIds.app}`}
                    defaultChannel={channelIds.app}
                    clientOptions={clientOptions}
                    convenienceFeeTypeOptions={convenienceFeeTypeOptions}
                />
            }
            {
                channelIds.kiosk && convenienceFeeTypeOptions && <Settings
                    name={`Kiosk Channel Settings`}
                    FormComponent={ClientChannelSettingsForm}
                    apiUrl={`/user/rest/channel-settings/?client=${client_id}&channel=${channelIds.kiosk}`}
                    defaultChannel={channelIds.kiosk}
                    clientOptions={clientOptions}
                    convenienceFeeTypeOptions={convenienceFeeTypeOptions}
                />
            }
        </Flex>
    )
}

export default ClientSettingsPage