import { Button, Card, Col, Flex, Form, Input, Row, Select, Spin, Tag } from 'antd'
import FoodCard from 'Components/Order/FoodCard';
import ShowDetailsVertical from 'Components/Order/ShowDetailsVertical'
import React from 'react'
import { IoIosArrowBack, IoIosCloseCircleOutline } from 'react-icons/io';
import SeatSvg20 from './SeatSvg20';
import LoginModal from 'Components/Order/LoginModal';
import PaymentModal from '../PaymentModal';
import CountryCodeFormField from 'Components/Forms/CountryCodeFormField/CountryCodeFormField';

const FoodAndCheckoutMode = ({
    showPaymentTimeLeft,
    timeLeft,
    movieData,
    showData,
    thisTheatre,
    large,
    medium,
    small,
    unlockSeats,
    currentCategory,
    setCurrentCategory,
    categories,
    errorMessage,
    foods,
    orderedFood,
    setOrderedFood,
    maxAllowedFoodQuantity,
    totalFoodQuantity,
    setErrorMessage,
    setTotalFoodQuantity,
    ticketVariety,
    bookedSeats,
    totalPrice,
    ticketCoupon,
    taxes,
    seatCouponTitle,
    setSeatCouponTitle,
    setTicketCoupon,
    setTicketCouponId,
    checkSeatCoupon,
    orderedFoodPrice,
    foodCoupon,
    foodCouponTitle,
    setFoodCouponTitle,
    setFoodCoupon,
    setFoodCouponId,
    checkFoodCoupon,
    totalTicketTaxes,
    totalFoodTaxes,
    isSubmitting,
    setAllowCheckout,
    showLoginModal,
    setShowLoginModal,
    showForm,
    setShowForm,
    email,
    setEmail,
    phone,
    setPhone,
    phoneCode,
    setPhoneCode,
    setNoLoginUserId,
    showPaymentModal,
    myTHeaders,
    showId,
    order,
    setShowPaymentModal,
    paymentTimeLeft,
    setPaymentTimeLeft,
    setShowPaymentTimeLeft,
    paymentId,
    paymentInfoLoading,
    mytPaymentInfo,
    paymentMethod,
    setPaymentMethod,
    fetchMipsPaymentDetails,
    fetchMytPaymentDetails,
    iframeContent,
    convenienceFee,
    fetchCashPaymentDetails,
    handleCheckout,
    posSession,
    posSessions,
    setPosSession,
}) => {

    const total_tickets = bookedSeats?.length;

    let convenience_fee = 0.0;
    let convenience_fee_text = "0.00";
    if (convenienceFee?.value) {
        console.log({ convenienceFee })
        if (convenienceFee?.value && convenienceFee?.type) {
            const type = convenienceFee?.type;
            const value = convenienceFee?.value;

            if (type.includes?.("flat")) {
                if (type.includes?.("per_ticket")) {
                    convenience_fee = value * total_tickets;
                } else if (type.includes?.("per_order")) {
                    convenience_fee = value;
                }
                convenience_fee_text = `${convenience_fee?.toFixed?.(2)}`;
            } else if (type?.includes?.("percentage")) {
                convenience_fee = totalPrice * (value / 100);
                convenience_fee_text = `${convenience_fee?.toFixed?.(2)}`;
            }
        }
    }

    let total_value = totalPrice - ticketCoupon + orderedFoodPrice - foodCoupon + totalTicketTaxes + totalFoodTaxes;
    let total_value_with_convenience_fee = total_value + convenience_fee;
    let checkout_value = Math.ceil(total_value_with_convenience_fee);

    let round_value = 0.00;
    if (checkout_value - total_value_with_convenience_fee >= 0) {
        round_value = checkout_value - total_value_with_convenience_fee
    }

    const onFinish = async (values) => {
        console.log({values}, {phone, phoneCode, email})
        if (bookedSeats.length > 0) {
            handleCheckout()
        }
    };

    return (
        <div className="booking-wrapper">
            <Row gutter={8}>
                <Col span={4}>
                    <ShowDetailsVertical
                        title={movieData?.title}
                        image={movieData?.poster_image}
                        language={showData?.language_data}
                        theatre={thisTheatre}
                        showDate={showData?.date}
                        startTime={showData?.start_time}
                        formatType={showData?.format_type_data?.title}
                        screen={showData?.hall_data?.hall_no}
                    />
                </Col>
                <Col span={14} className={large ? "" : "p-0 m-0"}>
                    <Card
                        className="px-lg-4 px-3 py-4 text-white mb-4"
                        style={{
                            // backgroundColor: "#1c1e29",
                            borderRadius: "0px"
                        }}
                    >
                        <Flex align="center">
                            <IoIosArrowBack size={25} className="me-2 cursor-pointer" onClick={() => unlockSeats()} />
                            <div className="text-white">
                                Select some quick bites!
                            </div>
                        </Flex>
                        <Flex justify="center" align="center" style={{ margin: "1rem 0rem" }} className="text-center my-4">
                            <Tag
                                color={`${currentCategory === "" ? "#1c1e29" : "red"}`}
                                style={{
                                    border: "1px solid #dc3545",
                                    color: `${currentCategory === "" ? "#fff" : "red"}`,
                                    cursor: "pointer",
                                    padding: "0.25rem",
                                    margin: "0.25rem",
                                    borderRadius: 5,
                                }}
                                onClick={() => setCurrentCategory("")}
                            >
                                All
                            </Tag>
                            {categories.map((category) => {
                                return (
                                    <Tag
                                        color={`${currentCategory === category.id ? "#1c1e29" : "red"}`}
                                        style={{
                                            border: "1px solid #dc3545",
                                            color: `${currentCategory === category.id ? "#fff" : "red"}`,
                                            cursor: "pointer",
                                            padding: "0.25rem",
                                            margin: "0.25rem",
                                            borderRadius: 5,
                                        }}
                                        onClick={() => setCurrentCategory(category.id)}
                                    >
                                        {category.title}
                                    </Tag>
                                )
                            })}
                        </Flex>
                        <Flex justify="center" align="center" style={{ color: "red", marginBottom: errorMessage ? "1rem" : 0 }}>{errorMessage}</Flex>
                        <div style={{ maxHeight: "72vh", overflow: "auto" }}>
                            <Row>
                                {foods?.map?.((food) => {
                                    return (
                                        <FoodCard
                                            food={food}
                                            orderedFood={orderedFood}
                                            setOrderedFood={setOrderedFood}
                                            maxAllowedFoodQuantity={maxAllowedFoodQuantity}
                                            totalFoodQuantity={totalFoodQuantity}
                                            setTotalFoodQuantity={setTotalFoodQuantity}
                                            errorMessage={errorMessage}
                                            setErrorMessage={setErrorMessage}
                                        />
                                    )
                                })}
                            </Row>
                        </div>
                    </Card>
                </Col>
                <Col span={6} className={large ? "" : "p-0 m-0"}>
                    <Card
                        className="p-4"
                        style={{
                            // backgroundColor: "#1c1e29",
                            borderRadius: "0px",
                            padding: "1rem"
                        }}
                    >
                        <div className="text-white mb-1">
                            Booking Summary
                            <hr />
                        </div>
                        <div style={{ marginBottom: "0.75rem" }} className="mb-3 text-white">
                            {ticketVariety?.map((variety) => {
                                return (
                                    <Flex gap={2} align="center" >
                                        <SeatSvg20 color={variety.color} />
                                        <span style={{ marginLeft: "0.5rem" }}>&nbsp;{variety.type} </span>
                                        <span > -&nbsp;
                                            {bookedSeats?.filter(bookedSeat => bookedSeat.seatType_data.title === variety.type).map((item, index) => {
                                                return (
                                                    <span>{item?.display_label}{index === bookedSeats?.filter(bookedSeat => bookedSeat.seatType_data.title === variety.type).length - 1 ? "" : ", "}</span>
                                                )
                                            })}
                                        </span>
                                    </Flex>
                                )
                            })}
                        </div>
                        <Flex justify="space-between">
                            <b>{bookedSeats.length} Tickets</b>
                            <b>MUR &nbsp;{totalPrice.toFixed(2)}</b>
                        </Flex>
                        {ticketCoupon > 0 && <Flex justify="space-between" >
                            <b>Coupon</b>
                            <b>- MUR &nbsp;{ticketCoupon.toFixed(2)}</b>
                        </Flex>}
                        {taxes.filter((t) => t.type === "TICKET").length > 0 &&
                            taxes.filter((t) => t.type === "TICKET").map((tax) => {
                                return (
                                    <Flex justify="space-between">
                                        <b>{tax.title}({tax.value}%)</b>
                                        <b>MUR &nbsp;{((totalPrice.toFixed(2) - ticketCoupon.toFixed(2)) * tax.value / 100).toFixed(2)}</b>
                                    </Flex>
                                )
                            })
                        }
                        <Form style={{ marginTop: "1rem" }}>
                            <Flex gap={5} justify="space-between">
                                <Input
                                    allowClear
                                    type="text"
                                    name="coupon"
                                    placeholder="COUPON"
                                    className="border-grey text-grey"
                                    value={seatCouponTitle}
                                    style={{
                                        // backgroundColor: "#1c1e29",
                                        borderRadius: "5px 0px 0px 5px",
                                        border: "1px solid gray",
                                        color: "gray"
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setSeatCouponTitle(e.target.value)
                                        }
                                        else {
                                            setSeatCouponTitle("")
                                            setTicketCoupon(0)
                                            setTicketCouponId(null)
                                        }
                                    }}
                                    required
                                />
                                <Button
                                    className={`${ticketCoupon > 0 ? "bg-success border-success" : "bg-primary border-primary"} px-3 py-1`}
                                    disabled={ticketCoupon > 0}
                                    type="primary"
                                    htmlType="submit"
                                    style={{ backgroundColor: "#f5382c" }}
                                    onClick={(e) => checkSeatCoupon(e)}
                                >
                                    {ticketCoupon > 0 ? "APPLIED!" : "APPLY"}
                                </Button>
                            </Flex>
                        </Form>
                        <hr className="text-white" />
                        <Flex justify="space-between" align="center">
                            <b>Food & Beverage</b>
                            <b>MUR &nbsp;{orderedFoodPrice.toFixed(2)}</b>
                        </Flex>
                        {orderedFood.map((ordFood) => {
                            return (
                                <Flex justify="space-between">
                                    <Flex align="center">
                                        <Flex justify="center" align="center"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setTotalFoodQuantity(totalFoodQuantity - orderedFood.filter(item => item.food === ordFood.food)[0].quantity)
                                                setOrderedFood(orderedFood.filter(item => item.food !== ordFood.food))
                                            }}
                                        >
                                            <IoIosCloseCircleOutline size={20} />
                                        </Flex>
                                        <span className="ms-1">{ordFood.title}(Qt. {ordFood.quantity})</span>
                                    </Flex>
                                    <div>MUR &nbsp;{(ordFood.quantity * ordFood.price).toFixed(2)}</div>
                                </Flex>
                            )
                        })}
                        {foodCoupon > 0 && <Flex justify="space-between">
                            <b>Food Coupon</b>
                            <b>- MUR &nbsp;{foodCoupon.toFixed(2)}</b>
                        </Flex>}
                        {taxes.filter((t) => t.type === "FOOD").length > 0 && orderedFoodPrice > 0 &&
                            taxes.filter((t) => t.type === "FOOD").map((tax) => {
                                return (
                                    <Flex justify="space-between">
                                        <b>{tax.title}({tax.value}%)</b>
                                        <b>MUR &nbsp;{((orderedFoodPrice.toFixed(2) - foodCoupon.toFixed(2)) * tax.value / 100).toFixed(2)}</b>
                                    </Flex>
                                )
                            })
                        }
                        <Form style={{ margin: "1rem 0rem" }}>
                            <Flex justify="space-between" gap={5}>
                                <Input
                                    type="text"
                                    name="coupon"
                                    allowClear
                                    placeholder="COUPON"
                                    className="border-grey text-grey"
                                    value={foodCouponTitle}
                                    style={{
                                        // backgroundColor: "#1c1e29",
                                        borderRadius: "5px 0px 0px 5px",
                                        border: "1px solid gray",
                                        color: "gray"
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            setFoodCouponTitle(e.target.value)
                                        } else {
                                            setFoodCouponTitle("")
                                            setFoodCoupon(0)
                                            setFoodCouponId(null)
                                        }
                                    }}
                                    required
                                />
                                <Button
                                    className={`${foodCoupon > 0 ? "bg-success border-success" : "bg-primary border-primary"} px-3 py-1`}
                                    disabled={foodCoupon > 0}
                                    type="primary"
                                    style={{ backgroundColor: "#f5382c" }}
                                    htmlType="submit"
                                    onClick={(e) => checkFoodCoupon(e)}
                                >
                                    {foodCoupon > 0 ? "APPLIED!" : "APPLY"}
                                </Button>
                            </Flex>
                        </Form>
                        <Flex justify="space-between" style={{ marginTop: "0.5rem" }}>
                            <b>Total</b>
                            <b>
                                MUR {(total_value).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            </b>
                        </Flex>
                        <Flex justify="space-between">
                            <b>Convenience Fee</b>
                            <b>
                                MUR{" "}
                                {convenience_fee_text}
                            </b>
                        </Flex>
                        <Flex justify="space-between" style={{ marginTop: "0.5rem" }}>
                            <b>Rounding</b>
                            <b>MUR {round_value.toLocaleString?.("en-IN", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</b>
                        </Flex>
                        <hr />
                        <Form onFinish={onFinish}>
                            <Flex vertical align='start' gap={5} style={{ marginTop: "0.5rem" }}>
                                <Form.Item
                                    label="POS Session"
                                    name="pos_session"
                                    rules={[
                                        {
                                            // required: true,
                                            message: 'Please input pos-session!',
                                        },
                                    ]}
                                    style={{ marginBottom: 8 }}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Choose a POS Session"
                                        required
                                        // loading={Array.isArray(filterValues) ? false : true}
                                        onSelect={(value) => setPosSession(value)}
                                    // value={channelFilterVal}
                                    >
                                        {posSessions.map((item) => {
                                            return <Select.Option key={item.id}>{`${item.cashier_data.first_name} ${item.cashier_data.last_name}`}</Select.Option>
                                        })}

                                    </Select>
                                </Form.Item>
                            </Flex>
                            <Flex vertical>
                                <CountryCodeFormField setEmail={setEmail} setPhone={setPhone} setPhoneCode={setPhoneCode}/>
                            </Flex>
                            <hr />
                            <Flex justify="space-between" style={{ marginTop: "1.25rem", marginBottom: "1.25rem" }}>
                                <h2>Amount Payable</h2>
                                <h2 style={{ textAlign: "right" }}>MUR {checkout_value.toFixed(2)}</h2>
                            </Flex>
                            <Flex justify="center" align="center">
                                <Button
                                    style={{
                                        border: "1px solid gray",
                                        padding: "0rem 0.75rem"
                                    }}
                                    type="primary"
                                    htmlType='submit'
                                    className="w-100 bg-primary primary-button border border-primary px-3"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <span>
                                            <Spin size="small" style={{ marginRight: 10 }} />
                                            Processing ...
                                        </span>
                                    ) : (
                                        <Flex justify="space-between">
                                            <span style={{ marginRight: 5 }}>Checkout</span>
                                            <span>Total : {checkout_value.toFixed(2)}</span>
                                        </Flex>
                                    )}
                                </Button>
                            </Flex>
                        </Form>
                    </Card>
                </Col>
            </Row>
            <LoginModal
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
                showForm={showForm}
                setShowForm={setShowForm}
                showNoLogin={true}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                setPhoneCode={setPhoneCode}
                checkoutMode={true}
                setAllow={setAllowCheckout}
            />
            <PaymentModal
                showPaymentModal={showPaymentModal}
                setShowPaymentModal={setShowPaymentModal}
                paymentTimeLeft={paymentTimeLeft}
                setPaymentTimeLeft={setPaymentTimeLeft}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                paymentId={paymentId}
                fetchCashPaymentDetails={fetchCashPaymentDetails}
                order={order}
                amountPayable={checkout_value}
                unlockSeats={unlockSeats}
                theatre_id={showData?.theatre}
            // fetchMipsPaymentDetails={fetchMipsPaymentDetails}
            // fetchMytPaymentDetails={fetchMytPaymentDetails}
            // paymentInfoLoading={paymentInfoLoading}
            // iframeContent={iframeContent}
            // mytPaymentInfo={mytPaymentInfo}
            />
        </div >
    )
}

export default FoodAndCheckoutMode