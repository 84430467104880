import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/SeatType/AddEditForm";
import { Checkbox, Flex } from "antd";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";
import { getFormattedAmount } from "Utils/textFormat";

const apiUrl = "/theatre/rest/seat-type";
const headerTitle = "Theatre Channels";
const editPermission = "seat-type:edit";
const deletePermission = "seat-type:delete";
const addPermission = "seat-type:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        render: (text, row) => {
            return <Flex gap={5}>
                <div style={{ borderRadius: 5, width: 25, height: 25, backgroundColor: row.color }}></div>
                <span>{row.color}</span>
            </Flex>
        }
    },
    {
        title: 'Client',
        dataIndex: 'client_title',
        key: 'client_title',
    },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        align: "right"
    },
    {
        title: 'Default Price',
        dataIndex: 'default_price',
        key: 'default_price',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'Web',
        dataIndex: 'web_price',
        key: 'web_price',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'App',
        dataIndex: 'app_price',
        key: 'app_price',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'Kiosk',
        dataIndex: 'kiosk_price',
        key: 'kiosk_price',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const AllClientSeatTypesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            filters={[allowedFiltersEnum.CLIENT.theatre__client]}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default AllClientSeatTypesPage;