import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRolesList } from 'Api/commonApis';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';

const { Option } = Select;

const RoleCodeFilter = ({ openNotification, apiUrl, extraApiParamsState, setExtraApiParamsState, field }) => {

    // const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [filterValues, setFilterValues] = useState([]);
    // const [selectedFilter, setSelectedFilter] = useState("all");

    useEffect(() => {
        const fetchModelNameOptions = async () => {
            try {
                const response = await fetchRolesList();
                setFilterValues(response);
            } catch (err) {
                setFilterValues([]);
            }
        }
        if (!filterValues || (Array.isArray(filterValues) && filterValues.length === 0)) fetchModelNameOptions();;
    }, []);

    const onFilterSelect = (v) => {
        // console.log({ v });
        setSearchParams(params => {
            params.set(searchParamsEnum.PAGE, 1);
        });
        setExtraApiParamsState((p) => {
            if (v === "all") {
                let s = p && JSON.parse(JSON.stringify(p));
                if (!s) {
                    s = {}
                }
                if (field in s) {
                    delete s[field]
                }
                if ('roles__isnull' in s) {
                    delete s['roles__isnull'];
                }
                // console.log({v, s})
                return s;
            } else if (v === "website") {
                const s = {
                    ...p,
                    roles__isnull: 'True'
                }
                if (field in s) {
                    delete s[field]
                }
                console.log({website: s, v});
                return s;
            } else {
                const s = {
                    ...p,
                    [field]: v === "all" ? null : v
                }
                if ('roles__isnull' in s) {
                    delete s['roles__isnull'];
                }
                // console.log({s});
                return s;
            }
        })
    }

    return (
        <>
            <Flex align='center' justify='center' gap={5}>
                <label>Role</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Theatre to Filter"
                    loading={Array.isArray(filterValues) ? false : true}
                    defaultValue={"all"}
                    onSelect={onFilterSelect}
                >
                    <Option key={"all"} value="all">All Users</Option>
                    {
                        filterValues?.map((afv, ind) => {
                            return <Option key={afv.id} value={afv.id}>{afv?.role_name} Users</Option>
                        })
                    }
                    <Option key={"website"} value="website">Website Users</Option>
                </Select>
            </Flex>
        </>
    )
}

export default RoleCodeFilter;