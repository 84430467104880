import { Button, Form, Spinner } from "react-bootstrap";
import React from "react";
import { onInputTextShouldBeLowerCase } from "Utils/formValidators";

const NoLoginForm = ({ email, setEmail, phone, setPhone, setPhoneCode, setShowForm, setShowLoginModal }) => {

    return (
        <Form
            className="text-start w-100"
        >
            <Form.Group className="mb-3">
                <Form.Label className="text-white">Email address</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    className="bg-extradark py-2 border-grey text-lightgrey"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onInput={onInputTextShouldBeLowerCase}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="text-feather">Mobile</Form.Label>
                <Form.Control
                    type="mobile"
                    name="mobile"
                    maxLength={10}
                    placeholder="9988989898"
                    className="bg-extradark text-lightgrey border-grey"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Form.Group>
            <Button
                className="bg-primary border-primary w-100 custom-button my-3 py-2"
                onClick={() => setShowLoginModal(false)}
            >
                <span className="poppins">Continue to Checkout</span>
            </Button>
            <div className="d-flex justify-content-center align-items-center">
                <div className="me-2 text-lightgrey">
                    Already have an account?
                </div>
                <div
                    onClick={() => setShowForm("login")}
                    className="cursor-pointer text-bold text-white"
                >
                    Log In
                </div>
            </div>
        </Form>
    );
};

export default NoLoginForm;
