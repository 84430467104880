import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/Tax/AddEditForm";
import { useParams } from "react-router-dom";
import { Checkbox } from "antd";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/theatre/rest/theatretax";
const headerTitle = "Theatre Users";
const editPermission = "theatretax:edit";
const deletePermission = "theatretax:delete";
const addPermission = "theatretax:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const TheatreTaxPage = () => {

    const params = useParams();
    const { id } = params;

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);
    
    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            extraApiParams={{theatre: id}}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default TheatreTaxPage;