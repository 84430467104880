import { notification } from "antd";
import { useContext, createContext } from "react";


const notificationsContext = createContext();

export const NotificationContextProvider = ({ children }) => {

    const [notificationApi, contextHolder] = notification.useNotification();

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    return (
        <notificationsContext.Provider value={openNotification}>
            {contextHolder}
            {children}
        </notificationsContext.Provider>
    )

}

export const useNotificationContext = () => {
    return useContext(notificationsContext);
}