import { Button, Drawer, Flex } from 'antd'
import React, { useState } from 'react'
import { FaArrowRight } from "react-icons/fa6";
import { Outlet, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import drawerStyles from "./DashboardDrawer.module.css";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import AccessControl from 'Router/Access/AccessControl';
import { useSelector } from 'react-redux';

const paymentGatewayItems = [
    {
        key: '1',
        label: (
            <Link to={'/temp_payment_cybersource'}>
                <Button type='dashed'>CyberSource Secure Acceptance POC</Button>
            </Link>
        ),
    }, {
        key: '2',
        label: (
            <Link to={'/temp_payment_absa_spark'}>
                <Button danger type='dashed'>Absa Spark Hosted Checkout POC</Button>
            </Link>
        ),
    }, {
        key: '3',
        label: (
            <Link to={'/temp_payment_mips'}>
                <Button danger type='dashed'>MIPS Iframe</Button>
            </Link>
        ),
    }, {
        key: '4',
        label: (
            <Link to={'/temp_payment_myt'}>
                <Button danger type='dashed'>MyT Web</Button>
            </Link>
        ),
    },
];

const reportTitlesAndLinks = [
    {
        title: "At A Glance (Today)",
        link: "/dashboard",
    },
    {
        title: "Movie Analysis",
        link: "/reports/movie-analysis/",
    },
    {
        title: "Food Analysis",
        link: "/reports/food-analysis/"
    },
    {
        title: "Hall Seat Heatmap",
        link: "/reports/hall-seat-heatmap/"
    },
    {
        title: "Theatre Performance Comparison Report",
        link: "/reports/theatre-performance-comparison-report/"
    },
    {
        title: "Pre-Sales Report",
        link: "/reports/pre-sales-report/"
    },
    {
        title: "Daily Performance Report",
        link: "/reports/daily-performance-report/"
    },
    {
        title: "Distributor Report",
        link: "/reports/distributor-report/"
    },
    {
        title: "User Onboarding Report",
        link: "/reports/user-onboarding-report/"
    },
    {
        title: "Loyalty/Usage Data Report",
        link: "/reports/loyalty-usage-data-report/"
    },
    {
        title: "Sales Reconciliation Report",
        link: "/reports/sales-reconciliation-report/"
    }
]

const DashoardOutlet = () => {
    const [showReportsList, setShowReportsList] = useState(false);
    const user = useSelector(store => store.auth.user);

    const location = useLocation();
    const path = location.pathname;

    const closeShowReportsList = () => {
        setShowReportsList(false);
    }

    const openShowReportsList = () => {
        setShowReportsList(true);
    }
    return (
        <>
            <Flex justify='flex-start' style={{ width: "100%", height: "100%" }}>
                {/* <Flex style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 10,
                    position: "absolute",
                    top: 10,
                    right: '50%',
                    zIndex: 999,
                    transform: "translate(50%, 0%)"
                }}>
                    <AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={["developer:view"]}
                        renderNoAccess={() => (
                            <></>
                        )}>
                        <Dropdown menu={{ items: paymentGatewayItems }}>
                            <Button>
                                <Space>
                                    Payment Gateways
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </AccessControl>
                </Flex > */}
                <Outlet />
            </Flex >
            {/* <Drawer
                title={`All Reports`}
                width={"350px"}
                onClose={closeShowReportsList}
                open={showReportsList}
                styles={{
                    body: {
                        padding: 0,
                        paddingBottom: 20,
                    },
                }}
            >
                {
                    reportTitlesAndLinks.map((rtl, index) => {
                        console.log({ rtl, index })
                        return (
                            <Link to={rtl.link} key={index} className={`${drawerStyles['link']} ${rtl.link === path ? drawerStyles['active'] : ''}`}>
                                {rtl.title}
                            </Link>
                        );
                    })
                }
            </Drawer> */}
        </>
    )
}

export default DashoardOutlet