import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useNotificationContext } from 'Config/NotificationsContext';
import axios from 'axios';

const { Option } = Select;

const TheatreFilterForReports = ({ theatreFilterVal, setTheatreFilterVal }) => {

    const openNotification = useNotificationContext();
    const [filterValues, setFilterValues] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("/theatre/theatres-list/");
                // console.log(response);
                setFilterValues(response.data);
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setTheatreFilterVal(response.data[0].id);
                }
            } catch (e) {
                console.error(e);
                openNotification("Couldn't fetch Theatres", e?.message, "error");
            }
        }
        fetchData();
    }, []);

    const onFilterSelect = (v) => {
        setTheatreFilterVal(v);
    }

    return (
        <>
            <Flex justify='flex-start' align='center' gap={5}>
                <label>Theatre</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Theatre to Filter"
                    loading={Array.isArray(filterValues) ? false : true}
                    onSelect={onFilterSelect}
                    value={theatreFilterVal}
                >
                    {
                        filterValues?.map?.((afv, ind) => {
                            return <Option key={afv.id} value={afv.id}>{afv?.title}</Option>
                        })
                    }
                </Select>
            </Flex>
        </>
    )
}

export default TheatreFilterForReports;