import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserClientsListAsync } from 'Redux/ShowReducer/showSlices';
import { fetchModelNames } from 'Api/commonApis';
import searchParamsEnum from 'Utils/searchParamsEnum';
import { useSearchParams } from 'react-router-dom';

const { Option } = Select;

const ModelNameFilter = ({ openNotification, apiUrl, extraApiParamsState, setExtraApiParamsState, field }) => {

    const dispatch = useDispatch();
    const user = useSelector(store => store.auth.user);

    const [searchParams, setSearchParams] = useSearchParams();
    const [showModelNameFilter, setShowModelNameFilter] = useState(true);
    const [filterValues, setFilterValues] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("all");
    const [disableFilter, setDisableFilter] = useState(false);

    // const filterValues = useSelector(store => store.shows?.userClientsList);
    // const selectedClient = useSelector(store => store.shows?.selectedClient);


    useEffect(() => {
        const fetchModelNameOptions = async () => {
            try {
                const response = await fetchModelNames();
                setFilterValues(response);
            } catch (err) {
                setFilterValues([]);
            }
        }
        if (!filterValues || (Array.isArray(filterValues) && filterValues.length === 0)) fetchModelNameOptions();;
    }, []);

    const onModelNameSelect = (v) => {
        // console.log({ v });
        if (v === "all") {
            setSelectedFilter("all");
        } else {
            setSelectedFilter(v);
        }
        setSearchParams(params => {
            params.set(searchParamsEnum.PAGE, 1);
        });
        setExtraApiParamsState((p) => {
            if (v === "all") {
                let s = p && JSON.parse(JSON.stringify(p));
                if (!s) {
                    s = {}
                }
                if (field in s) {
                    delete s[field]
                }
                return s;
            } else {
                const s = {
                    ...p,
                    [field]: v === "all" ? null : v
                }
                // console.log({ s, v });
                return s;
            }
        })
    }

    if (!showModelNameFilter){
        return <></>;
    }

    return (
        <>
            <Flex align='center' justify='center' gap={5}>
                <label>Model</label>
                <Select
                    style={{ width: "150px"}}
                    placeholder="Choose a Client to Filter"
                    disabled={disableFilter}
                    loading={Array.isArray(filterValues) ? false : true}
                    value={!selectedFilter ? "all" : selectedFilter}
                    onSelect={onModelNameSelect}
                >
                    {
                        filterValues?.length > 1 ? <>
                            <Option key={"all"} value="all">All</Option>
                            {
                                filterValues?.map((afv, ind) => {
                                    return <Option key={afv.id} value={afv.id}>
                                            <span>{afv.value}</span>
                                    </Option>
                                })
                            }
                        </> : filterValues?.length == 1 ? <Option key={filterValues[0].id} value={filterValues[0].id}>{filterValues[0]?.title}</Option> : <></>
                    }
                </Select>
            </Flex>
        </>
    )
}

export default ModelNameFilter;