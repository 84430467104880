import axios from "axios";

export const fetchCountryList = async () => {
    try {
        const response = await axios.get('/master/countries-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching countries list");
    }
}

export const fetchProgramTypeList = async () => {
    try {
        const response = await axios.get('/user/program-types-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching program types list");
    }
}

export const fetchStateList = async () => {
    try {
        const response = await axios.get('/master/states-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching states list");
    }
}

export const fetchCityList = async () => {
    try {
        const response = await axios.get('/master/cities-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching cities list");
    }
}

export const fetchTerritoryList = async () => {
    try {
        const response = await axios.get('/master/territories-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching territories list");
    }
}

export const fetchUserList = async () => {
    try {
        const response = await axios.get('/user/users-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching users list");
    }
}

export const fetchClientList = async () => {
    try {
        const response = await axios.get('/user/clients-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching clients list");
    }
}

export const fetchChannelsList = async () => {
    try {
        const response = await axios.get('/master/channels-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching channels list");
    }
}

export const fetchConvenienceFeeTypeChoices = async () => {
    try {
        const response = await axios.get('/user/rest/channel-settings/convenience_fee_type_choices/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching convenience fee type choices");
    }
}

export const fetchTheatreTaxTypeChoices = async () => {
    try {
        const response = await axios.get('/theatre/theatre-tax-types/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching theatre tax type choices");
    }
}

export const fetchFoodTypeChoices = async () => {
    try {
        const response = await axios.get('/food/food-types/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching food type choices");
    }
}

export const fetchInventoryStatusChoices = async () => {
    try {
        const response = await axios.get('/food/inventory-status-choices/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching food type choices");
    }
}

export const fetchPaymentMethodChoices = async () => {
    try {
        const response = await axios.get('/order/payment-method-choices/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching payment method choices");
    }
}

export const fetchSeatCouponTypeChoices = async () => {
    try {
        const response = await axios.get('/coupon/seat-coupon-types/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching seat coupon types type choices");
    }
}

export const fetchFoodCouponTypeChoices = async () => {
    try {
        const response = await axios.get('/coupon/food-coupon-types/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching food coupon types type choices");
    }
}

export const validateTheatre = async (id) => {
    try {
        const response = await axios.post(`/theatre/rest/theatre/${id}/validate_theatre/`);
        console.log({ response });
        if (response?.data?.valid) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error("Error", err);
        return false;
    }
}

export const fetchSeatTypeList = async () => {
    try {
        const response = await axios.get('/theatre/seat-type-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching seat type list");
    }
}

export const fetchProgramTypeSurcharges = async (show_id) => {
    try {
        const response = await axios.get(`/program/rest/shows/${show_id}/show_program_type_surcharges/`);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching seat type list");
    }
}

export const fetchTheatresList = async (search_param) => {
    try {
        const response = await axios.get(`/theatre/theatres-list/${search_param}`);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching theatres list");
    }
}

export const fetchThisTheatreChannels = async (id) => {
    try {
        const response = await axios.get(`/theatre/rest/theatrechannel/?theatre=${id}`);
        console.log(response);
        if (response.status === 200) {
            return response.data.results;
        } else {
            return []
        }
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching theatres list");
    }
}

export const fetchProgramsList = async () => {
    try {
        const response = await axios.get('/program/programs-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching programs list");
    }
}

export const fetchClientProgramsList = async (search_param) => {
    try {
        const response = await axios.get(`/program/client-programs-list/${search_param}`);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching client programs list");
    }
}

export const fetchShowDetails = async (id) => {
    try {
        const response = await axios.get(`/program/rest/shows/${id}/`);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching show details");
    }
}

export const fetchShowFormatTypeChoices = async () => {
    try {
        const response = await axios.get('/program/show-format-types/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching show format list");
    }
}

export const fetchHallsList = async (search_param) => {
    try {
        const response = await axios.get(`/hall/halls-list/${search_param}`);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching halls list");
    }
}

export const fetchLanguagesList = async () => {
    try {
        const response = await axios.get('/master/languages-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching languages list");
    }
}

export const fetchFoodCategoryList = async (filter) => {
    try {
        let response = null
        if (filter) {
            response = await axios.get('/food/categories-list/' + filter);
        } else {
            response = await axios.get('/food/categories-list/');
        }
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching categories list");
    }
}

export const fetchFoodList = async (filter) => {
    try {
        let response = null
        if (filter) {
            response = await axios.get('/food/food-list/' + filter);
        } else {
            response = await axios.get('/food/food-list/');
        }
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching food list");
    }
}

export const fetchAllPermissionsList = async () => {
    try {
        const response = await axios.get('/user/permissions-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching all permissions list");
    }
}

export const fetchRolesList = async () => {
    try {
        const response = await axios.get('/user/roles-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching all roles list");
    }
}

export const fetchGenresList = async () => {
    try {
        const response = await axios.get('/master/genres-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching genres list");
    }
}

export const fetchAllDistributorsList = async () => {
    try {
        const response = await axios.get('/distributor/distributors-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching all distributors list");
    }
}

export const fetchAllCertificationList = async () => {
    try {
        const response = await axios.get('/user/certifications-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching all certifications list");
    }
}

export const fetchSeatTypesForThisHall = async (hallId) => {
    try {
        const response = await axios.get(`/hall/rest/hall/${hallId}/distinct_seat_types/`)
        return response.data
    } catch (err) {
        console.error(err);
        throw new Error(`Error while fetching seat types for hall id: ${hallId}`)
    }
}

export const fetchDeviceList = async () => {
    try {
        const response = await axios.get('/devices/devices-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching devices list");
    }
}

export const fetchTheatreUsersList = async () => {
    try {
        const response = await axios.get('/theatre/theatre-users-list/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching theatre users list");
    }
}

export const fetchModelNames = async () => {
    try {
        const response = await axios.get('/utilities/audit_models/');
        console.log(response);
        return response.data;
    } catch (err) {
        console.error(err);
        throw new Error("Error while fetching users list");
    }
}