

import { Flex } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { Segmented, Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SearchForm from './SearchForm';
import AddEditForm from './AddEditForm';
import { SEGMENTS, resetProgramSlice, setCurrentSegmentToManual, setCurrentSegmentToSearch } from 'Redux/ProgramReducer/programSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCertificationList, fetchAllDistributorsList, fetchGenresList } from 'Api/commonApis';
import { useNotificationContext } from 'Config/NotificationsContext';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';
const { confirm } = Modal;

const FormDecider = () => {

    const [distributorOptions, setDistributorsOptions] = useState(null);
    const [certificationOptions, setCertificationOptions] = useState(null);
    const [genresList, setGenresList] = useState(null);

    const openNotification = useNotificationContext();

    const selectedSegment = useSelector(store => store.program.currentSegment);
    const dispatch = useDispatch();

    const onSegmentChange = v => {
        if (v === SEGMENTS.SEARCH) {
            dispatch(setCurrentSegmentToSearch());
        } else if (v === SEGMENTS.MANUAL) {
            dispatch(setCurrentSegmentToManual());
        }
    }

    useEffect(() => {
        const fetchGenresOptions = async () => {
            try {
                const list = await fetchGenresList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: Number(l.tmdb_id), label: l.title }))
                    setGenresList(options);
                } else {
                    throw new Error("Fetch Genres did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Genre Options", err.message, "error");
                } else {
                    openNotification("Genre Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        const fetchDistributorsOptions = async () => {
            try {
                const list = await fetchAllDistributorsList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.name }))
                    setDistributorsOptions(options);
                } else {
                    throw new Error("Fetch Distributors did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Distributor Options", err.message, "error");
                } else {
                    openNotification("Distributor Options", "Something went wrong while fetching distributor options", "error")
                }
            }
        }
        const fetchCertificationOptions = async () => {
            try {
                const list = await fetchAllCertificationList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title }))
                    setCertificationOptions(options);
                } else {
                    throw new Error("Fetch Distributors did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Distributor Options", err.message, "error");
                } else {
                    openNotification("Distributor Options", "Something went wrong while fetching distributor options", "error")
                }
            }
        }
        fetchDistributorsOptions();
        fetchGenresOptions();
        fetchCertificationOptions();
    }, []);

    useEffect(() => {
        return () => dispatch(resetProgramSlice());
    }, []);

    return (
        <CheckIfPrequisitesAreAvailable thisModel='Programs' requiredData={[
            {data: genresList, field: "genre"},
            {data: distributorOptions, field: "distributor"},
            {data: certificationOptions, field: "certification"},
        ]}>
            <Flex vertical justify='flex-start' align='center' gap={20} style={{ height: "100%" }}>
                <Segmented
                    options={[
                        { label: 'Search TMDB', value: SEGMENTS.SEARCH, icon: <BarsOutlined />, },
                        { label: 'Manual Creation', value: SEGMENTS.MANUAL, icon: <AppstoreOutlined /> },
                    ]}
                    style={{ width: "80%" }}
                    onChange={onSegmentChange}
                    block
                    value={selectedSegment}
                />
                <Flex style={{ width: "100%", height: "100%" }}>
                    {
                        selectedSegment === SEGMENTS.SEARCH ? <SearchForm /> :
                            <AddEditForm
                                distributorOptions={distributorOptions}
                                certificationOptions={certificationOptions}
                                genresList={genresList}
                            />
                    }
                </Flex>
            </Flex>
        </CheckIfPrequisitesAreAvailable>
    )
}

export default FormDecider