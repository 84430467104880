import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchTheatreTaxTypeChoices, fetchUserList } from 'Api/commonApis';
import { useParams } from 'react-router';
import { validateTwoDecimalNumber } from 'Utils/formValidators';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();

    console.log({ params });

    const theatreList = useSelector(store => store.theatre?.theatreList);

    const [theatreTaxTypeChoices, setTheatreTaxTypeChoices] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchTheatreTaxTypeOptions = async () => {
            try {
                const obj = await fetchTheatreTaxTypeChoices();
                if (Array.isArray(obj)) {
                    const options = [];
                    obj.forEach(o => {
                        options.push({
                            value: o[0],
                            label: o[0]
                        })
                    })
                    console.log({ foodTypeOptions: options, obj })
                    setTheatreTaxTypeChoices(options);
                } else {
                    throw new Error("Fetch Food Type did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("FoodType Options", err.message, "error");
                } else {
                    openNotification("Food Type Options", "Something went wrong while fetching convenienceFeeType options", "error")
                }
            }
        }
        fetchTheatreTaxTypeOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        let url = apiUrl?.split?.("?")[0];
        // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
        dispatch(editARecord({ apiUrl: url, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        delete val.confirm_password;
        val.username = val.email;
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Theatre Taxes"
                requiredData={[{data: theatreTaxTypeChoices, field: "Theatre Tax Type"}]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW}
                >

                    <Form.Item
                        name="type"
                        label="Tax Type"
                        rules={[
                            {
                                required: true,
                                message: "Theatre Tax Type is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(theatreTaxTypeChoices) && theatreTaxTypeChoices.length > 0 ? false : true}
                            options={theatreTaxTypeChoices}
                        />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter title',
                            },
                            {
                                max: 255,
                                message: "Title cannot be more than 255 characters long"
                            }
                        ]}
                    >
                        <Input showCount placeholder="Please enter title" />
                    </Form.Item>
                    <Form.Item
                        name="slug"
                        label="Slug"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Slug',
                            },
                            {
                                max: 255,
                                message: "Slug cannot be more than 255 characters long"
                            }
                        ]}
                    >
                        <Input showCount placeholder="Please enter Slug" />
                    </Form.Item>
                    <Form.Item
                        name="value"
                        label="Value"
                        rules={[
                            { required: true, message: 'Value is required.' },
                            { validator: validateTwoDecimalNumber },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    {
                        params.id ?
                            <Form.Item
                                hidden
                                name="theatre"
                                initialValue={params?.id}
                            >
                                <Input />
                            </Form.Item> :
                            <Form.Item
                                label="Theatre"
                                name="theatre"
                                style={{ width: "150px" }}
                                rules={[{
                                    required: true
                                }]}
                            >
                                <Select
                                    placeholder="Choose a Theatre to Filter"
                                    loading={Array.isArray(theatreList) ? false : true}
                                    onSelect={(v) => {
                                        console.log({ v });
                                    }}
                                >
                                    {
                                        theatreList?.map((afv, ind) => {
                                            return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                    }
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form>
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;