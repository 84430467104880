import { Modal } from "react-bootstrap";
import React from "react";
import NoLoginForm from "./NoLoginForm";

const LoginModal = ({ showLoginModal, setShowLoginModal, showForm, setShowForm, showNoLogin = false, email, setEmail, phone, setPhone, setPhoneCode }) => {

  return (
    <Modal
      dialogClassName="d-flex align-items-center h-100"
      contentClassName="bg-dark"
      show={showLoginModal}
      onHide={() => {
        setShowForm("login");
        setShowLoginModal(false);
      }}
      animation={false}
    >
      <Modal.Header closeButton={false} className="d-flex border-grey d-flex ">
        <Modal.Title className="text-white">
          {""}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-5">
        <div className="bg-dark border-grey rounded">
          <NoLoginForm email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} setPhoneCode={setPhoneCode} setShowForm={setShowForm} setShowLoginModal={setShowLoginModal} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
