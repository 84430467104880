class FilterType {
    constructor(model, field) {
        this.model = model;
        this.field = field;
    }
}

const allowedFiltersEnum = {
    THEATRE: {
        model: "THEATRE",
        theatre: new FilterType("THEATRE", "theatre"),
        category__theatre: new FilterType("THEATRE", "category__theatre"),
        hall__theatre: new FilterType("THEATRE", "hall__theatre"),
        order__theatre: new FilterType("THEATRE", "order__theatre"),
    },
    CLIENT: {
        model: "CLIENT",
        client: new FilterType("CLIENT", "client"),
        theatre__client: new FilterType("CLIENT", "theatre__client"),
        client_program__client: new FilterType("CLIENT", "client_program__client"),
        category__theatre__client: new FilterType("CLIENT", "category__theatre__client"),
        order__theatre__client: new FilterType("CLIENT", "order__theatre__client")
    },
    USER: {
        model: 'USER',
        history_user: new FilterType("USER", "history_user"),
        user: new FilterType("USER", "user")
    },
    NOTIFICATION: {
        model: "NOTIFICATION",
        notification_type: new FilterType("NOTIFICATION", "notification_type")
    },
    MODELS: {
        model: 'MODELS',
        model_name: new FilterType("MODELS", "model_name")
    },
    ROLE: {
        model: 'ROLE',
        roles__id: new FilterType("ROLE", "roles__id"),
        roles__isnull: new FilterType("ROLE", "roles__isnull")
    }
}

export const allowedFields = (() => {
    const allowedFields = [];
    for (let modelKey in allowedFiltersEnum) {
        if (modelKey !== "model") {
            for (let key in allowedFiltersEnum[modelKey]) {
                if (key !== "model") {
                    allowedFields.push(key);
                }
            }
        }
    }
    return allowedFields;
})();

export default allowedFiltersEnum;