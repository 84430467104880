import { Collapse, Flex, Table, Typography } from 'antd'
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { getFormattedAmount } from 'Utils/textFormat';
const { Text } = Typography;

const getTableColumns = (show_vouchers_used) => {
    const tableColumns = [
        {
            title: 'Film',
            dataIndex: 'film',
            key: 'film'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: "right",
            // sorter: (a, b) => moment(a.date) - moment(b.date),
        },
        {
            title: 'Show',
            dataIndex: 'show',
            key: 'show',
            align: "center",
            // sorter: (a, b) => a.show?.localCompare?.(b.show),
        },
        {
            title: 'Starting',
            dataIndex: 'start_time',
            defaultSortOrder: 'descend',
            key: 'start_time',
            align: "right",
            // sorter: (a, b) => a.start_time - b.start_time,
        },
        {
            title: 'Sold',
            dataIndex: 'sold',
            key: 'sold',
            align: "right",
            // sorter: (a, b) => a.sold - b.sold,
        },
        {
            title: 'Collection',
            dataIndex: 'collection',
            key: 'collection',
            align: "right",
            render: (text) => getFormattedAmount(text)
            // sorter: (a, b) => a.collection - b.collection,
        },
        {
            title: 'Availability',
            dataIndex: 'available',
            key: 'available',
            align: "right",
            // sorter: (a, b) => a.available - b.available,
        }
    ]

    if (show_vouchers_used) {
        tableColumns.push({
            title: 'Vouchers Used',
            dataIndex: 'vouchers_used',
            key: 'vouchers_used',
            align: "right",
            // sorter: (a, b) => a.occupancy - b.occupancy,
        });
    }

    tableColumns.push({
        title: 'Occupancy',
        dataIndex: 'occupancy',
        key: 'occupancy',
        align: "right",
        // sorter: (a, b) => a.occupancy - b.occupancy,
    },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
            align: "right",
            // sorter: (a, b) => a.capacity - b.capacity,
        });

    return tableColumns;
}

const DateData = ({ date, date_data, hall_totals, data_totals, is_last_date, showVouchersUsed }) => {

    const [tableColumns, setTableColumns] = useState(getTableColumns(showVouchersUsed));

    useEffect(() => {
        setTableColumns(getTableColumns(showVouchersUsed));
    }, [showVouchersUsed]);

    const all_program_ids = Object.keys(date_data || {}).filter(r => r !== 'date_totals');
    const date_totals = date_data['date_totals'];
    console.log({ date_data, date_totals, data_totals, is_last_date })
    if (all_program_ids.length === 0) return <></>;

    const data = []

    for (const program of all_program_ids) {
        const program_data = date_data[program]
        const client_program_name = program_data['client_program_title'] || "";
        const show_count = program_data['show_count'];
        const shows = program_data['shows'];

        shows?.forEach?.((show, index) => {
            let row = {
                show: show['id'],
                key: show['id'],
                start_time: show['start_time']?.substring?.(0, 5),
                sold: show['sold'],
                capacity: show['capacity'],
                available: show['available'],
                occupancy: show['occupancy'],
                collection: show['collection'],
                vouchers_used: show['vouchers_used'],
            }

            if (index === 0) {
                row['film'] = client_program_name;
                row['date'] = date;
            }

            data.push(row);
        })
    }

    console.log({ data })

    return (
        <Flex vertical gap={10}>
            <Table
                columns={tableColumns}
                style={{ marginBottom: 0 }}
                bordered
                dataSource={data}
                size='small'
                scrollToFirstRowOnChange
                // stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                scroll={{
                    y: "100%",
                    x: "100%"
                }}
                pagination={false}
                summary={pageData => {
                    return (
                        <>
                            <Table.Summary.Row >
                                <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell'>Day Total</Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell' align='right'>
                                    <Text>{date_totals['sold'] || 0}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell' align='right'>
                                    <Text>{getFormattedAmount(date_totals['collection'] || 0)}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                {
                                    showVouchersUsed ? <Table.Summary.Cell className='table-summary-cell' align='right'>
                                        <Text>{date_totals['vouchers_used'] || 0}</Text>
                                    </Table.Summary.Cell> : <></>
                                }
                                <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                <Table.Summary.Cell className='table-summary-cell' align='right'>
                                    <Text>{date_totals['capacity'] || 0}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            {
                                is_last_date ? <Table.Summary.Row >
                                    <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell' align='right'>
                                        <Text>{data_totals['sold'] || 0}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell' align='right'>
                                        <Text>{getFormattedAmount(data_totals['collection'] || 0)}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                    {
                                        showVouchersUsed ? <Table.Summary.Cell className='table-summary-cell' align='right'>
                                            <Text>{data_totals['vouchers_used'] || 0}</Text>
                                        </Table.Summary.Cell> : <></>
                                    }
                                    <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                    <Table.Summary.Cell className='table-summary-cell' align='right'>
                                        <Text>{data_totals['capacity'] || 0}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row> : <></>
                            }
                        </>
                    );
                }}
            />
        </Flex>
    )
}

export default DateData;