import { landscapePlaceholder } from 'Assets';
import { Button, Col, Flex, Form, Image, Row, Spin, Upload, message } from 'antd';
import React, { useState } from 'react'
import styles from "./AddEditForm.module.css";
import { TMDB_IMAGE_BASE_URL } from './AddEditForm';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { FormModesEnum } from 'Redux/enums';
import { Pagination } from 'antd';

const PAGE_SIZE = 4;

const AvailalbleLogos = ({ availableLogos, selectedLogoPath, changeSelectedLogoPath, setAvailableLogos }) => {

    const currentRecordData = useSelector(store => store.master?.currentRecordData);
    const currentFormMode = useSelector(store => store.master?.currentFormMode);

    const [currentStartIndex, setCurrentStartIndex] = useState(0);
    const [currentEndIndex, setCurrentEndIndex] = useState(PAGE_SIZE - 1);

    const onImageUploadChange = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info?.file?.xhr?.response) {
            const response = JSON.parse(info?.file?.xhr?.response);
            console.log({ response });
            if (response) {
                if (response?.data?.images?.logos) {
                    let new_logos = response?.data?.images?.logos;
                    if (currentFormMode === FormModesEnum.ADD) {
                        if (Array.isArray(availableLogos)) {
                            new_logos = [...new_logos, ...availableLogos];
                        }
                        setAvailableLogos(new_logos);
                        changeSelectedLogoPath(new_logos[0]);
                    } else {
                        setAvailableLogos(new_logos);
                        changeSelectedLogoPath(new_logos[0]);
                    }
                    console.log({ new_logo_images: response?.data?.images?.logos });
                }
            }
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    const changePage = (pageSelected) => {
        let indexAdjustedPageSelected = pageSelected - 1;
        let newStartIndex = indexAdjustedPageSelected * PAGE_SIZE;
        let newEndIndex = newStartIndex + PAGE_SIZE - 1;
        console.log({ pageSelected, indexAdjustedPageSelected, newStartIndex, newEndIndex });
        setCurrentStartIndex(newStartIndex);
        setCurrentEndIndex(newEndIndex);
    }

    return (
        <Row>

            <Col span={24}>
                <Form.Item
                    label="Please choose a Logo"
                    name="logo_image"
                    valuePropName='fileList'
                    style={{ marginBottom: 0 }}
                    rules={[{ required: false, message: 'Please choose a logo image!' }]}
                >
                    <Row>
                        <Flex gap={20}>
                            <Flex>
                                <Upload
                                    name='file'
                                    action={`${axios.defaults.baseURL}/program/rest/client-programs/upload_logo_image/`}
                                    data={{
                                        id: currentFormMode === FormModesEnum.ADD ? "" : currentRecordData.id
                                    }}
                                    accept={".jpg,.jpeg,.png"}
                                    headers={{
                                        authorization: 'authorization-text',
                                    }}
                                    onChange={onImageUploadChange}
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Flex>
                        </Flex>
                    </Row>
                    {
                        availableLogos
                            ?
                            <Row>
                                {
                                    availableLogos?.filter?.((f, i) => i >= currentStartIndex && i <= currentEndIndex)?.map?.((logo, index) => {
                                        let url = `${logo.file_path}`;
                                        if (logo?.is_uploaded) {
                                            url = url.replace("/mcine/media", "/media");
                                            if (!window.location.origin.includes("https")) {
                                                url = `${axios.defaults.baseURL}${url}`;
                                            }
                                        } else {
                                            url = `${TMDB_IMAGE_BASE_URL}${url}`;
                                        }
                                        // console.log({ url });
                                        const logo_path = logo.file_path;
                                        const current_selected = selectedLogoPath?.file_path === logo_path;
                                        return (
                                            <Col
                                                key={logo.file_path}
                                                span={10}
                                                style={{ margin: 10 }}
                                                className={`${current_selected ? styles['selected-logo'] : styles['not-selected-logo']} ${styles['logo-image']}`}
                                                onClick={(e) => { changeSelectedLogoPath(logo, e) }}
                                            >
                                                <Image
                                                    src={url}
                                                    // style={{ width: "100%", objectFit: "contain" }}
                                                    className={styles['logo-image']}
                                                    fallback={landscapePlaceholder}
                                                    placeholder={
                                                        <Flex justify='center' align='center' className={styles['logo-image']}>
                                                            <Spin size='sm' />
                                                        </Flex>
                                                    }
                                                />
                                                <div
                                                    className={styles['selector']}
                                                    data-click-select="selector"
                                                >

                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            : <></>
                    }
                </Form.Item>
                <Flex justify='center' align='center' style={{ padding: 10 }}>
                    <Pagination
                        align="center"
                        showSizeChanger={false}
                        pageSize={4}
                        defaultCurrent={1}
                        total={availableLogos?.length}
                        onChange={changePage}
                    />
                </Flex>
            </Col>
        </Row>
    )
}

export default AvailalbleLogos