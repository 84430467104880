import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Flex, Form, Input, Modal, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchDeviceList, fetchTheatreUsersList, fetchUserList } from 'Api/commonApis';
import { useParams } from 'react-router';
import { DATE_TIME_FORMAT } from 'Redux/ShowReducer/showSlices';
import dayjs from 'dayjs';
import { validateTwoDecimalNumber } from 'Utils/formValidators';
import axios from 'axios';
import CloseSessionModal from './CloseSessionModal';

const AddEditForm = ({ apiUrl, extraApiParamsState, searchParams, setDrawerComponentOpen }) => {

    const params = useParams();
    console.log({ params });

    const [notificationApi, contextHolder] = notification.useNotification();

    const theatreList = useSelector(store => store.theatre?.theatreList);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [deviceOptions, setDeviceOptions] = useState(null);
    const [cashierOptions, setCashierOptions] = useState(null);
    const [confirmPasswordReqStatus, setConfirmPasswordReqStatus] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPasswordModal, setConfirmPasswordModal] = useState(false);
    const [updateValues, setUpdateValues] = useState(null);

    const [closeSessionModal, setCloseSessionModal] = useState(false);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchDeviceOptions = async () => {
            try {
                const list = await fetchDeviceList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.device_name }))
                        setDeviceOptions(options);
                    } else {
                        openNotification("No devices were Found", "Please enter devices first.", "error");
                    }
                } else {
                    throw new Error("Fetch devices did not return a list");
                }
            } catch (err) {
                console.log({ err });
                if (err.message) {
                    openNotification("Device Options", err.message, "error");
                } else {
                    openNotification("Device Options", "Something went wrong while fetching device options", "error")
                }
            }
        }
        const fetchCashierOptions = async () => {
            try {
                const list = await fetchTheatreUsersList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.username }))
                        setCashierOptions(options);
                    } else {
                        openNotification("No cashiers were Found", "Please enter cashiers first.", "error");
                    }
                } else {
                    throw new Error("Fetch Theatre Users did not return a list");
                }
            } catch (err) {
                console.log({ err });
                if (err.message) {
                    openNotification("Cashier Options", err.message, "error");
                } else {
                    openNotification("Cashier Options", "Something went wrong while fetching cashier options", "error")
                }
            }
        }
        fetchDeviceOptions();
        fetchCashierOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
        return form.resetFields();
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let currentRecordDataCopy = JSON.parse(JSON.stringify(currentRecordData));
        if (currentRecordDataCopy.start_time) {
            let timeString = currentRecordDataCopy.start_time;
            currentRecordDataCopy.start_time = dayjs(timeString, DATE_TIME_FORMAT);
        }
        if (currentRecordDataCopy.end_time) {
            let timeString = currentRecordDataCopy.end_time;
            currentRecordDataCopy.end_time = dayjs(timeString, DATE_TIME_FORMAT);
        }
        form.setFieldsValue(currentRecordDataCopy);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        let url = apiUrl?.split?.("?")[0];
        // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
        dispatch(editARecord({ apiUrl: url, id: currentEditViewFormId, data: val }));
    }

    const onConfirmPasswordFinish = async (val) => {
        setConfirmPasswordReqStatus(asyncStatuses.LOADING);
        try {
            const response = await axios.post('/user/confirm-password/', { password: password });
            console.log({ response });
            if (response.data?.message) {
                openNotification(response.data.message, null, "success");
                setConfirmPasswordReqStatus(asyncStatuses.SUCCESS);
                if (currentFormMode === FormModesEnum.ADD) {
                    addNew(updateValues);
                } else if (currentFormMode === FormModesEnum.EDIT) {
                    edit(updateValues);
                } else {
                    console.log("No suitable mode found");
                }
            }
        } catch (err) {
            setConfirmPasswordReqStatus(asyncStatuses.FAILED);
            if (err.response?.data?.password) {
                openNotification("Incorrect Password", null, "error");
            }
            console.log(err);
        } finally {
            setConfirmPasswordModal(false);
        }
    }

    const onFinish = (val) => {
        val.start_time = val.start_time.format(DATE_TIME_FORMAT);
        val.session_check_required = !!val.session_check_required;
        val.is_active = !!val.is_active;
        console.log({val});
        setUpdateValues(val);
        setConfirmPasswordModal(true);
    }

    return (
        <>
            {contextHolder}
            <Flex align='center' justify='end' style={{marginTop: 10, marginBottom: 20}}>
                {
                    currentRecordData?.is_active ? <Button type='primary' onClick={()=>setCloseSessionModal(true)}>
                        Close Session
                    </Button> : <Button disabled>Session Closed</Button>
                }
            </Flex>
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Form.Item
                    name="cashier"
                    label="Cashier"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a Cashier',
                        },
                    ]}
                >
                    <Select
                        disabled
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(cashierOptions) ? false : true}
                        options={cashierOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="start_device"
                    label="Start Device"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please select a Start Device',
                //     },
                // ]}
                >
                    <Select
                        showSearch
                        disabled
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(deviceOptions) ? false : true}
                        options={deviceOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="start_time"
                    label="Start Time"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please enter Start Time',
                //     },
                // ]}
                >
                    {/* <Input placeholder="Please enter Start Time" /> */}
                    <DatePicker
                        disabled
                        format={DATE_TIME_FORMAT}
                        showTime={{
                            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="end_time"
                    label="End Time"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please enter End Time',
                //     },
                // ]}
                >
                    {/* <Input placeholder="Please enter End Time" /> */}
                    <DatePicker
                        disabled
                        format={DATE_TIME_FORMAT}
                        showTime={{
                            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="end_device"
                    label="End Device"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please select a End Device',
                //     },
                // ]}
                >
                    <Select
                        showSearch
                        disabled
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(deviceOptions) ? false : true}
                        options={deviceOptions}
                    />
                </Form.Item>
                <Form.Item
                    label="Opening Balance"
                    name="opening_balance"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please input Opening Balance!',
                //     },
                //     { validator: validateTwoDecimalNumber },
                // ]}
                >
                    <Input disabled type="number" placeholder="Please enter Opening Balance" />
                </Form.Item>
                <Form.Item
                    label="Closing Balance"
                    name="closing_balance"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please input Closing Balance',
                //     },
                //     { validator: validateTwoDecimalNumber },
                // ]}
                >
                    <Input disabled type="number" placeholder="Please enter Closing Balance" />
                </Form.Item>
                <Form.Item
                    label="Session Check Required"
                    name="session_check_required"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox disabled />
                </Form.Item>
                <Form.Item
                    label="Is Active?"
                    name="is_active"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="note"
                    label="Note"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please enter Note',
                //     },
                // ]}
                >
                    <Input.TextArea allowClear placeholder="Please enter Note" />
                </Form.Item>
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Submit
                                </Button>
                        }
                    </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                        {
                            editRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Updating
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
            <Modal
                title={"Please enter your login password to continue ..."}
                centered
                open={confirmPasswordModal}
                onOk={() => setConfirmPasswordModal(false)}
                onCancel={() => setConfirmPasswordModal(false)}
                footer={null}
            >
                
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onConfirmPasswordFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    style={{
                        marginTop: 20
                    }}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password value={password} onChange={({ target: { value } }) => setPassword(value)} />
                    </Form.Item>
                    <Flex align='center' justify='end' gap={20}>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                disabled={(confirmPasswordReqStatus === asyncStatuses.LOADING || editRecordStatus === asyncStatuses.LOADING) ? true : false}
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%" }}
                            >
                                {
                                    confirmPasswordReqStatus === asyncStatuses.LOADING ? <>
                                        <LoadingOutlined /> Verifying your password
                                    </> :
                                        confirmPasswordReqStatus === asyncStatuses.FAILED || confirmPasswordReqStatus === null ? <>
                                            Verify
                                        </> : editRecordStatus === asyncStatuses.LOADING ? "Updating the record now" : editRecordStatus === asyncStatuses.FAILED ? "Verify" : "Verify"
                                }
                            </Button>
                        </Form.Item>
                    </Flex>
                </Form>
            </Modal>
            { closeSessionModal && <CloseSessionModal closeSessionModal={closeSessionModal} setCloseSessionModal={setCloseSessionModal} apiUrl={apiUrl} extraApiParamsState={extraApiParamsState} searchParams={searchParams} setDrawerComponentOpen={setDrawerComponentOpen}/>}
        </>
    );
};
export default AddEditForm;