import { setStartTime, setTimeDifference, MAX_DAYS_ALLOWED_TO_CREATE_SHOWS_INCLUDING_TODAY, START_TIME_FORMAT, TIME_DIFFERENCE_FORMAT, setSelectedDate, SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { Flex, Segmented, TimePicker } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const getLabelFromDate = (dayJsDate) => {

    const month = dayJsDate.format('MMM');
    const date = dayJsDate.format('DD');
    const year = dayJsDate.format('YYYY')

    return <Flex
        vertical
        style={{
            padding: 4,
        }}
    >
        <small style={{ lineHeight: '18px' }}>{month}</small>
        <h3 style={{ margin: 0 }}>{date}</h3>
        <small style={{ lineHeight: '18px' }}>{year}</small>
    </Flex>
}

const generateOption = (dayJsDate) => {

    return {
        label: getLabelFromDate(dayJsDate),
        value: dayJsDate.format(SELECTED_DATE_FORMAT)
    }
}

const generateDateOptions = (release_date) => {
    let startDate = dayjs();

    if (release_date) {
        startDate = dayjs(release_date, SELECTED_DATE_FORMAT);
    }

    let today = dayjs();
    if (today > startDate) {
        startDate = today;
    }

    const options = [];

    let date = startDate;
    Array.from({ length: MAX_DAYS_ALLOWED_TO_CREATE_SHOWS_INCLUDING_TODAY }).forEach(element => {
        const option = generateOption(date);
        options.push(option);
        date = date.add(1, 'day');
    });
    console.log({options})
    return options;
}

const DateSelection = () => {

    const [options, setOptions] = useState([]);

    const dispatch = useDispatch();
    const currentRecordData = useSelector(store => store.shows?.selectedProgramData);
    const selectedDate = useSelector(store => store.shows?.selectedDate);

    // useEffect(() => {
    //     const today = dayjs();
    //     const formattedToday = today.format(SELECTED_DATE_FORMAT);
    //     dispatch(setSelectedDate(formattedToday));
    // }, []);

    useEffect(()=>{
        setOptions(generateDateOptions(currentRecordData?.release_date));
    }, [currentRecordData]);

    useEffect(()=>{
        if (options.length > 0) {
            console.log(options[0].value)
            dispatch(setSelectedDate(options[0].value));
        }
    }, [options]);

    const startTime = useSelector(store => store.shows?.startTime);
    const timeDifference = useSelector(store => store.shows?.timeDifference);

    const onStartTimeChange = (value) => {
        const newStartTime = value.format(START_TIME_FORMAT);
        // console.log({ newStartTime });
        dispatch(setStartTime(newStartTime));
    }

    const onTimeDifferenceChange = (value) => {
        const newTimeDifference = value.format(TIME_DIFFERENCE_FORMAT);
        // console.log({ newTimeDifference });
        dispatch(setTimeDifference(newTimeDifference));
    }

    const onDateSelect = (v) => {
        dispatch(setSelectedDate(v));
    }

    return (
        <>
            {options && <Segmented onChange={onDateSelect} value={selectedDate} options={options} block />}
            <Flex align='center' gap={40} style={{ padding: "10px 0px" }}>
                <Flex align='center' justify='center' gap={5}>
                    <label>Show Times From</label>
                    <TimePicker
                        onChange={onStartTimeChange}
                        value={dayjs(startTime, START_TIME_FORMAT)}
                        format={START_TIME_FORMAT}
                        allowClear={false}
                    />
                </Flex>
                <Flex align='center' justify='center' gap={5}>
                    <label>Slots</label>
                    <TimePicker
                        onChange={onTimeDifferenceChange}
                        value={dayjs(timeDifference, TIME_DIFFERENCE_FORMAT)}
                        format={TIME_DIFFERENCE_FORMAT}
                        allowClear={false}
                    />
                </Flex>
            </Flex>
        </>
    )
}

export default DateSelection