import React from 'react';
import { Card, Button, Space, Typography, Table, Flex } from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const UpcomingShowCard = ({ show }) => {
    return (
        <Card style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} bordered={false}>
            <Space direction="horizontal" size="large">
                <Flex vertical>
                    <Text strong>Theatre:</Text>
                    <Text>{`${show?.hall_data?.theatre_data?.title} (${show?.hall_data?.hall_no})`}</Text>
                </Flex>
                <Flex vertical>
                    <Text strong>Date:</Text>
                    <Text>{show?.date}</Text>
                </Flex>
                <Flex vertical>
                    <Text strong>Start Time:</Text>
                    <Text>{show?.start_time}</Text>
                </Flex>
                <Flex vertical>
                    <Text strong>Language:</Text>
                    <Text>{show?.language_data?.title}</Text>
                </Flex>
                <Flex>
                    <Link to={`/book-show/${show?.client_program_data?.id}/seats/${show?.id}`}>
                        <Button type="primary" size="small">
                            Book Ticket
                        </Button>
                    </Link>
                </Flex>
            </Space>

        </Card>
    );
};

export default UpcomingShowCard;
