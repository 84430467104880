import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Order/Payments/AddEditForm";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";
import { getFormattedAmount } from "Utils/textFormat";
import { Flex } from "antd";
import { useSelector } from "react-redux";

const apiUrl = "/order/rest/payments";
const headerTitle = "Payments";
const editPermission = "";
const deletePermission = "";
const addPermission = "";

const columns = [
    {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
    },
    {
        title: 'Food Order',
        dataIndex: 'food_order',
        key: 'food_order'
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        key: 'payment_method',
    },
    {
        title: 'Show',
        dataIndex: 'show',
        key: 'show',
        render: (text, row) => (
            <Flex vertical>
                <span>{row?.order_data?.show_data?.client_program_data?.title}</span>
                <span>{row?.order_data?.show_data?.date} {row?.order_data?.show_data?.start_time?.slice?.(0, 5)}</span>
                <span>{row?.order_data?.show_data?.hall_data?.hall_no}</span>
            </Flex>
        ),
    },
    {
        title: 'Payment Status',
        dataIndex: 'payment_status',
        key: 'payment_status',
    },
    {
        title: 'Transaction Ref. No.',
        dataIndex: 'payment_response',
        key: 'payment_my_t_trade_no',
        render: (text, record) => (
            <>
                {record.payment_response && record.payment_response.hasOwnProperty(" tradeNo") ? (
                    <div>{record.payment_response[" tradeNo"]}</div>
                ) : null}
                {record.payment_response && record.payment_response.hasOwnProperty("id_order") ? (
                    <div>{record.payment_response["id_order"]}</div>
                ) : null}
                {record.payment_response && record.payment_response.hasOwnProperty("transactionId") ? (
                    <div>{record.payment_response["transactionId"]}</div>
                ) : null}
            </>
        ),
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const OrderPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
        filters={[allowedFiltersEnum.THEATRE.order__theatre, allowedFiltersEnum.CLIENT.order__theatre__client]}
    />;
}

export default OrderPage;