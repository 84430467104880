import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Coupons/Food/AddEditForm";
import { Checkbox, Image } from "antd";
import { useSelector } from "react-redux";
import getPermittedColumns from "Utils/getPermittedColumns";
import CustomTable from "Components/CustomTable/CustomTable";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import { FileImageOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ExportXlsx from "../Reports/ExportXlsx";

const apiUrl = "/coupon/rest/food-coupons";
const headerTitle = "Food Coupons";
const editPermission = "food_coupons:edit";
const deletePermission = "food_coupons:delete";
const addPermission = "food_coupons:add";

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Is expired',
    dataIndex: 'is_expired',
    key: 'is_expired',
    align: "center",
    render: (text, row) => {
      return <Checkbox disabled checked={row.is_expired}></Checkbox>
    }
  },
  {
    title: 'Used Times',
    dataIndex: 'count',
    key: 'count',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Limit',
    dataIndex: 'limit',
    key: 'limit',
    render: (text) => (text || "-"),
    // align: "right"
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
    render: (text, row) => {
      return <span>{dayjs(row.start_date)?.format?.('MMM DD, YYYY')}</span>
    },
    align: "right"
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
    render: (text, row) => {
      return <span>{dayjs(row.end_date)?.format?.('MMM DD, YYYY')}</span>
    },
    align: "right"
  },
  {
    title: 'POS Active',
    dataIndex: 'pos_active',
    key: 'pos_active',
    align: "center",
    render: (text, row) => {
      return <Checkbox disabled checked={row.pos_active}></Checkbox>
    }
  },
  {
    title: 'Kiosk Active',
    dataIndex: 'kiosk_active',
    key: 'kiosk_active',
    align: "center",
    render: (text, row) => {
      return <Checkbox disabled checked={row.kiosk_active}></Checkbox>
    }
  },
  {
    title: 'Web Active',
    dataIndex: 'web_active',
    key: 'web_active',
    align: "center",
    render: (text, row) => {
      return <Checkbox disabled checked={row.web_active}></Checkbox>
    }
  },
  {
    title: 'App Active',
    dataIndex: 'app_active',
    key: 'app_active',
    render: (text, row) => {
      return <Checkbox disabled checked={row.app_active}></Checkbox>
    }
  },
  ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const FoodCouponsPage = () => {

  const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
  let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

  return <>
    <CustomTable
      columns={_columns}
      apiUrl={apiUrl}
      headerTitle={headerTitle}
      MyFormComponent={AddEditForm}
      // filters={[allowedFiltersEnum.CLIENT.theatre__client, allowedFiltersEnum.THEATRE.theatre]}
      addPermissionNames={[addPermission]}
      moreActionsJsx={<ExportXlsx
        apiUrl={`/coupon/food-coupons-export`}
        payload={{}}
        reportName={'food_coupons'}
        reportTitleForNotification={'Food Coupons'}
      />}
    />;
  </>
}

export default FoodCouponsPage;