import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/PlatformUsers/Permissions/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/user/rest/permissions";
const headerTitle = "Platform User Permissions";
const editPermission = "permissions:edit";
const deletePermission = "permissions:delete";
const addPermission = "permissions:add"
const columns = [
    {
        title: 'Name',
        dataIndex: 'permission_name',
        key: 'permission_name',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const PermissionsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default PermissionsPage;