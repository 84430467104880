import { useNotificationContext } from 'Config/NotificationsContext';
import { Button, Flex } from 'antd';
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import React, { useState } from 'react';

export const ORIENTATION_OPTIONS = {
    LANDSCAPE: "l",
    PORTRAIT: "p"
}

const ExportPdf = ({
    elementIdToExport = "report-content",
    orientation = ORIENTATION_OPTIONS.PORTRAIT,
    report_name = 'report'
}) => {
    const [isExporting, setIsExporting] = useState(false);

    const openNotification = useNotificationContext();

    const handleExportPDF = () => {
        setIsExporting(true);

        try {
            const content = document.getElementById(elementIdToExport);

            if (!content) {
                setIsExporting(false);
                openNotification(`${elementIdToExport} not found`, `Element with id="${elementIdToExport}" not found in the current page`, "error");
                return;
            }

            // console.dir(content)

            html2canvas(content).then(canvas => {
                const imgData = canvas.toDataURL('image/png');

                const pdf = new jsPDF(orientation, 'pt', 'a4');

                const imgProps = pdf.getImageProperties(imgData);
                console.log({ imgProps })
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                let heightLeft = pdfHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                heightLeft -= pdf.internal.pageSize.getHeight();

                while (heightLeft >= 0) {
                    position = heightLeft - pdfHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                    heightLeft -= pdf.internal.pageSize.getHeight();
                }

                pdf.save(`${report_name}.pdf`);
                setIsExporting(false);
                openNotification("Export successful", "The PDF file has been downloaded successfully.", "success");
            });
        } catch (err) {
            setIsExporting(false);
            console.log(err);
            openNotification('Export Failed', 'An error occurred while exporting the PDF file.', "error");
        }
    };


    return (
        <Button type="primary" onClick={handleExportPDF} loading={isExporting}>
            <Flex gap={10}>
                <span>PDF</span>
                <DownloadOutlined />
            </Flex>
        </Button>
    )
}

export default ExportPdf