import { useEffect, useState } from "react";
import { Button, Flex, Space, Spin, notification, Radio, Drawer, Form, Input, Select } from "antd";
import axios from "axios";
import { useParams } from "react-router";
import Seat from "Components/Theatre/BulkModeSingleMode/Seat";
import SeatBulk from "Components/Theatre/BulkModeSingleMode/SeatBulk";
import HallLayoutForm from "Components/Forms/Theatres/Halls/HallLayoutForm";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatuses } from "Redux/enums";
import { resetUpdateBulkStatus, resetUpdateSingleStatus } from "Redux/HallLayoutReducer/hallLayoutSlice";

const STATUS = {
  FETCHING: "FETCHING",
  FETCHING_FAILED: "FETCHING_FAILED",
  PROCESSING: "PROCESSING",
  PROCESSING_FAILED: "PROCESSING_FAILED",
  DONE: "DONE"
}

export const MODE = {
  SINGLE: "SINGLE",
  BULK: "BULK"
}

const TheatreHallLayoutPage = () => {
  const dispatch = useDispatch();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [seats, setSeats] = useState(null);
  const [fetchProcessLayoutStatus, setFetchProcessLayoutStatus] = useState(null);

  const [selectedSeats, setSelectedSeats] = useState(null);
  const [currentMode, setCurrentMode] = useState(MODE.SINGLE);

  const [openDrawer, setOpenDrawer] = useState(false);

  const params = useParams();
  const { id, hall_id } = params;
  console.log({ id, hall_id });

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const processSeats = (data) => {
    setFetchProcessLayoutStatus(STATUS.PROCESSING);
    try {
      let seatsArrangement = [];
      for (const seat of data) {

        let index = seat?.index?.split(",");

        let noIndex = !index;
        let indexLengthIsNotTwo = Array.isArray(index) && index.length !== 2;

        let indexAreNotNumbers = true;
        if (!indexLengthIsNotTwo) {
          indexAreNotNumbers = isNaN(Number(index[0])) && isNaN(Number(index[1]))
        }

        if (noIndex || indexLengthIsNotTwo || indexAreNotNumbers) {
          throw new Error("no index in seat");
        }

        let i = Number(index[0]);
        let j = Number(index[1]);
        if (seatsArrangement[i]) {
          seatsArrangement[i][j] = seat;
        } else {
          seatsArrangement[i] = [];
          seatsArrangement[i][j] = seat;
        }
      }
      console.log({ seatsArrangement });
      setFetchProcessLayoutStatus(STATUS.DONE);
      setSeats(seatsArrangement);
    } catch (err) {
      console.error(err);
      setFetchProcessLayoutStatus(STATUS.PROCESSING_FAILED);
      if (typeof err === "string") {
        openNotification("Couldn't process data", err, "error");
      }
    }

  }

  const fetchSeats = async () => {
    try {
      setFetchProcessLayoutStatus(STATUS.FETCHING)
      const response = await axios.get(`/hall/rest/seat/?hall=${hall_id}`);
      console.log({ response });
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          processSeats(response.data);
        } else {
          setFetchProcessLayoutStatus(STATUS.PROCESSING_FAILED);
          openNotification("Error in Seats Data", "No Seats present in the hall", "error");
        }
      } else {
        setFetchProcessLayoutStatus(STATUS.PROCESSING_FAILED);
        openNotification("Error in Seats Data", "Could not find any seats in this hall", "error");
      }
    } catch (err) {
      console.error(err);
      setFetchProcessLayoutStatus(STATUS.FETCHING_FAILED);
      openNotification("Error in Seats Data", "Couldn't fetch seats for this hall. Please contact developers or try again.", "error");
    }
  }

  const updateSingleSeatStatus = useSelector(store => store.hallLayout?.updateSingleSeatStatus);
  const updateSingleSeatErrorMessage = useSelector(store => store.hallLayout?.updateSingleSeatErrorMessage);
  const updateBulkSeatStatus = useSelector(store => store.hallLayout?.updateBulkSeatStatus);
  const updateBulkSeatErrorMessage = useSelector(store => store.hallLayout?.updateBulkSeatErrorMessage);

  useEffect(() => {
    if (updateSingleSeatStatus === asyncStatuses.SUCCESS) {
      openNotification("Successfully updated", null, "success");
      dispatch(resetUpdateSingleStatus());
      setOpenDrawer(false);
      setSelectedSeats(null);
      fetchSeats();
    } else if (updateBulkSeatStatus === asyncStatuses.SUCCESS) {
      openNotification("Successfully updated", null, "success");
      setOpenDrawer(false);
      dispatch(resetUpdateBulkStatus());
      setSelectedSeats(null);
      fetchSeats()
    } else if (updateBulkSeatStatus === asyncStatuses.FAILED) {
      openNotification("Failed to update", updateBulkSeatErrorMessage, "error");
      dispatch(resetUpdateBulkStatus());
    } else if (updateSingleSeatStatus === asyncStatuses.FAILED) {
      openNotification("Failed to update", updateSingleSeatErrorMessage, "error");
      dispatch(resetUpdateSingleStatus());
    }
  }, [updateBulkSeatStatus, updateSingleSeatStatus]);

  const changeSelectedSeats = (newSeats) => {
    console.log("changing selected seats", newSeats)
    if (typeof newSeats === "object" && currentMode === MODE.SINGLE) {
      setSelectedSeats(newSeats);
    } else if (Array.isArray(newSeats) && currentMode === MODE.BULK) {
      setSelectedSeats(newSeats);
    }
  }

  useEffect(() => {
    fetchSeats();
  }, []);

  const onModeChange = (e) => {
    console.log('radio checked', e.target.value);
    setCurrentMode(e.target.value);
  };

  return (
    <>
      {contextHolder}
      <Flex style={{ width: "100%", height: "100%", gap: 20 }} vertical justify="start" align="center">
        <Flex vertical gap={5} style={{ width: "100%", height: "85%", minHeight: "85%", border: "1px solid gray", padding: 2 }} justify="center" align="center">
          <Flex flex={1} justify="center" align="center">
            <Radio.Group onChange={onModeChange} value={currentMode}>
              <Radio value={MODE.SINGLE}>Single Mode</Radio>
              <Radio value={MODE.BULK}>Bulk Mode</Radio>
            </Radio.Group>
          </Flex>
          <Flex flex={12} vertical justify="flex-start" align="center" style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
            {
              fetchProcessLayoutStatus === STATUS.FETCHING ?
                <>
                  <Spin />
                  <span>Loading</span>
                </>
                : fetchProcessLayoutStatus === STATUS.PROCESSING ?
                  <>
                    <Spin />
                    <span>Processing</span>
                  </> : fetchProcessLayoutStatus === STATUS.DONE ? <>
                    {
                      seats?.map?.((row, i) => {
                        return (
                          <Flex key={i}>
                            {
                              row?.map?.((seat, index) => {
                                return (<div key={index}>
                                  {
                                    currentMode === MODE.SINGLE ?
                                      <Seat
                                        seat={seat}
                                        key={seat?.id}
                                        changeSelectedSeats={changeSelectedSeats}
                                        setOpenDrawer={setOpenDrawer}
                                        selectedSeats={selectedSeats}
                                      /> :
                                      <SeatBulk
                                        seat={seat}
                                        key={seat?.id}
                                        changeSelectedSeats={changeSelectedSeats}
                                        setOpenDrawer={setOpenDrawer}
                                        selectedSeats={selectedSeats}
                                      />
                                  }
                                </div>);
                              })
                            }
                          </Flex>
                        )
                      })
                    }
                  </> : <>
                    <em style={{color: "red"}}>Failed to {fetchProcessLayoutStatus === STATUS.FETCHING_FAILED ? "Fetch" : "Process"} Data</em>
                  </>
            }
          </Flex>
        </Flex>
        {
          fetchProcessLayoutStatus === STATUS.DONE ? <>
            {
              currentMode === MODE.BULK ? <Button onClick={() => setOpenDrawer(true)}>
                Save Layout
              </Button> : <></>
            }
          </> : <></>
        }
      </Flex>
      <Drawer
        title={`Save Layout`}
        width={"fit-content"}
        style={{ minWidth: "30vw" }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <HallLayoutForm
          changeSelectedSeats={changeSelectedSeats}
          currentMode={currentMode}
          selectedSeats={selectedSeats}
          key={`form-for-hall-layout`}
          openDrawer={openDrawer}
        />
      </Drawer>
    </>
  );
}

export default TheatreHallLayoutPage;