import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Flex, Upload, Image, Segmented } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { UploadOutlined } from '@ant-design/icons';
import { fetchUserList } from 'Api/commonApis';
import { useNotificationContext } from 'Config/NotificationsContext';
import { FiUserPlus } from "react-icons/fi";
import { BsBroadcastPin } from "react-icons/bs";
import axios from 'axios';

export const NOTIFICATION_TYPES = {
    GENERAL_MESSAGE: {
        title: "General Message",
        value: 'general_message'
    },
    USER_MESSAGE: {
        title: "User Message",
        value: 'user_message'
    }
}

const AddEditForm = ({ apiUrl }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [imageBase64, setImageBase64] = useState(null);
    const [newImage, setNewImage] = useState(null);

    const [userOptions, setUserOptions] = useState(null);

    const openNotification = useNotificationContext()

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);

    const [fetchRecordStatus, setFetchRecordStatus] = useState();
    const [addRecordStatus, setAddRecordStatus] = useState();
    const [currentRecordData, setCurrentRecordData] = useState();
    const [notificationType, setNotificationType] = useState(NOTIFICATION_TYPES.GENERAL_MESSAGE.value);

    useEffect(() => {
        const fetchUserOptions = async () => {
            try {
                const list = await fetchUserList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.username }))
                    setUserOptions(options);
                } else {
                    throw new Error("Fetch Users did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("User Options", err.message, "error");
                } else {
                    openNotification("User Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        fetchUserOptions();
    }, [openNotification]);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
            setImageBase64(null);
            setNewImage(null);
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImageBase64(null);
            setNewImage(null);
        }
    }, [addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData, form]);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch, apiUrl, openNotification]);

    const addNew = async (val) => {
        // console.log(val);
        try {
            setAddRecordStatus(asyncStatuses.LOADING);
            const response = await axios.post('/notifications/send-notification/', val, {headers: {"Content-Type": "multipart/form-data"}});
            console.log(response);
            setAddRecordStatus(asyncStatuses.SUCCESS);
        } catch (err) {
            setAddRecordStatus(asyncStatuses.FAILED);
        }
    }

    const getFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleImageUpload = (file) => {
        console.log(file.file)
        try {
            // Convert the image to base64
            getBase64(file.file, (base64) => {
                setImageBase64(base64);
            });
            setNewImage(file.file);
            // openNotification(`${file.file.name} file uploaded successfully`, null, "success");
        } catch (err) {
            // openNotification(`${file.file.name} file upload failed.`, null, "error");
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const onFinish = (val) => {
        val.image = newImage;
        val.notificationType = notificationType;
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else {
            console.log("No suitable mode found");
        }
    }

    const onSegmentChange = v => {
        setNotificationType(v);
    }

    return (
        <>
            <Flex justify='center' align='center' style={{ width: "100%", marginTop: 10, marginBottom: 20 }}>
                <Segmented
                    options={[
                        { label: NOTIFICATION_TYPES.GENERAL_MESSAGE.title, value: NOTIFICATION_TYPES.GENERAL_MESSAGE.value, icon: <BsBroadcastPin /> },
                        { label: NOTIFICATION_TYPES.USER_MESSAGE.title, value: NOTIFICATION_TYPES.USER_MESSAGE.value, icon: <FiUserPlus /> },
                    ]}
                    style={{ width: "80%" }}
                    onChange={onSegmentChange}
                    block
                    value={notificationType}
                />
            </Flex>
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter title',
                        },
                    ]}
                >
                    <Input placeholder="Please enter title" />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Message"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Message',
                        },
                    ]}
                >
                    <Input.TextArea allowClear placeholder="Please enter Message" rows={9}/>
                </Form.Item>
                <Form.Item
                    label="Image"
                    name="image"
                    valuePropName='fileList'
                    getValueFromEvent={getFile}
                    rules={[{ required: false, message: 'Please upload an image!' }]}
                >
                    <Flex gap={20}>
                        <Flex>
                            <Upload
                                // action={handleImageUpload}
                                beforeUpload={() => false} // Prevent automatic upload
                                listType="picture-card"
                                fileList={[]}
                                showUploadList={false}
                                onChange={handleImageUpload}
                            >
                                {
                                    imageBase64 ? (
                                        <img src={imageBase64} alt="Preview" style={{ width: '100%' }} />
                                    ) : (
                                        <div>
                                            <UploadOutlined />
                                            <div style={{ marginTop: 8 }}>{currentFormMode === FormModesEnum.ADD ? "Upload" : "Change"}</div>
                                        </div>
                                    )
                                }
                            </Upload>
                        </Flex>
                        {
                            currentRecordData?.image && !imageBase64 ? <Image src={currentRecordData.image} alt='Preview' style={{ maxWidth: 100 }} /> : <></>
                        }
                    </Flex>
                </Form.Item>
                {
                    notificationType === NOTIFICATION_TYPES.USER_MESSAGE.value ? <Form.Item
                        name="user_id"
                        label="User"
                        rules={[
                            {
                                required: true,
                                message: "User is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(userOptions) && userOptions.length > 0 ? false : true}
                            options={userOptions}
                        />
                    </Form.Item> : <></>
                }
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Submit
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
        </>
    );
};
export default AddEditForm;