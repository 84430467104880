import React from 'react'

const SuccessPage = () => {
    const containerStyle = {
        textAlign: 'center',
        border: '1px solid #d0e9c6', /* Light green border */
        padding: '40px',
        borderRadius: '8px',
        margin: '30px auto',
        maxWidth: '500px'
    };

    const headingStyle = {
        color: '#28a745', /* Success green color */
        marginBottom: '20px',
        fontSize: '24px'
    };

    const buttonStyle = {
        backgroundColor: '#28a745',
        color: 'white',
        padding: '15px 30px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px' 
    };

    return (
        <div style={containerStyle}>
            <h1 style={headingStyle}>Transaction successful!</h1>
            <p>Your payment has been processed successfully.</p>
        </div>
    );
}

export default SuccessPage