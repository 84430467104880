import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Food/Food/AddEditForm";
import { Link, useParams } from "react-router-dom";
import { Button, Checkbox, Flex, Image } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import getPermittedColumns from "Utils/getPermittedColumns";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";

const apiUrl = "/food/foods";
const headerTitle = "Food";
const editPermission = "food:edit";
const deletePermission = "food:delete";
const addPermission = "food:add";

const columns = [
  {
    title: 'Default Image',
    dataIndex: 'image',
    key: 'image',
    width: "100px",
    render: (text) => <>
      {
        text && <Image.PreviewGroup
          items={[
            text
          ]}
        >
          <Image
            src={text}
            width={100}
            fallback={<FileImageOutlined />}
            style={{objectFit: "contain", width: 85}}
          />
        </Image.PreviewGroup>
      }
    </>,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (text, row) => <>{row?.category_data?.title}</>,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    // render: (text) => <a>{text}</a>,
    align: "right"
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    // render: (text) => <a>{text}</a>,
    align: "right"
  },
  {
    title: 'Available Stock',
    dataIndex: 'available_stock',
    key: 'available_stock',
    // render: (text) => <a>{text}</a>,
    align: "right"
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text, row) => <Checkbox disabled checked={row.status} />,
  },
  ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const FoodPage = () => {

  const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
  let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

  return <CustomTable
    columns={_columns}
    apiUrl={apiUrl}
    headerTitle={headerTitle}
    MyFormComponent={AddEditForm}
    filters={[
      allowedFiltersEnum.CLIENT.category__theatre__client, 
      // allowedFiltersEnum.THEATRE.category__theatre
    ]}
    addPermissionNames={[addPermission]}
  />;
}

export default FoodPage;