import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/Devices/AddEditForm";
import { useParams } from "react-router-dom";
import { Checkbox } from "antd";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/devices/rest/devices";
const headerTitle = "Theatre Devices";
const editPermission = "theatredevices:edit";
const deletePermission = "theatredevices:delete";
const addPermission = "theatredevices:add";
// const editPermission = "~~~~";
// const deletePermission = "~~~~";
// const addPermission = "~~~~";

const columns = [
    {
        title: 'Device Name',
        dataIndex: 'device_name',
        key: 'device_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Theatre',
        dataIndex: 'theatre',
        key: 'theatre',
        render: (text, row) => row?.theatre_data?.title,
    },
    {
        title: 'MAC Address',
        dataIndex: 'mac_address',
        key: 'mac_address',
    },
    {
        title: 'UDID',
        dataIndex: 'udid',
        key: 'udid',
    },
    {
        title: 'General Pin',
        dataIndex: 'general_pin',
        key: 'general_pin',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, row) => {
            return <Checkbox disabled checked={row.status}></Checkbox>
        }
    },
    {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        width: 150,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const TheatreDevicesPage = () => {

    const params = useParams();
    const { id } = params;

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);
    
    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            extraApiParams={{theatre: id}}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default TheatreDevicesPage;