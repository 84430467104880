import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import { Flex, Space, Spin } from 'antd';
import axios from 'axios';
import ReportTitle from 'Components/Dashboard/ReportTitle';
import { getFormattedAmount } from 'Utils/textFormat';

const customLabel = (_, datum) => (
    <span style={{
        fontWeight: 'bold',
        color: '#fff',
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontSize: '12px'
    }}>
        {getFormattedAmount(datum.value)}
    </span>
);

const WIDTH = 380;
const HEIGHT = 290;

const PieChart = ({ defaultData, openNotification, title, url, payload }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Custom color palette with smoother gradient
    const colorPalette = ['#ff6666', '#ff9999', '#ff3333', '#ff0000', '#cc0000'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(url, payload);
                console.log({ pie_chart: response.data });
                if (Array.isArray(response.data)) setData(response.data);
            } catch (e) {
                setData([]);
                openNotification("Error occurred", `Failed to fetch ${title} data`, "error");
                console.error(e);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [url, payload, openNotification, title]);

    const allZero = data.every((item) => item.value === 0);

    return (
        <Space direction="vertical" style={{ flex: 1, width: "100%" }}>
            <ReportTitle title={title} />
            {(allZero || loading) ? (
                <Flex align='center' justify='center' style={{width: WIDTH, height: HEIGHT, backgroundColor: "#f5f5f5"}}>
                    {
                        loading ? <Spin /> : "No Data"
                    }
                </Flex>
            ) : <>
                <Pie
                    data={data}
                    angleField="value"
                    colorField="type"
                    innerRadius={0.6}
                    scale={{
                        color: {
                            palette: colorPalette,
                            offset: (t) => t * 0.8 + 0.1,
                        }
                    }}
                    label={{
                        text: 'value',
                        textAlign: 'center',
                        render: customLabel,
                    }}
                    tooltip={false}
                    legend={{
                        position: 'right',
                        itemSpacing: 5,
                        textStyle: {
                            fontSize: 12,
                        },
                    }} // Enhance tooltip to format value
                    interactions={[
                        { type: 'element-active' }, // Add interaction on hover
                    ]}
                    style={{
                        stroke: '#fff',
                        inset: 1,
                        radius: 10,
                    }}
                    width={WIDTH}
                    height={HEIGHT}
                />
            </>
            }
        </Space>
    );
};

export default PieChart;
