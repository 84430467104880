import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchChannelsList } from 'Api/commonApis';
import { useParams } from 'react-router';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
  const params = useParams();
  console.log({ params });

  const [notificationApi, contextHolder] = notification.useNotification();

  const theatreList = useSelector(store => store.theatre?.theatreList);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [channelOptions, setChannelOptions] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  useEffect(() => {
    const fetchChannelOptions = async () => {
      try {
        const list = await fetchChannelsList();
        if (Array.isArray(list)) {
          if (list.length > 0) {
            const options = list.map(l => ({ value: l.id, label: l.title }))
            setChannelOptions(options);
          } else {
            openNotification("No Channels Present", <p>Please create channels before attempting to create a Theatre Channel.<br /><br />Go to Master {'>'} Channels {'>'} New</p>, "error");
            setChannelOptions([]);
          }
        } else {
          throw new Error("Fetch Channels did not return a list");
        }
      } catch (err) {
        console.log({ err })
        if (err.message) {
          openNotification("Channel Options", err.message, "error");
        } else {
          openNotification("Channel Options", "Something went wrong while fetching channel options", "error")
        }
      }
    }
    fetchChannelOptions();
  }, []);

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (currentFormMode === FormModesEnum.ADD) {
      form.resetFields();
    }
  }, [form, currentFormMode]);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
    return form.resetFields();
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    let url = apiUrl?.split?.("?")[0];
    // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
    dispatch(editARecord({ apiUrl: url, id: currentEditViewFormId, data: val }));
  }

  const onFinish = (val) => {
    val.status = !!val.status;
    console.log({val});
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }

  return (
    <>
      {contextHolder}
      <CheckIfPrequisitesAreAvailable thisModel="Theatre Channels"
        requiredData={[{data: channelOptions, field: "channel"}]}
      >
        <Form layout="vertical"
          requiredMark={true}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(channelOptions) && channelOptions.length > 0) || currentRecordData?.channel_data?.title === "POS"}
        >

          <Form.Item
            name="channel"
            label="Channel"
            rules={[{
              required: true,
              message: "Please add a channel"
            }]}
          >
            <Select
              showSearch
              style={{
                width: 200,
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              loading={Array.isArray(channelOptions) && channelOptions.length > 0 ? false : true}
              options={channelOptions}
            />
          </Form.Item>
          <Form.Item
            name="status"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Status</Checkbox>
          </Form.Item>
          {
            params.id ?
              <Form.Item
                hidden
                name="theatre"
                initialValue={params?.id}
              >
                <Input />
              </Form.Item> :
              <Form.Item
                label="Theatre"
                name="theatre"
                initialValue={params?.id}
                style={{ width: "150px" }}
                rules={[{
                  required: true
                }]}
              >
                <Select

                  placeholder="Choose a Theatre to Filter"
                  loading={Array.isArray(theatreList) ? false : true}
                  onSelect={(v) => {
                    console.log({ v });

                  }}
                >
                  {
                    theatreList?.map((afv, ind) => {
                      return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                    })
                  }
                </Select>
              </Form.Item>
          }
          {
            currentFormMode === FormModesEnum.ADD ? <Form.Item>
              {
                addRecordStatus === asyncStatuses.LOADING ?
                  <Button type="primary" htmlType="submit" disabled>
                    <LoadingOutlined />
                    Submitting
                  </Button> :
                  <Button type="primary" htmlType="submit" >
                    Submit
                  </Button>
              }
            </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
              {
                editRecordStatus === asyncStatuses.LOADING ?
                  <Button type="primary" htmlType="submit" disabled>
                    <LoadingOutlined />
                    Updating
                  </Button> :
                  <Button type="primary" htmlType="submit" >
                    Update
                  </Button>
              }
            </Form.Item> : <></>
          }

        </Form>
      </CheckIfPrequisitesAreAvailable>
    </>
  );
};
export default AddEditForm;