import { Button, Col, Flex, Form, Input, Row, Spin } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'

const SelfHostedCheckout = () => {
    const [loading, setLoading] = useState(false);
    const [userMsg, setUserMsg] = useState("not initiated");
    const [html, setHtml] = useState(null);

    const [form, setForm] = useState(null);

    const initiateProcess = async () => {
        try {
            setLoading(true);
            setUserMsg("initiated");

            let data = {
                order_id: "43a7ce28-0122-4b09-86d6-ac28c8f2d0af"
            }

            const response = await axios.post('/payment/absa_spark/', data);
            console.log({ response });

            if (response.data?.absa_form_data && response.data.absa_form_url) {
                let fields = response.data.absa_form_data;
                let url = response.data.absa_form_url;

                let obj = {
                    fields,
                    url
                }

                if (!fields || !url) {
                    throw new Error("Invalid data received");
                }

                if (response.data.json_response) {
                    setHtml(response.data.json_response)
                }

                setForm(obj)
                setLoading(false);
            } else {
                throw new Error("no data in response")
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            setUserMsg("Error")
        }
    }

    return <Flex vertical gap={5} align='start' justify='start'>
        <Button type='primary' onClick={initiateProcess}>Self Hosted</Button>

        <Flex style={{ padding: 10 }}>
            {
                loading ? <Spin /> : <></>
            }
        </Flex>
        <pre>
            {userMsg}
        </pre>
        {
            form && form.fields && form.url ? <>
                <form method='post' action={form.url}>
                    <Row style={{ gap: 10 }} justify={"center"} align={"center"}>
                        {
                            Object.keys(form.fields).map((f, i) => {

                                return <Col span={12} style={{ display: "flex", gap: 10 }}>
                                    <label htmlFor={f}>{f}</label>
                                    <input type='text' name={f} value={form.fields[f]} style={{flex: 1}}/>
                                </Col>
                            })
                        }
                        <input type="text" name='paymentUri' value={'https://serveo.serveo.net'} />
                        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                            <button type='submit'>submit</button>
                        </Col>
                    </Row>
                </form>
            </> : <></>
        }
        
    </Flex>
}

const TempAbsaSpark = () => {
    return (
        <Flex vertical align='start' justify='flex-start' style={{ width: "100%", height: "100%", gap: 15 }}>
            <h1>ABSA Spark Payment Gateway</h1>
            <Flex style={{ border: "1px solid lightgray", padding: 10, width: "100%" }}>
                <SelfHostedCheckout />
            </Flex>
            <Flex style={{ border: "1px solid lightgray", padding: 10, width: "100%" }}>
                <CustomCheckout />
            </Flex>
        </Flex>
    )
}

const CustomCheckout = () => {
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userMsg, setUserMsg] = useState("not initiated");
    const [html, setHtml] = useState(null);

    const validateCardNumber = (rule, value) => Promise.resolve(value);
    const validateExpiryMonth = (rule, value) => Promise.resolve(value);
    const validateExpiryYear = (rule, value) => Promise.resolve(value);
    const validateCVM = (rule, value) => Promise.resolve(value);

    const initiateProcess = async () => {
        try {
            setLoading(true);
            setUserMsg("initiated");

            let data = {
                order_id: "43a7ce28-0122-4b09-86d6-ac28c8f2d0af"
            }

            const response = await axios.post('/payment/absa_spark/', data);
            console.log({ response });

            if (response.data?.absa_form_data && response.data.absa_form_url) {
                let fields = response.data.absa_form_data;
                let url = response.data.absa_form_url;

                let obj = {
                    fields,
                    url
                }

                if (!fields || !url) {
                    throw new Error("Invalid data received");
                }

                if (response.data.json_response) {
                    setHtml(response.data.json_response)
                }
                obj.fields['full_bypass'] = true
                console.log({ obj })
                setFormData(obj)
                setLoading(false);
            } else {
                throw new Error("no data in response")
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            setUserMsg("Error")
        }
    }


    return (
        <Flex vertical>
            <Button type='primary' onClick={initiateProcess}>Direct Post</Button>

            <Flex style={{ padding: 10 }}>
                {
                    loading ? <Spin /> : <></>
                }
                {
                    formData && formData.fields && typeof formData.fields === "object" ?
                        <form
                            action='https://test.ipg-online.com/connect/gateway/processing'
                            method='post'
                            autoComplete="off"
                        >
                            <Row gutter={15}>
                                <Col span={18}>
                                    <input type='text' name='cardnumber' placeholder='Enter Card Number' required />
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col span={13}>
                                    <Row gutter={15}>
                                        <Col span={12}>
                                            <input name='expmonth' type='text' placeholder='Expiry Month' required />
                                        </Col>
                                        <Col span={12}>
                                            <input name='expyear' type='text' placeholder='Expiry Year' required />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={5}>
                                    <input name='cvm' type='text' placeholder='CVM' required />
                                </Col>
                            </Row>
                            {
                                Object.keys(formData?.fields).map((f, i) => {
                                    return <Col span={8} style={{ display: "flex", gap: 10 }}>
                                        <label htmlFor={f}>{f}</label>
                                        <input type='text' name={f} value={formData.fields[f]} />
                                    </Col>
                                })
                            }
                            <Row gutter={15} style={{ marginTop: 15 }}>
                                <Col span={18}>
                                    <Button htmlType="submit" type='primary'>Proceed</Button>
                                </Col>
                            </Row>
                        </form>
                        : null
                }
            </Flex>
            <pre>
                {userMsg}
            </pre>
        </Flex>
    )
}

export default TempAbsaSpark