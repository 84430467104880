import { Button, Flex, Input, Select, Spin, notification } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import _ from "lodash";

const TempMips = () => {

    const [notificationApi, contextHolder] = notification.useNotification();
    const [iframeContent, setIframeContent] = useState('');
    const [iframeLoading, setIframeLoading] = useState(false);
    const [orderManagementLoading, setOrderManagementLoading] = useState(false);
    const [filterValues, setFilterValues] = useState(null);
    const [instructionValue, setInstructionValue] = useState(null);
    const [orderIdRequired, setOrderIdRequired] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [orderManagementResponse, setOrderManagementResponse] = useState(null);

    useEffect(() => {
        const fetchFilterValues = async () => {
            try {
                const response = await axios.get(`/payment/mips/order-management-instructions/`);
                console.log(response);
                const data = response.data?.data;
                try {
                    if (Array.isArray(data)) {
                        if (_.isObject(data[0])) {
                            setFilterValues(data);
                        } else {
                            new Error("unrecognized")
                        }
                    } else {
                        new Error("unrecognized");
                    }
                } catch (e) {
                    if (e === "unrecognized") {
                        notificationApi.error({
                            message: "Error processing instructions",
                            description: "Data format unrecognized",
                            placement: "top"
                        })
                    }
                }
            } catch (e) {
                setFilterValues([]);
                console.error(e);
            }
        }
        fetchFilterValues();
    }, []);

    const requestIframe = useCallback(async () => {
        try {
            setIframeLoading(true);
            const response = await axios.post('/payment/mips/iframe/');
            console.log({ response });
            if (response?.data?.answer?.payment_zone_data) {
                setIframeContent(response.data.answer.payment_zone_data);
            } else {
                notificationApi.error({
                    message: "Response structure has changed",
                    description: "Couldn't locate answer.payment_zone_data",
                    placement: "top"
                });
                setIframeContent('');
            }
            setIframeLoading(false);
        } catch (e) {
            notificationApi.error({
                message: "Error",
                description: e?.response?.data?.error || e.message,
                placement: "top"
            });
            setIframeLoading(false);
        }
    }, []);

    const callOrderManagementAPI = useCallback(async () => {
        try {
            setOrderManagementLoading(true);
            const url = "/payment/mips/order-management/";
            const payload = {
                "instruction": instructionValue,
                "order_id": orderId
            }
            const response = await axios.post(url, payload);
            console.log({response});
            setOrderManagementResponse(response.data);
            setOrderManagementLoading(false);
        } catch (e) {
            notificationApi.error({
                message: "Error",
                description: e.message,
                placement: "top"
            });
            setOrderManagementLoading(false);
            setOrderManagementResponse(null);
        }
    }, [instructionValue, orderId, notification]);

    const onFilterSelect = (v, obj) => {
        console.log({ v, obj });
        setInstructionValue(v);
        if (obj['data-value']?.order_id_required) {
            setOrderIdRequired(true);
            setOrderId(null);
        } else {
            setOrderIdRequired(false);
            setOrderId(null);
        }
    }

    const changeOrderId = ({ target: { value } }) => setOrderId(value);

    return (
        <>
            {contextHolder}
            <Flex justify='center' align='flex-start' gap={10}>
                <Flex vertical justify='center' align='center' flex={1} gap={10} >
                    <h3>IFrame</h3>
                    <Button danger disabled={iframeLoading} onClick={requestIframe}>Request new Iframe</Button>
                    {
                        iframeLoading ?
                            <Flex justify='center' align='center' flex={1} style={{ height: "100%" }}>
                                <Spin size='small' />
                            </Flex> :
                            <div dangerouslySetInnerHTML={{ __html: iframeContent }} />
                    }
                </Flex>
                <Flex vertical justify='center' align='center' flex={1} gap={10}>
                    <h3>Order Management</h3>
                    <Flex vertical gap={5} style={{ width: "200px" }}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Choose an Instruction"
                            loading={Array.isArray(filterValues) ? false : true}
                            value={instructionValue}
                            onSelect={onFilterSelect}
                        >
                            {
                                filterValues?.map((afv, ind) => {
                                    return <Select.Option key={ind} value={afv.value} data-value={afv}>{afv.label}</Select.Option>
                                })
                            }
                        </Select>
                        {orderIdRequired ? <Input value={orderId} onChange={changeOrderId} placeholder='Order Id' /> : <></>}
                        <Button disabled={orderManagementLoading} onClick={callOrderManagementAPI}>Call API</Button>
                    </Flex>
                    {
                        orderManagementLoading ?
                            <Flex justify='center' align='center' flex={1} style={{ height: "100%" }}>
                                <Spin size='small' />
                            </Flex> :
                            <div style={{maxHeight: "460px", overflow: "auto", backgroundColor: "#f1f1f1", padding: 10}}>
                                <code>
                                    <pre lang='JSON'>
                                        {JSON.stringify(orderManagementResponse, null, 2) }
                                    </pre>
                                </code>
                            </div>
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default TempMips;