import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Space, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchAllPermissionsList, fetchRolesList } from 'Api/commonApis';
import lodash from "lodash";
import PermissionSelectionView, { processPermissions } from '../Roles/Permissions/PermissionSelectionView';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';
import { onInputTextShouldBeLowerCase } from 'Utils/formValidators';

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [availablePermissions, setAvailablePermissions] = useState(null);
  const [availablePermissionsLoading, setAvailablePermissionsLoading] = useState(null);

  const [allPermissionsMap, setAllPermissionsMap] = useState(new Map());

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (currentFormMode === FormModesEnum.ADD) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form, currentFormMode]);

  // useEffect(() => {
  //   const fetchAllPermissionsOptions = async () => {
  //     setAvailablePermissionsLoading(true);
  //     try {
  //       const list = await fetchAllPermissionsList();
  //       if (Array.isArray(list)) {
  //         const options = list.map(l => ({ ...l, value: l.id, label: l.permission_name }))
  //         setAvailablePermissions(options);
  //         let sp = options.filter(r => {
  //           console.log({ currentRecordData, r })
  //           if (currentRecordData.permissions && (r.id in currentRecordData.permissions)) {
  //             return r;
  //           } else {
  //             return null;
  //           }
  //         });
  //         setSelectedPermissions(sp);
  //       } else {
  //         throw new Error("Fetch AllPermissions did not return a list");
  //       }
  //     } catch (err) {
  //       console.log({ err })
  //       if (err.message) {
  //         openNotification("AllPermissions Options", err.message, "error");
  //       } else {
  //         openNotification("AllPermissions Options", "Something went wrong while fetching user options", "error")
  //       }
  //     }
  //     setAvailablePermissionsLoading(false);
  //   }
  //   if (currentFormMode === FormModesEnum.ADD) {
  //     fetchAllPermissionsOptions();
  //   } else {
  //     if (currentFormMode === FormModesEnum.EDIT && currentRecordData?.permissions) {
  //       fetchAllPermissionsOptions();
  //     }
  //   }
  // }, [currentEditViewFormId, currentFormMode, currentRecordData]);

  useEffect(() => {
    const fetchAllPermissionsOptions = async () => {
      setAvailablePermissionsLoading(true);
      try {
        const list = await fetchAllPermissionsList();
        if (Array.isArray(list)) {
          const options = list.map(l => ({ ...l, value: l.id, label: l.permission_name }))
          console.log({ options, p: currentRecordData.permissions })
          setAvailablePermissions(options);
          const permissionsMap = processPermissions(list);
          setAllPermissionsMap(permissionsMap);
          let sp = options.filter(r => {
            if (currentRecordData.permissions && (currentRecordData.permissions.includes(r.id))) {
              console.log({ currentRecordData, r })
              return r;
            } else {
              return null;
            }
          });
          setSelectedPermissions(sp);
          console.log({ sp })
        } else {
          throw new Error("Fetch All Permissions did not return a list");
        }
      } catch (err) {
        console.log({ err })
        if (err.message) {
          openNotification("AllPermissions Options", err.message, "error");
        } else {
          openNotification("AllPermissions Options", "Something went wrong while fetching user options", "error")
        }
      }
      setAvailablePermissionsLoading(false);
    }
    if (currentFormMode === FormModesEnum.ADD || currentFormMode === FormModesEnum.VIEW) {
      fetchAllPermissionsOptions();
    } else {
      if (currentFormMode === FormModesEnum.EDIT && currentRecordData?.permissions) {
        fetchAllPermissionsOptions();
      }
    }
  }, [currentEditViewFormId, currentFormMode, currentRecordData]);

  useEffect(() => {
    const fetcRolesOptions = async () => {
      try {
        const list = await fetchRolesList();
        if (Array.isArray(list)) {
          const options = list.map(l => ({ value: l.id, label: `${l.role_name}` }))
          setAvailableRoles(options);
        } else {
          throw new Error("Fetch Roles did not return a list");
        }
      } catch (err) {
        console.log({ err })
        if (err.message) {
          openNotification("Role Options", err.message, "error");
        } else {
          openNotification("Role Options", "Something went wrong while fetching Role options", "error")
        }
      }
    }
    fetcRolesOptions();
  }, []);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    const rolesAlreadySet = currentRecordData.roles?.map?.(s => {
      console.log({ s });
      let _s = {}
      // _s["key"] = s.id;
      _s["label"] = s.role_name;
      _s["value"] = s.id;
      console.log({ _s });
      return _s;
    });
    console.log({ rolesAlreadySet });
    setSelectedRoles(rolesAlreadySet);
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  }

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    console.log({ rolesAlreadySet___111___: selectedRoles })
    val.roles = selectedRoles?.map?.(r => r.id || r.value);
    val.permissions = selectedPermissions.map(r => r.id);
    console.log({ val })
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }

  // const onSelectPermissionsChange = function (value, option) {
  //   console.log({ value, option });
  //   setSelectedPermissions(prev => {
  //     let r = JSON.parse(JSON.stringify(prev))
  //     if (Array.isArray(r)) {
  //       r.push(option);
  //     } else {
  //       r = []
  //       r.push(option);
  //     }
  //     return r;
  //   })
  // }

  // const onDeselectPermissionsChange = function (value, option) {
  //   console.log({ value, option });
  //   let ss = JSON.parse(JSON.stringify(selectedPermissions));
  //   if (Array.isArray(ss)) {
  //     // 
  //   } else {
  //     ss = [];
  //   }
  //   ss = ss.filter(r => r.id !== value);
  //   setSelectedPermissions(ss);
  // }

  const onSelectPermissionChange = function (value, option) {
    console.log({ value, option });
    setSelectedPermissions(prev => {
      let r = JSON.parse(JSON.stringify(prev))
      console.log({ option, r })
      if (Array.isArray(r)) {
        r.push(option);
      } else {
        r = []
        r.push(option);
      }
      return r;
    })
  }

  useEffect(() => {
    console.log({ rolesAlreadySet_: selectedRoles })
  }, [selectedRoles]);

  const onDeselectPermissionChange = function (value, option) {
    console.log({ value, option });
    let ss = JSON.parse(JSON.stringify(selectedPermissions));
    if (Array.isArray(ss)) {
      // 
    } else {
      ss = [];
    }
    ss = ss.filter(r => r.id !== option.id);
    setSelectedPermissions(ss);
  }

  const onCheckPermission = function (value, option) {
    if (value) {
      onSelectPermissionChange(value, option);
    } else {
      onDeselectPermissionChange(value, option);
    }
  }

  const onSelectRolesChange = function (value, option) {
    console.log({ value, option });
    setSelectedRoles(prev => {
      let r = lodash.cloneDeep(prev);
      if (Array.isArray(r)) {
        r.push(option);
      } else {
        r = []
        r.push(option);
      }
      return r;
    })
  }

  const onDeselectRolesChange = function (value, option) {
    console.log({ value, option });
    let ss = lodash.cloneDeep(selectedRoles);
    if (Array.isArray(ss)) {
      // 
    } else {
      ss = [];
    }
    ss = ss.filter(r => r.value !== value);
    setSelectedRoles(ss);
  }

  return (
    <>
      {contextHolder}
      <CheckIfPrequisitesAreAvailable thisModel="Users" requiredData={[{data: availableRoles, field: "role"}, {data: availablePermissions, field: "permission"}]}>
        <Form layout="vertical"
          requiredMark={true}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          disabled={currentFormMode === FormModesEnum.VIEW}
        >
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: 'Please enter first name',
              },
            ]}
          >
            <Input placeholder="Please enter first name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: 'Please enter last name',
              },
            ]}
          >
            <Input placeholder="Please enter last name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email!',
              },
              {
                type: 'email',
                message: 'Invalid email format!',
              },
            ]}
          >
            <Input onInput={onInputTextShouldBeLowerCase} placeholder="Please enter email" />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="mobile"
            rules={[
              {
                required: true,
                message: 'Please input your mobile number!',
              },
              {
                pattern: /^[0-9]*$/,
                message: 'Please enter a valid mobile number!',
              },
            ]}
          >
            <Input placeholder="Please enter mobile" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: currentFormMode === FormModesEnum.EDIT ? false : true,
                message: 'Please input your password!',
              },
              {
                min: 6,
                message: 'Password must be at least 6 characters!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: currentFormMode === FormModesEnum.EDIT ? false : true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Roles"
          // name="roles"
          // initialValue={selectedRoles}
          >
            <Select
              mode="multiple"
              allowClear
              // style={{ width: '300px' }}
              value={selectedRoles}
              options={availableRoles}
              onSelect={onSelectRolesChange}
              onDeselect={onDeselectRolesChange}
            // labelRender={(props) => {
            //   const { label, value } = props;
            //   console.log({rolesAlreadySet__1__: props})
            //   if (label) {
            //     return value;
            //   }
            //   return <span>value</span>;
            // }}
            />
          </Form.Item>
          {/*
        <Form.Item
          label="Extra Permissions"
          name="permissions"
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: '300px' }}
            loading={availablePermissionsLoading}
            value={selectedPermissions?.map?.(s => {
              s["key"] = s.id;
              s["label"] = s.role_name;
              s["value"] = s.id;
              return s;
            })}
            options={availablePermissions}
            onSelect={onSelectPermissionsChange}
            onDeselect={onDeselectPermissionsChange}
          />
        </Form.Item>
        */}
          <PermissionSelectionView
            allPermissionsMap={allPermissionsMap}
            selectedPermissions={selectedPermissions}
            onCheckPermission={onCheckPermission}
            text='Extra Permissions'
          />
          {
            currentFormMode === FormModesEnum.ADD ? <Form.Item>
              {
                addRecordStatus === asyncStatuses.LOADING ?
                  <Button type="primary" htmlType="submit" disabled>
                    <LoadingOutlined />
                    Submitting
                  </Button> :
                  <Button type="primary" htmlType="submit" >
                    Submit
                  </Button>
              }
            </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
              {
                editRecordStatus === asyncStatuses.LOADING ?
                  <Button type="primary" htmlType="submit" disabled>
                    <LoadingOutlined />
                    Updating
                  </Button> :
                  <Button type="primary" htmlType="submit" >
                    Update
                  </Button>
              }
            </Form.Item> : <></>
          }

        </Form>
      </CheckIfPrequisitesAreAvailable>
    </>
  );
};
export default AddEditForm;