import { Flex, Table, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import TimeTags from './TimeTags';
import { useSelector } from 'react-redux';

const columns = [
    {
        title: 'Hall',
        key: 'hall_no',
        dataIndex: 'hall_no',
        width: 100
    },
    {
        title: 'Timings',
        dataIndex: 'age',
        render: (text, row, index) => {
            return <TimeTags hall={row} />
        },
    },
];

const Halls = (props) => {

    const { halls } = props;
    const timeDifference = useSelector(store => store.shows.timeDifference);
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        if (timeDifference) {
            setShowTable(false);
            setTimeout(() => setShowTable(true), 500);
        }
    }, [timeDifference]);

    if (!halls || !(Array.isArray(halls) && halls.length > 0)) {
        return <Flex justify='center' align='center' style={{ width: "100%" }}>
            <p><em>No halls in this theatre</em></p>
        </Flex>
    }

    return (
        <>
            {showTable ? <Table
                columns={columns}
                bordered
                dataSource={halls.map(hall => ({
                    ...hall,
                    key: hall.id
                }))}
                size="small"
                pagination={false}
            /> : <></>}

        </>
    )
}

export default Halls;