import { Flex } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

const TempMyT = () => {
    const [bookingId, setBookingId] = useState('20222');
    const [location, setLocation] = useState('Port Louis');
    const [amount, setAmount] = useState('1');
    const [data, setData] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post('/payment/myt/process/', { booking_id: bookingId, location, amount });
        const data = await response.data;
        setData(data);
        // Handle response
    }

    return (
        <div>
            <h1>My.T POC</h1>
            <form style={{ marginBottom: 30 }} onSubmit={handleSubmit}>
                <input type="submit" value="Get Data" />
            </form>
            <Flex gap={20} justify='between'>
                <Flex flex={1}>
                    <form
                        action="https://pay.mytmoney.mu/Mt/web/payments"
                        method="post"
                        accept-charset="application/json"
                        id="paymentform"
                        target='my_iframe'
                        style={{ width: "100%" }}
                    >
                        <Flex flex={1} vertical gap={20}>
                            <Flex gap={20} style={{ width: "100%" }}>
                                <label htmlFor="appId">AppId</label>
                                <input style={{ flex: 1 }} name="appId" value={data?.appId} onChange={({ target: { value } }) => setData(prev => ({ ...prev, appId: value }))} />
                            </Flex>
                            <Flex gap={20} style={{ width: "100%" }}>
                                <label htmlFor="merTradeNo">merTradeNo</label>
                                <input style={{ flex: 1 }} name="merTradeNo" value={data?.merTradeNo} onChange={({ target: { value } }) => setData(prev => ({ ...prev, sign: value }))} />
                            </Flex>
                            <Flex gap={20} style={{ width: "100%" }}>
                                <label htmlFor="paymentType">paymentType</label>
                                <input style={{ flex: 1 }} name="paymentType" value={data?.paymentType} onChange={({ target: { value } }) => setData(prev => ({ ...prev, sign: value }))} />
                            </Flex>
                            <Flex gap={20} style={{ width: "100%" }}>
                                <label htmlFor="payload">payload</label>
                                <input style={{ flex: 1 }} name="payload" value={data?.payload} onChange={({ target: { value } }) => setData(prev => ({ ...prev, sign: value }))} />
                            </Flex>
                            <Flex gap={20} style={{ width: "100%" }}>
                                <label htmlFor="sign">sign</label>
                                <input style={{ flex: 1 }} name="sign" value={data?.sign} onChange={({ target: { value } }) => setData(prev => ({ ...prev, sign: value }))} />
                            </Flex>
                            <p><input type="submit" value="Pay by my.t money" /></p>
                        </Flex>
                    </form>
                </Flex>
                <Flex>
                    <iframe name="my_iframe" style={{ width: 350, height: 500 }}></iframe>
                </Flex>
            </Flex>
        </div>
    );
}

export default TempMyT;
