import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchUserList } from 'Api/commonApis';
import { useParams } from 'react-router';
import lodash from "lodash";
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';
import { onInputTextShouldBeLowerCase } from 'Utils/formValidators';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();

    // console.log({ params });

    const theatreList = useSelector(store => store.theatre?.theatreList);

    const [userOptions, setUserOptions] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchUserOptions = async () => {
            try {
                const list = await fetchUserList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.username }))
                    setUserOptions(options);
                } else {
                    throw new Error("Fetch Users did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("User Options", err.message, "error");
                } else {
                    openNotification("User Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        fetchUserOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD && addRecordStatus !== asyncStatuses.LOADING) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let copy = lodash.cloneDeep(currentRecordData);
        copy.password = "";
        console.log({ copy })
        form.setFieldsValue(copy);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        let url = apiUrl?.split?.("?")[0];
        // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
        dispatch(editARecord({ apiUrl: url, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        delete val.confirm_password;
        val.username = val.email;
        val.is_superuser = !!val.is_superuser;
        val.is_active = !!val.is_active;
        val.is_staff = !!val.is_staff;
        console.log({val});
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Theatre Users"
                requiredData={[{ data: userOptions, field: "user" }]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(userOptions) && userOptions.length > 0)}
                >

                    <Form.Item
                        name="user"
                        label="Attached User"
                        rules={[{
                            // required: true,
                            // message: "Please add a user"
                        }]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(userOptions) && userOptions.length > 0 ? false : true}
                            options={userOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter first name',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter first name" />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter last name',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter last name" />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input email!',
                            },
                            {
                                type: 'email',
                                message: 'Invalid email format!',
                            },
                        ]}
                    >
                        <Input onInput={onInputTextShouldBeLowerCase} placeholder="Please enter email" />
                    </Form.Item>
                    <Form.Item
                        label="Mobile Number"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your mobile number!',
                            },
                            {
                                pattern: /^[0-9]*$/,
                                message: 'Please enter a valid mobile number!',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter mobile" />
                    </Form.Item>
                    <Form.Item
                        label="Is Super User?"
                        name="is_superuser"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label="Is Active?"
                        name="is_active"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label="Is Staff?"
                        name="is_staff"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: currentFormMode === FormModesEnum.EDIT ? false : true,
                                message: 'Please input your password!',
                            },
                            {
                                min: 6,
                                message: 'Password must be at least 6 characters!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: currentFormMode === FormModesEnum.EDIT ? false : true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {
                        params.id ?
                            <Form.Item
                                hidden
                                name="theatre"
                                initialValue={params?.id}
                            >
                                <Input />
                            </Form.Item> :
                            <Form.Item
                                label="Theatre"
                                name="theatre"
                                style={{ width: "150px" }}
                                rules={[{
                                    required: true
                                }]}
                            >
                                <Select
                                    placeholder="Choose a Theatre to Filter"
                                    loading={Array.isArray(theatreList) ? false : true}
                                    onSelect={(v) => {
                                        console.log({ v });
                                    }}
                                >
                                    {
                                        theatreList?.map((afv, ind) => {
                                            return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                    }
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form >
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;