// import { ACTION_COLUMN_KEY } from "Components/CustomTable/Commons";

const getPermittedColumns = (columns, userPermissions, editPermission, deletePermission) => {
    let _columns = [];

    // if (userPermissions && Array.isArray(userPermissions)) {
    //     if ((userPermissions.includes(editPermission)) || (userPermissions.includes(deletePermission))) {
    //         _columns = columns;
    //     } else {
    //         let newColumns = columns.filter(r => r.key !== ACTION_COLUMN_KEY);
    //         _columns = newColumns
    //     }
    // }

    _columns = columns;
    return _columns;
}

export default getPermittedColumns;