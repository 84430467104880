import React, { useEffect } from 'react'
import { Flex, Select } from 'antd';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTheatresListAsync } from 'Redux/TheatreReducer/theatreSlice';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';

const { Option } = Select;

const TheatreFilter = ({ openNotification, apiUrl, extraApiParamsState, setExtraApiParamsState, field }) => {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const filterValues = useSelector(store => store.theatre?.theatreList);
    const selectedClient = useSelector(store => store.shows?.selectedClient);

    useEffect(() => {
        if (selectedClient === "all") {
            dispatch(fetchTheatresListAsync());
        } else {
            dispatch(fetchTheatresListAsync(`?client=${selectedClient}`));
        }
    }, [selectedClient, dispatch]);

    const onFilterSelect = (v) => {
        // console.log({ v });
        setSearchParams(params => {
            params.set(searchParamsEnum.PAGE, 1);
        });
        setExtraApiParamsState((p) => {
            if (v === "all"){
                let s = p && JSON.parse(JSON.stringify(p));
                if (!s){
                    s = {}
                }
                if (field in s){
                    delete s[field]
                }
                // console.log({v, s})
                return s;
            } else {
                const s = {
                    ...p,
                    [field]: v === "all" ? null : v
                }
                // console.log({s});
                return s;
            }
        })
    }

    return (
        <>
            <Flex align='center' justify='center' gap={5}>
                <label>Theatre</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Theatre to Filter"
                    loading={Array.isArray(filterValues) ? false : true}
                    defaultValue={"all"}
                    onSelect={onFilterSelect}
                >
                    <Option key={"all"} value="all">All</Option>
                    {
                        filterValues?.map((afv, ind) => {
                            return <Option key={afv.id} value={afv.id}>{afv?.title}</Option>
                        })
                    }
                </Select>
            </Flex>
        </>
    )
}

export default TheatreFilter