import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Checkbox, Select, Flex, Upload, Image, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { useParams } from 'react-router';
import { fetchTheatresListAsync } from 'Redux/TheatreReducer/theatreSlice';
import { validatePositiveInteger } from 'Utils/formValidators';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import dayjs from 'dayjs';
import { fetchInventoryStatusChoices, fetchFoodList } from 'Api/commonApis';
import axios from 'axios';
import { landscapePlaceholder } from 'Assets';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();

    const theatreList = useSelector(store => store.theatre?.theatreList);
    const selectedClient = useSelector(store => store.shows?.selectedClient);
    const [inventoryStatusOptions, setInventoryStatusOptions] = useState();
    const [foodOptions, setFoodOptions] = useState();
    const [selectedFoodItemImage, setSelectedFoodItemImage] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    // useEffect(() => {
    //     dispatch(fetchTheatresListAsync());
    // }, []);

    useEffect(() => {
        if (selectedClient === "all") {
            dispatch(fetchTheatresListAsync());
        } else {
            dispatch(fetchTheatresListAsync(`?client=${selectedClient}`));
        }
    }, [selectedClient]);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        const fetchInventoryStatusOptions = async () => {
            try {
                const obj = await fetchInventoryStatusChoices();
                if (Array.isArray(obj)) {
                    const options = [];
                    obj.forEach(o => {
                        options.push({
                            value: o[0],
                            label: o[1]
                        })
                    })
                    console.log({ foodTypeOptions: options, obj })
                    setInventoryStatusOptions(options);
                } else {
                    throw new Error("Fetch Inventory Status did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Inventory Status Options", err.message, "error");
                } else {
                    openNotification("Inventory Status Options", "Something went wrong while fetching convenienceFeeType options", "error")
                }
            }
        }
        const fetchFoodOptions = async () => {
            try {
                let list = null
                if (selectedClient === "all") {
                    list = await fetchFoodList();
                } else {
                    list = await fetchFoodList(`?client=${selectedClient}`);
                }
                if (Array.isArray(list)) {
                    // const options = list.map(l => ({ value: l.id, label: l.title }))
                    setFoodOptions(list);
                } else {
                    throw new Error("Fetch Food did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Food Options", err.message, "error");
                } else {
                    openNotification("Food Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        fetchInventoryStatusOptions();
        fetchFoodOptions();
    }, []);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    useEffect(() => {
        let currentRecordDataCopy = JSON.parse(JSON.stringify(currentRecordData));
        if (currentRecordDataCopy.expiry) {
            currentRecordDataCopy.expiry = dayjs(currentRecordDataCopy.expiry, SELECTED_DATE_FORMAT);
        }
        if (currentRecordData.food_item_data?.image) {
            setSelectedFoodItemImage(currentRecordData.food_item_data.image);
        } else {
            setSelectedFoodItemImage(null);
        }
        form.setFieldsValue(currentRecordDataCopy);
    }, [fetchRecordStatus, currentRecordData]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({
            apiUrl: apiUrl, data: val, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }));
    }

    const edit = (val) => {
        // console.log(val);
        dispatch(editARecord({
            apiUrl, id: currentEditViewFormId, data: val, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }));
    }

    const onFinish = (val) => {
        val.expiry = val.expiry.format(SELECTED_DATE_FORMAT)
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Inventories" requiredData={[{data: foodOptions, field: "food"}]}>
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(theatreList) && theatreList.length > 0)}
                >
                    <Flex align='end' justify='space-between' gap={10}>
                        <Form.Item
                            name="food_item"
                            label="Food Item"
                            rules={[
                                {
                                    required: true,
                                    message: "Food Item is required"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: 200,
                                }}
                                placeholder="Search to Select"
                                // optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return (option?.["data-value"]?.title?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())
                                }}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.["data-value"]?.title ?? '').toLowerCase().localeCompare((optionB?.["data-value"]?.title ?? '').toLowerCase())
                                }
                                onSelect={(v, obj) => {
                                    console.log({ v, obj });
                                    setSelectedFoodItemImage(obj['data-value']?.image);
                                }}
                                loading={Array.isArray(foodOptions) ? false : true}
                            // options={foodOptions}
                            >
                                {
                                    foodOptions?.map((afv, ind) => {
                                        return <Select.Option key={afv.id} value={afv.id} data-value={afv}>
                                            <Flex align='center' gap={10}>
                                                <Image width={30} height={30} style={{ objectFit: "cover" }} src={axios.defaults.baseURL + afv.image} />
                                                <span>{afv.title}</span>
                                            </Flex>
                                        </Select.Option>
                                    })
                                }
                            </Select>

                        </Form.Item>
                        <Flex style={{ width: 100, height: 100, padding: 10, border: selectedFoodItemImage ? "1px solid gray" : "" }}>
                            {
                                selectedFoodItemImage ? <>
                                    <Image style={{ width: "100%", height: "100%", objectFit: "cover" }} src={axios.defaults.baseURL + selectedFoodItemImage} />
                                </> : <>
                                    {/* <Image style={{ width: "100%", height: "100%", objectFit: "cover" }} src={landscapePlaceholder} /> */}
                                </>
                            }
                        </Flex>
                    </Flex>
                    <Form.Item
                        label="Theatre"
                        name="theatre"
                        initialValue={params?.id}
                        rules={[{
                            required: true
                        }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Choose a Theatre"
                            loading={Array.isArray(theatreList) ? false : true}
                            onSelect={(v) => {
                                console.log({ v });

                            }}
                        >
                            {
                                theatreList?.map((afv, ind) => {
                                    return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="expiry"
                        label="Expiry Date"
                        rules={[
                            {
                                required: false,
                                message: "Expiry Date is required"
                            }
                        ]}
                    >
                        <DatePicker format={SELECTED_DATE_FORMAT} style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="quantity"
                        label="Quantity"
                        initialValue={0}
                        rules={[
                            { required: true, message: 'Quantity is required.' },
                            { validator: validatePositiveInteger },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    {/* <Form.Item
                    name="used_quantity"
                    label="Used Quantity"
                    initialValue={0}
                    rules={[
                        { required: true, message: 'Used Quantity is required.' },
                        { validator: validatePositiveInteger },
                    ]}
                >
                    <Input type="number" />
                </Form.Item> */}
                    {/* <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                        {
                            required: true,
                            message: "Status is required"
                        }
                    ]}
                >
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(inventoryStatusOptions) && inventoryStatusOptions.length > 0 ? false : true}
                        options={inventoryStatusOptions}
                    />
                </Form.Item> */}
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form>
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;