import React, { useState, useRef, useEffect } from "react";
import { Button } from "antd";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { useMediaQuery } from "react-responsive";
import { Col, Flex, Image } from "antd";
import { landscapePlaceholder } from "Assets";

const FoodCard = ({ food, orderedFood, setOrderedFood, maxAllowedFoodQuantity, totalFoodQuantity, setTotalFoodQuantity, errorMessage, setErrorMessage }) => {

    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(null);

    const units = [
        { value: "UNIT", label: "unit" },
        { value: "GRAM", label: "g" },
        { value: "LITER", label: "L" },
        { value: "MILLI_LITER", label: "ml" },
        { value: "POUND", label: "lb" },
        { value: "BASKET", label: "Basket" },
        { value: "PIECE", label: "pc" },
    ]

    const handleIncrement = (foodId) => {
        const newArray = orderedFood.map(item => {
            if (item.food === foodId) {
                return { ...item, quantity: item.quantity + 1 };
            }
            return item;
        });
        setOrderedFood(newArray);
    };

    const handleDecrement = (foodId) => {
        const newArray = orderedFood.map(item => {
            if (item.food === foodId) {
                return { ...item, quantity: item.quantity - 1 };
            }
            return item;
        });
        setOrderedFood(newArray);
    };

    useEffect(() => {
        if (containerRef.current) {
            const updateDimensions = () => {
                const width = containerRef.current.offsetWidth;
                setContainerWidth(width);
            };

            updateDimensions();

            window.addEventListener('resize', updateDimensions);

            return () => {
                window.removeEventListener('resize', updateDimensions);
            };
        }
    }, []);

    return (
        <Col span={12} className="p-2">
            <Flex
                className={`p-3 text-white d-flex ${large ? "flex-row" : ""}`}
                style={{
                    // backgroundColor: "#13151f",
                    borderRadius: "0px",
                    padding: "0.75rem"
                }}
            >
                <div className="border" ref={containerRef} style={{ width: 100, height: 100, border: "1px solid gray" }}>
                    <Image
                        className="w-100 h-100"
                        src={food?.image}
                        alt={food?.title}
                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                        preview={false}
                        fallback={landscapePlaceholder}
                    />
                </div>
                <Flex vertical justify="space-betweem"
                    style={{
                        width: "75%",
                        marginLeft: "0.75rem"
                    }}
                >
                    <Flex flex={1} vertical justify="space-between">
                        <Flex justify="space-between" align="center" className={`${large ? "d-flex justify-content-between align-items-center" : ""}`}>
                            <b>{food?.title}</b>
                            <Flex
                                justify="center"
                                align="center"
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    width: 20,
                                    height: 20,
                                    border: food?.type === "VEG" ? "1px solid #00923f" : "1px solid red"
                                }}
                            >
                                <GoDotFill fill={food?.type === "VEG" ? "#00923f" : "red"} />
                            </Flex>
                        </Flex>
                        <div className="fs-14">{food?.description} {food?.quantity} {units.filter(unit => unit.value === food?.unit)[0].label}</div>
                    </Flex>
                    <Flex flex={1} justify="space-between" align="center" className={`${large ? "d-flex justify-content-between align-items-center" : "mt-2"}`}>
                        <div>Rs. {food.price}</div>
                        {orderedFood.filter(item => item.food === food.id).length > 0 ?
                            <Flex justify="center" gap={5} align="center" className={`d-flex align-items-center justify-content-center ${large ? "" : "mt-2"}`}>
                                <Button
                                    type="default"
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: 20, height: 20, borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center" }}
                                    onClick={() => {
                                        setErrorMessage(null)
                                        if (orderedFood.filter(item => item.food === food.id)[0].quantity >= 2) {
                                            handleDecrement(food.id)
                                            setTotalFoodQuantity(totalFoodQuantity - 1)
                                        } else if (orderedFood.filter(item => item.food === food.id)[0].quantity >= 1) {
                                            setOrderedFood(orderedFood.filter(item => item.food !== food.id))
                                            setTotalFoodQuantity(totalFoodQuantity - 1)
                                        }
                                    }}
                                >
                                    {/* <IoMdRemove color="#000" /> */}
                                    -
                                </Button>
                                <span className="px-3">{orderedFood.filter(item => item.food === food.id)[0].quantity}</span>
                                <Button
                                    type="default"
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: 20, height: 20, borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center"}}
                                    onClick={() => {
                                        if (totalFoodQuantity < maxAllowedFoodQuantity) {
                                            handleIncrement(food.id)
                                            setTotalFoodQuantity(totalFoodQuantity + 1)
                                        } else {
                                            setErrorMessage(`Only ${maxAllowedFoodQuantity} Food items can be selected at once!`)
                                        }
                                    }}
                                >
                                    {/* <IoMdAdd color="#000" /> */}
                                    +
                                </Button>
                            </Flex>
                            :
                            <Button
                                className={`bg-primary primary-button border border-primary px-3 py-1 ${large ? "" : "mt-2 w-100"}`}
                                type="default"
                                style={{
                                    padding: ".250rem 0.75rem",
                                }}
                                onClick={() => {
                                    if (totalFoodQuantity < maxAllowedFoodQuantity) {
                                        setOrderedFood(orderedFood => [...orderedFood, { "food": food.id, "title": food.title, "quantity": 1, "price": food.price }])
                                        setTotalFoodQuantity(totalFoodQuantity + 1)
                                    } else {
                                        setErrorMessage("Only 10 Food items can be selected at once!")
                                    }
                                }}
                            >
                                ADD
                            </Button>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Col>
    )
}

export default FoodCard;
