import React, { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Badge, Button, Col, Flex, Form, Image, Input, message, Modal, Row, Spin, Tag } from 'antd';
import Title from "antd/es/typography/Title";
import { useNotificationContext } from "Config/NotificationsContext";
import { PAYMENT_METHOD } from "./SelectSeats";
import { LoadingOutlined } from "@ant-design/icons"
import { validatePositiveInteger } from "Utils/formValidators";
import * as bookingApis from "../../../Api/bookingApis"
import { CopyOutlined, CloseCircleOutlined } from "@ant-design/icons";

const TIMER_TEXT_VALUES = {
    TIME_LEFT: "Time Left",
    RELOADING: "Reloading this page in "
}


const PaymentModal = ({
    showPaymentModal,
    setShowPaymentModal,
    paymentTimeLeft,
    setPaymentTimeLeft,
    paymentMethod,
    setPaymentMethod,
    paymentId,
    fetchMipsPaymentDetails,
    paymentInfoLoading,
    iframeContent,
    fetchMytPaymentDetails,
    mytPaymentInfo,
    fetchCashPaymentDetails,
    order,
    amountPayable,
    unlockSeats,
    theatre_id
}) => {

    let interval = useRef(null);
    const [form] = Form.useForm();

    const openNotification = useNotificationContext();
    const [confirmPaymentLoading, setConfirmPaymentLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [timerText, setTimerText] = useState(TIMER_TEXT_VALUES.TIME_LEFT);
    const [returnAmount, setReturnAmount] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [messageApi, contextHolder] = message.useMessage();
    const [errorState, setErrorState] = useState(null);

    const copyPaymentId = () => {
        navigator.clipboard.writeText(paymentId);
        messageApi.success(`${paymentId} copied to clipboard.`)
    }

    const calculateReturnAmount = (_, val) => {
        let amount_taken = Number(val);
        if (isNaN(amount_taken)) return Promise.reject("Not a number");

        let amount_payable = Number(amountPayable);
        console.log(amount_taken, amount_payable);
        let amount_to_return = amount_taken - amount_payable;
        setReturnAmount(amount_to_return);
        console.log(amount_to_return)

        if (amount_to_return < 0) {
            setSubmitDisabled(true);
            return Promise.reject(`Cannot take less than MUR ${amount_payable}.`);
        }

        setSubmitDisabled(false);
        return Promise.resolve();
    }

    const confirmPayment = async (val) => {
        try {
            setConfirmPaymentLoading(true);
            fetchCashPaymentDetails(paymentId);
            const response = await bookingApis.confirmCashPayment(paymentId, val.cash_credit, returnAmount, theatre_id);
            console.log(response);
            openNotification("Seat(s) Booked!", null, "success");
            setDisabled(true);
            setPaymentTimeLeft(5);
            setTimerText(TIMER_TEXT_VALUES.RELOADING);
            setErrorState(null);
        } catch (err) {
            console.error(err);
            openNotification("Couldn't confirm the payment", `${`${err}`.includes?.('<!DOCTYPE html>') ? "Something went wrong" : err}`, "error");
            if (typeof(err?.msg) === "string") {
                setErrorState(err);
            } else if (typeof(err?.message) === "string") {
                setErrorState(err?.message);
            } else {
                setErrorState("Error occurred");
            }
        } finally {
            setConfirmPaymentLoading(false);
        }
    }

    useEffect(() => {
        interval.current = setTimeout(() => {
            if (paymentTimeLeft > 0) {
                setPaymentTimeLeft(paymentTimeLeft - 1);
            }
            if (showPaymentModal && paymentTimeLeft === 0) {
                openNotification("Timeout!", null, "error")
                window.location.reload();
            }

        }, 1000);

        return () => clearInterval(interval.current);
    }, [paymentTimeLeft, setPaymentTimeLeft, showPaymentModal, openNotification]);

    const displayTime = () => {
        const minutes = Math.floor(paymentTimeLeft / 60);
        const remainingSeconds = paymentTimeLeft % 60;
        return `0${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const onCancel = () => {
        unlockSeats(true);
    }

    return (
        <>
            {contextHolder}
            <Modal
                open={showPaymentModal}
                style={{
                    border: "none"
                }}
                onCancel={onCancel}
                title={<p style={{ textAlign: "center" }}>Please collect cash from the customer</p>}
                footer=""
            >
                <p style={{ textAlign: "center", color: "red", marginBottom: 5, marginTop: 25 }}>
                    <b className="text-primary">{timerText} :&nbsp;</b>
                    <b>{displayTime()}</b>
                </p>
                <p style={{ textAlign: "center", color: "red", marginTop: 5, marginBottom: 5 }}>
                    {
                        timerText === TIMER_TEXT_VALUES.TIME_LEFT ? "Do not close or reload this window!" : <Spin size="small"/>
                    }
                </p>


                {/* <p style={{ textAlign: "center" }}>
                Please collect the cash from the customer
            </p> */}

                <Flex vertical align="center" justify="center" style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Form layout="vertical"
                        requiredMark={true}
                        onFinish={(val) => {
                            setPaymentMethod(PAYMENT_METHOD.CASH)
                            confirmPayment(val);
                        }}
                        autoComplete="off"
                        form={form}
                        disabled={disabled}
                    >
                        <Flex vertical align="center" justify="center">
                            <Flex gap={8}>
                                <Form.Item
                                    name="cash_credit"
                                    label="Cash Taken"
                                    className="cash_credit_input"
                                    style={{
                                        width: 280,
                                        flex: 1,
                                        paddingBottom: 10,
                                        marginBottom: 0
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter cash taken',
                                        },
                                        { validator: validatePositiveInteger },
                                        { validator: calculateReturnAmount }
                                    ]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                                {/* <Form.Item
                                name="cash_returned"
                                label="Cash Returned"
                                style={{
                                    width: 180
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter cash returned',
                                    },
                                    { validator: validatePositiveInteger }
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item> */}
                            </Flex>
                            {
                                !submitDisabled && <p style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}>
                                    <b>To Return: <br /> <span style={{ color: "red" }}>MUR {returnAmount}</span></b>
                                </p>
                            }
                            {
                                confirmPaymentLoading ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" disabled={submitDisabled}>
                                        Submit
                                    </Button>
                            }
                        </Flex>
                    </Form>
                </Flex>
                {
                    errorState && <Flex justify="center" align="center">
                        <Tag color="error">

                            <Flex vertical gap={5} align="center" justify="center">
                                <div>
                                    {console.log({ errorState })}
                                    {errorState}
                                </div>
                                <Flex>
                                    Payment Id: <Button
                                        type="default"
                                        shape="default"
                                        icon={<CopyOutlined size={18} />}
                                        size="small"
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5 }}
                                        onClick={copyPaymentId}
                                    >
                                    </Button>
                                </Flex>
                            </Flex>
                            <div>{paymentId}</div>
                        </Tag>
                    </Flex>
                }
            </Modal >
        </>
    );
};

export default PaymentModal;
