import { landscapePlaceholder } from 'Assets';
import { getTimeReadable } from 'Utils/timeUtilities';
import { Flex, Image, Skeleton } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react'
import { useSelector } from 'react-redux';

const PosterAndData = () => {

  const currentRecordData = useSelector(store => store.shows?.selectedProgramData);

  return (
    <Flex
      justify='center'
      align='start'
      style={{
        width: "100%",
        height: "fit-content",
        backgroundImage: `url(https://media.themoviedb.org/t/p/w1920_and_h800_multi_faces${currentRecordData?.tbdm_json?.backdrop_path})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        backgroundRepeat: "no-repeat",
        zIndex: 10
      }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 11,
          display: "flex"
        }}></div>

      <Flex
        justify='center'
        align='center'
        flex={1}
        style={{ position: "relative", zIndex: 12, padding: 20, height: "100%", boxSizing: "border-box" }}>
        {
          currentRecordData ?
            <Image
              src={currentRecordData?.poster_image}
              alt='Preview'
              style={{
                width: "100%",
                height: 250
              }}
              fallback={landscapePlaceholder}
            /> : <Skeleton.Image active={true} style={{ width: "100%" }} />
        }
      </Flex>
      <Flex vertical justify='center' align='flex-start' flex={3} gap={20}
        style={{ position: "relative", zIndex: 12, color: "white", padding: 20 }}
      >
        {
          currentRecordData ? <Title level={3} style={{ color: "white", margin: 0 }}>
            {currentRecordData.title}
          </Title> : <Skeleton.Button active={true} size={"large"} shape={"square"} block={false} style={{ width: "100%" }} />
        }
        <span>{
          currentRecordData ? currentRecordData.runtime ? getTimeReadable(currentRecordData?.runtime) : '0h 0m' :
            <Skeleton.Button active={true} size={"small"} shape={"square"} block={false} />
        }</span>
      </Flex>
    </Flex>
  )
}

export default PosterAndData