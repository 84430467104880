import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/Channels/AddEditForm";
import { Checkbox } from "antd";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/theatre/rest/theatrechannel";
const headerTitle = "Theatre Channels";
const editPermission = "theatrechannel:edit";
const deletePermission = "theatrechannel:delete";
const addPermission = "theatrechannel:add";

const columns = [
    {
        title: 'Channel',
        dataIndex: 'channel',
        key: 'channel',
        render: (text, row) => {
            // console.log({text, row});
            return row?.channel_data?.title
        }
    },
    {
        title: 'Theatre',
        dataIndex: 'theatre',
        key: 'theatre',
        render: (text, row) => {
            // console.log({text, row});
            return row?.theatre_data?.title
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, row) => {
            return <Checkbox disabled checked={row.status}></Checkbox>
        }
    },
    ...getCommonColumns({ apiUrl, editPermission, created: true, updated: true, action: true })
]


const AllTheatreChannellsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        filters={[allowedFiltersEnum.CLIENT.theatre__client, allowedFiltersEnum.THEATRE.theatre]}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default AllTheatreChannellsPage;