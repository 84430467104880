import { Flex } from "antd";
import moment from "moment-timezone";
import { getFormattedAmount } from "Utils/textFormat";
import ExportXlsx from '../ExportXlsx';

export const getName = row => (row?.employee?.first_name || "") + " " + (row?.employee?.last_name || "");

const API_URL_MOVIE_PER_HALL = "/reports/movie-per-hall-report/";
export const getTime = text => <Flex>
    {moment(text).format("YYYY-MM-DD")}
    <br />
    {moment(text).format("hh:mm a")}
</Flex>

export const getTableColumns = ({ includeCash = true, includeMYT = true, includeMIPS = true, downloadTicketOnlyReport = true}) => {

    const tableColumns = [
        {
            title: 'Download Screenwise Report',
            dataIndex: 'screenwise',
            key: 'screenwise',
            align: "center",
            render: (_, row) =>((row?.cashier_end_date_time) ? <ExportXlsx
            apiUrl={`${API_URL_MOVIE_PER_HALL}export/`}
            payload={{
                theatre: row?.cashier_session_theatre,
                start_date: row?.cashier_start_date_time,
                end_date: row?.cashier_end_date_time,
                client_program: "all",
                show_vouchers_used: true,
                cashier_session_id: row?.cashier_session_id,
            }}
            reportName={`movie_per_hall_report_cashier_session_${row?.cashier_session_id}`}
            reportTitleForNotification={'Movie Per Hall'}
        /> : null),
            sorter: (a, b) => a.employee?.username - b.employee?.username,
        },
        {
            title: 'Cashier',
            dataIndex: 'employee',
            key: 'employee',
            align: "center",
            render: (_, row) => row?.employee?.username || getName(row),
            sorter: (a, b) => a.employee?.username - b.employee?.username,
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
            sorter: (a, b) => moment(a.start_time) - moment(b.start_time),
            render: (text) => getTime(text)
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
            sorter: (a, b) => moment(a.end_time) - moment(b.end_time),
            render: (text) => text ? getTime(text) : "-",
        },
        {
            title: 'Start Cash (A)',
            dataIndex: 'start_cash',
            key: 'start_cash',
            sorter: (a, b) => Number(a.start_cash) - Number(b.start_cash),
            render: (text) => getFormattedAmount(text)
        },
        {
            title: 'End Cash (B)',
            dataIndex: 'end_cash',
            key: 'end_cash',
            sorter: (a, b) => Number(a.end_cash) - Number(b.end_cash),
            render: (text) => getFormattedAmount(text)
        },
        {
            title: 'End Cash - Start Cash\n(B - A)',
            dataIndex: 'end_cash_start_cash',
            key: 'end_cash_start_cash',
            sorter: (a, b) => Number(a.end_cash) - Number(b.end_cash),
            render: (text, row) => getFormattedAmount(row?.end_cash - row?.start_cash)
        }
    ]

    const no_of_tickets_sold = {
        title: 'No. of tickets sold',
        dataIndex: 'no_tickets_sold',
        key: 'no_tickets_sold',
        children: [],
    }
    if (includeCash) {
        no_of_tickets_sold['children'].push({
            title: 'CASH',
            dataIndex: 'cash',
            key: 'cash',
            children: [
                {
                    title: 'Count',
                    dataIndex: 'count',
                    key: 'count',
                    render: (_, row) => {
                        let val = row?.tickets_sold;
                        return val;
                    },
                    sorter: (a, b) => a?.tickets_sold - b?.tickets_sold,
                    align: "center",
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (_, row) => {
                        let val = row?.cash_collected;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.cash_collected - b?.cash_collected,
                    align: "center",
                },
                {
                    title: '# of Vouchers Used',
                    dataIndex: 'vouchers_used',
                    key: 'vouchers_used',
                    render: (_, row) => {
                        let val = row?.vouchers_used;
                        return val;
                    },
                    sorter: (a, b) => a?.vouchers_used - b?.vouchers_used,
                    align: "center",
                },
                {
                    title: 'Refund Count',
                    dataIndex: 'refund_count',
                    key: 'refund_count',
                    render: (_, row) => {
                        let val = row?.total_cash_refund_count;
                        return val;
                    },
                    sorter: (a, b) => a?.total_cash_refund_count - b?.total_cash_refund_count,
                    align: "center",
                },
                {
                    title: 'Refund Amount',
                    dataIndex: 'refund_amount',
                    key: 'refund_amount',
                    render: (_, row) => {
                        let val = row?.total_cash_refund_amount;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.total_cash_refund_amount - b?.total_cash_refund_amount,
                    align: "center",
                },
                {
                    title: 'Online Payment Refunds',
                    dataIndex: 'online_refund_amount',
                    key: 'online_refund_amount',
                    render: (_, row) => {
                        let val = row?.total_online_refund_amount;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.total_online_refund_amount - b?.total_online_refund_amount,
                    align: "center",
                },
                {
                    title: 'Cash in Hand',
                    dataIndex: 'cash_in_hand_final',
                    key: 'cash_in_hand_final',
                    render: (_, row) => {
                        let val = row?.cash_in_hand_final;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.cash_in_hand_final - b?.cash_in_hand_final,
                    align: "center",
                }
            ],
        });
    }

    if (includeMYT){
        no_of_tickets_sold['children'].push({
            title: 'my.t',
            dataIndex: 'MYT',
            key: 'MYT',
            children: [
                {
                    title: 'Count',
                    dataIndex: 'count',
                    key: 'count',
                    render: (_, row) => {
                        let val = row?.myt?.ticket_sold;
                        return val;
                    },
                    sorter: (a, b) => a?.myt?.ticket_sold - b?.myt?.ticket_sold,
                    align: "center",
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (_, row) => {
                        let val = row?.myt?.session_amount;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.myt?.session_amount - b?.myt?.session_amount,
                    align: "center",
                },
                {
                    title: 'Refund Count',
                    dataIndex: 'myt_refund_count',
                    key: 'myt_refund_count',
                    render: (_, row) => {
                        let val = row?.myt?.total_refund_count;
                        return val;
                    },
                    sorter: (a, b) => a?.myt?.total_refund_count - b?.myt?.total_refund_count,
                    align: "center",
                },
                {
                    title: 'Refund Amount (Cash)',
                    dataIndex: 'myt_refund_amount',
                    key: 'myt_refund_amount',
                    render: (_, row) => {
                        let val = row?.myt?.total_refund_amount;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.myt?.total_refund_amount - b?.myt?.total_refund_amount,
                    align: "center",
                },
                {
                    title: 'Effective Amount',
                    dataIndex: 'myt_refund_amount',
                    key: 'myt_refund_amount',
                    render: (_, row) => {
                        let val = row?.myt?.effective_sales;
                        return getFormattedAmount(val);
                    },
                    sorter: (a, b) => a?.myt?.effective_sales - b?.myt?.effective_sales,
                    align: "center",
                },
            ],
        })
    }

    if (includeMIPS){
        no_of_tickets_sold['children'].push({
                title: 'MIPS',
                dataIndex: 'MIPS',
                key: 'MIPS',
                children: [
                    {
                        title: 'Count',
                        dataIndex: 'count',
                        key: 'count',
                        render: (_, row) => {
                            let val = row?.mips?.ticket_sold;
                            return val;
                        },
                        sorter: (a, b) => a?.mips?.ticket_sold - b?.mips?.ticket_sold,
                        align: "center",
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (_, row) => {
                            let val = row?.mips?.session_amount;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.mips?.session_amount - b?.mips?.session_amount,
                        align: "center",
                    },
                    {
                        title: 'Refund Count',
                        dataIndex: 'mips_refund_count',
                        key: 'mips_refund_count',
                        render: (_, row) => {
                            let val = row?.mips?.total_refund_count;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.mips?.total_refund_count - b?.mips?.total_refund_count,
                        align: "center",
                    },
                    {
                        title: 'Refund Amount (Cash)',
                        dataIndex: 'mips_refund_amount',
                        key: 'mips_refund_amount',
                        render: (_, row) => {
                            let val = row?.mips?.total_refund_amount;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.mips?.total_refund_amount - b?.mips?.total_refund_amount,
                        align: "center",
                    },
                    {
                        title: 'Effective Amount',
                        dataIndex: 'mips_refund_amount',
                        key: 'mips_refund_amount',
                        render: (_, row) => {
                            let val = row?.mips?.effective_sales;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.mips?.effective_sales - b?.mips?.effective_sales,
                        align: "center",
                    },
                ],
            })
    }
    
    if (includeCash || includeMIPS || includeMYT) tableColumns.push(no_of_tickets_sold);
        if (!downloadTicketOnlyReport){
        const no_of_food_items_sold_column = {
            title: 'No. of food items sold',
            dataIndex: 'no_food_items_sold',
            key: 'no_food_items_sold',
            align: "center",
            children: [],
        }

        if (includeCash){
            no_of_food_items_sold_column['children'].push({
                title: 'CASH',
                dataIndex: 'cash',
                key: 'cash',
                children: [
                    {
                        title: 'Count',
                        dataIndex: 'count',
                        key: 'count',
                        render: (_, row) => {
                            let val = row?.no_food_items_sold?.CASH?.count;
                            return val;
                        },
                        sorter: (a, b) => a?.no_food_items_sold?.CASH?.count - b?.no_food_items_sold?.CASH?.count,
                        align: "center",
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (_, row) => {
                            let val = row?.no_food_items_sold?.CASH?.amount;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.no_food_items_sold?.CASH?.amount - b?.no_food_items_sold?.CASH?.amount,
                        align: "center",
                    }
                ],
            })
        }

        if (includeMYT){
            no_of_food_items_sold_column['children'].push({
                title: 'my.t',
                dataIndex: 'MYT',
                key: 'MYT',
                children: [
                    {
                        title: 'Count',
                        dataIndex: 'count',
                        key: 'count',
                        render: (_, row) => {
                            let val = row?.no_food_items_sold?.MYT?.count;
                            return val;
                        },
                        sorter: (a, b) => a?.no_food_items_sold?.MYT?.count - b?.no_food_items_sold?.MYT?.count,
                        align: "center",
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (_, row) => {
                            let val = row?.no_food_items_sold?.MYT?.amount;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.no_food_items_sold?.MYT?.amount - b?.no_food_items_sold?.MYT?.amount,
                        align: "center",
                    }
                ],
            })
        }

        if (includeMIPS){
            no_of_food_items_sold_column['children'].push({
                title: 'MIPS',
                dataIndex: 'MIPS',
                key: 'MIPS',
                children: [
                    {
                        title: 'Count',
                        dataIndex: 'count',
                        key: 'count',
                        render: (_, row) => {
                            let val = row?.no_food_items_sold?.MIPS?.count
                            return val;
                        },
                        sorter: (a, b) => a?.no_food_items_sold?.MIPS?.count - b?.no_food_items_sold?.MIPS?.count,
                        align: "center",
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (_, row) => {
                            let val = row?.no_food_items_sold?.MIPS?.amount;
                            return getFormattedAmount(val);
                        },
                        sorter: (a, b) => a?.no_food_items_sold?.MIPS?.amount - b?.no_food_items_sold?.MIPS?.amount,
                        align: "center",
                    }
                ],
            })
        }

        if (includeCash || includeMIPS || includeMYT) tableColumns.push(no_of_food_items_sold_column);
    }
    return tableColumns;
};