import { Button, Flex, notification, Spin, Tooltip } from 'antd';
import { IoMdSync } from "react-icons/io";
import { BiSolidCloudDownload, BiSolidCloudUpload } from "react-icons/bi";
// import { CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons"
import { CgPushUp, CgPushDown } from "react-icons/cg";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { asyncStatuses } from 'Redux/enums';
import axios from 'axios';
import moment from 'moment-timezone';
import AccessControl from 'Router/Access/AccessControl';

const iconStyles = { display: "flex", alignItems: "center", justifyContent: "center", minHeight: 28, minWidth: 28, overflow: "hidden" };

const TheatreSync = ({ text, row, index }) => {

    const dispatch = useDispatch();
    const [notificationApi, contextHolder] = notification.useNotification();

    const [syncPushStatus, setSyncPushStatus] = useState(null);
    const [syncPullStatus, setSyncPullStatus] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const syncPushTheatreAsync = async () => {
        setSyncPushStatus(asyncStatuses.LOADING);
        setDisabled(true);
        try {
            const data = { theatre_id: row.id };
            const response = await axios.post("/utilities/push-on-theatre/", data);
            console.log(response);
            setSyncPushStatus(asyncStatuses.SUCCESS);
            setDisabled(false);
        } catch (err) {
            console.error(err);
            setSyncPushStatus(asyncStatuses.FAILED);
            setDisabled(false);
            if (err.response?.data?.message) {
                setErrorMsg(err.response.data.message);
            } else {
                setErrorMsg("Please check console")
            }
        }
    }

    const syncTheatrePullAsync = async () => {
        setSyncPullStatus(asyncStatuses.LOADING);
        setDisabled(true);
        try {
            const data = { theatre_id: row.id };
            const response = await axios.post("/utilities/pull-from-theatre/", data);
            console.log(response);
            setSyncPullStatus(asyncStatuses.SUCCESS);
            setDisabled(false);
        } catch (err) {
            console.error(err);
            setSyncPullStatus(asyncStatuses.FAILED);
            setDisabled(false);
            if (err.response?.data?.message) {
                setErrorMsg(err.response.data.message);
            } else {
                setErrorMsg("Please check console")
            }
        }
    }

    useEffect(() => {
        const pullStatusSuccess = syncPullStatus && syncPullStatus === asyncStatuses.SUCCESS;
        const pullStatusFailure = syncPullStatus && syncPullStatus === asyncStatuses.FAILED;

        if (pullStatusSuccess) {
            setTimeout(() => {
                openNotification(`Sync Successfull`, null, "success");
                setSyncPullStatus(null);
            }, 300);
        }
        if (pullStatusFailure) {
            setTimeout(() => {
                openNotification(`Error while syncing`, errorMsg, "error");
                setSyncPullStatus(null);
            }, 300);
        }
    }, [syncPullStatus, errorMsg]);

    useEffect(() => {
        const pushStatusSuccess = syncPushStatus && syncPushStatus === asyncStatuses.SUCCESS;
        const pushStatusFailure = syncPushStatus && syncPushStatus === asyncStatuses.FAILED;

        if (pushStatusSuccess) {
            setTimeout(() => {
                openNotification(`Sync Successfull`, null, "success");
                setSyncPushStatus(null);
            }, 300);
        }
        if (pushStatusFailure) {
            setTimeout(() => {
                openNotification(`Error while syncing`, errorMsg, "error");
                setSyncPushStatus(null);
            }, 300);
        }
    }, [syncPushStatus, errorMsg]);

    const onPushSync = (event) => {
        // console.log({event});
        syncPushTheatreAsync();
        event.stopPropagation();
    }

    const onPullSync = (event) => {
        // console.log({event});
        syncTheatrePullAsync();
        event.stopPropagation();
    }

    console.log({ disabled })

    return (
        <>
            {contextHolder}
            <Flex vertical gap={5}>
                <Flex>
                    {/* {
                        syncPushStatus === asyncStatuses.LOADING ?
                            <>
                                <Tooltip title="Syncing">
                                    <Spin spinning indicator={
                                        <Button
                                            type="default"
                                            shape="circle"
                                            icon={<IoMdSync size={18} />}
                                            size="small"
                                            disabled={disabled}
                                            className='custom-spinner'
                                            style={iconStyles}
                                            onClick={onPushSync}
                                        />
                                    }>

                                    </Spin>
                                </Tooltip>
                            </>
                            :
                            <>
                                <Tooltip title="Sync">
                                    <Button
                                        type="default"
                                        shape="circle"
                                        icon={<IoMdSync size={18} />}
                                        size="small"
                                        disabled={disabled}
                                        style={iconStyles}
                                        onClick={onPushSync}
                                    />
                                </Tooltip>
                            </>
                    } */}

                    {
                        syncPushStatus === asyncStatuses.LOADING ?
                            <Tooltip title="Pushing to Theatre">
                                <Button
                                    type="default"
                                    shape="circle"
                                    icon={<CgPushUp className='custom-slide-up' size={20} />}
                                    size="small"
                                    disabled={disabled}
                                    style={{ ...iconStyles, marginLeft: 5 }}
                                    onClick={onPushSync}
                                />
                            </Tooltip>
                            :
                            <Tooltip title={<ToolTipInfo date={row?.last_push?.timestamp} result={row?.last_push?.status} text={"Push to Theatre"} />}>
                                <Button
                                    type="default"
                                    shape="circle"
                                    icon={<BiSolidCloudUpload className='custom-fade-in' size={20} />}
                                    size="small"
                                    disabled={disabled}
                                    style={{ ...iconStyles, marginLeft: 5 }}
                                    onClick={onPushSync}
                                />
                            </Tooltip>
                    }
                    {
                        syncPullStatus === asyncStatuses.LOADING ?
                            <Tooltip title="Pulling from Theatre">
                                <Button
                                    type="default"
                                    shape="circle"
                                    icon={<CgPushDown className='custom-slide-down' size={20} />}
                                    size="small"
                                    disabled={disabled}
                                    style={{ ...iconStyles, marginLeft: 5 }}
                                    onClick={onPullSync}
                                />
                            </Tooltip>
                            :
                            <Tooltip title={<ToolTipInfo date={row?.last_pull?.timestamp} result={row?.last_pull?.status} text={"Pull from Theatre"} />}>
                                <Button
                                    type="default"
                                    shape="circle"
                                    icon={<BiSolidCloudDownload className='custom-fade-in' size={20} />}
                                    size="small"
                                    disabled={disabled}
                                    style={{ ...iconStyles, marginLeft: 5 }}
                                    onClick={onPullSync}
                                />
                            </Tooltip>
                    }
                </Flex>
                {/* <span>Last Synced: </span> */}
            </Flex>
        </>
    )
}

const ToolTipInfo = ({ text, date, result }) => {

    let validDate = moment(date).format("DD MMM yyyy, hh:mm a");

    return (
        <div>
            <p style={{ marginBottom: 0 }}> {text} </p>
            {
                date ? <p>
                    Last Sync {
                        result === "UPDATED" ? <span style={{ color: "green" }}>
                            was successful
                        </span> : <span style={{ color: "red" }}>
                            Failed
                        </span>
                    } at: <br />
                    {validDate}
                </p> :
                    <p>Last Synced: Never</p>
            }
        </div>
    );
}

const TheatreSyncWithPermissionCheck = (props) => {
    const user = useSelector(store => store.auth.user);

    return <AccessControl
        userPermissions={user.user_permissions}
        allowedPermissions={["theatresync:view"]}
        renderNoAccess={() => (
            <></>
        )}
    >
        <TheatreSync {...props} />
    </AccessControl>
}

export default TheatreSyncWithPermissionCheck;