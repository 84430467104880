import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/PlatformUsers/Roles/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/user/rest/roles";
const headerTitle = "Platform User Roles";
const editPermission = "roles:edit";
const deletePermission = "roles:delete";
const addPermission = "roles:add";

const columns = [
    {
        title: 'Role Name',
        dataIndex: 'role_name',
        key: 'role_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Role Code',
        dataIndex: 'role_code',
        key: 'role_code',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const RolesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default RolesPage;