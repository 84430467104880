import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import ChannelFilterForReports from 'Components/Dashboard/Reports/Filters/ChannelFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { DatePicker, Flex, Spin, Table, Collapse } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportPdf from './ExportPdf';
import ExportXlsx from './ExportXlsx';
import { fetchPaymentMethodChoices } from 'Api/commonApis';
import PaymentMethodFilterForReports from 'Components/Dashboard/Reports/Filters/PaymentMethodFilterForReports';
import GatewayWiseTable from './SalesReconcilliation/GatewayWiseTable';
const { RangePicker } = DatePicker;

const API_URL = "/reports/sales-reconcilliation-report/";
const initialDates = dayjs();

const tableColumns = [
    {
        title: 'Order Id',
        dataIndex: 'order',
        key: 'order',
        sorter: (a, b) => a.order - b.order,
    },
    {
        title: 'Food Order Id',
        dataIndex: 'food_order',
        key: 'food_order',
        sorter: (a, b) => a.food_order - b.food_order,
    },
    {
        title: 'Payment Method',
        dataIndex: 'payment_method',
        key: 'payment_method',
        align: "center",
        sorter: (a, b) => a.payment_method - b.payment_method,
    },
    {
        title: 'Payment Gateway',
        dataIndex: 'payment_gateway',
        key: 'payment_gateway',
        align: "center",
        sorter: (a, b) => a.payment_gateway - b.payment_gateway,
    },
    {
        title: 'Sale',
        dataIndex: 'total',
        key: 'total',
        render: (text) => getFormattedAmount(text),
        defaultSortOrder: 'descend',
        sorter: (a, b) => Number(a.total) - Number(b.total),
        align: "right",
    },
]

const SalesReconciliationReport = () => {
    const [data, setData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");
    const [channelFilterVal, setChannelFilterVal] = useState("");
    const [paymentMethodFilterVal, setPaymentMethodFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['theatre'] = theatreFilterVal;
                payload['channel'] = channelFilterVal;
                payload['paymentMethod'] = paymentMethodFilterVal
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data);
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Sales Reconcilliation Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }

        if (startDate && endDate && theatreFilterVal && channelFilterVal && paymentMethodFilterVal) {
            fetchData();
        }
    }, [startDate, endDate, theatreFilterVal, channelFilterVal, paymentMethodFilterVal, openNotification]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        theatre: theatreFilterVal,
                        paymentMethod: paymentMethodFilterVal,
                        channel: channelFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'sales_reconcilliation_report'}
                    reportTitleForNotification={'Sales Reconcilliation Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10} id='report-content'>
                <Flex justify='space-between' style={{ width: "100%", marginTop: 20 }}>
                    <ReportTitle title={`Sales Reconcilliation Report`} />
                    <Flex gap={15}>
                        <ChannelFilterForReports channelFilterVal={channelFilterVal} setChannelFilterVal={setChannelFilterVal} />
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        <PaymentMethodFilterForReports paymentMethodFilterVal={paymentMethodFilterVal} setPaymentMethodFilterVal={setPaymentMethodFilterVal} />
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex vertical gap={20} style={{ flex: 1, overflow: "auto", width: "100%", maxHeight: "75vh" }}>
                    {
                        loading ? <Spin /> : <>
                            <Collapse
                                collapsible="header"
                                defaultActiveKey={[]}
                                items={[
                                    {
                                        key: '1',
                                        label: <Flex style={{ width: "100%" }}>Payment Gateway-wise Data</Flex>,
                                        children: <GatewayWiseTable data={data.gateways_data} />
                                    }
                                ]}
                            />
                            {
                                Object.keys(data?.channels || {})?.map?.((d, i) => {

                                    const tableData = data.channels[d].data?.map?.((r, ind) => ({ ...r, key: r.id || ind }));
                                    console.log({ tableData });
                                    // const totalSales = tableData?.reduce?.((acc, curr) => isNaN(curr.total) ? acc : Number(curr.total) + acc, 0)
                                    return <Collapse
                                        collapsible="header"
                                        defaultActiveKey={['1']}
                                        items={[
                                            {
                                                key: '1',
                                                label: <Flex style={{ width: "100%" }}>{`${data.channels[d]?.title}`}</Flex>,
                                                children: <Flex vertical gap={10} style={{ marginLeft: 20, padding: 10 }}>
                                                    <Table
                                                        columns={tableColumns}
                                                        bordered
                                                        dataSource={tableData}
                                                        size='small'
                                                        scrollToFirstRowOnChange
                                                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                                                        loading={loading}
                                                        scroll={{
                                                            y: "65vh",
                                                            x: "100%"
                                                        }}
                                                        pagination={false}
                                                        summary={pageData => {
                                                            let _total = 0;
                                                            pageData.forEach(({ total }) => {
                                                                _total += isNaN(total) ? 0 : Number(total);
                                                            });

                                                            return (
                                                                <>
                                                                    <Table.Summary.Row >
                                                                        <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                                                                        <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                                                        <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                                                        <Table.Summary.Cell className='table-summary-cell'></Table.Summary.Cell>
                                                                        <Table.Summary.Cell className='table-summary-cell' align='center'>
                                                                            <p style={{ margin: 0 }}>{getFormattedAmount(_total)}</p>
                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                </Flex>,
                                            },
                                        ]}
                                    />
                                })
                            }
                        </>
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default SalesReconciliationReport;