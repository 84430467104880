import getContrastingColor from "Utils/getContrastingColor";
import { Flex, Checkbox } from "antd";
import { useEffect, useState } from "react";

const commonStyles = {
    margin: 2,
    width: 45,
    height: 45,
    padding: 2,
    position: "relative",
}

const DEFAULT_OPACITY = 0.5;

const SeatPriceDrag = ({ seat, isSeatDragged, onMouseDown, onMouseOver, currentTheatreChannel }) => {

    const seatClassName = `seat no-text-select ${isSeatDragged ? 'selected' : ''}`
    const [seatColor, setSeatColor] = useState(null)
    const [seatTextColor, setSeatTextColor] = useState(null)
    const [seatPrice, setSeatPrice] = useState(null)

    useEffect(() => {
        setSeatColor(seat?.seatType_data?.color ? seat?.seatType_data?.color : null)
        setSeatTextColor(seat?.seatType_data?.color ? getContrastingColor(seat?.seatType_data?.color) : null)
        if (seat.is_seat) {
            if (currentTheatreChannel?.label === "POS") {
                setSeatPrice(seat?.seatType_data?.default_price)
                if ('pos_active' in seat) {
                    if (!seat?.pos_active) {
                        setSeatPrice("N.A.")
                        setSeatColor("#000000")
                        setSeatTextColor(getContrastingColor("#000000"))
                    }
                }
            } else if (currentTheatreChannel?.label === "Kiosk") {
                setSeatPrice(seat?.seatType_data?.kiosk_price)
                if ('kiosk_active' in seat) {
                    if (!seat?.kiosk_active) {
                        setSeatPrice("N.A.")
                        setSeatColor("#000000")
                        setSeatTextColor(getContrastingColor("#000000"))
                    }
                }
            } else if (currentTheatreChannel?.label === "Website") {
                setSeatPrice(seat?.seatType_data?.web_price)
                if ('web_active' in seat) {
                    if (!seat?.web_active) {
                        setSeatPrice("N.A.")
                        setSeatColor("#000000")
                        setSeatTextColor(getContrastingColor("#000000"))
                    }
                }
            } else if (currentTheatreChannel?.label === "Android and IOS App") {
                setSeatPrice(seat?.seatType_data?.app_price)
                if ('app_active' in seat) {
                    if (!seat?.app_active) {
                        setSeatPrice("N.A.")
                        setSeatColor("#000000")
                        setSeatTextColor(getContrastingColor("#000000"))
                    }
                }
            }
        }
    }, [seat, currentTheatreChannel])

    if (seat.is_seat) {
        return (
            <Flex
                key={seat.id}
                align="end"
                justify="end"
                className={seatClassName}
                onMouseOver={onMouseOver}
                onMouseDown={onMouseDown}
                style={{
                    ...commonStyles,
                    border: "1px solid gray",
                    borderRadius: 2,
                    color: seatTextColor,
                    backgroundColor: seatColor,
                    opacity: isSeatDragged ? 1 : DEFAULT_OPACITY,
                }}
            >
                <small
                    style={{
                        position: "absolute",
                        top: 3, left: 1,
                        lineHeight: 0,
                        padding: 5,
                        borderRadius: 2,
                        fontSize: 9
                    }}
                >
                    {seatPrice}
                </small>
                <small>{seat.display_label}</small>
            </Flex>
        );
    } else {
        return (
            <Flex
                key={seat.id}
                align="end"
                justify="end"
                className={seatClassName}
                style={{
                    ...commonStyles,
                    border: "1px solid transparent",
                    borderRadius: 2,
                    opacity: isSeatDragged ? 1 : DEFAULT_OPACITY,
                }}
            >
                <small style={{ color: "lightgray" }}>{seat.label}</small>
            </Flex>
        );
    }
}

export default SeatPriceDrag;