import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Food/Inventory/AddEditForm";
import { Link, useParams } from "react-router-dom";
import { Button, Checkbox, Flex, Image } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import getPermittedColumns from "Utils/getPermittedColumns";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";

const apiUrl = "/food/inventories";
const headerTitle = "Inventory";
const editPermission = "inventory:edit";
const deletePermission = "inventory:delete";
const addPermission = "inventory:add";

const columns = [
    {
        title: 'Food Item',
        dataIndex: 'food_item',
        key: 'food_item',
        render: (_, row) => row?.food_item_data?.title
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        align: "right"
    },
    {
        title: 'Expiry',
        dataIndex: 'expiry',
        key: 'expiry',
    },
    {
        title: 'Theatre',
        dataIndex: 'theatre',
        key: 'theatre',
        render: (_, row) => row?.theatre_data?.title
    },
    {
        title: 'Used Quantity',
        dataIndex: 'used_quantity',
        key: 'used_quantity',
        align: "right"
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
            return text?.replaceAll?.("_", " ");
        }
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const InventoryPage = () => {

    const params = useParams();
    const { id } = params;

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        extraApiParams={{theatre: id}}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        filters={[allowedFiltersEnum.CLIENT.category__theatre__client, allowedFiltersEnum.THEATRE.category__theatre]}
        addPermissionNames={[addPermission]}
    />;
}

export default InventoryPage;