import { Flex, Spin } from "antd";
import React, { useEffect, useState } from "react";

const NoAccess = ({permissionNeeded=''}) => {

    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(()=>{
        setTimeout(()=>{
            setShowSpinner(false);
        }, 350)
    }, []);
    
    switch(permissionNeeded){
        case "":
        default:
            return (
                <Flex vertical align="center" justify="center" style={{height: "100%"}}>
                    {
                        showSpinner ? <Spin size="small" /> : <p>You do not have access for this page.</p>
                    }
                </Flex>
            );
    }
}

export default NoAccess;
