import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Order/FoodOrders/AddEditForm";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";
import { getFormattedAmount } from "Utils/textFormat";
import { Flex } from "antd";
import { useSelector } from "react-redux";

const apiUrl = "/order/rest/food_orders";
const headerTitle = "Food Orders";
const editPermission = "";
const deletePermission = "";
const addPermission = "";

const columns = [
    // {
    //     title: 'Invoice No.',
    //     dataIndex: 'invoice_no',
    //     key: 'invoice_no',
    // },
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, row) => row?.user_data?.username || text
    },
    {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
        render: (text, row) => (
            <Flex vertical>
                <span>{row?.order?.id}</span>
            </Flex>
        )
    },
    {
        title: 'Theatre',
        dataIndex: 'theatre',
        key: 'theatre',
        render: (text, row) => (
            <Flex vertical>
                <span>{row?.order?.theatre_data?.title}</span>
            </Flex>
        ),
    },
    {
        title: 'Show',
        dataIndex: 'show',
        key: 'show',
        render: (text, row) => (
            <Flex vertical>
                <span>{row?.order?.show_data?.client_program_data?.title}</span>
                <span>{row?.order?.show_data?.date} {row?.order?.show_data?.start_time?.slice?.(0, 5)}</span>
                <span>{row?.order?.show_data?.hall_data?.hall_no}</span>
            </Flex>
        ),
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'Payment Total',
        dataIndex: 'related_payment_total',
        key: 'related_payment_total',
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    // {
    //     title: 'Hall',
    //     dataIndex: 'hall',
    //     key: 'hall',
    //     render: (text, row) => (
    //         <Flex vertical>
    //             <span>{row?.show_data?.hall?.hall_no}</span>
    //         </Flex>
    //     ),
    // },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const FoodOrdersPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
        filters={[allowedFiltersEnum.THEATRE.theatre, allowedFiltersEnum.CLIENT.theatre__client]}
    />;
}

export default FoodOrdersPage;