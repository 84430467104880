import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Master/Cities/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/master/rest/cities";
const headerTitle = "Cities";
const editPermission = "cities:edit";
const deletePermission = "cities:delete";
const addPermission = "cities:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text, row) => {
            // console.log({text, row});
            return row?.state_data?.title
        }
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const CitiesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default CitiesPage;