/**
 * Groups an array of objects by a specified key.
 *
 * @param {Array<Object>} array - The array of objects to be grouped.
 * @param {string} key - The key to group the objects by.
 * @returns {Object} An object where keys are unique values from the specified key,
 *                   and values are arrays of objects with matching key values.
 *
 * @example
 * const data = [
 *   { id: 1, category: 'A', value: 'apple' },
 *   { id: 2, category: 'B', value: 'banana' },
 *   { id: 3, category: 'A', value: 'avocado' },
 *   // ...
 * ];
 *
 * const groupedData = groupBy(data, 'category');
 * // Output: { 'A': [ { id: 1, category: 'A', value: 'apple' }, { id: 3, category: 'A', value: 'avocado' } ],
 * //           'B': [ { id: 2, category: 'B', value: 'banana' } ] }
 */
const groupBy = function (array, key) {
    return array.reduce(function (result, item) {
        const keyValue = item[key];
        (result[keyValue] = result[keyValue] || []).push(item);
        return result;
    }, {});
};

export default groupBy;
