export const validatePositiveInteger = (rule, value) => {
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 0) {
        return Promise.reject('It must be a non-negative numeric value.');
    }

    const stringValue = String(value);
    const decimalIndex = stringValue.indexOf('.');
    // console.log({ decimalIndex })

    if (decimalIndex > -1) {
        return Promise.reject('It cannot contain decimal points.');
    }

    return Promise.resolve();
};

export const validateTwoDecimalNumber = (rule, value) => {
    if (!value) return Promise.resolve();
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 0) {
        return Promise.reject('It must be a non-negative numeric value.');
    }

    const stringValue = String(value);
    const decimalIndex = stringValue.indexOf('.');
    const numberOfDigitsBeforeDecimal = decimalIndex === -1 ? stringValue.length : decimalIndex;

    if (numberOfDigitsBeforeDecimal > 10) {
        return Promise.reject('It can have at most 10 digits.');
    }

    const numberOfDecimalPoints = decimalIndex === -1 ? 0 : stringValue.length - decimalIndex - 1;
    if (numberOfDecimalPoints > 2) {
        return Promise.reject('It can have at most 2 decimal points.');
    }

    return Promise.resolve();
};

export const onInputTextShouldBeLowerCase = (event) => {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) { return; }
    Object.assign(target, { value: target.value.toLowerCase() });
}