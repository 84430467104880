import { useEffect, useState } from "react";
import { Button, Flex, Space, Spin, notification, Radio, Drawer, Form, Input, Select } from "antd";
import axios from "axios";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatuses } from "Redux/enums";
import { resetUpdateBulkStatus, resetUpdateSingleStatus } from "Redux/HallLayoutReducer/hallLayoutSlice";
import HallLayoutForm from "Components/Forms/Theatres/Halls/HallLayoutForm";
import SeatDrag from "Components/Theatre/DragMode/SeatDrag";
import { fetchThisTheatreChannels } from "Api/commonApis";

const STATUS = {
  FETCHING: "FETCHING",
  FETCHING_FAILED: "FETCHING_FAILED",
  PROCESSING: "PROCESSING",
  PROCESSING_FAILED: "PROCESSING_FAILED",
  DONE: "DONE"
}

export const MODE = {
  SINGLE: "SINGLE",
  BULK: "BULK"
}

function App() {
  const [seats, setSeats] = useState(null);
  const [draggedSeats, setDraggedSeats] = useState(null);

  const [isSelecting, setIsSelecting] = useState(false);
  const [startPoint, setStartPoint] = useState({ row: 0, col: 0 });

  const dispatch = useDispatch();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [fetchProcessLayoutStatus, setFetchProcessLayoutStatus] = useState(null);

  const [selectedSeats, setSelectedSeats] = useState(null);
  const [currentMode, setCurrentMode] = useState(MODE.BULK); // Always bulk for this component, value used in Form

  const [openDrawer, setOpenDrawer] = useState(false);

  const [theatreChannels, setTheatreChannels] = useState([])
  const [currentTheatreChannel, setCurrentTheatreChannel] = useState(null)

  const params = useParams();
  const { id, hall_id } = params;

  const fetchTheatreChannels = async () => {
    try {
      const list = await fetchThisTheatreChannels(id);
      if (Array.isArray(list)) {
        const options = list.map(l => ({ value: l.id, label: l?.channel_data?.title }))
        setTheatreChannels(options);
        setCurrentTheatreChannel(options.find(op => op.label === "POS"))
      } else {
        throw new Error("Fetch Theatre Channels did not return a list");
      }
    } catch (err) {
      console.log({ err })
      if (err.message) {
        openNotification("Theatre Channels", err.message, "error");
      } else {
        openNotification("Theatre Channels", "Something went wrong while fetching Theatre Channel options", "error")
      }
    }
  }

  useEffect(() => {
    if (id) {
      fetchTheatreChannels();
    }
  }, [id])

  const handleMouseDown = (row, col) => {
    console.log("handleMouseDown", row, col);
    setIsSelecting(true);
    setStartPoint({ row, col });
    // Initialize selection with the current seat
    toggleSeat(row, col);
  };

  const handleMouseOver = (row, col) => {
    console.log("handleMouseOver", row, col);
    if (!isSelecting) return;
    // Select seats within the drag area
    selectSeats(startPoint, { row, col });
  };

  const handleMouseUp = () => {
    console.log("handleMouseOver");
    setIsSelecting(false);
  };

  const toggleSeat = (row, col) => {
    const updatedSeats = draggedSeats.map((seatRow, rowIndex) =>
      seatRow.map((seat, colIndex) =>
        rowIndex === row && colIndex === col ? !seat : seat
      )
    );
    console.log(updatedSeats);
    setDraggedSeats(updatedSeats);
    updateSelectedSeats(updatedSeats);
  };

  const selectSeats = (start, end) => {
    const updatedSeats = [];
    // draggedSeats.map((row, rowIndex) =>
    //   row.map((seat, colIndex) =>
    //     rowIndex >= Math.min(start.row, end.row) && rowIndex <= Math.max(start.row, end.row) &&
    //       colIndex >= Math.min(start.col, end.col) && colIndex <= Math.max(start.col, end.col) ? true : seat
    //   )
    // );
    for (let [rowIndex, row] of draggedSeats.entries()){
      let thisRow = [];
      for (let [colIndex, seat] of row.entries()){
        if (
          (rowIndex >= Math.min(start.row, end.row))
          &&
          (rowIndex <= Math.max(start.row, end.row))
          &&
          (colIndex >= Math.min(start.col, end.col))
          &&
          (colIndex <= Math.max(start.col, end.col))
        ) {
          thisRow.push(true);
        } else {
          thisRow.push(seat);
        }
      }
      updatedSeats.push(thisRow);
    }
    setDraggedSeats(updatedSeats);
    updateSelectedSeats(updatedSeats);
  };

  const updateSelectedSeats = (allSeatsWithIsDraggedValues) => {
    let newSelectedSeats = [];
    for (let [rowIndex, row] of allSeatsWithIsDraggedValues.entries()){
      for (let [colIndex, seat] of row.entries()){
        if (seat) {
          newSelectedSeats.push(seats[rowIndex][colIndex]);
        }
      }
    }      
    console.log(newSelectedSeats);
    setSelectedSeats(newSelectedSeats);
  }

  useEffect(() => {
    console.log({ seats, draggedSeats });
  }, [seats, draggedSeats])

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const processSeats = (data) => {
    setFetchProcessLayoutStatus(STATUS.PROCESSING);
    try {
      let seatsArrangement = [];
      for (const seat of data) {

        let index = seat?.index?.split(",");

        let noIndex = !index;
        let indexLengthIsNotTwo = Array.isArray(index) && index.length !== 2;

        let indexAreNotNumbers = true;
        if (!indexLengthIsNotTwo) {
          indexAreNotNumbers = isNaN(Number(index[0])) && isNaN(Number(index[1]))
        }

        if (noIndex || indexLengthIsNotTwo || indexAreNotNumbers) {
          throw new Error("no index in seat");
        }

        let i = Number(index[0]);
        let j = Number(index[1]);
        if (seatsArrangement[i]) {
          seatsArrangement[i][j] = seat;
        } else {
          seatsArrangement[i] = [];
          seatsArrangement[i][j] = seat;
        }
      }
      console.log({ seatsArrangement });
      setDraggedSeats(Array(seatsArrangement.length).fill().map(() => Array(seatsArrangement[0].length).fill(false)))
      setFetchProcessLayoutStatus(STATUS.DONE);
      setSeats(seatsArrangement);
    } catch (err) {
      console.error(err);
      setFetchProcessLayoutStatus(STATUS.PROCESSING_FAILED);
      if (typeof err === "string") {
        openNotification("Couldn't process data", err, "error");
      }
    }

  }

  const fetchSeats = async () => {
    try {
      setFetchProcessLayoutStatus(STATUS.FETCHING)
      const response = await axios.get(`/hall/rest/seat/?hall=${hall_id}`);
      console.log({ response });
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          processSeats(response.data);
        } else {
          setFetchProcessLayoutStatus(STATUS.PROCESSING_FAILED);
          openNotification("Error in Seats Data", "No Seats present in the hall", "error");
        }
      } else {
        setFetchProcessLayoutStatus(STATUS.PROCESSING_FAILED);
        openNotification("Error in Seats Data", "Could not find any seats in this hall", "error");
      }
    } catch (err) {
      console.error(err);
      setFetchProcessLayoutStatus(STATUS.FETCHING_FAILED);
      openNotification("Error in Seats Data", "Couldn't fetch seats for this hall. Please contact developers or try again.", "error");
    }
  }

  const updateSingleSeatStatus = useSelector(store => store.hallLayout?.updateSingleSeatStatus);
  const updateSingleSeatErrorMessage = useSelector(store => store.hallLayout?.updateSingleSeatErrorMessage);
  const updateBulkSeatStatus = useSelector(store => store.hallLayout?.updateBulkSeatStatus);
  const updateBulkSeatErrorMessage = useSelector(store => store.hallLayout?.updateBulkSeatErrorMessage);

  useEffect(() => {
    if (updateSingleSeatStatus === asyncStatuses.SUCCESS) {
      openNotification("Successfully updated", null, "success");
      dispatch(resetUpdateSingleStatus());
      setOpenDrawer(false);
      setSelectedSeats(null);
      fetchSeats();
    } else if (updateBulkSeatStatus === asyncStatuses.SUCCESS) {
      openNotification("Successfully updated", null, "success");
      setOpenDrawer(false);
      dispatch(resetUpdateBulkStatus());
      setSelectedSeats(null);
      fetchSeats()
    } else if (updateBulkSeatStatus === asyncStatuses.FAILED) {
      openNotification("Failed to update", updateBulkSeatErrorMessage, "error");
      dispatch(resetUpdateBulkStatus());
    } else if (updateSingleSeatStatus === asyncStatuses.FAILED) {
      openNotification("Failed to update", updateSingleSeatErrorMessage, "error");
      dispatch(resetUpdateSingleStatus());
    }
  }, [updateBulkSeatStatus, updateSingleSeatStatus]);

  const changeSelectedSeats = (newSeats) => {
    console.log("changing selected seats", newSeats);
    setSelectedSeats(newSeats);
  }

  useEffect(() => {
    fetchSeats();
  }, []);

  return (
    <>
      {contextHolder}
      <Flex style={{ width: "100%", height: "100%", gap: 20 }} vertical justify="start" align="center">
        <Flex vertical gap={5} style={{ width: "100%", height: "85%", minHeight: "85%", border: "1px solid gray", padding: 10 }} justify="center" align="center">
          <Flex flex={12} vertical justify="flex-start" align="center" style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>
            {currentTheatreChannel &&
              <Form
                requiredMark={true}
                autoComplete="off"
              >
                <Form.Item name="theatre_channel" label="Prices for" initialValue={currentTheatreChannel?.value}>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    loading={Array.isArray(theatreChannels) ? false : true}
                    options={theatreChannels}
                    onChange={(value) => {
                      setCurrentTheatreChannel(theatreChannels.find(th => th.value === value))
                    }}
                  />
                </Form.Item>
              </Form>
            }
            {
              fetchProcessLayoutStatus === STATUS.FETCHING ?
                <>
                  <Spin />
                  <span>Loading</span>
                </>
                : fetchProcessLayoutStatus === STATUS.PROCESSING ?
                  <>
                    <Spin />
                    <span>Processing</span>
                  </> : fetchProcessLayoutStatus === STATUS.DONE ? <div onMouseUp={handleMouseUp}>
                    {
                      seats?.map?.((row, rowIndex) => {
                        return (
                          <Flex key={rowIndex}>
                            {
                              row?.map?.((seat, colIndex) => {
                                return (
                                  <SeatDrag
                                    isSeatDragged={draggedSeats[rowIndex][colIndex]}
                                    onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                                    onMouseOver={() => handleMouseOver(rowIndex, colIndex)}
                                    seat={seat}
                                    key={seat?.id}
                                    changeSelectedSeats={changeSelectedSeats}
                                    setOpenDrawer={setOpenDrawer}
                                    selectedSeats={selectedSeats}
                                    currentTheatreChannel={currentTheatreChannel}
                                  />);
                              })
                            }
                          </Flex>
                        )
                      })
                    }
                  </div> : <>
                    {
                      (fetchProcessLayoutStatus === STATUS.FETCHING_FAILED || fetchProcessLayoutStatus === STATUS.PROCESSING_FAILED) ?
                        <em style={{ color: "red" }}>
                          Failed to {fetchProcessLayoutStatus === STATUS.FETCHING_FAILED ? "Fetch" : "Process"} Data
                        </em>
                        :
                        <Spin />
                    }
                  </>
            }
          </Flex>
        </Flex>
        {
          fetchProcessLayoutStatus === STATUS.DONE ?
            <Flex gap={10}>
              <Button onClick={() => setOpenDrawer(true)}>
                Save Layout
              </Button>
              <Button onClick={() => {
                setSelectedSeats([])
                setDraggedSeats(draggedSeats.map((seatRow, rowIndex) => seatRow.map((seat, colIndex) => false)))
              }}>
                Clear Selection
              </Button>
            </Flex>
            :
            <></>
        }
      </Flex>
      <Drawer
        title={`Save Layout`}
        width={"fit-content"}
        style={{ minWidth: "30vw" }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <HallLayoutForm
          changeSelectedSeats={changeSelectedSeats}
          currentMode={currentMode}
          selectedSeats={selectedSeats}
          key={`form-for-hall-layout`}
          openDrawer={openDrawer}
        />
      </Drawer>
    </>
  );
}

export default App;