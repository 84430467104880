import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchUserList } from 'Api/commonApis';
import { useParams } from 'react-router';
import lodash from "lodash";

const AddEditForm = ({ apiUrl }) => {

    const params = useParams();
    console.log({ params });

    const [notificationApi, contextHolder] = notification.useNotification();

    const theatreList = useSelector(store => store.theatre?.theatreList);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [channelOptions, setChannelOptions] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
        return form.resetFields();
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let copy = lodash.cloneDeep(currentRecordData);
        copy.password = "";
        console.log({copy})
        form.setFieldsValue(copy);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    useEffect(()=>{
        if (currentFormMode === FormModesEnum.ADD){
            form.resetFields();
        }
    }, [form, currentFormMode]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
    let url = apiUrl?.split?.("?")[0];
    // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
    dispatch(editARecord({ apiUrl:url , id: currentEditViewFormId, data: val }));
  }

    const onFinish = (val) => {
        delete val.confirm_password;
        val.status = !!val.status;
        console.log({val});
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }

    return (
        <>
            {contextHolder}
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Form.Item
                    name="device_name"
                    label="Device Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter device name',
                        },
                    ]}
                >
                    <Input placeholder="Please enter device name" />
                </Form.Item>
                <Form.Item
                    name="mac_address"
                    label="MAC Address"
                    rules={[
                        {
                            required: false,
                            message: 'Please enter MAC Address',
                        },
                    ]}
                >
                    <Input placeholder="Please enter MAC Address" />
                </Form.Item>
                <Form.Item
                    name="udid"
                    label="UDID"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter UDID',
                        },
                    ]}
                >
                    <Input placeholder="Please enter UDID" />
                </Form.Item>
                <Form.Item
                    label="General Pin"
                    name="general_pin"
                    rules={[
                        {
                            required: true,
                            message: 'Please input General Pin!',
                        },
                    ]}
                >
                    <Input placeholder="Please enter General Pin" />
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    name="note"
                    label="Note"
                    rules={[
                        // {
                        //     required: true,
                        //     message: 'Please enter Note',
                        // },
                    ]}
                >
                    <Input.TextArea allowClear placeholder="Please enter Note" />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: currentFormMode === FormModesEnum.EDIT ? false : true,
                            message: 'Please input your password!',
                        },
                        {
                            min: 6,
                            message: 'Password must be at least 6 characters!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: currentFormMode === FormModesEnum.EDIT ? false : true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                {
                    params.id ?
                        <Form.Item
                            hidden
                            name="theatre"
                            initialValue={params?.id}
                        >
                            <Input />
                        </Form.Item> :
                        <Form.Item
                            label="Theatre"
                            name="theatre"
                            style={{ width: "150px" }}
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Select
                                placeholder="Choose a Theatre to Filter"
                                loading={Array.isArray(theatreList) ? false : true}
                                onSelect={(v) => {
                                    console.log({ v });
                                }}
                            >
                                {
                                    theatreList?.map((afv, ind) => {
                                        return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                }
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Submit
                                </Button>
                        }
                    </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                        {
                            editRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Updating
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
        </>
    );
};
export default AddEditForm;