import { SELECTED_DATE_FORMAT } from "Redux/ShowReducer/showSlices";
import dayjs from "dayjs";

export const RELATIVE_DATE_OPTIONS = {
    TODAY: 'Today',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    THIS_YEAR: 'This Year',
    CUSTOM: 'Custom'
}

export const getStartAndEndDate = function (input) {
    const now = dayjs();

    if (input === RELATIVE_DATE_OPTIONS.TODAY) {
        const startOfDay = now.startOf('day');
        const endOfDay = now.endOf('day');

        return {
            start_date: startOfDay.format(SELECTED_DATE_FORMAT),
            end_date: endOfDay.format(SELECTED_DATE_FORMAT)
        };
    } else if (input === RELATIVE_DATE_OPTIONS.THIS_MONTH) {
        const startOfMonth = now.startOf('month');
        const endOfMonth = now.endOf('month');

        return {
            start_date: startOfMonth.format(SELECTED_DATE_FORMAT),
            end_date: endOfMonth.format(SELECTED_DATE_FORMAT)
        };
    } else if (input === RELATIVE_DATE_OPTIONS.THIS_WEEK) {
        const startOfWeek = now.startOf('week');
        const endOfWeek = now.endOf('week');

        return {
            start_date: startOfWeek.format(SELECTED_DATE_FORMAT),
            end_date: endOfWeek.format(SELECTED_DATE_FORMAT)
        };
    } else if (input === RELATIVE_DATE_OPTIONS.THIS_YEAR) {
        const startOfYear = now.startOf('year');
        const endOfYear = now.endOf('year');

        return {
            start_date: startOfYear.format(SELECTED_DATE_FORMAT),
            end_date: endOfYear.format(SELECTED_DATE_FORMAT)
        };
    } else {
        return getStartAndEndDate(RELATIVE_DATE_OPTIONS.TODAY);
    }
}