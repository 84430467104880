import React, { useState } from 'react';
import { Button, Flex, notification } from 'antd';
import { DownloadOutlined } from "@ant-design/icons"
import axios from 'axios';
// import { xlsx_icon } from 'Assets';
import moment from 'moment-timezone';

const ExportPdfServer = ({ apiUrl, payload, getPayload, reportName, reportTitleForNotification }) => {
    const [loading, setLoading] = useState(false);

    const handleExport = async () => {
        setLoading(true);
        try {
            let payload_ = payload;
            if (typeof getPayload === "function"){
                payload_ = getPayload();
            }
            const response = await axios.post(apiUrl, payload_, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${reportName}_${moment(new Date()).format("YYYY_MMM_DD hh_mm_ss")}.pdf`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Success',
                description: `${reportTitleForNotification} report exported successfully!`,
            });
        } catch (error) {
            console.error('Export failed:', error);
            notification.error({
                message: 'Error',
                description: `Failed to export ${reportTitleForNotification} report`,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            type="primary" 
            onClick={handleExport} 
            loading={loading}
        >
            <Flex gap={10}>
                <span>PDF</span>
                <DownloadOutlined />
            </Flex>
        </Button>
    );
};

export default ExportPdfServer;
