/**
 * Converts a duration in minutes to a human-readable string representation.
 *
 * @param {number} durationInMinutes - The duration in minutes to be converted.
 * @returns {string} A string representation of the duration in the format "H [h] m [m]".
 *                  For example, "1h 40m" for 100 minutes.
 * @throws {TypeError} If the input is not a number.
 *
 * @example
 * const readableTime = getTimeReadable(100);
 * // Returns "1h 40m"
 */
export const getTimeReadable = (durationInMinutes) => {
    // Validate input
    if (typeof durationInMinutes !== 'number') {
        throw new TypeError('Input must be a number');
    }

    // Calculate hours and remaining minutes
    const hours = Math.floor(durationInMinutes / 60);
    const remainingMinutes = durationInMinutes % 60;

    // Build the human-readable string
    const formattedDuration = `${hours}h ${remainingMinutes}m`;

    return formattedDuration;
}