import { START_TIME_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getTimeReadable } from 'Utils/timeUtilities';
import { Button, Flex, Tooltip } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons';

const TooltipInfo = ({ thisShow }) => {
    // console.log({ thisShow });

    let endTime = dayjs(thisShow?.time, START_TIME_FORMAT).add(thisShow?.runtime, 'minute').format(START_TIME_FORMAT);
    let friendlyRuntime = getTimeReadable(thisShow?.runtime);

    const openShow = () => {
        let url = window.location.origin + "/shows?search=" + thisShow.id;
        window.open(url, "_blank");
    }

    return <Flex vertical>
        <span>{thisShow?.client_program_title}</span>
        <span>{thisShow?.time} - {endTime} ({friendlyRuntime})</span>
        {
            ("id" in thisShow) ? <>
                <Flex justify='flex-end' style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Button type='link' onClick={openShow} size='small'>
                        Go To Show
                        <ArrowRightOutlined />
                    </Button>
                </Flex>
            </> : <></>
        }
    </Flex>
}

const TimeTagTooltip = ({ thisShow, children }) => {
    return (
        <Tooltip title={<TooltipInfo thisShow={thisShow} />} placement='top'>
            {children}
        </Tooltip>
    )
}

export default TimeTagTooltip;