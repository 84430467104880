import { Bar } from '@ant-design/plots';
import ReportTitle from 'Components/Dashboard/ReportTitle';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';

const VerticalBar = ({ title, apiData }) => {
    const [data, setData] = useState([]);

    const colorPalette = ['#ff9999', '#ff6666', '#ff3333', '#ff0000', '#cc0000'];
    useEffect(() => {
        const processApiData = () => {
            if (apiData && typeof apiData === 'object') {
                let values = Object.values(apiData);
                if (values.length > 0) {
                    let d = values
                        .map((v, index) => ({
                            name: v.name, 
                            amount: parseFloat(v.amount.toFixed(2)), 
                            color: colorPalette[index % colorPalette.length]
                        }))
                        .sort((a, b) => (a.amount > b.amount ? -1 : 1))
                        .slice(0, 3);
                    setData(d);
                }
            }
        };
        processApiData();
    }, [apiData]);


    const config = {
        data,
        xField: 'name',
        yField: 'amount',
        autoFitis : true,
        scale:{color:{palette:colorPalette}},
        colorField: 'name', 
        label: false, 
        xAxis: {
            label: {
                style: {
                    fontSize: 14,
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fontSize: 12,
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: '#f0f0f0',
                    },
                },
            },
        },
        interactions: [{ type: 'element-active' }],
        animation: {
            appear: {
                animation: 'scale-in-x',
                duration: 800,
            },
        },
        barStyle: {
            radius: [4, 4, 0, 0],
        },
        legend: false,
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <ReportTitle title={title} />
            <div style={{ width: '100%' }}>
                <Bar {...config} />
            </div>
        </Space>
    );
};

export default VerticalBar;
