import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { Flex, Spin, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import ExportXlsx from './ExportXlsx';

const API_URL = "/reports/distributor-report/";

const initialDates = dayjs();

const tableColumns = [
    {
        title: 'Movie',
        dataIndex: 'movie_title',
        key: 'movie_title',
        sorter: (a, b) => a.movie_title - b.movie_title,
    },
    {
        title: 'Sale',
        dataIndex: 'total_sales',
        key: 'total_sales',
        render: (text) => getFormattedAmount(text),
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.total_sales - b.total_sales,
        align: "center",
    },
]

const DistributorReport = () => {

    const [data, setData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['theatre'] = theatreFilterVal;
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data?.map((a, index) => ({ ...a, key: index })));
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Distributor Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [startDate, endDate, theatreFilterVal]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        theatre: theatreFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'distributor_report'}
                    reportTitleForNotification={'Distributor Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Flex justify='space-between' style={{ width: "100%", marginTop: 20 }}>
                    <ReportTitle title={`Distributor Report`} />
                    <Flex gap={15}>
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex vertical gap={20}>
                    {
                        loading ? <Spin /> : <>
                            {
                                data?.map?.((d, i) => {

                                    const tableData = d.movies?.map?.((r, ind) => ({ ...r, key: r.movie_id || ind }));
                                    console.log({ tableData });
                                    const totalSales = tableData?.reduce?.((acc, curr) => curr.total_sales + acc, 0)
                                    return <Flex vertical>
                                        <Flex style={{ backgroundColor: "#d1d1d1", padding: 10, paddinTop: 5, paddingBottom: 5 }} gap={20}>
                                            <h4 style={{ margin: 2 }}>{d?.distributor_name}</h4>
                                            <h4 style={{ margin: 2 }}>{getFormattedAmount(totalSales)}</h4>
                                        </Flex>
                                        <Flex style={{ marginLeft: 20, padding: 10 }}>
                                            <Table
                                                columns={tableColumns}
                                                bordered
                                                dataSource={tableData}
                                                size='small'
                                                scrollToFirstRowOnChange
                                                stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                                                loading={loading}
                                                scroll={{
                                                    y: "65vh",
                                                    x: "100%"
                                                }}
                                                pagination={false}
                                            />
                                        </Flex>
                                    </Flex>;
                                })
                            }
                        </>
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default DistributorReport