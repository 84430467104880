import { fetchARecord } from 'Redux/MasterReducer/crudSlices';
import { getTimeReadable } from 'Utils/timeUtilities';
import { Flex, Image, Progress, Spin } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CrewCastCard from './CrewCastCard';
import groupBy from 'Utils/arrayGroupBy';
import { landscapePlaceholder } from 'Assets';
import { asyncStatuses } from 'Redux/enums';
import UpcomingShows from './UpcomingShows';

const ProgramView = ({ apiUrl = `/program/rest/client-programs` }) => {
  const dispatch = useDispatch();

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  const [groupedCrewData, setCrewGroupedData] = useState(null);

  useEffect(() => {
    dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }))
  }, [currentEditViewFormId]);

  useEffect(() => {
    console.log({ currentEditViewFormId, currentRecordData, currentFormMode });
  }, [currentEditViewFormId, currentRecordData, currentFormMode]);

  useEffect(() => {
    if (Array.isArray(currentRecordData?.program_data?.crew)) {
      const data = groupBy(currentRecordData?.program_data?.crew, 'department');
      setCrewGroupedData(data);
      console.log({ groupedCrewData: data })
    } else {
      setCrewGroupedData(null);
    }
  }, [currentRecordData]);

  let backdrop = `https://media.themoviedb.org/t/p/w1920_and_h800_multi_faces${currentRecordData?.tbdm_json?.backdrop_path}`;
  if (currentRecordData?.backdrop_image) {
    backdrop = currentRecordData.backdrop_image;
  }

  if (fetchRecordStatus === asyncStatuses.LOADING) {
    return <Spin />
  }

  if (fetchRecordStatus === asyncStatuses.FAILED) {
    return <p className='text-center' style={{color: "red"}}>
      <em>failed to load data.</em>
    </p>
  }

  return (
    <>
      <Flex
        justify='center'
        align='start'
        style={{
          width: "100%",
          height: "fit-content",
          backgroundImage: `url(${backdrop})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          backgroundRepeat: "no-repeat",
          zIndex: 10
        }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 11,
            display: "flex"
          }}></div>

        <Flex
          justify='center'
          align='center'
          flex={1}
          style={{ position: "relative", zIndex: 12, padding: 20, height: "100%", boxSizing: "border-box" }}>
          <Image
            src={currentRecordData?.poster_image || `https://media.themoviedb.org/t/p/w300_and_h450_bestv2${currentRecordData?.tbdm_json?.poster_path}`}
            alt='Preview'
            style={{
              width: "100%"
            }}
            fallback={landscapePlaceholder}
          />
        </Flex>
        <Flex vertical justify='center' align='flex-start' flex={2} style={{ position: "relative", zIndex: 12, color: "white" }}>
          <Title level={3} style={{ color: "white" }}>{currentRecordData.title}</Title>
          <span>
            {currentRecordData?.release_date}
            {
              Array.isArray(currentRecordData.genres) && currentRecordData.genres.length > 0 ?
                <>
                  <span style={{ margin: "0px 10px", display: "inline-block" }}>•</span>
                  <span>
                    {currentRecordData.genres.reduce((a, c) => {
                      return a + (a && ", ") + (c.title || c);
                    }, "")}
                  </span>
                </> : <></>
            }
            {
              currentRecordData?.runtime ? <>
                <span style={{ margin: "0px 10px", display: "inline-block" }}>•</span>
                <span>{getTimeReadable(currentRecordData?.runtime)}</span>
              </> : <></>
            }
          </span>
          {
            currentRecordData.tagline ? <>
              <Title level={5} style={{ color: "gray", fontStyle: "italic", marginBottom: 0 }}>{currentRecordData.tagline}</Title>
            </> : <></>
          }
          {
            currentRecordData?.tbdm_json?.overview ? <Flex vertical style={{ paddingBottom: 10, paddingRight: 10 }}>
              <Title level={5} style={{ color: "white" }}>Overview</Title>
              <span style={{ color: "white" }}>{currentRecordData.tbdm_json.overview}</span>
            </Flex> : currentRecordData?.overview ? <Flex vertical style={{ paddingBottom: 10, paddingRight: 10 }}>
              <Title level={5} style={{ color: "white" }}>Overview</Title>
              <span style={{ color: "white" }}>{currentRecordData.overview}</span>
            </Flex> : <></>
          }
          {
            currentRecordData?.program_data?.tmdb_json?.vote_average ? <Flex gap={10} align='center' style={{ marginTop: 25, marginBottom: 15 }}>
              <Progress
                type="circle"
                percent={Math.floor(currentRecordData?.program_data?.tmdb_json?.vote_average * 10)}
                format={(percent) => `${percent}%`}
                size={60}
                style={{ color: "white" }}
                strokeColor={"#dedede"}
                trailColor={"#4b4b4b"}
                className='user-rating'
                strokeWidth={8}
              />
              <span style={{ color: "white" }}>
                User Rating
              </span>
            </Flex> : <></>
          }
        </Flex>
      </Flex>
      <Flex gap={5} style={{ marginBottom: 15, padding: 15, paddingTop: 20, backgroundColor: "lightgray" }} justify='space-between'>
        {currentRecordData?.program_data?.tmdb_json?.status && <Flex vertical gap={5}>
          <span><strong>Status</strong></span>
          <span>{currentRecordData?.program_data?.tmdb_json?.status}</span>
        </Flex>}
        {currentRecordData?.program_data?.tmdb_json?.spoken_languages?.[0]?.english_name && <Flex vertical gap={5}>
          <span><strong>Original Language</strong></span>
          <span>{currentRecordData?.program_data?.tmdb_json?.spoken_languages?.[0]?.english_name}</span>
        </Flex>}
        {currentRecordData?.program_data?.tmdb_json?.budget?.toLocaleString?.("en-US") && <Flex vertical gap={5}>
          <span><strong>Budget</strong></span>
          <span>$ {currentRecordData?.program_data?.tmdb_json?.budget?.toLocaleString?.("en-US")}</span>
        </Flex>}
        {currentRecordData?.program_data?.tmdb_json?.revenue?.toLocaleString?.("en-US") && <Flex vertical gap={5}>
          <span><strong>Revenue</strong></span>
          <span>$ {currentRecordData?.program_data?.tmdb_json?.revenue?.toLocaleString?.("en-US")}</span>
        </Flex>}
      </Flex>
      <UpcomingShows />
      <Flex>
        <Flex vertical flex={1}>
          <>
            {(Array.isArray(currentRecordData?.program_data?.cast) && currentRecordData?.program_data?.cast.length > 0) ? <>
              <Title level={4}>Cast ({Array.isArray(currentRecordData?.program_data?.cast) ? currentRecordData.program_data.cast.length : 0})</Title>
              {
                Array.isArray(currentRecordData.program_data.cast) && currentRecordData.program_data.cast.length > 0 ?
                  <Flex vertical gap={10}>
                    {
                      currentRecordData.program_data.cast.map((rd, i) => <CrewCastCard data={rd} key={rd.id} />)
                    }
                  </Flex>
                  : <div>
                    <p style={{ color: "gray" }}>No Data Available</p>
                  </div>
              }
            </> : <></>}

          </>
        </Flex>
        <Flex vertical flex={1}>
          <>
            {(Array.isArray(currentRecordData?.program_data?.crew) && currentRecordData?.program_data?.crew.length > 0) ?
              <>
                <Title level={4}>Crew ({Array.isArray(currentRecordData?.program_data?.crew) ? currentRecordData?.program_data?.crew.length : 0})</Title>
                {
                  groupedCrewData ?
                    <Flex vertical>
                      {
                        Object.keys(groupedCrewData)?.sort()?.map((gcd, ind) => {
                          return <div key={ind + gcd}>
                            <Title level={5} style={{ marginTop: 20, marginBottom: 10 }}>{gcd}</Title>
                            <Flex vertical gap={15}>
                              {
                                groupedCrewData[gcd].sort((a, b) => a.department > b.department ? 1 : -1).map((rd, i) => <CrewCastCard data={rd} key={rd.id + rd.department + i} />)
                              }
                            </Flex>
                          </div>
                        })
                      }
                    </Flex>
                    : <div>
                      <p style={{ color: "gray" }}>No Data Available</p>
                    </div>
                }
              </>
              : <></>
            }
          </>
        </Flex>

      </Flex>
    </>
  )
}

export default ProgramView