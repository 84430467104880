import './App.css';
import { ConfigProvider } from 'antd';
import AllRoutes from 'Router';
import { Provider } from "react-redux";
import store, { persistor } from 'Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import "Config/dayjsTimeZone";
import { NotificationContextProvider } from 'Config/NotificationsContext';
import { DARK_COLOR } from 'Redux/enums';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 2,
              colorPrimary: "#f5382c",
            },
            components: {
              Menu: {
                darkItemBg: DARK_COLOR,
                darkSubMenuItemBg: "#181818",
                darkPopupBg: DARK_COLOR
              }
            }
          }}
        >
          <NotificationContextProvider>
            <AllRoutes />
          </NotificationContextProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
