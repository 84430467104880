import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Food/Category/AddEditForm";
import { Checkbox, Image } from "antd";
import { useSelector } from "react-redux";
import getPermittedColumns from "Utils/getPermittedColumns";
import CustomTable from "Components/CustomTable/CustomTable";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import { FileImageOutlined } from "@ant-design/icons";

const apiUrl = "/food/categories";
const headerTitle = "Food Categories";
const editPermission = "food_categories:edit";
const deletePermission = "food_categories:delete";
const addPermission = "food_categories:add";

const columns = [
    {
        title: 'Default Image',
        dataIndex: 'default_image',
        key: 'default_image',
        render: (text) => <>
            {
                text && <Image.PreviewGroup
                    items={[
                        text
                    ]}
                >
                    <Image
                        src={text}
                        width={100}
                        fallback={<FileImageOutlined />}
                    />
                </Image.PreviewGroup>
            }
        </>,
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    // {
    //     title: 'Theatre',
    //     dataIndex: 'theatre',
    //     key: 'theatre',
    //     render: (text, row) => {
    //         // console.log({text, row});
    //         return row?.theatre_data?.title
    //     }
    // },
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        render: (text, row) => {
            // console.log({text, row});
            return row?.client_data?.title
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, row) => {
            return <Checkbox disabled checked={row.status}></Checkbox>
        }
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const CategoriesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        // filters={[allowedFiltersEnum.CLIENT.theatre__client, allowedFiltersEnum.THEATRE.theatre]}
        filters={[allowedFiltersEnum.CLIENT.client]}
        addPermissionNames={[addPermission]}
    />;
}

export default CategoriesPage;