import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Modal, Spin } from 'antd';
import { deleteARecord, resetDeleteRecordStatus } from "Redux/MasterReducer/crudSlices";
import { useDispatch, useSelector } from 'react-redux';
import { asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import axios from 'axios';
import { useNotificationContext } from 'Config/NotificationsContext';
import { Spinner } from 'react-bootstrap';

// user/rest/settings/?client=cc5d4596-c4e4-4a7d-a24f-55a23a9c7375

const ConfirmPasswordDeleteModal = ({ modalOpen, setModalOpen, apiUrl, row_id }) => {
    const dispatch = useDispatch();
    const openNotification = useNotificationContext();
    const deleteRecordStatus = useSelector(store => store.master?.deleteRecordStatus);
    const deleteRecordErrorMsg = useSelector(store => store.master?.deleteRecordStatus);
    const userId = useSelector(store => store.auth?.user?.id);
    const userIsAdmin = useSelector(store => store.auth?.user?.is_admin);

    const [adminHasTurnedOnPasswordConfirmation, setAdminHasTurnedOnPasswordConfirmation] = useState(true);
    const [clientSettingsReqStatus, setClientSettingsReqStatus] = useState(null);
    const [confirmPasswordReqStatus, setConfirmPasswordReqStatus] = useState(null);

    useEffect(() => {
        if (userId && modalOpen) {
            const fetchClientSettings = async () => {
                setClientSettingsReqStatus(asyncStatuses.LOADING);
                try {
                    const response = await axios.get(`/user/rest/settings/?client__user=${userId}`);
                    console.log({ response });
                    if (Array.isArray(response.data.results) && response.data.results.length > 0) {
                        setAdminHasTurnedOnPasswordConfirmation(response.data.results[0].password_confirmation);
                    }
                    setClientSettingsReqStatus(asyncStatuses.SUCCESS);
                } catch (err) {
                    console.log(err);
                    // check if we get the error response related to client field.
                    if (err.response?.data?.client) {
                        // if yes, that means the user may be admin
                        setClientSettingsReqStatus(asyncStatuses.SUCCESS);
                    } else {
                        setClientSettingsReqStatus(asyncStatuses.FAILED);
                    }
                }
            }

            fetchClientSettings();
        }
    }, [userId, modalOpen]);

    useEffect(() => {
        if (deleteRecordStatus === asyncStatuses.SUCCESS) {
            setModalOpen(false);
            dispatch(resetDeleteRecordStatus());
        } else if (deleteRecordStatus === asyncStatuses.FAILED) {
            openNotification("Failed to delete the record", deleteRecordErrorMsg, "error");
        }
    }, [deleteRecordStatus]);

    const onFinish = async (values) => {
        setConfirmPasswordReqStatus(asyncStatuses.LOADING);
        try {
            const response = await axios.post('/user/confirm-password/', { password: values.password });
            console.log({ response });
            if (response.data?.message) {
                openNotification(response.data.message, null, "success");
                setConfirmPasswordReqStatus(asyncStatuses.SUCCESS);
                dispatch(deleteARecord({ apiUrl: apiUrl, id: row_id }));
            }
        } catch (err) {
            setConfirmPasswordReqStatus(asyncStatuses.FAILED);
            if (err.response?.data?.password) {
                openNotification("Incorrect Password", null, "error");
            }
            console.log(err);
        }
    };

    useEffect(()=>{
        // console.log({adminHasTurnedOnPasswordConfirmation});
        if (adminHasTurnedOnPasswordConfirmation === false) {
            dispatch(deleteARecord({ apiUrl: apiUrl, id: row_id }))
        }
    }, [adminHasTurnedOnPasswordConfirmation, apiUrl, dispatch, row_id]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Modal
                title={adminHasTurnedOnPasswordConfirmation === false ? "" : "Please enter your login password to continue ..."}
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                footer={null}
            >
                {
                    clientSettingsReqStatus === asyncStatuses.LOADING ?
                        <>
                            <Flex justify='center' align='center' style={{ width: "100%", minHeight: "10vh" }}>
                                <Spin size='sm' />
                            </Flex>
                        </> : clientSettingsReqStatus === asyncStatuses.FAILED ?
                            <>
                                <Flex justify='center' align='center' style={{ width: "100%", minHeight: "10vh" }}>
                                    <p style={{ color: "red" }}>
                                        Something went wrong! Data was not deleted.
                                    </p>
                                </Flex>
                            </> : <>
                                {
                                    adminHasTurnedOnPasswordConfirmation ? <>
                                        <Form
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            layout="vertical"
                                            style={{
                                                marginTop: 20
                                            }}
                                        >
                                            <Form.Item
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>
                                            <Flex align='center' justify='end' gap={20}>
                                                <Form.Item style={{ marginBottom: 0 }}>
                                                    <Button
                                                        disabled={(confirmPasswordReqStatus === asyncStatuses.LOADING || deleteRecordStatus === asyncStatuses.LOADING) ? true : false}
                                                        type="primary"
                                                        htmlType="submit"
                                                        style={{ width: "100%" }}
                                                    >
                                                        {
                                                            confirmPasswordReqStatus === asyncStatuses.LOADING ? <>
                                                                <LoadingOutlined /> Verifying your password
                                                            </> :
                                                                confirmPasswordReqStatus === asyncStatuses.FAILED || confirmPasswordReqStatus === null ? <>
                                                                    Verify
                                                                </> : deleteRecordStatus === asyncStatuses.LOADING ? "Deleting the record now" : deleteRecordStatus === asyncStatuses.FAILED ? "Verify" : ""
                                                        }
                                                    </Button>
                                                </Form.Item>
                                            </Flex>
                                        </Form>
                                    </> : <>
                                        <Flex>
                                            {
                                                deleteRecordStatus === asyncStatuses.LOADING ? "Deleting the record now" : deleteRecordStatus === asyncStatuses.FAILED ? "Verify" : "Deleted"
                                            }
                                        </Flex>
                                    </>
                                }
                            </>
                }
            </Modal>
        </>
    );
};


export default ConfirmPasswordDeleteModal;