import VerticalBar from 'Components/Charts/VerticalBarChart';
import { DatePicker, Flex, Segmented, Space, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import TopSalesTable from './TopSales/TopSalesTable';
import { RELATIVE_DATE_OPTIONS, getStartAndEndDate } from 'Utils/getStartAndEndDate';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import axios from 'axios';
import dayjs from 'dayjs';

export const TYPE_OPTIONS = {
    FOOD: "Food",
    MOVIE: "Movie",
    TICKET_TYPE: "Ticket Type"
}

let url = `reports/top-sales-data/`
const initialDates = dayjs()

const TopSalesComponent = ({ style, openNotification }) => {

    let payload = {}

    const [data, setData] = useState({});
    const [selectedDateFilter, setSelectedDateFilter] = useState(RELATIVE_DATE_OPTIONS.TODAY);
    const [selectedType, setSelectedType] = useState(TYPE_OPTIONS.MOVIE);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);

    const modifyDates = (value) => {
        console.log({ value })
        setStartDate(value[0]);
        setEndDate(value[1])
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                payload['type'] = selectedType;
                if (selectedDateFilter !== RELATIVE_DATE_OPTIONS.CUSTOM) {
                    const { start_date, end_date } = getStartAndEndDate(selectedDateFilter);
                    console.log({ start_date, end_date })
                    payload['start_date'] = start_date;
                    payload['end_date'] = end_date;
                } else {
                    payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                    payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                }
                console.log(payload)
                const response = await axios.post(url, payload);
                console.log({ response });
                setData(response.data);
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch Top Sales data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [url, selectedDateFilter, startDate, endDate, selectedType]);

    return (
        <Space 
        direction='vertical'
        style={{
          ...style,
          border: "0px solid",
          borderRadius: 0, // Sharp corners
          transition: "all 0.3s ease",
        }}>
            <Flex gap={10} align='center'>
                <h3 style={{ margin: 0 }}>Top Sales</h3>
                <Segmented
                    style={{ width: 'max-content' }}
                    options={Object.values(TYPE_OPTIONS)}
                    value={selectedType}
                    onChange={(value) => {
                        setSelectedType(value)
                    }}
                />
            </Flex>
            <Flex vertical gap={10} style={{ width: "100%" }}>
                <Segmented
                    style={{ width: 'max-content' }}
                    options={Object.values(RELATIVE_DATE_OPTIONS)}
                    value={selectedDateFilter}
                    onChange={(value) => {
                        setSelectedDateFilter(value)
                    }}
                />
                {
                    selectedDateFilter === RELATIVE_DATE_OPTIONS.CUSTOM ?
                        <DatePicker.RangePicker defaultValue={[initialDates, initialDates]} onChange={modifyDates} style={{ width: "max-content" }} /> :
                        <></>
                }
                <Flex justify='space-between' style={{ width: "100%" }}>
                    {
                        loading ? <Flex flex={1} justify='center' align='center'><Spin size='small' /></Flex> : <>
                            <Flex flex={2}>
                                <VerticalBar apiData={data} selectedType={selectedType} />
                            </Flex>
                            <Flex flex={5}>
                                <TopSalesTable apiData={data} selectedType={selectedType} />
                            </Flex>
                        </>
                    }
                </Flex>
            </Flex>
        </Space>
    )
}

export default TopSalesComponent