import { Flex, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TIME_DIFFERENCE_FORMAT, START_TIME_FORMAT, SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import TimeTag from './TimeTag';

const getAllTimeTagsPossible = ({ startTime, timeDifference, selectedDate }) => {
    let allPossibleTimesArray = [];

    let fullStartTimeInString = `${selectedDate} ${startTime}`;
    let fullStartTimeFormat = `${SELECTED_DATE_FORMAT} ${START_TIME_FORMAT}`;

    let dayjsTime = dayjs(fullStartTimeInString, fullStartTimeFormat);
    const nextDay = dayjs(selectedDate, SELECTED_DATE_FORMAT).add(1, 'day');

    while (dayjsTime < nextDay) {
        allPossibleTimesArray.push(dayjsTime.format(START_TIME_FORMAT))
        dayjsTime = dayjsTime.add(Number(timeDifference), 'minute');
    }

    return allPossibleTimesArray;
}

const getPossibleTimeTags = ({ startTime, runtime, timeDifference, bookedTimes = [], selectedDate }) => {
    let allPossibleTimesArray = getAllTimeTagsPossible({ startTime, timeDifference, selectedDate });

    let actualPossibleTimesArray = []

    return allPossibleTimesArray;
}

const TimeTags = (props) => {

    const { hall } = props;

    const [allTimes, setAllTimes] = useState([]);
    const [showTimeTags, setShowTimeTags] = useState(false);
    const [reasonToNotShowTags, setReasonToNotShowTags] = useState('');

    const startTime = useSelector(store => store.shows.startTime);
    const timeDifference = useSelector(store => store.shows.timeDifference);
    const selectedDate = useSelector(store => store.shows.selectedDate);
    const runtime = useSelector(store => store.shows.selectedProgramData?.runtime);

    useEffect(() => {

        const calculateTimeTags = () => {
            try {
                let timeTags = getPossibleTimeTags({ startTime, runtime, timeDifference, selectedDate });
                setAllTimes(timeTags);
                setShowTimeTags(true);
                setReasonToNotShowTags('');
            } catch (err) {
                setShowTimeTags(false);
                setReasonToNotShowTags('Error occurred while calculating times');
            }
        }

        if (!selectedDate) {
            setReasonToNotShowTags('No Date Selected');
            setShowTimeTags(false);
        } else if (!runtime) {
            setReasonToNotShowTags('No runtime present for the movie');
            setShowTimeTags(false);
        } else if (!timeDifference) {
            setReasonToNotShowTags('No Time Difference set. Please set it first');
            setShowTimeTags(false);
        } else if (!startTime) {
            setReasonToNotShowTags('No start time set. Please set it first');
            setShowTimeTags(false);
        } else {
            calculateTimeTags();
        }
    }, [selectedDate, startTime, timeDifference, runtime]);

    if (!showTimeTags) {
        return <div>
            <em style={{ color: "gray" }}>{reasonToNotShowTags}</em>
        </div>
    }

    return (
        <Flex wrap='wrap' flex={1} gap={5} style={{width: "100%"}}>
            {
                allTimes.map((time, ind) => {
                    return <TimeTag key={selectedDate+time} time={time} hall={hall} lastTagTime={ind > 0 ? allTimes[ind-1] : allTimes[ind]}/>
                })
            }
        </Flex>
    )
}

export default TimeTags