import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Clients/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Button, Flex } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const apiUrl = "/user/rest/clients";
const headerTitle = "Clients";
const editPermission = "clients:edit";
const deletePermission = "clients:delete";
const addPermission = "clients:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, row) => {
            // console.log({text, row});
            return row?.user_data?.username
        }
    },
    {
        title: 'Client Info',
        key: 'view-client-info',
        fixed: 'right',
        // width: 100,
        render: (text, row, index) => {
            return <Flex vertical style={{ gap: 5 }}>
                <Flex wrap="wrap" gap={5}>
                    <Link to={`/clients/${row.id}/settings`}>
                        <Button size="small">
                            Settings
                        </Button>
                    </Link>
                </Flex>
            </Flex>
        },
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: false, updated: false, action: true })
];

const ClientPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ClientPage;