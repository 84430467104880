import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Checkbox, ColorPicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchUserList, fetchClientList } from 'Api/commonApis';
import { useParams } from 'react-router';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const DEFAULT_COLOR = "#000000";

function isHexColor(str) {
    // Hex color pattern: # followed by either 3 or 6 hexadecimal characters
    const hexColorPattern = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

    return hexColorPattern.test(str);
}

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();

    // const theatreList = useSelector(store => store.theatre?.theatreList);

    console.log({ params });

    const [userOptions, setUserOptions] = useState(null);
    const [clientOptions, setClientOptions] = useState(null);

    useEffect(() => {
        const fetcClientOptions = async () => {
            try {
                const list = await fetchClientList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.title }))
                        setClientOptions(options);
                    } else {
                        openNotification("No Clients Found", "Please enter clients first.", "error");
                    }
                } else {
                    throw new Error("Fetch Clients did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Client Options", err.message, "error");
                } else {
                    openNotification("Client Options", "Something went wrong while fetching client options", "error")
                }
            }
        }
        fetcClientOptions();
    }, []);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchUserOptions = async () => {
            try {
                const list = await fetchUserList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.username }))
                    setUserOptions(options);
                } else {
                    throw new Error("Fetch Users did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("User Options", err.message, "error");
                } else {
                    openNotification("User Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        fetchUserOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        val.color = val?.color?.toHexString?.();
        if (!val.color) {
            val.color = DEFAULT_COLOR;
        }
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        // console.log(val)
        if (typeof val.color === "object" && "toHexString" in val.color) {
            val.color = val.color.toHexString();
        } else if (!isHexColor(val.color)) {
            val.color = DEFAULT_COLOR;
        }
        // console.log(val);
        dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Theatre SeatTypes" requiredData={[{data: clientOptions, field: "client"}]}>
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(userOptions) && userOptions.length > 0)}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a title',
                            },
                        ]}
                    >
                        <Input placeholder='Title' />
                    </Form.Item>
                    <Form.Item
                        label="Color"
                        name="color"
                        initialValue={"#000000"}
                        rules={[
                            {
                                required: true,
                                message: 'Please choose a color!',
                            },
                        ]}
                    >
                        <ColorPicker size="large" showText />
                    </Form.Item>
                    {
                        params.id ?
                            <Form.Item
                                hidden
                                name="client"
                                initialValue={params?.id}
                            >
                                <Input />
                            </Form.Item> :
                            <Form.Item
                                label="Client"
                                name="client"
                                initialValue={params?.id}
                                style={{ width: "150px" }}
                                rules={[{
                                    required: true
                                }]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: 200,
                                    }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    loading={Array.isArray(clientOptions) ? false : true}
                                    options={clientOptions}
                                />
                            </Form.Item>
                    }
                    <Form.Item
                        name="priority"
                        label="Priority"
                        style={{ width: "150px" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter priority',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="default_price"
                        label="Default Price"
                        style={{ width: "150px" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter price',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="web_price"
                        label="Web Price"
                        style={{ width: "150px" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter price',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="app_price"
                        label="App Price"
                        style={{ width: "150px" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter price',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="kiosk_price"
                        label="Kiosk Price"
                        style={{ width: "150px" }}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter price',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form>
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;