import { setFormModeAsEdit } from 'Redux/MasterReducer/crudSlices';
import { setCurrentSegmentToManual } from 'Redux/ProgramReducer/programSlice';
import { fetchTheatreScheduleAsync, showViewScheduleDrawer } from 'Redux/TheatreReducer/theatreSlice';
import { Button, Flex } from 'antd';
import React from 'react'
import { useDispatch } from 'react-redux';

const ViewScheduleButton = (props) => {
    const dispatch = useDispatch();
    const { row: selectedTheatre } = props;

    console.log({selectedTheatre})

    const onViewScheduleClick = () => {
        dispatch(fetchTheatreScheduleAsync({theatre: selectedTheatre.id}));
        dispatch(showViewScheduleDrawer());
    }

    return (
        <Flex vertical gap={5}>
            <Button
                type="default"
                size="small"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={onViewScheduleClick}
            >
                View Schedule
            </Button>
        </Flex>
    )
}

export default ViewScheduleButton;