import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Checkbox, Select, Upload, Image, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { useParams } from 'react-router';
import { fetchFoodTypeChoices, fetchFoodCategoryList } from 'Api/commonApis';
import { UploadOutlined } from '@ant-design/icons';
import { validatePositiveInteger } from 'Utils/formValidators';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const unitOptions = [
    { value: "UNIT", label: "UNIT" },
    { value: "GRAM", label: "GRAM" },
    { value: "LITER", label: "LITER" },
    { value: "MILLI_LITER", label: "MILLI LITER" },
    { value: "POUND", label: "POUND" },
    { value: "OUNCE", label: "OUNCE" },
    { value: "BASKET", label: "BASKET" },
    { value: "PIECE", label: "PIECE" },
]

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();

    const selectedClient = useSelector(store => store.shows?.selectedClient);

    const [foodTypeOptions, setFoodTypeOptions] = useState(null);
    const [FoodCategoryOptions, setFoodCategoryOptions] = useState(null);
    const [imageBase64, setImageBase64] = useState(null);
    const [newDefaultImage, setNewDefaultImage] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchFoodTypeOptions = async () => {
            try {
                const obj = await fetchFoodTypeChoices();
                if (Array.isArray(obj)) {
                    const options = [];
                    obj.forEach(o => {
                        options.push({
                            value: o[0],
                            label: o[0]
                        })
                    })
                    console.log({ foodTypeOptions: options, obj })
                    setFoodTypeOptions(options);
                } else {
                    throw new Error("Fetch Food Type did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("FoodType Options", err.message, "error");
                } else {
                    openNotification("Food Type Options", "Something went wrong while fetching convenienceFeeType options", "error")
                }
                setFoodTypeOptions("error");
            }
        }
        const fetchFoodCategoryOptions = async () => {
            try {
                let list = null
                if (selectedClient === "all") {
                    list = await fetchFoodCategoryList();
                } else {
                    list = await fetchFoodCategoryList(`?client=${selectedClient}`);
                }
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title }))
                    setFoodCategoryOptions(options);
                } else {
                    throw new Error("Fetch FoodCategory did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("FoodCategory Options", err.message, "error");
                } else {
                    openNotification("FoodCategory Options", "Something went wrong while fetching user options", "error")
                }
                setFoodCategoryOptions("error");
            }
        }
        fetchFoodTypeOptions();
        fetchFoodCategoryOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({
            apiUrl: apiUrl, data: val, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }));
    }

    const edit = (val) => {
        // console.log(val);
        dispatch(editARecord({
            apiUrl, id: currentEditViewFormId, data: val, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }));
    }

    const validateConvenienceFee = (rule, value) => {
        const numericValue = Number(value);
        if (isNaN(numericValue) || numericValue < 0) {
            return Promise.reject('It must be a non-negative numeric value.');
        }

        const stringValue = String(value);
        const decimalIndex = stringValue.indexOf('.');
        const numberOfDigitsBeforeDecimal = decimalIndex === -1 ? stringValue.length : decimalIndex;

        if (numberOfDigitsBeforeDecimal > 10) {
            return Promise.reject('It can have at most 10 digits.');
        }

        const numberOfDecimalPoints = decimalIndex === -1 ? 0 : stringValue.length - decimalIndex - 1;
        if (numberOfDecimalPoints > 2) {
            return Promise.reject('It can have at most 2 decimal points.');
        }

        return Promise.resolve();
    };

    const getFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleImageUpload = (file) => {
        console.log(file.file)
        try {
            // Convert the image to base64
            getBase64(file.file, (base64) => {
                setImageBase64(base64);
            });
            setNewDefaultImage(file.file);
            openNotification(`${file.file.name} file uploaded successfully`, null, "success");
        } catch (err) {
            openNotification(`${file.file.name} file upload failed.`, null, "error");
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            openNotification('You can only upload JPG/PNG file!', null, "error");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            openNotification('Image must smaller than 2MB!', null, "error");
        }
        console.log({ ImageValid: isJpgOrPng && isLt2M })
        return isJpgOrPng && isLt2M;
    };

    const onFinish = (val) => {
        // console.log(val);
        val.image = newDefaultImage;
        // console.log(val);
        val.status = !!val.status;
        console.log({val});
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Foods"
                requiredData={[{data: FoodCategoryOptions, field: "Food Category"}, {data: foodTypeOptions, field: "Food Type"}]}
            >
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter title',
                        },
                    ]}
                >
                    <Input placeholder="Please enter title" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Description',
                        },
                    ]}
                >
                    <Input.TextArea allowClear placeholder="Please enter Description" rows={5} />
                </Form.Item>
                <Form.Item
                    name="category"
                    label="Category"
                    rules={[
                        {
                            required: true,
                            message: "Category is required"
                        }
                    ]}
                >
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(FoodCategoryOptions) ? false : true}
                        options={FoodCategoryOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="price"
                    label="Price"
                    rules={[
                        { required: true, message: 'Price is required.' },
                        { validator: validateConvenienceFee },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="unit"
                    label="Unit"
                    rules={[
                        {
                            required: true,
                            message: "Unit is required"
                        }
                    ]}
                >
                    <Select
                        showSearch
                        // style={{
                        //     width: 200,
                        // }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(unitOptions) ? false : true}
                        options={unitOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="quantity"
                    label="Quantity"
                    rules={[
                        { required: true, message: 'Quantity is required.' },
                        { validator: validatePositiveInteger },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="available_stock"
                    label="Available Stock"
                    rules={[
                        { required: true, message: 'Available Stock is required.' },
                        { validator: validatePositiveInteger },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Food Type"
                    rules={[
                        {
                            required: true,
                            message: "Food Type is required"
                        }
                    ]}
                >
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        loading={Array.isArray(foodTypeOptions) && foodTypeOptions.length > 0 ? false : true}
                        options={foodTypeOptions}
                    />
                </Form.Item>
                <Form.Item
                    label="Default Image"
                    name="image"
                    valuePropName='fileList'
                    getValueFromEvent={getFile}
                    rules={[{ required: false, message: 'Please upload a default image!' }]}
                >
                    <Flex gap={20}>
                        <Flex>
                            <Upload
                                // action={handleImageUpload}
                                beforeUpload={() => false} // Prevent automatic upload
                                listType="picture-card"
                                fileList={[]}
                                showUploadList={false}
                                onChange={handleImageUpload}
                            >
                                {
                                    imageBase64 ? (
                                        <img src={imageBase64} alt="Preview" style={{ width: '100%' }} />
                                    ) : (
                                        <div>
                                            <UploadOutlined />
                                            <div style={{ marginTop: 8 }}>{currentFormMode === FormModesEnum.ADD ? "Upload" : "Change"}</div>
                                        </div>
                                    )
                                }
                            </Upload>
                        </Flex>
                        {
                            currentRecordData.image && !imageBase64 ? <Image src={currentRecordData.image} alt='Preview' style={{ maxWidth: 100 }} /> : <></>
                        }
                    </Flex>

                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    valuePropName="checked"
                    initialValue={false}
                >
                    <Checkbox />
                </Form.Item>
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Submit
                                </Button>
                        }
                    </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                        {
                            editRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled>
                                    <LoadingOutlined />
                                    Updating
                                </Button> :
                                <Button type="primary" htmlType="submit" >
                                    Update
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
        </CheckIfPrequisitesAreAvailable >
        </>
    );
};
export default AddEditForm;