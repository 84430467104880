import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Master/Languages/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/master/rest/languages";
const headerTitle = "Languages";
const editPermission = "languages:edit";
const deletePermission = "languages:delete";
const addPermission = "languages:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'ISO 639 1',
        dataIndex: 'iso_639_1',
        key: 'iso_639_1',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'In Language',
        dataIndex: 'in_language',
        key: 'in_language',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const LanguagesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default LanguagesPage;