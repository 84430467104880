import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { asyncStatuses } from "Redux/enums";
import responseCodeMessages from "Utils/responseCodeMessages.enum";

export const SEGMENTS = {
    SEARCH: "SEARCH",
    MANUAL: "MANUAL"
}

export const sanitizeTmdbData = (data) => {
    let sanitizedData = {};

    try {
        sanitizedData['title'] = data.title;
        sanitizedData['release_date'] = data.release_date;
        sanitizedData['overview'] = data.overview;
        sanitizedData['tmdb_id'] = data.id;
        sanitizedData['rating'] = data.vote_average;
        sanitizedData['genres'] = data.genre_ids;
        sanitizedData['runtime'] = data.runtime;
        sanitizedData['trailer_link'] = data.trailer_link;
        sanitizedData['poster_image'] = `https://media.themoviedb.org/t/p/w300_and_h450_bestv2${data.poster_path}`;
        sanitizedData = {
            ...sanitizedData,
            ...data
        }
    } catch (err) {
        console.error(err);
    }

    return sanitizedData;
}

export const performSearchAsync = createAsyncThunk("program/search", async ({ value, page }, thunkAPI) => {
    try {
        let data = { search: value, page }
        const response = await axios.post(`/program/movie-search/`, data);
        console.log(response);
        return response.data;
    } catch (err) {
        console.error("Some error occurred", err);
        if (err?.code && err.code === "ERR_NETWORK") {
            return thunkAPI.rejectWithValue({ msg: responseCodeMessages.ERR_NETWORK.code });
        } else {
            return thunkAPI.rejectWithValue({ msg: "Something Went Wrong" })
        }
    }
})

export const getMovieSpecificData = async (tmdbId) => {
    const response = await axios.get(`/program/movie-search/?tmdb_id=${tmdbId}`)
    console.log(response);
    return response.data;
}

const initialState = {
    searchTerm: "",
    currentSegment: SEGMENTS.SEARCH,
    searchResults: null,
    searchStatus: null,
    searchStatusErrorMessage: "",
    searchLoadingMoreStatus: null,
    searchLoadingMoreStatusErrorMessage: "",
}

const programSlice = createSlice({
    name: "programCreation",
    initialState: initialState,
    reducers: {
        resetProgramSlice: () => {
            return initialState;
        },
        setCurrentSegmentToSearch: (state) => {
            state.currentSegment = SEGMENTS.SEARCH;
        },
        setCurrentSegmentToManual: (state) => {
            state.currentSegment = SEGMENTS.MANUAL;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(performSearchAsync.pending, (state, action) => {
            console.log(action.meta)
            if (action.meta?.arg?.page === 1) {
                state.searchStatus = asyncStatuses.LOADING;
                state.searchResults = []
            } else {
                state.searchLoadingMoreStatus = asyncStatuses.LOADING;
            }
        });
        builder.addCase(performSearchAsync.fulfilled, (state, action) => {
            let data = action.payload;
            let metaArgs = action.meta.arg
            if (metaArgs?.page === 1) {
                state.searchResults = data;
                state.searchStatus = asyncStatuses.SUCCESS;
                state.searchStatusErrorMessage = null;
            } else {
                let results = [...state.searchResults?.results, ...data?.results];
                state.searchResults = data;
                state.searchResults.results = results;
                state.searchLoadingMoreStatus = asyncStatuses.SUCCESS;
            }
        });
        builder.addCase(performSearchAsync.rejected, (state, action) => {
            let data = action.payload;
            let metaArgs = action.meta.arg;
            if (metaArgs?.page === 1) {
                state.searchStatus = asyncStatuses.FAILED;
                state.searchResults = null;
                state.searchStatusErrorMessage = action.payload?.msg;
            } else {
                state.searchLoadingMoreStatus = asyncStatuses.FAILED;
                state.searchLoadingMoreStatusErrorMessage = action.payload?.msg;
            }

        });
    }
});

export const { resetProgramSlice, setCurrentSegmentToSearch, setCurrentSegmentToManual, setSearchTerm } = programSlice.actions;
export default programSlice.reducer;