import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { asyncStatuses } from "Redux/enums";
import axios from "axios";

const initialState = {
    user: {},
    token: null,
    isAuthenticated: null,
    status: null,
    errorMsg: null,
}

export const loginUserAsync = createAsyncThunk("user/login", async ({ username, password }, thunkAPI) => {
    try {
        const response = await axios.post("/user/login/", { username, password });
        let returnedDataKeys = Object.keys(response.data);
        if (returnedDataKeys.includes('token') && returnedDataKeys.includes('user')){
            return response.data;
        }
        return thunkAPI.rejectWithValue({msg: "Token not found"});
    } catch (err) {
        console.error(err);
        if (err?.response?.data?.msg) {
            return thunkAPI.rejectWithValue(err.response.data);
        } else if (err.code === "ERR_NETWORK") {
            return thunkAPI.rejectWithValue({msg: "Server unreachable."})
        }
        return thunkAPI.rejectWithValue({});
    }
});

export const logoutUserAsync = createAsyncThunk("user/logout", async () => {
    const response = await axios.post("/user/logout/");
    
});

const authSlices = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        resetAuthSlice: (state, action) => {
            return initialState
        },
    },
    extraReducers: builder => {
        builder.addCase(loginUserAsync.pending, (state) => {
            state.status = asyncStatuses.LOADING
        });
        builder.addCase(loginUserAsync.fulfilled, (state, action) => {
            state.status = asyncStatuses.SUCCESS;
            const data = action.payload;
            console.log({ loginData: data });
            state.isAuthenticated = true;
            state.token = data.token;
            state.user = data.user;
        });
        builder.addCase(loginUserAsync.rejected, (state, action) => {
            state.status = asyncStatuses.FAILED;
            state.isAuthenticated = null;
            state.token = null;
            state.user = {};
            state.errorMsg = action.payload?.msg;
        });
        builder.addCase(logoutUserAsync.pending, (state) => {
            state.status = asyncStatuses.LOADING;
        });
        builder.addCase(logoutUserAsync.fulfilled, (state, action) => {
            state.status = asyncStatuses.FAILED;
            state.isAuthenticated = null;
            state.token = null;
            state.user = {};
        });
        builder.addCase(logoutUserAsync.rejected, (state, action) => {
            state.status = asyncStatuses.FAILED;
            // state.isAuthenticated = null;
            // state.token = null;
            // state.user = {};
        });
    }
});

export const { resetAuthSlice } = authSlices.actions;
export default authSlices.reducer;