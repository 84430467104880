import React from 'react'

const ReportTitle = ({ title }) => {
    return (
        <>
            {title && <h3 style={{ marginBottom: 5, marginTop: 5 }} >{title}</h3>}
        </>
    )
}

export default ReportTitle