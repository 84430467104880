import { screen } from 'Assets';
import ReportTitle from 'Components/Dashboard/ReportTitle';
import HallFilterForReports from 'Components/Dashboard/Reports/Filters/HallFilterForReports';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { Button, Flex, Image, Spin } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadOutlined } from "@ant-design/icons";
import ExportPdf, { ORIENTATION_OPTIONS } from './ExportPdf';
import ExportXlsx from './ExportXlsx';
import ExportPdfServer from './ExportPdfServer';

function rgbaToRgbIgnoringAlpha(rgba) {
    // Assume input is in the form 'rgba(r, g, b, a)'
    const [r, g, b] = rgba.match(/\d+/g);
    return `rgb(${r}, ${g}, ${b})`;
  }
  

const xLabels = new Array(10).fill(0).map((_, i) => `${i}`)
const yLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri']
const defdata = new Array(yLabels.length)
    .fill(0)
    .map(() =>
        new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 50 + 50))
    );

const initialDates = dayjs();
const API_URL = "/reports/hall-seatmap-report/";

const HallSeatHeatmap = () => {

    const [data, setData] = useState([]);
    const [fullSeatsData, setFullSeatsData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");
    const [hallFilterVal, setHallFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const fullSeatsDataForExport = useRef([]);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['hall'] = hallFilterVal;
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.seats) {
                    let displayData = response.data.seats.map(s => s.map(si => si.count));
                    setData(displayData);
                    setFullSeatsData(response.data.seats);
                    fullSeatsDataForExport.current = response.data.seats;
                }
                else {
                    setData([]);
                    setFullSeatsData([]);
                    fullSeatsDataForExport.current = [];
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Failed to fetch Hall Seat HeatMap Report data.", ` ${e.response?.data?.message || e.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        if (startDate, endDate, hallFilterVal) {
            fetchData();
        }
    }, [startDate, endDate, hallFilterVal]);

    const getExportPdfPayload = () => {

        return {
            start_date: startDate.format(SELECTED_DATE_FORMAT),
            end_date: endDate.format(SELECTED_DATE_FORMAT),
            hall: hallFilterVal,
            data: data,
            fullSeatsData: fullSeatsDataForExport.current,
        }
    }

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportPdfServer
                    apiUrl={`${API_URL}export/`}
                    getPayload={() => getExportPdfPayload()}
                    reportName={'hall_seat_heatmap_report'}
                    reportTitleForNotification={'Hall Seat Heatmap Report'}
                />
                {/* <ExportPdf report_name={`Hall Seat HeatMap Report`} orientation={ORIENTATION_OPTIONS.LANDSCAPE} /> */}
            </Flex>
            <Flex vertical style={{ padding: 10, width: "100%" }} id='report-content'>
                <Flex vertical gap={5}>
                    <ReportTitle title={`Hall Seat Heatmap`} />
                    <Flex justify='space-between' flex={1} gap={20}>
                        <Flex>
                            <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                        </Flex>
                        <Flex justify='flex-end' gap={5}>
                            <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                            <HallFilterForReports theatreFilterVal={theatreFilterVal} hallFilterVal={hallFilterVal} setHallFilterVal={setHallFilterVal} />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex flex={1} justify='center' align='center' style={{ paddingTop: 10, paddingBottom: 10 }}>
                    {
                        loading ? <Spin /> :
                            <Flex vertical gap={10}>
                                <HeatMapGrid
                                    data={data}
                                    cellStyle={(_x, _y, ratio) => {
                                        // console.log({hrllo: fullSeatsData[_x][_y]});
                                        const is_seat = fullSeatsData[_x][_y].is_seat;

                                        let style = {
                                            width: 40,
                                            height: 40,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // border: "1px solid black",
                                            margin: 3,
                                        }
                                        let exportStyle = {}

                                        let opacity = (ratio === 0 || isNaN(ratio)) ? 0.05 : ratio

                                        if (!is_seat) {
                                            style['background'] = 'transparent';
                                            exportStyle['background'] = 'transparent';
                                        } else {
                                            style['background'] = `rgba(255, 104, 87, ${opacity})`;
                                            exportStyle['background'] = style['background'];
                                            style['color'] = opacity === 0.05 ? `rgba(0, 0, 0, ${0 / 2 + 0.4})` : `rgba(0, 0, 0, ${ratio / 2 + 0.4})`;
                                            exportStyle['color'] = style['color'];
                                        }

                                        fullSeatsDataForExport.current[_x][_y]['styles'] = exportStyle;

                                        return style;
                                    }}
                                    cellRender={(x, y, value) => {
                                        const is_seat = fullSeatsData[x][y].is_seat;
                                        const label = fullSeatsData[x][y]?.label || "";

                                        if (!is_seat) {
                                            return <></>;
                                        }
                                        return (
                                            <div className='hall-seatmap-seat' title={`${label}`}>{value}</div>
                                        )
                                    }}
                                />
                                {
                                    !loading && Array.isArray(data) && data.length > 0 ?
                                        <Flex justify="center">
                                            <Image preview={false} src={screen} />
                                        </Flex> : <></>
                                }
                            </Flex>
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default HallSeatHeatmap