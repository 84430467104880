import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { Button, Dropdown, Flex, Space, Spin, Switch } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from '../ExportXlsx';
import HallAccordion from './HallData';
import ClientProgramFilterForReports from 'Components/Dashboard/Reports/Filters/ClientProgramFilterForReports';
import { FaExclamationTriangle } from "react-icons/fa";
import { SettingOutlined } from '@ant-design/icons';
// import ExportPdfServer from '../ExportPdfServer';


const API_URL = "/reports/movie-per-hall-report/";

const initialDates = dayjs();

const MoviePerHallReport = () => {

    const [data, setData] = useState([]);
    const [theatreFilterVal, setTheatreFilterVal] = useState("");
    const [clientProgramFilterVal, setClientProgramFilterVal] = useState("");
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);
    const [thereIsData, setThereIsData] = useState(false);
    const [dataTotals, setDataTotals] = useState({});
    
    const [open, setOpen] = useState(false);
    const [showVouchersUsed, setShowVouchersUsed] = useState(true);

    const openNotification = useNotificationContext();

    const items = [
        {
            label: <Flex gap={5} align='center' justify='end'>
                <span>Show Vouchers Used?</span>
                <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={showVouchersUsed}
                    onChange={(v) => {
                        console.log({ v })
                        setShowVouchersUsed(v)
                    }} />
            </Flex>,
            key: '1',
        },
    ];

    const menuProps = {
        items,
        // onClick: handleMenuClick,
    };

    const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!theatreFilterVal) {
                return;
            }
            try {
                setLoading(true);
                const payload = {}
                payload['theatre'] = theatreFilterVal;
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                payload['client_program'] = clientProgramFilterVal;
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) {
                    // setData(response.data?.data);
                    try {
                        const data = response.data?.data || {};
                        const dataTotals = data?.['data_totals'] || {};
                        setDataTotals(dataTotals);

                        const hallsWithData = {}
                        let count = 0;
                        for (let hall in data) {
                            const dataCount = Object.keys(data[hall]['data'] || {}).length
                            console.log(hall, dataCount);
                            if (dataCount > 0) {
                                hallsWithData[hall] = data[hall];
                            }
                            count += dataCount;
                        }
                        setData(hallsWithData);
                        if (count === 0) {
                            setThereIsData(false);
                        } else {
                            setThereIsData(true);
                        }
                    } catch (e) {
                        console.log(e);
                        setThereIsData(false);
                        setData({});
                        setDataTotals({});
                        openNotification("Error occurred while processing the data", "error");
                    }

                } else {
                    setData({});
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Failed to fetch Movie Per Hall Report data.", ` ${e.response?.data?.message || e.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        if (startDate && endDate && theatreFilterVal && clientProgramFilterVal)
            fetchData();
    }, [startDate, endDate, theatreFilterVal, clientProgramFilterVal, openNotification]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        theatre: theatreFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                        client_program: clientProgramFilterVal,
                        show_vouchers_used: showVouchersUsed,
                    }}
                    reportName={'movie_per_hall_report'}
                    reportTitleForNotification={'Movie Per Hall'}
                />
                <Dropdown
                    menu={menuProps}
                    open={open}
                    onOpenChange={handleOpenChange}
                >
                    <Button>
                        <Space>
                            Settings
                            <SettingOutlined />
                        </Space>
                    </Button>
                </Dropdown>
                {/* <ExportPdfServer
                    apiUrl={`${API_URL}export/pdf/`}
                    getPayload={() => ({
                        theatre: theatreFilterVal,
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    })}
                    reportName={'movie_per_hall_report'}
                    reportTitleForNotification={'Movie Per Hall Report'}
                /> */}
                {/* <ExportPdf report_name='Movie Per Hall Report'/> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10} id='report-content'>
                <Flex justify='space-between' style={{ width: "100%" }}>
                    <ReportTitle title={`Movie Per Hall Report`} />
                    <Flex gap={15}>
                        <ClientProgramFilterForReports clientProgramFilterVal={clientProgramFilterVal} setClientProgramFilterVal={setClientProgramFilterVal} start_date={startDate.format(SELECTED_DATE_FORMAT)} end_date={endDate.format(SELECTED_DATE_FORMAT)} />
                        <TheatreFilterForReports theatreFilterVal={theatreFilterVal} setTheatreFilterVal={setTheatreFilterVal} />
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex vertical gap={10} style={{ width: "100%", maxHeight: "75vh", overflow: "auto" }}>
                    {
                        loading ? <>
                            <Flex vertical align='center' justify='center' gap={10} style={{ width: "100%", padding: 10 }}>
                                <Spin size='small' />
                                <small>We're loading your report, please wait.</small>
                            </Flex>
                        </> : <>
                            {
                                (Object.keys(data || {}).filter(hall => hall !== "data_totals").length > 0 && thereIsData) ?
                                    Object.keys(data || {}).filter(hall => hall !== "data_totals").map?.((hall, index) => {
                                        const total_halls = Object.keys(data).length;
                                        const is_last_hall = index === total_halls - 1;
                                        console.log({ total_halls, index })
                                        return <HallAccordion
                                            key={hall}
                                            hall={data[hall]}
                                            hall_id={hall}
                                            is_last_hall={is_last_hall}
                                            data_totals={dataTotals}
                                            showVouchersUsed={showVouchersUsed}
                                        />
                                    })
                                    : <Flex vertical align='center' justify='center' style={{ height: '30vh', width: "100%" }}>
                                        <FaExclamationTriangle color='gray' size={35} />
                                        <p style={{ color: "gray" }}>No Data</p>
                                    </Flex>
                            }
                        </>
                    }
                </Flex>
            </Flex>
        </>
    )
}

export default MoviePerHallReport;