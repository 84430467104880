import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Checkbox, Select, Flex, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchUserList } from 'Api/commonApis';
import { fetchSeatTypeList } from "Api/commonApis";
import { useParams } from 'react-router';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();
    const [selectedTheatre, setSelectedTheatre] = useState(params?.id);
    const theatreList = useSelector(store => store.theatre?.theatreList);
    const [skipRowLabelOptions] = useState(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"])

    console.log({ params });

    const [userOptions, setUserOptions] = useState(null);
    const [seatTypeOptions, setSeatTypeOptions] = useState([]);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchUserOptions = async () => {
            try {
                const list = await fetchUserList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.username }))
                    setUserOptions(options);
                } else {
                    throw new Error("Fetch Users did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("User Options", err.message, "error");
                } else {
                    openNotification("User Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        const fetchSeatTypeOptions = async () => {
            try {
                const list = await fetchSeatTypeList();
                if (Array.isArray(list)) {
                    // const options = list.map(l => ({ value: l.id, label: l.username }))
                    setSeatTypeOptions(list);
                } else {
                    throw new Error("Fetch SeatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("SeatType Options", err.message, "error");
                } else {
                    openNotification("SeatType Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        fetchSeatTypeOptions();
        fetchUserOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        console.log({ val });
        dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
    }

    const validatePositiveInteger = (rule, value) => {
        const numericValue = Number(value);
        if (isNaN(numericValue) || numericValue < 0) {
            return Promise.reject('It must be a non-negative numeric value.');
        }

        const stringValue = String(value);
        const decimalIndex = stringValue.indexOf('.');
        console.log({ decimalIndex })

        if (decimalIndex > -1) {
            return Promise.reject('It cannot contain decimal points.');
        }

        return Promise.resolve();
    };

    const onFinish = (val) => {
        val.status = !!val.status;
        val.continuous_series = !!val.continuous_series;
        val.rtl = !!val.rtl;
        console.log({val});
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Theatre Halls"
                requiredData={[{data: userOptions, field: "user"}, {data: seatTypeOptions, field: "Seat Type"}]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(userOptions) && userOptions.length > 0)}
                >
                    <Form.Item
                        name="hall_no"
                        label="Hall no."
                        rules={[
                            { required: true, message: 'Hall no. is required.' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Row>
                        <Col>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Form.Item
                        name="row_length"
                        label="Row Length"
                        rules={[
                            { required: true, message: 'Row Length is required.' },
                            { validator: validatePositiveInteger },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="column_length"
                        label="Column Length"
                        rules={[
                            { required: true, message: 'Column Length is required.' },
                            { validator: validatePositiveInteger },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="skip_row_label"
                        label="Skip Row Label"
                        rules={[
                            // {
                            //     required: true,
                            //     message: "Skip Row Label is required"
                            // }
                        ]}
                    >
                        <Select
                            showSearch
                            mode='multiple'
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            loading={Array.isArray(skipRowLabelOptions) && skipRowLabelOptions.length > 0 ? false : true}
                        >
                            {
                                Array.isArray(skipRowLabelOptions) && skipRowLabelOptions.map((st, ind) => {

                                    return <Select.Option key={ind} value={st}>
                                        <Flex
                                            gap={3}
                                            align="center"
                                        >
                                            <span>{st}</span>
                                        </Flex>
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="status"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox>Status</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="continuous_series"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox>Continuous Series</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="rtl"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox>RTL Layout</Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="client_seat_type"
                        label="Default Seat Type"
                        rules={[
                            {
                                required: true,
                                message: "Seat Type is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            loading={Array.isArray(seatTypeOptions) && seatTypeOptions.length > 0 ? false : true}
                        >
                            {
                                Array.isArray(seatTypeOptions) && seatTypeOptions.map((st, ind) => {

                                    return <Select.Option key={st.id} value={st?.id}>
                                        <Flex gap={3} align="center"><div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: st.color }} ></div>
                                            <span>{st.title}</span></Flex>
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {
                        params.id ?
                            <Form.Item
                                hidden
                                name="theatre"
                                initialValue={params?.id}
                            >
                                <Input />
                            </Form.Item> :
                            <Form.Item
                                label="Theatre"
                                name="theatre"
                                initialValue={params?.id}
                                style={{ width: "150px" }}
                                rules={[{
                                    required: true
                                }]}
                            >
                                <Select

                                    placeholder="Choose a Theatre to Filter"
                                    loading={Array.isArray(theatreList) ? false : true}
                                    onSelect={(v) => {
                                        console.log({ v });

                                    }}
                                >
                                    {
                                        theatreList?.map((afv, ind) => {
                                            return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                    }
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form >
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;