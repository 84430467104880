
import { Flex, Image } from 'antd'
import { landscapePlaceholder } from 'Assets';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentRecordData, setFormModeAsAdd } from 'Redux/MasterReducer/crudSlices';
import { getMovieSpecificData, sanitizeTmdbData, setCurrentSegmentToManual } from 'Redux/ProgramReducer/programSlice';

const SearchResultCard = ({ currentRecordData }) => {

    const dispatch = useDispatch();
    const searchResultsMovies = useSelector(store => store.program.searchResults.movies);

    const fetchMovieSpecificData = async () => {
        const response = await getMovieSpecificData(currentRecordData.id)
        dispatch(setCurrentRecordData(sanitizeTmdbData(response)));
    }

    const setData = () => {
        dispatch(setCurrentSegmentToManual());
        fetchMovieSpecificData()
        dispatch(setFormModeAsAdd());
    }

    return (
        <Flex
            vertical
            flex={1}
            justify='space-between'
            align='space-between'
            style={{
                borderRadius: 10,
                border: "1px solid #aba7a7",
                width: "100%",
                height: "100%",
                cursor: "pointer"
            }}
            onClick={setData}
        >
            <Flex flex={8}>
                <img
                    src={`https://media.themoviedb.org/t/p/w300_and_h450_bestv2${currentRecordData?.poster_path}`}
                    alt='Preview'
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px 10px 0px 0px",
                        minHeight: "284px"
                    }}
                    onError={(e) => { e.target.src = landscapePlaceholder }}
                />
            </Flex>
            <Flex vertical flex={2} style={{ padding: 5 }}>
                <span>{currentRecordData?.title}</span>
                <span>{currentRecordData?.release_date}</span>
            </Flex>
        </Flex>
    )
}

export default React.memo(SearchResultCard);