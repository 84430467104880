import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Drawer, Flex, Spin, Table, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderTitle } from 'Redux/UtilitiesReducer/utilitySlice';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useLocation, useSearchParams } from 'react-router-dom';
import { fetchAllRecords, incrementPageNumber, resetAddRecordStatus, resetDeleteRecordStatus, resetEditRecordStatus, resetFetchRequestStatus, resetFormModeToNone, resetMasterSlice, setFormModeAsAdd, setFormModeAsEdit, setFormModeAsView } from 'Redux/MasterReducer/crudSlices';
import CustomPagination from './CustomPagination';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import searchParamsEnum from 'Utils/searchParamsEnum';
import Search from './Search';
import { PlusCircleOutlined } from "@ant-design/icons";
import Filters from './Filters';
import getHeaderTitle from 'Utils/getHeaderTitle';
import AccessControl from 'Router/Access/AccessControl';
import DrawerComponent from './DrawerComponent';
import responseCodeMessages from 'Utils/responseCodeMessages.enum';
import { useNotificationContext } from 'Config/NotificationsContext';

const CustomTable = ({
    columns = [],
    dataFetchingFunction,
    apiUrl,
    extraApiParams,
    filters,
    headerTitle,
    useSelectorKeys,
    MyFormComponent,
    ViewComponent,
    OtherFormComponent,
    formCompononentStyle = {},
    nonViewFormCompononentStyle = {},
    addPermissionNames = [],
    hasSearch = true,
    moreActionsJsx = <></>
}) => {
    const dispatch = useDispatch();
    const openNotification = useNotificationContext();

    const [searchParams] = useSearchParams();
    const [extraApiParamsState, setExtraApiParamsState] = useState(extraApiParams);
    const [_columns, setColumns] = useState(columns);

    useEffect(() => {
        if (columns.length > 0) {
            setColumns(col => [{
                title: ' ',
                dataIndex: 'sr_no',
                key: 'sr_no',
                width: '60px'
            }, ...columns]);
        }
    }, [columns]);

    const [open, setOpen] = useState(false);

    const fetchAllTimerForDebouncing = useRef(null);

    // const pageNumber = useSelector(store => store.master?.currentPageNumber);
    const location = useLocation();
    console.log({ newPageNumber: location })
    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);

    const user = useSelector(store => store.auth.user);

    const changeFormMode = useCallback((newMode, id) => {
        if (newMode !== FormModesEnum.ADD && newMode !== FormModesEnum.NONE) {
            if (!id) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            }
            if (Object.keys(FormModesEnum).includes(newMode)) {
                setOpen(true);
                if (newMode === FormModesEnum.EDIT) {
                    dispatch(setFormModeAsEdit({ id: id }));
                } else if (newMode === FormModesEnum.VIEW) {
                    dispatch(setFormModeAsView({ id: id }));
                }
            } else {
                openNotification("Something went wrong", "Please contact developers. Provided mode does not exist.", "error");
                return;
            }
        } else if (newMode === FormModesEnum.ADD) {
            setOpen(true);
            dispatch(setFormModeAsAdd());
        }
    }, [dispatch, openNotification])

    useEffect(() => {
        // console.log({ currentEditViewFormId, currentFormMode });
        if (currentFormMode !== FormModesEnum.NONE) {
            changeFormMode(currentFormMode, currentEditViewFormId);
        }
    }, [currentFormMode, currentEditViewFormId, changeFormMode]);

    useEffect(() => {
        dispatch(resetMasterSlice());
    }, [dispatch]);

    const resetFormMode = useCallback(() => {
        setOpen(false);
        dispatch(resetFormModeToNone());
    }, [dispatch]);


    useEffect(() => {
        dispatch(changeHeaderTitle(headerTitle));
        return () => dispatch(changeHeaderTitle("Cineboxx"))
    }, [dispatch, headerTitle]);

    const data = useSelector(store => store.master.data);
    // console.log(data);
    const dataTotalCount = useSelector(store => store.master.count);

    const fetchRequestStatus = useSelector(store => store.master.fetchRequestStatus);
    const fetchRequestErrorMsg = useSelector(store => store.master.fetchRequestErrorMsg);
    const deleteRecordStatus = useSelector(store => store.master.deleteRecordStatus);
    const deleteRecordErrorMsg = useSelector(store => store.master.deleteRecordErrorMsg);
    const addRecordStatus = useSelector(store => store.master.addRecordStatus);
    const addRecordErrorMsg = useSelector(store => store.master.addRecordErrorMsg);
    const editRecordStatus = useSelector(store => store.master.editRecordStatus);
    const editRecordErrorMsg = useSelector(store => store.master.editRecordErrorMsg);

    const getPageNumber = useCallback(() => {
        let currentPageNumber = 1;
        if (!isNaN(searchParams.get('page'))) {
            currentPageNumber = Number(searchParams.get('page'));
        }
        // console.log({ currentPageNumber });
        return currentPageNumber;
    }, [searchParams])

    useEffect(() => {
        // console.log({ searchParams, extraApiParams, apiUrl })
        if (apiUrl) {
            clearTimeout(fetchAllTimerForDebouncing.current);

            let currentPageNumber = getPageNumber();
            fetchAllTimerForDebouncing.current = setTimeout(() => {
                dispatch(fetchAllRecords({ 
                    apiUrl, 
                    extraApiParams: extraApiParamsState, 
                    searchParams, 
                    pageNumber: currentPageNumber 
                }));
            }, 300);
        }
    }, [dispatch, searchParams, apiUrl, extraApiParamsState, getPageNumber]);

    // useEffect(() => {
    //     const handleScroll = (e) => {
    //         const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //         // console.log(bottom)
    //         if (bottom) {
    //             dispatch(incrementPageNumber());
    //         }
    //     };

    //     const antTableBody = document.querySelector('.ant-table-body');
    //     if (antTableBody) {
    //         antTableBody.addEventListener('scroll', handleScroll);
    //     }

    //     return () => antTableBody.removeEventListener('scroll', handleScroll);
    // }, []);

    useEffect(() => {
        if (fetchRequestStatus && fetchRequestStatus !== asyncStatuses.LOADING) {
            setTimeout(() => dispatch(resetFetchRequestStatus()), 300);
            if (fetchRequestStatus === asyncStatuses.FAILED) {
                openNotification("Error occurred while fetching records.", fetchRequestErrorMsg, "error")
            }
        }
        if (deleteRecordStatus && deleteRecordStatus === asyncStatuses.SUCCESS) {
            setTimeout(() => {
                dispatch(resetDeleteRecordStatus());
                let currentPageNumber = getPageNumber();
                dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: currentPageNumber }));
                openNotification(`Successfully deleted the ${getHeaderTitle(headerTitle)}`, null, "success");
            }, 300);
        }
        if (deleteRecordStatus && deleteRecordStatus === asyncStatuses.FAILED) {
            setTimeout(() => {
                let errorMsg = deleteRecordErrorMsg;
                dispatch(resetDeleteRecordStatus());
                openNotification(`Error while deleting the ${getHeaderTitle(headerTitle)}`, errorMsg, "error");
            }, 300);
        }
        if (addRecordStatus && addRecordStatus === asyncStatuses.SUCCESS) {
            setTimeout(() => {
                dispatch(resetAddRecordStatus());
                resetFormMode();
                let currentPageNumber = getPageNumber();
                dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: currentPageNumber }));
                openNotification(`Successfully added new ${getHeaderTitle(headerTitle)}`, null, "success");
                setTimeout(() => dispatch(resetAddRecordStatus()), 300);
            }, 300);
        }
        if (addRecordStatus && addRecordStatus === asyncStatuses.FAILED) {
            setTimeout(() => {
                let errorMsg = addRecordErrorMsg;
                if (typeof errorMsg === "object") {
                    errorMsg = addRecordErrorMsg?.message || null
                }
                openNotification(`Error while adding the ${getHeaderTitle(headerTitle)}`, errorMsg, "error");
                dispatch(resetAddRecordStatus());
            }, 300);
        }
        if (editRecordStatus && editRecordStatus === asyncStatuses.SUCCESS) {
            dispatch(resetEditRecordStatus());
            resetFormMode();
            let currentPageNumber = getPageNumber();
            dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: currentPageNumber }));
            openNotification(`Successfully updated ${getHeaderTitle(headerTitle)}`, null, "success");
            setTimeout(() => dispatch(resetEditRecordStatus()), 300);
        }
        if (editRecordStatus && editRecordStatus === asyncStatuses.FAILED) {
            setTimeout(() => {
                let errorMsg = editRecordErrorMsg;
                dispatch(resetEditRecordStatus());
                openNotification(`Error while editing the ${getHeaderTitle(headerTitle)}`, errorMsg, "error");
            }, 300);
        }
    }, [fetchRequestStatus, deleteRecordStatus, dispatch, addRecordStatus, editRecordStatus, apiUrl, searchParams, headerTitle, openNotification, addRecordErrorMsg, deleteRecordErrorMsg, extraApiParamsState, fetchRequestErrorMsg, resetFormMode, editRecordErrorMsg, getPageNumber]);

    // const start_record = ((getPageNumber()-1) * 50) + 1;
    const start_record = dataTotalCount - (getPageNumber() - 1) * 50;
    // let end_record = start_record + 50 - 1;
    let end_record = Math.max(start_record - 50 + 1, 1)

    // if (end_record > dataTotalCount) {
    //     end_record = dataTotalCount
    // }

    return (<>
        <Flex vertical align='start' justify='space-between' style={{ position: "relative" }}>
            <Flex justify='space-between' align='center' style={{ width: "100%", padding: 10 }}>
                <Title style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }} level={5}>
                    {/* Showing {(Array.isArray(data) && data.length) || 0} of {dataTotalCount || 0} */}
                    {
                        dataTotalCount > 0 ? <>Viewing {start_record} - {end_record} of {dataTotalCount || 0} records</> : <>Viewing 0 - 0 of 0 records</>
                    }
                </Title>
                <Flex gap={5}>
                    <Filters
                        filters={filters}
                        apiUrl={apiUrl}
                        extraApiParamsState={extraApiParamsState}
                        setExtraApiParamsState={setExtraApiParamsState}
                    />
                    {moreActionsJsx}
                    {hasSearch && <Search />}
                    <AccessControl
                        userPermissions={user.user_permissions}
                        allowedPermissions={addPermissionNames}
                        renderNoAccess={() => (
                            <></>
                        )}
                    >
                        <Button type="primary" onClick={() => changeFormMode(FormModesEnum.ADD, null)} icon={<PlusCircleOutlined />}>
                            New
                        </Button>
                    </AccessControl>
                </Flex>
            </Flex>
            {/* <div style={{ height: "68vh", overflow: "scroll", marginTop: 20, width: "100%" }} onScroll={handleScroll}> */}
            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            // console.log({ record, rowIndex, event });
                            if (event.target.localName === "td") {
                                dispatch(setFormModeAsView({ id: record.id || record.uuid }));
                            }
                        }, // click row
                        // onDoubleClick: (event) => { }, // double click row
                        // onContextMenu: (event) => { }, // right button click row
                        // onMouseEnter: (event) => { }, // mouse enter row
                        // onMouseLeave: (event) => { }, // mouse leave row
                    };
                }}
                columns={_columns}
                dataSource={data}
                size='small'
                scrollToFirstRowOnChange
                stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                loading={fetchRequestStatus === asyncStatuses.LOADING && data.length === 0}
                scroll={{
                    y: "65vh",
                    x: "100%"
                }}
                pagination={false}
            />
            {/* </div> */}
            {/* <Flex vertical justify='center' align='center' flex={1} style={{ width: "90%", height: '6vh', position: 'absolute', bottom: 20 }}>
            {fetchRequestStatus === asyncStatuses.LOADING && data.length > 0 && <>
                <Flex gap={10} style={{ width: 150, height: 50, backgroundColor: "white", borderRadius: 10, boxShadow: "1px 2px 15px #d1d1d1" }} justify='center' align='center'>
                    <Spin size="small" />
                    <Text style={{ color: "black" }}>Loading...</Text>
                </Flex>
            </>}
        </Flex> */}
        </Flex>
        <Flex vertical justify='center' align='center' flex={1} style={{
            width: "90%",
            height: '6vh',
            position: "absolute",
            bottom: 0
        }}>
            <CustomPagination />
        </Flex>
        <DrawerComponent
            formCompononentStyle={formCompononentStyle}
            nonViewFormCompononentStyle={nonViewFormCompononentStyle}
            resetFormMode={resetFormMode}
            open={open}
            setDrawerComponentOpen={setOpen}
            headerTitle={headerTitle}
            MyFormComponent={MyFormComponent}
            apiUrl={apiUrl}
            ViewComponent={ViewComponent}
            changeFormMode={changeFormMode}
            extraApiParams={extraApiParams}
            searchParams={searchParams}
        />
    </>)
}

export default CustomTable;