import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { IoSunnyOutline } from "react-icons/io5";
import { IoMdMoon } from "react-icons/io";
import { Layout, Button, theme, Flex, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { switchTheme } from 'Redux/UtilitiesReducer/utilitySlice';
import { DARK_COLOR, LIGHT_COLOR, ThemeEnum } from 'Redux/enums';
import { IoMdLogOut } from 'react-icons/io';
import { logoutUserAsync } from 'Redux/AuthReducer/authSlices';
import Title from 'antd/es/typography/Title';
import dispatchLogoutAction from 'Redux/logoutAction';
const { Header } = Layout;


const TopNavigation = ({ collapsed, setCollapsed }) => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const currentTheme = useSelector(store => store.utilities.theme);
    const currentHeaderTitle = useSelector(store => store.utilities.currentHeaderTitle);
    const currentUserFirstName = useSelector(store => store.auth?.user?.first_name);
    const currentUserLastName = useSelector(store => store.auth?.user?.last_name);

    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatchLogoutAction()
    }

    return <>
        <Header
            style={{
                padding: 0,
                backgroundColor: currentTheme === ThemeEnum.DARK ? DARK_COLOR : LIGHT_COLOR,
            }}
        >
            <Flex justify='space-between' align='center'>
                <Space direction='horizontal' style={{ alignItems: "center", justifyContent: "center" }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                            color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                        }}
                    />
                    <Title
                        style={{
                            margin: 0,
                            // color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                            color: '#f5382c',
                        }} level={4}
                    >{currentHeaderTitle}</Title>
                </Space>
                <Space direction='horizontal' style={{ alignItems: "center", justifyContent: "center" }} className='top-nav-buttons'>
                    <Button
                        type="text"
                        icon={currentTheme === ThemeEnum.LIGHT ? <IoMdMoon size={24} /> : <IoSunnyOutline size={24} />}
                        onClick={() => { dispatch(switchTheme()); }}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                            color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                        }}
                    />
                    <Tooltip placement="bottom" title={"Logout"}>
                        <Button
                            type="text"
                            icon={<Space style={{ padding: "0px 10px" }} direction='horizontal'><IoMdLogOut size={24} />{`${currentUserFirstName} ${currentUserLastName}`}</Space>}
                            onClick={logoutUser}
                            style={{
                                fontSize: '16px',
                                width: "fit-content",
                                height: 64,
                                color: currentTheme === ThemeEnum.DARK ? LIGHT_COLOR : DARK_COLOR,
                            }}
                        />
                    </Tooltip>

                </Space>
            </Flex>
        </Header>
    </>
}

export default TopNavigation;