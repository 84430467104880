import getContrastingColor from "Utils/getContrastingColor";
import { Flex, Checkbox } from "antd";
import { useEffect } from "react";

const commonStyles = {
    margin: 2,
    width: 45,
    height: 45,
    padding: 2,
    position: "relative",
}

const DEFAULT_OPACITY = 0.5;

const SeatDrag = ({
    seat, changeSelectedSeats, setOpenDrawer, selectedSeats,
    isSeatDragged, onMouseDown, onMouseOver, currentTheatreChannel
}) => {
    // if(isSeatDragged){
    //     // console.log(seat.display_label);
    // }

    // const isSeatChecked = selectedSeats?.filter?.(r => r.id === seat.id).length > 0;
    const seatClassName = `seat no-text-select ${isSeatDragged ? 'selected' : ''}`

    // useEffect(() => {
    //     const modifySelectedSeats = () => {
    //         let ss = JSON.parse(JSON.stringify(selectedSeats));
    //         if (Array.isArray(ss)) {
    //             // 
    //         } else {
    //             ss = [];
    //         }
    //         let ids = ss.map(r => r.id);
    //         let isThiSeatAlreadyInIds = ids.includes(seat.id);
    //         if (isSeatDragged && !isThiSeatAlreadyInIds) {
    //             console.log("Hello")
    //             ss.push(seat);
    //         } else {
    //             ss = ss.filter(r => r.id !== seat.id);
    //         }
    //         // changeSelectedSeats(ss);
    //     }
    //     modifySelectedSeats();
    // }, [isSeatDragged]);

    if (seat.is_seat) {
        return (
            <Flex
                key={seat.id}
                align="end"
                justify="end"
                className={seatClassName}
                onMouseOver={onMouseOver}
                onMouseDown={onMouseDown}
                style={{
                    ...commonStyles,
                    border: "1px solid gray",
                    borderRadius: 2,
                    color: seat?.seatType_data?.color ? getContrastingColor(seat?.seatType_data?.color) : null,
                    backgroundColor: seat?.seatType_data?.color ? seat?.seatType_data?.color : null,
                    opacity: isSeatDragged ? 1 : DEFAULT_OPACITY,
                }}
            >
                <small
                    style={{
                        position: "absolute",
                        top: 3, left: 1,
                        lineHeight: 0,
                        padding: 5,
                        borderRadius: 2,
                        fontSize: 9
                    }}
                >
                    {currentTheatreChannel?.label === "POS" ?
                        seat?.seatType_data?.default_price
                        : currentTheatreChannel?.label === "Kiosk" ?
                            seat?.seatType_data?.kiosk_price
                            : currentTheatreChannel?.label === "Website" ?
                                seat?.seatType_data?.web_price
                                : seat?.seatType_data?.app_price
                    }
                </small>
                <small>{seat.display_label}</small>
            </Flex>
        );
    } else {
        return (
            <Flex
                key={seat.id}
                align="end"
                justify="end"
                className={seatClassName}
                onMouseOver={onMouseOver}
                onMouseDown={onMouseDown}
                style={{
                    ...commonStyles,
                    border: "1px solid transparent",
                    borderRadius: 2,
                    opacity: isSeatDragged ? 1 : DEFAULT_OPACITY,
                }}
            >
                <small style={{ color: "lightgray" }}>{seat.label}</small>
            </Flex>
        );
    }
}

export default SeatDrag;