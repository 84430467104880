// import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";


const AccessControl = (props) => {
    
    const {children, userPermissions=[], allowedPermissions = [], renderNoAccess} = props;

    const [loading, setLoading] = useState(true);
    const [permitted, setPermitted] = useState(false);
    
    useEffect(()=> {
        // console.log(allowedPermissions);
        // console.log(userPermissions);
        if(allowedPermissions.length === 0){
            setPermitted(true);
        } else {
            for(let i in allowedPermissions){
                if(userPermissions?.find(item => item === allowedPermissions[i])){
                    setPermitted(true);
                    break;
                }
            }
        }
        setLoading(false);
    }, [userPermissions, allowedPermissions]);

    // if(loading){
    //     return (
    //         <div>
    //             <Skeleton.Button active={true} size={"large"} shape={"square"} block={false} />
    //         </div>
    //     );
    // }

    if(!permitted){
        return renderNoAccess();
    }

    return (
        <>
            {children}
        </>
    );
}

export default AccessControl;
