import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, ACTION_COLUMN_KEY } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/Halls/AddEditForm";
import { Link, useParams } from "react-router-dom";
import { Button, Checkbox, Flex } from "antd";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/hall/rest/hall";
const headerTitle = "Theatre Halls";
const editPermission = "theatrehall:edit";
const deletePermission = "theatrehall:delete";
const addPermission = "theatrehall:add";

const AllTheatreHallsPage = () => {

    const params = useParams();
    const { id } = params;

    const columns = [
        {
            title: 'Hall no',
            dataIndex: 'hall_no',
            key: 'hall_no',
            // render: (text) => <a>{text}</a>,
        },
        {
            title: 'Row Length',
            dataIndex: 'row_length',
            key: 'row_length',
            // render: (text) => <a>{text}</a>,
            align: "right"
        },
        {
            title: 'Column Length',
            dataIndex: 'column_length',
            key: 'column_length',
            // render: (text) => <a>{text}</a>,
            align: "right"
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, row) => {
                return <Checkbox disabled checked={row.status}></Checkbox>
            }
        },
        {
            title: 'Theatre',
            dataIndex: 'theatre',
            key: 'theatre',
            render: (text, row) => { return row.theatre_data?.title }
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created',
            render: (text, row, index) => {
                return <RenderCreated text={text} row={row} index={index} />
            },
            align: "right"
        },
        {
            title: 'Updated',
            dataIndex: 'updated_at',
            key: 'updated',
            render: (text, row, index) => {
                return <RenderUpdated text={text} row={row} index={index} />
            },
            align: "right"
        },
        {
            title: 'Action',
            key: ACTION_COLUMN_KEY,
            fixed: 'right',
            width: 250,
            render: (text, row, index) => {
                let theatre_id = id;
                console.log({theatre_id});
                if (id && id.includes("undefined")){
                    theatre_id = row.theatre;
                } else {
                    theatre_id = row.theatre;
                }
                console.log({theatre_id});
                return (<Flex style={{ gap: 5 }}>
                    <Link to={`/theatres/${theatre_id}/halls/${row.id}/layout`}>
                        <Button size="small">
                            Layout
                        </Button>
                    </Link>
                    <RenderActionButtons
                        text={text}
                        row={row}
                        index={index}
                        apiUrl={apiUrl}
                        editPermission={editPermission}
                        deletePermission={deletePermission} />
                </Flex>);
            },
        },
    ]

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            filters={[allowedFiltersEnum.CLIENT.theatre__client, allowedFiltersEnum.THEATRE.theatre]}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default AllTheatreHallsPage;