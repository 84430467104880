import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Form, Input, Select, Spin, Tooltip } from 'antd';
import { useNotificationContext } from 'Config/NotificationsContext';
import { onInputTextShouldBeLowerCase } from 'Utils/formValidators';

const FLAGCDN_BASE_URL = "https://flagcdn.com/20x15/";
const DEFAULT_PHONE_CODE = "230";
const { Option } = Select;

const CountryCodeFormField = ({ initialMobileCodeValue, initialMobileValue, setPhone, setPhoneCode, setEmail }) => {
    const [countryCodes, setCountryCodes] = useState([]);
    const [countryCodesLoading, setCountryCodesLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedCode, setSelectedCode] = useState('');
    const [mobile, setMobile] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    const openNotification = useNotificationContext();

    const handleSelect = useCallback((value) => {
        console.log(value);
        const selected = countryCodes.find(country => country.phone_code === value);
        setSelectedCountry(selected);
        setSelectedCode(value);
    }, [countryCodes]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                setCountryCodesLoading(true);
                const response = await axios.get("/master/countries-list/");
                if (Array.isArray(response.data)) {
                    setCountryCodes(response.data);
                }
            } catch (err) {
                console.error(err);
                openNotification("Couldn't load country codes!", null, "error");
            } finally {
                setCountryCodesLoading(false);
            }
        }

        fetchCountries();
    }, [openNotification]);

    useEffect(() => {
        if (initialMobileCodeValue) {
            handleSelect(initialMobileCodeValue);
            if (typeof setPhoneCode === "function") {
                setPhoneCode(initialMobileCodeValue);
            }
        } else {
            handleSelect(DEFAULT_PHONE_CODE);
            if (typeof setPhoneCode === "function") {
                setPhoneCode(DEFAULT_PHONE_CODE);
            }
        }

        if (initialMobileValue) {
            setMobile(initialMobileValue);
            if (typeof setPhone === "function") {
                setPhone(initialMobileValue);
            }
        } else {
            setMobile("");
            if (typeof setPhone === "function") {
                setPhone("");
            }
        }
    }, [initialMobileCodeValue, initialMobileValue, handleSelect, setPhone, setPhoneCode]);

    return (
        <Flex gap={10} vertical>
            <Flex justify='space-between' gap={8}>
                <label for="phone" title="Phone">
                    <small style={{
                        display: 'inline-block',
                        marginInlineEnd: '4px',
                        color: '#ff4d4f',
                        fontSize: '14px',
                        lineHeight: 1,
                        fontFamily: "SimSun,sans-serif"
                    }}>*</small>
                    Phone:
                </label>
                <Flex vertical flex={1}>
                    <Select
                        value={selectedCode}
                        onChange={handleSelect}
                        loading={countryCodesLoading}
                        placeholder="Select country"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}

                    >
                        {countryCodes.map(country => (
                            <Option key={country.iso_country_code} value={country.phone_code}>
                                <Tooltip title={`${country.title}`}>
                                    <Flex align='center' gap={8}>
                                        <img
                                            src={`${FLAGCDN_BASE_URL}${country.iso_country_code.toLowerCase()}.png`}
                                            alt={country.title}
                                            style={{ width: '20px', height: '15px' }}
                                        />
                                        <span>(+{country.phone_code})</span>
                                        <span>{country.title}</span>
                                    </Flex>
                                </Tooltip>
                            </Option>
                        ))}
                    </Select>
                    <Form.Item
                        // label="Phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: 'Please input phone!',
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input
                            type="text"
                            placeholder="Phone Number"
                            value={mobile}
                            onChange={({ target: { value } }) => {
                                if (isNaN(value)) {
                                    openNotification('Phone number can only contain numbers', null, "error");
                                    return;
                                }
                                setMobile(value);
                                if (typeof setPhone === "function") {
                                    setPhone(value);
                                }
                            }}
                            style={{ width: '100%', marginTop: 8 }}
                        />
                    </Form.Item>
                </Flex>
            </Flex>
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input email!',
                    },
                    {
                        type: 'email',
                        message: 'Invalid email format!',
                    },
                ]}
                style={{ marginBottom: 0 }}
            >
                <Input
                    onInput={onInputTextShouldBeLowerCase}
                    value={emailAddress}
                    onChange={({ target: { value } }) => {
                        setEmailAddress(value);
                        if (typeof setEmail === "function") {
                            setEmail(value);
                        }
                    }}
                    placeholder="Please enter email"
                />
            </Form.Item>
        </Flex >
    );
}

export default CountryCodeFormField;