import { combineReducers } from "@reduxjs/toolkit";
import utilitiesReducer from "./UtilitiesReducer/utilitySlice";
import authReducer from "./AuthReducer/authSlices";
// import citiesReducer from "./MasterReducer/citiesSlices";
// import genresReducer from "./MasterReducer/genresSlices";
import masterReducer from "./MasterReducer/crudSlices"
import hallLayoutSlice from "./HallLayoutReducer/hallLayoutSlice";
import theatreSlice from "./TheatreReducer/theatreSlice";

import storage from 'redux-persist/lib/storage'
import { persistReducer } from "redux-persist";
import programSlice from "./ProgramReducer/programSlice";
import showsSlice from "./ShowReducer/showSlices";

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['status', 'errorMsg']
}

const utilitiesPersistConfig = {
    key: 'utilities',
    storage: storage,
}

const rootReducer = combineReducers({
    utilities: persistReducer(utilitiesPersistConfig, utilitiesReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    master: masterReducer,
    hallLayout: hallLayoutSlice,
    theatre: theatreSlice,
    program: programSlice,
    shows: showsSlice,
});

export default rootReducer;