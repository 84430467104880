import React, { useEffect, useState } from 'react';
import { Flex, Table } from 'antd';
import { TYPE_OPTIONS } from '../TopSalesComponent';
import { getFormattedAmount } from 'Utils/textFormat';

const movieColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Seats Sold',
        dataIndex: 'seatsSold',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (text) => getFormattedAmount(text),
        sorter: {
            compare: (a, b) => a.math - b.math,
            multiple: 2,
        },
    },
];

const foodColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Number Sold',
        dataIndex: 'quantity',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (text) => getFormattedAmount(text),
        sorter: {
            compare: (a, b) => a.math - b.math,
            multiple: 2,
        },
    },
];

const ticketTypeColumns = [
    {
        title: 'Type',
        dataIndex: 'name',
    },
    {
        title: 'Number Sold',
        dataIndex: 'numbersSold',
        sorter: {
            compare: (a, b) => a.chinese - b.chinese,
            multiple: 3,
        },
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (text) => getFormattedAmount(text),
        sorter: {
            compare: (a, b) => a.math - b.math,
            multiple: 2,
        },
    },
];

const defaultTableData = [
    {
        key: '1',
        name: 'A',
        seatsSold: 123,
        amount: 67198,
        english: 70,
    },
    {
        key: '2',
        name: 'B',
        seatsSold: 98,
        amount: 123198,
        english: 89,
    },
    {
        key: '3',
        name: 'C',
        seatsSold: 23,
        amount: 20000,
        english: 70,
    },
    {
        key: '4',
        name: 'D',
        seatsSold: 43,
        amount: 430891,
        english: 89,
    },
];

const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};
const TopSalesTable = ({ apiData,  selectedType }) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        const processApiData = () => {
            if (typeof apiData === "object") {
                let values = Object.values(apiData);
                
                if (selectedType === TYPE_OPTIONS.FOOD){
                    let d = values.map(v => ({key: v.id, name: v.name, quantity: v.quantity, amount: v.amount})).sort((a, b) => a.amount > b.amount ? -1 : 1);
                    setData(d);
                } else if (selectedType === TYPE_OPTIONS.MOVIE) {
                    let d = values.map(v => ({key: v.id, name: v.name, amount: v.amount, seatsSold: v.seatsSold})).sort((a, b) => a.amount > b.amount ? -1 : 1);
                    setData(d);
                } else if (selectedType === TYPE_OPTIONS.TICKET_TYPE) {
                    let d = values.map(v => ({key: v.id, name: v.name, amount: v.amount, numbersSold: v.numbersSold})).sort((a, b) => a.amount > b.amount ? -1 : 1);
                    setData(d);
                }
            }
        }
        processApiData()
    }, [apiData]);

    return <Flex flex={1}>
        <Table
            style={{ width: "100%" }}
            columns={selectedType === TYPE_OPTIONS.FOOD ? foodColumns : selectedType === TYPE_OPTIONS.MOVIE ? movieColumns : ticketTypeColumns}
            dataSource={data || []}
            onChange={onChange}
        />
    </Flex>
};
export default TopSalesTable;