import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Checkbox, Select, Flex, Upload, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { useParams } from 'react-router';
import { UploadOutlined } from '@ant-design/icons';
import { fetchTheatresListAsync } from 'Redux/TheatreReducer/theatreSlice';
import { fetchClientList } from 'Api/commonApis';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();

    const [imageBase64, setImageBase64] = useState(null);
    const [newDefaultImage, setNewDefaultImage] = useState(null);

    const theatreList = useSelector(store => store.theatre?.theatreList);
    const selectedClient = useSelector(store => store.shows?.selectedClient);

    const [clientOptions, setClientOptions] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    // useEffect(() => {
    //     dispatch(fetchTheatresListAsync());
    // }, []);

    useEffect(() => {
        if (selectedClient === "all") {
            dispatch(fetchTheatresListAsync());
        } else {
            dispatch(fetchTheatresListAsync(`?client=${selectedClient}`));
        }
    }, [selectedClient]);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        const fetcClientOptions = async () => {
            try {
                const list = await fetchClientList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.title }))
                        setClientOptions(options);
                    } else {
                        openNotification("No Clients Found", "Please enter clients first.", "error");
                    }
                } else {
                    throw new Error("Fetch Clients did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Client Options", err.message, "error");
                } else {
                    openNotification("Client Options", "Something went wrong while fetching client options", "error")
                }
            }
        }
        fetcClientOptions();
    }, []);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({
            apiUrl: apiUrl, data: val, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }));
    }

    const edit = (val) => {
        // console.log(val);
        dispatch(editARecord({
            apiUrl, id: currentEditViewFormId, data: val, headers: {
                'Content-Type': 'multipart/form-data',
            }
        }));
    }

    const validatePositiveInteger = (rule, value) => {
        const numericValue = Number(value);
        if (isNaN(numericValue) || numericValue < 0) {
            return Promise.reject('It must be a non-negative numeric value.');
        }

        const stringValue = String(value);
        const decimalIndex = stringValue.indexOf('.');
        console.log({ decimalIndex })

        if (decimalIndex > -1) {
            return Promise.reject('It cannot contain decimal points.');
        }

        return Promise.resolve();
    };

    const getFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleImageUpload = (file) => {
        console.log(file.file)
        try {
            // Convert the image to base64
            getBase64(file.file, (base64) => {
                setImageBase64(base64);
            });
            setNewDefaultImage(file.file);
            // openNotification(`${file.file.name} file uploaded successfully`, null, "success");
        } catch (err) {
            // openNotification(`${file.file.name} file upload failed.`, null, "error");
        }
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const onFinish = (val) => {
        val.default_image = newDefaultImage;
        val.status = !!val.status;
        console.log({val});
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable thisModel="Categories"
                requiredData={[{data: clientOptions, field: "client"}]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(theatreList) && theatreList.length > 0)}
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter title',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter title" />
                    </Form.Item>
                    {/* <Form.Item
                    label="Theatre"
                    name="theatre"
                    initialValue={params?.id}
                    style={{ width: "150px" }}
                    rules={[{
                        required: true
                    }]}
                >
                    <Select

                        placeholder="Choose a Theatre"
                        loading={Array.isArray(theatreList) ? false : true}
                        onSelect={(v) => {
                            console.log({ v });
                        }}
                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                    >
                        {
                            theatreList?.map((afv, ind) => {
                                return <Select.Option key={afv.id} value={afv.id}>{afv?.title}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item> */}
                    <Form.Item
                        name="client"
                        label="Client"
                        rules={[
                            // {
                            //     required: true,
                            //     message: "Client is required"
                            // }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(clientOptions) ? false : true}
                            options={clientOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Status"
                        name="status"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label="Default Image"
                        name="image"
                        valuePropName='fileList'
                        getValueFromEvent={getFile}
                        rules={[{ required: false, message: 'Please upload a default image!' }]}
                    >
                        <Flex gap={20}>
                            <Flex>
                                <Upload
                                    // action={handleImageUpload}
                                    beforeUpload={() => false} // Prevent automatic upload
                                    listType="picture-card"
                                    fileList={[]}
                                    showUploadList={false}
                                    onChange={handleImageUpload}
                                >
                                    {
                                        imageBase64 ? (
                                            <img src={imageBase64} alt="Preview" style={{ width: '100%' }} />
                                        ) : (
                                            <div>
                                                <UploadOutlined />
                                                <div style={{ marginTop: 8 }}>{currentFormMode === FormModesEnum.ADD ? "Upload" : "Change"}</div>
                                            </div>
                                        )
                                    }
                                </Upload>
                            </Flex>
                            {
                                currentRecordData.default_image && !imageBase64 ? <Image src={currentRecordData.default_image} alt='Preview' style={{ maxWidth: 100 }} /> : <></>
                            }
                        </Flex>

                    </Form.Item>
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form >
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;