import { validateTheatre } from "Api/commonApis";
import { Flex, Spin } from "antd";
import Title from "antd/es/typography/Title";
// import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const VerifyTheatrePage = ({ children }) => {
    const params = useParams();
    const { id } = params;

    const [isValid, setIsValid] = useState(null);

    useEffect(() => {
        validateTheatre(id).then(v => setIsValid(v)).catch(v => setIsValid(false));
    }, []);

    return <Flex justify="center" align="center" style={{ width: "100%" }}>
        {
            !id ?
                <>
                    <Title>Invalid Theatre ID</Title>
                </> :
                <>
                    {
                        isValid === null ? <Spin /> : isValid === true ? <>{children || "valid"}</> : <>Invalid Theatre Id</>
                    }
                </>
        }
    </Flex>

}

export default VerifyTheatrePage;