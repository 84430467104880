import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, Checkbox, DatePicker, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchChannelsList, fetchClientList, fetchFoodCouponTypeChoices, fetchSeatCouponTypeChoices, fetchTheatresList } from 'Api/commonApis';
import { useParams } from 'react-router';
import { validatePositiveInteger, validateTwoDecimalNumber } from 'Utils/formValidators';
import dayjs from 'dayjs';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
    const params = useParams();
    console.log({ params });

    const [notificationApi, contextHolder] = notification.useNotification();

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [clientOptions, setClientOptions] = useState(null);
    const [foodCouponTypes, setFoodCouponTypeOptions] = useState(null);
    const [isUserSpecific, setIsUserSpecific] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchClientOptions = async () => {
            try {
                const list = await fetchClientList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.title }))
                        setClientOptions(options);
                    } else {
                        openNotification("No Clients Found", "Please enter clients first.", "error");
                    }
                } else {
                    throw new Error("Fetch Clients did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Client Options", err.message, "error");
                } else {
                    openNotification("Client Options", "Something went wrong while fetching client options", "error")
                }
            }
        }

        const fetchFoodCouponTypeOptions = async () => {
            try {
                const obj = await fetchFoodCouponTypeChoices();
                if (Array.isArray(obj)) {
                    const options = [];
                    obj.forEach(o => {
                        options.push({
                            value: o[0],
                            label: o[1]
                        })
                    })
                    console.log({ foodTypeOptions: options, obj })
                    setFoodCouponTypeOptions(options);
                } else {
                    throw new Error("Fetch Food Coupon Type did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Seat Coupon Options", err.message, "error");
                } else {
                    openNotification("Seat Coupon Options", "Something went wrong while fetching convenienceFeeType options", "error")
                }
            }
        }
        fetchClientOptions();
        fetchFoodCouponTypeOptions();
    }, []);

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let currentRecordDataCopy = JSON.parse(JSON.stringify(currentRecordData));
        if (currentRecordData.start_date) {
            currentRecordDataCopy.start_date_ = dayjs(currentRecordDataCopy.start_date);
        }
        if (currentRecordData.end_date) {
            currentRecordDataCopy.end_date_ = dayjs(currentRecordDataCopy.end_date);
        }
        form.setFieldsValue(currentRecordDataCopy);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const modifyStartDate = (v) => {
        setStartDate(v);
    }

    const modifyEndDate = (v) => {
        setEndDate(v);
    }

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        let url = apiUrl?.split?.("?")[0];
        // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
        dispatch(editARecord({ apiUrl: url, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        val.is_expired = !!val.is_expired;
        val.pos_active = !!val.pos_active;
        val.web_active = !!val.web_active;
        val.app_active = !!val.app_active;
        val.kiosk_active = !!val.kiosk_active;
        console.log({val});
        if (startDate) {
            delete val.start_date_;
            val.start_date = startDate.format('YYYY-MM-DD');
        }
        if (endDate) {
            delete val.end_date_;
            val.end_date = endDate.format('YYYY-MM-DD');
        }
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }

    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable
                thisModel='Food Coupons'
                requiredData={[{ data: clientOptions, field: "client" }, { data: foodCouponTypes, field: "Food Coupon Type" }]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(clientOptions) && clientOptions.length > 0)}
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter title',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter title" />
                    </Form.Item>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="client"
                                label="Client"
                                rules={[
                                    {
                                        required: true,
                                        message: "Client is required"
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    // style={{
                                    //     width: 200,
                                    // }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    loading={Array.isArray(clientOptions) ? false : true}
                                    options={clientOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="coupon_type"
                                label="Coupon Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Seat Coupon Type is required"
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    // style={{
                                    //     width: 200,
                                    // }}
                                    placeholder="Search to Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    loading={Array.isArray(foodCouponTypes) && foodCouponTypes.length > 0 ? false : true}
                                    options={foodCouponTypes}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="value"
                                label="Value"
                                rules={[
                                    { required: true, message: 'Value is required.' },
                                    { validator: validateTwoDecimalNumber },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="upto_value"
                                label="Upto Value"
                                rules={[
                                    // { required: true, message: 'Limit is required.' },
                                    { validator: validateTwoDecimalNumber },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name="limit"
                                label="Limit"
                                rules={[
                                    // { required: true, message: 'Limit is required.' },
                                    { validator: validatePositiveInteger },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="min_subtotal"
                                label="Min Subtotal"
                                rules={[
                                    // { required: true, message: 'Limit is required.' },
                                    { validator: validateTwoDecimalNumber },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="count"
                        label="Count"
                        rules={[
                            // { required: true, message: 'Limit is required.' },
                            { validator: validatePositiveInteger },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                name='start_date_'
                                label="Start Date"
                                rules={[
                                    { required: true, message: "Start Date is required" }
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    value={startDate}
                                    onChange={modifyStartDate}
                                    format={'YYYY-MM-DD'}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='end_date_'
                                label="End Date"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    value={endDate}
                                    onChange={modifyEndDate}
                                    format={'YYYY-MM-DD'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    {/* <Form.Item
                        name="is_user_specific"
                        valuePropName="checked"
                    >
                        <Checkbox value={isUserSpecific} onChange={(v) => {
                            console.log({ is_user_specific: v });
                            setIsUserSpecific(v.target.checked)
                        }}>Is User Specific?</Checkbox>
                    </Form.Item>
                    {
                        isUserSpecific && <Form.Item
                            name="user"
                            label="User Id"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter user',
                                },
                            ]}
                        >
                            <Input placeholder="Please enter user" />
                        </Form.Item>
                    } */}
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name="is_expired"
                                valuePropName="checked"
                            >
                                <Checkbox>Is Expired?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="pos_active"
                                valuePropName="checked"
                            >
                                <Checkbox>POS Active</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="web_active"
                                valuePropName="checked"
                            >
                                <Checkbox>Web Active</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="app_active"
                                valuePropName="checked"
                            >
                                <Checkbox>App Active</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="kiosk_active"
                                valuePropName="checked"
                            >
                                <Checkbox>Kiosk Active</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form>
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;