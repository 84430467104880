import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/Tax/AddEditForm";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/theatre/rest/theatretax";
const headerTitle = "Theatre Users";
const editPermission = "theatretax:edit";
const deletePermission = "theatretax:delete";
const addPermission = "theatretax:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const AllTheatreTaxPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            headerTitle={headerTitle}
            filters={[allowedFiltersEnum.CLIENT.theatre__client, allowedFiltersEnum.THEATRE.theatre]}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default AllTheatreTaxPage;