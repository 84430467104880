import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Row, Col } from 'antd';
// import apiUrls from 'Utils/apiUrls';
import { LoadingOutlined } from "@ant-design/icons"
import { validatePositiveInteger } from 'Utils/formValidators';
import { useNotificationContext } from 'Config/NotificationsContext';
import { useParams } from 'react-router';
import axios from 'axios';

const ClientChannelSettingsForm = ({ saveUrl, data, defaultChannel, clientOptions, setData, convenienceFeeTypeOptions }) => {
    const params = useParams();
    const [form] = Form.useForm();
    const openNotification = useNotificationContext();
    const [loading, setLoading] = useState(false);
    console.log({ data, saveUrl, convenienceFeeTypeOptions })


    const validatePositiveIntegerAllowEmpty = (rule, value) => {
        console.log({ rule, value })
        // return validatePositiveInteger(rule, value);
        if (value === undefined || value === null) return Promise.resolve();
        else return validatePositiveInteger(rule, value);
    }

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let dataToSet = {};
        if (!data && defaultChannel) {
            dataToSet = {
                "channel": defaultChannel
            }
        } else {
            dataToSet = data;
        }
        form.setFieldsValue(dataToSet);
    }, [data]);

    const validateConvenienceFee = (rule, value) => {
        // if (value === undefined || value === null) return Promise.resolve();
        const numericValue = Number(value);
        if (isNaN(numericValue) || numericValue < 0) {
            return Promise.reject('Convenience Fee must be a non-negative numeric value.');
        }

        const stringValue = String(value);
        const decimalIndex = stringValue.indexOf('.');
        const numberOfDigitsBeforeDecimal = decimalIndex === -1 ? stringValue.length : decimalIndex;

        if (numberOfDigitsBeforeDecimal > 10) {
            return Promise.reject('Convenience Fee can have at most 10 digits.');
        }

        const numberOfDecimalPoints = decimalIndex === -1 ? 0 : stringValue.length - decimalIndex - 1;
        if (numberOfDecimalPoints > 2) {
            return Promise.reject('Convenience Fee can have at most 2 decimal points.');
        }

        return Promise.resolve();
    };

    const onFinish = async (val) => {
        console.log({ "Add Val": val });
        setLoading(true);
        try {
            let method = "post";
            let url = saveUrl;
            if (data) {
                method = "patch";
                if (saveUrl.includes("?")) {
                    url = saveUrl.split("?")[0];
                    if (url[url.length - 1] === "/") {
                        // as expected
                    } else {
                        url += "/";
                    }
                }
                url += `${data.id}/`;
            }
            console.log({ method, data, url })
            const response = await axios[method](url, val);
            console.log(response);
            setData(response.data);
            openNotification("Saved Successfully", null, "success");
        } catch (err) {
            console.error(err);
            openNotification("Error while saving data", null, "error");
        }
        setLoading(false);
    }
    return (
        <>
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                style={{ width: "100%" }}
            >
                <Row gutter={10}>
                    {/* <Col> */}
                    <Form.Item
                        hidden
                        name="channel"
                        label="Channel"
                        rules={[{
                            required: true,
                            message: "Please add a channel"
                        }]}
                    >
                        <Input />
                    </Form.Item>
                    {/* </Col> */}
                    <Col>
                        <Form.Item
                            name="checkout_timeout"
                            label="Checkout Timeout (in secs.)"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter Checkout Timeout',
                                // },
                                { validator: validatePositiveIntegerAllowEmpty },
                            ]}
                        >
                            <Input type="number" placeholder="Please enter Checkout Timeout" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="payment_timeout"
                            label="Payment Timeout (in secs.)"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter Payment Timeout',
                                // },
                                { validator: validatePositiveIntegerAllowEmpty },
                            ]}
                        >
                            <Input type="number" placeholder="Please enter Payment Timeout" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="max_seat_allowed"
                            label="Max Seat Allowed"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter Max Seat Allowed',
                                // },
                                { validator: validatePositiveIntegerAllowEmpty },
                            ]}
                        >
                            <Input type="number" placeholder="Please enter Max Seat Allowed" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="max_food_allowed"
                            label="Max Food Allowed"
                            rules={[
                                // {
                                //     required: true,
                                //     message: 'Please enter Max Food Allowed',
                                // },
                                { validator: validatePositiveIntegerAllowEmpty },
                            ]}
                        >
                            <Input type="number" placeholder="Please enter Max Food Allowed" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={5}>
                    <Col>
                        <Form.Item
                            name="convenience_fee"
                            label="Convenience Fee"
                            rules={[
                                { required: true, message: 'Convenience Fee is required.' },
                                { validator: validateConvenienceFee },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="convenience_fee_type"
                            label="Convenience Fee Type"
                            rules={[
                                { required: true, message: "Convenience Fee Type is required" }
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: 200,
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                loading={Array.isArray(convenienceFeeTypeOptions) && convenienceFeeTypeOptions.length > 0 ? false : true}
                                options={convenienceFeeTypeOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    params.id ?
                        <Form.Item
                            hidden
                            name="client"
                            initialValue={params?.id}
                        >
                            <Input />
                        </Form.Item> :
                        <Form.Item
                            name="client"
                            label="Client"
                            rules={[
                                {
                                    required: true,
                                    message: "Client is required"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: 200,
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                loading={Array.isArray(clientOptions) ? false : true}
                                options={clientOptions}
                            />
                        </Form.Item>
                }
                <Form.Item>
                    {
                        loading ?
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Saving
                            </Button> :
                            <Button type="primary" htmlType="submit" >
                                Save
                            </Button>
                    }
                </Form.Item>
            </Form >
        </>
    );
};
export default ClientChannelSettingsForm;