import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Clients/ProgramTypes/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/user/rest/program-types";
const headerTitle = "Program Types"
const editPermission = "program-types:edit";
const deletePermission = "program-types:delete";
const addPermission = "program-types:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Surcharge',
        dataIndex: 'surcharge',
        key: 'surcharge',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        render: (_, row) => row?.client_data?.username || "-",
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
];

const ProgramTypesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ProgramTypesPage;