import { asyncStatuses } from "Redux/enums";
import { Button, Checkbox, Flex, Form, Input, Select, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons"
import { fetchSeatTypeList } from "Api/commonApis";
import { MODE } from "Pages/Dashboard/Theatres/Hall/BulkModeSingleMode/TheatreHallLayoutPage";
import { updateBulkSeatsPrice } from "Redux/HallLayoutReducer/hallLayoutSlice";

const { Option } = Select;

const PriceLayoutForm = ({ showId, selectedSeats, changeSelectedSeats, currentMode, openDrawer, setOpenDrawer, fetchSeats }) => {

    const [seatTypeOptions, setSeatTypeOptions] = useState([]);
    const [notificationApi, contextHolder] = notification.useNotification();
    const [isSeat, setIsSeat] = useState(true);

    const dispatch = useDispatch();

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const updateSingleSeatStatus = useSelector(store => store.hallLayout?.updateSingleSeatStatus);
    const updateSingleSeatErrorMessage = useSelector(store => store.hallLayout?.updateSingleSeatErrorMessage);
    const updateBulkSeatStatus = useSelector(store => store.hallLayout?.updateBulkSeatStatus);
    const updateBulkSeatErrorMessage = useSelector(store => store.hallLayout?.updateBulkSeatErrorMessage);

    useEffect(() => {
        const fetchSeatTypeOptions = async () => {
            try {
                const list = await fetchSeatTypeList();
                if (Array.isArray(list)) {
                    // const options = list.map(l => ({ value: l.id, label: l.username }))
                    setSeatTypeOptions(list);
                } else {
                    throw new Error("Fetch SeatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("SeatType Options", err.message, "error");
                } else {
                    openNotification("SeatType Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        fetchSeatTypeOptions();
    }, []);

    useEffect(() => {
        if (typeof selectedSeats === "object" && selectedSeats) {
            console.log({ openDrawer, selectedSeats, hasKey: Object.keys(selectedSeats).includes("is_seat"), is_seat: selectedSeats?.is_seat })
            if (currentMode === MODE.SINGLE) {
                if (Object.keys(selectedSeats).includes("is_seat")) {
                    setIsSeat(selectedSeats.is_seat);
                }
            }
        }
    }, [selectedSeats, openDrawer, currentMode]);


    const onFinish = async (values) => {
        values.pos_active = !!values.pos_active;
        values.web_active = !!values.web_active;
        values.app_active = !!values.app_active;
        values.kiosk_active = !!values.kiosk_active;
        console.log({values});
        if (currentMode === MODE.BULK) {
            values.selectedSeats = selectedSeats;
            if (Array.isArray(values.selectedSeats)) {
                values.selectedSeats = values.selectedSeats.map(r => r.id);
                values.show = showId
                if (typeof values.seatType === "string") {
                    // no problem
                } else {
                    openNotification("No Seat Type selected", "Seat Type should be selected for a seat in order to proceed.", "error");
                    return;
                }
                const response = await updateBulkSeatsPrice(values);
                if (response) {
                    fetchSeats()
                    setOpenDrawer(false)
                }
            }
        }
    }

    const onSelectSeatChange = function (value, option) {
        console.log({ value, option })
        let ss = JSON.parse(JSON.stringify(selectedSeats));
        if (Array.isArray(ss)) {
            // 
        } else {
            ss = [];
        }
        ss = ss.filter(r => r.id !== value);
        changeSelectedSeats(ss);
    }

    return (
        <>
            {contextHolder}
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
            >
                {
                    currentMode === MODE.SINGLE ?
                        <>
                            <Form.Item
                                label="Selected Seat"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter selected seat',
                                    },
                                ]}
                            >
                                <Input value={selectedSeats?.display_label} disabled placeholder="Please enter selected seat" />
                            </Form.Item>
                            <Form.Item
                                name="label"
                                label="New Label (optional)"
                            >
                                <Input placeholder={selectedSeats?.label} />
                            </Form.Item>
                        </> : <Flex vertical style={{ marginBottom: 20 }}>
                            <span style={{ marginBottom: 10 }}>Selected Seats</span>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '300px' }}
                                value={selectedSeats?.map?.(s => {
                                    s["key"] = s.id;
                                    s["label"] = s.display_label;
                                    s["value"] = s.id;
                                    return s;
                                })}
                                options={selectedSeats?.map?.(s => {
                                    s["key"] = s.id;
                                    s["label"] = s.display_label;
                                    s["value"] = s.id;
                                    return s;
                                })}
                                onDeselect={onSelectSeatChange}
                            />
                        </Flex>
                }

                {
                    !isSeat ? <></> : <Form.Item
                        name="seatType"
                        label="Seat Type"
                        initialValue={selectedSeats?.seatType}
                        rules={[
                            {
                                required: true,
                                message: "Seat Type is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            loading={Array.isArray(seatTypeOptions) && seatTypeOptions.length > 0 ? false : true}

                        >
                            {
                                Array.isArray(seatTypeOptions) && seatTypeOptions.map((st, ind) => {

                                    return <Option key={st.id} value={st?.id}>
                                        <Flex gap={3} align="center"><div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: st.color }} ></div>
                                            <span>{st.title}</span></Flex>
                                    </Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                }
                <Form.Item
                    className="d-flex"
                    label="POS Active?"
                    name="pos_active"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    className="d-flex"
                    label="Kiosk Active?"
                    name="kiosk_active"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    className="d-flex"
                    label="Website Active?"
                    name="web_active"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item
                    className="d-flex"
                    label="App Active?"
                    name="app_active"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item>
                    {
                        (updateBulkSeatStatus === asyncStatuses.LOADING || updateSingleSeatStatus === asyncStatuses.LOADING) ?
                            <Button type="primary" htmlType="submit" disabled>
                                <LoadingOutlined />
                                Submitting
                            </Button> :
                            <Button type="primary" htmlType="submit" >
                                Submit
                            </Button>
                    }
                </Form.Item>
            </Form >
        </>)
}

export default PriceLayoutForm;