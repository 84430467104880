import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserClientsListAsync, setSelectedClient } from 'Redux/ShowReducer/showSlices';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';

const { Option } = Select;

const ClientFilter = ({ openNotification, apiUrl, extraApiParamsState, setExtraApiParamsState, field }) => {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showClientFilter, setShowClientFilter] = useState(false);

    const user = useSelector(store => store.auth.user);
    const filterValues = useSelector(store => store.shows?.userClientsList);
    const selectedClient = useSelector(store => store.shows?.selectedClient);

    const [disableFilter, setDisableFilter] = useState(false)

    useEffect(() => {
        if (!filterValues || (Array.isArray(filterValues) && filterValues.length === 0)) dispatch(fetchUserClientsListAsync());
    }, []);

    useEffect(() => {
        const isUserAdmin = user.is_admin;
        const userHasAdminRole = user?.roles?.filter?.(r => r.role_code === "ADMIN").length;
        const userHasClientRole = user?.roles?.filter?.(r => r.role_code === "CLIENT").length;
        const userHasTheatreUserRole = user?.roles?.filter?.(r => r.role_code === "THEATREUSER").length;
        const userHasAdminRights = isUserAdmin && userHasAdminRole;

        if (user.id && !userHasAdminRights && filterValues.length > 0) {
            
            if (userHasTheatreUserRole) {
                // only get one client
                setShowClientFilter(true);
                if (filterValues.length >= 1) {
                    setDisableFilter(true)
                    dispatch(setSelectedClient(filterValues[0].id));
                }

            } else {
                setShowClientFilter(true);
                let value = filterValues.filter((val) => val.user === user.id)
                if (value.length > 0) {
                    setDisableFilter(true)
                    dispatch(setSelectedClient(value[0].id));
                    // if (value[0].id) {
                    //     setExtraApiParamsState((p) => {
                    //         const s = {
                    //             ...p,
                    //             [field]: value[0].id === "all" ? null : value[0].id
                    //         }
                    //         if (apiUrl?.includes?.('food/foods')) {
                    //             s['category__theatre__client'] = s[field];
                    //             delete s['client']
                    //         }
                    //         console.log(s)
                    //         return s;
                    //     })
                    // }
                }
            }
        } else {
            if (userHasAdminRights) {
                setShowClientFilter(true)
            }
        }
    }, [user, filterValues]);

    const onClientSelect = (v) => {
        // console.log({ v });
        if (v === "all") {
            dispatch(setSelectedClient("all"));
        } else {
            dispatch(setSelectedClient(v));
        }
        setSearchParams(params => {
            params.set(searchParamsEnum.PAGE, 1);
        });
        setExtraApiParamsState((p) => {
            if (v === "all") {
                let s = p && JSON.parse(JSON.stringify(p));
                if (!s) {
                    s = {}
                }
                if (field in s) {
                    delete s[field]
                }
                return s;
            } else {
                const s = {
                    ...p,
                    [field]: v === "all" ? null : v
                }
                // console.log({ s, v });
                return s;
            }
        })
    }

    if (!showClientFilter){
        return <></>;
    }

    return (
        <>
            <Flex align='center' justify='center' gap={5}>
                <label>Client</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Client to Filter"
                    disabled={disableFilter}
                    loading={Array.isArray(filterValues) ? false : true}
                    value={!selectedClient ? "all" : selectedClient}
                    onSelect={onClientSelect}
                >
                    {
                        filterValues?.length > 1 ? <>
                            <Option key={"all"} value="all">All</Option>
                            {
                                filterValues?.map((afv, ind) => {
                                    return <Option key={afv.id} value={afv.id}>{afv?.title}</Option>
                                })
                            }
                        </> : filterValues?.length == 1 ? <Option key={filterValues[0].id} value={filterValues[0].id}>{filterValues[0]?.title}</Option> : <></>
                    }
                </Select>
            </Flex>
        </>
    )
}

export default ClientFilter;