import { Button, Space, Popconfirm, Tooltip, message } from "antd";
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { deleteARecord, setFormModeAsEdit } from "Redux/MasterReducer/crudSlices";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import AccessControl from "Router/Access/AccessControl";
import { ClientProgramApiUrl } from "Components/Forms/Programs/Client/AddEditForm";
import { setCurrentSegmentToManual } from "Redux/ProgramReducer/programSlice";
import ConfirmPasswordDeleteModal from "./ConfirmPasswordDeleteModal";
import { useState } from "react";
import { useLocation } from "react-router";

export const ACTION_COLUMN_KEY = 'operation';

export const RenderActionButtons = ({ text, row, index, apiUrl, editPermission, deletePermission }) => {

    const location = useLocation();

    const dispatch = useDispatch();
    const user = useSelector(store => store.auth.user);
    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmPasswordModalOpen, setConfirmPasswordModalOpen] = useState(false);

    const userHasEditPermission = userPermissions?.includes?.(editPermission);
    const userHasDeletePermission = userPermissions?.includes?.(deletePermission);

    const copyIdToClipboard = () => {
        navigator.clipboard.writeText(row.id);
        messageApi.success(`${row.id} copied to clipboard.`)
    }

    let isTheatresPage = location.pathname === "/theatres";
    let hasCopyIdPermission = userPermissions?.includes?.("theatres_copy_id:view");

    let showCopyId = false;
    if (isTheatresPage) {
        if (hasCopyIdPermission) {
            showCopyId = true;
        } else {
            showCopyId = false;
        }
    } else {
        showCopyId = true;
    }

    return (
        <>
            <Space direction="horizontal">
                {contextHolder}
                <Space direction="horizontal">
                    {
                        userHasEditPermission && <AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={[editPermission]}
                            renderNoAccess={() => <></>}
                        >
                            <Button type="default" shape="circle" icon={<EditOutlined />} size="small" onClick={(event) => {
                                // console.log({event});
                                dispatch(setFormModeAsEdit({ id: row.id }));
                                if (apiUrl === ClientProgramApiUrl) {
                                    dispatch(setCurrentSegmentToManual());
                                }
                                event.stopPropagation();
                            }} />
                        </AccessControl>
                    }
                    {
                        userHasDeletePermission && <AccessControl
                            userPermissions={user.user_permissions}
                            allowedPermissions={[deletePermission]}
                            renderNoAccess={() => <></>}
                        >
                            <Popconfirm
                                title="Delete"
                                description="Are you sure to delete this entry?"
                                onConfirm={(e) => {
                                    setConfirmPasswordModalOpen(true);
                                }}
                                onCancel={(e) => console.log(e)}
                                okText="Yes"
                                cancelText="No"
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button type="default" color="red" shape="circle" danger icon={<DeleteOutlined />} size="small" />
                            </Popconfirm>
                        </AccessControl>
                    }
                </Space>
                {
                    showCopyId ? <Tooltip title={`${row.id}l`} trigger="hover">
                        <Button
                            type="default"
                            shape="default"
                            icon={<CopyOutlined size={18} />}
                            size="small"
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 5 }}
                            onClick={copyIdToClipboard}
                        >
                            Copy ID
                        </Button>
                    </Tooltip> : <></>
                }

            </Space>
            <ConfirmPasswordDeleteModal modalOpen={confirmPasswordModalOpen} setModalOpen={setConfirmPasswordModalOpen} apiUrl={apiUrl} row_id={row?.id} />
        </>
    );
}

export const RenderCreated = ({ text, row, index }) => {
    // console.log({text, row});
    return <Space direction="vertical" style={{ gap: 0 }}>
        <span>{row?.created_by_data?.first_name} {row?.created_by_data?.last_name}</span>
        <span>{row?.created_at ? moment(row.created_at).format("DD MMM yyyy, hh:mm a") : ""}</span>
    </Space>
}

export const RenderUpdated = ({ text, row, index }) => {
    // console.log({text, row});
    return <Space direction="vertical" style={{ gap: 0 }}>
        <span>{row?.updated_by_data?.first_name || row?.updated_by?.first_name} {row?.updated_by_data?.last_name || row?.updated_by?.last_name}</span>
        <span>{row?.updated_at ? moment(row.updated_at).format("DD MMM yyyy, hh:mm a") : ""}</span>
    </Space>
}

export const getCommonColumns = ({ apiUrl, editPermission, deletePermission, created, updated, action }) => {

    let toReturn = []

    if (created) {
        toReturn.push({
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created',
            render: (text, row, index) => {
                return <RenderCreated text={text} row={row} index={index} />
            },
            align: "right"
        });
    }

    if (updated) {
        toReturn.push({
            title: 'Updated',
            dataIndex: 'updated_at',
            key: 'updated',
            render: (text, row, index) => {
                return <RenderUpdated text={text} row={row} index={index} />
            },
            align: "right"
        });
    }

    if (action) {
        toReturn.push({
            title: 'Action',
            key: ACTION_COLUMN_KEY,
            fixed: 'right',
            width: 200,
            render: (text, row, index) => {
                return <RenderActionButtons
                    text={text}
                    row={row}
                    index={index}
                    apiUrl={apiUrl}
                    editPermission={editPermission}
                    deletePermission={deletePermission}
                />
            },
        });
    }

    return toReturn;
}