// import { Gauge } from '@ant-design/charts';
import { Flex, Space, Spin, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd';
import ReportTitle from 'Components/Dashboard/ReportTitle';
import axios from 'axios';
import { RELATIVE_DATE_OPTIONS, getStartAndEndDate } from 'Utils/getStartAndEndDate';
import dayjs from 'dayjs';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import ReactGaugeChart from 'react-gauge-component'

const initialDates = dayjs()

const GaugeChart = ({ defaultData, title, style, url, payload = {}, openNotification }) => {

    const [data, setData] = useState(null);
    const [selectedDateFilter, setSelectedDateFilter] = useState(RELATIVE_DATE_OPTIONS.TODAY);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);

    const modifyDates = (value) => {
        console.log({ value })
        setStartDate(value[0]);
        setEndDate(value[1])
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (selectedDateFilter !== RELATIVE_DATE_OPTIONS.CUSTOM) {
                    const { start_date, end_date } = getStartAndEndDate(selectedDateFilter);
                    console.log({ start_date, end_date })
                    payload['start_date'] = start_date;
                    payload['end_date'] = end_date;
                } else {
                    payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                    payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                }
                console.log(payload)
                const response = await axios.post(url, payload);
                console.log({ response });
                setData(response.data);
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch ${title} data`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [url, selectedDateFilter, startDate, endDate]);

    return  <Space
      direction='vertical'
      style={{
        ...style,
        border: "0px solid",
        borderRadius: 0, // Sharp corners
        transition: "all 0.3s ease",
      }}
    >
        <ReportTitle title={title} />
        <Segmented
            style={{ width: '100%' }}
            options={Object.values(RELATIVE_DATE_OPTIONS)}
            value={selectedDateFilter}
            onChange={(value) => {
                setSelectedDateFilter(value)
            }}
        />
        {
            selectedDateFilter === RELATIVE_DATE_OPTIONS.CUSTOM ?
                <DatePicker.RangePicker defaultValue={[initialDates, initialDates]} onChange={modifyDates} /> :
                <></>
        }
        {
            loading ? <Flex justify='center' align='center' flex={1}>
                <Spin size='small' style={{ margin: 20 }} />
            </Flex> : <>
                {/* <Gauge
                    width={350}
                    height={250}
                    autoFit={true}
                    data={data}
                    percent={data?.target || 0} // Assuming data contains a value for the gauge
                    legend={false}
                    tooltip={{
                        showTitle: false,
                        formatter: (datum) => {
                            console.log({ datum });
                            return { name: 'Amount', value: getFormattedAmount(datum.percent) };
                        },
                    }}
                    scale={{
                        color: {
                            range: ['#F4664A', '#e1e1e1'],
                        },
                    }}
                    style={{ margin: 20 }}
                    interaction={[
                        {
                            type: 'active-region',
                            enable: false
                        }
                    ]}
                /> */}
                <ReactGaugeChart id="gauge-chart8"
                    value={data?.target || 0}
                    // pointer={{
                    //     type: 'needle',
                    //     color: '#000',
                    //     animate: true,
                    //     elastic: false,
                    //     width: 10
                    // }}
                    arc={{
                        emptyColor: "#dedede",
                        subArcs: [
                            {
                                limit: 20,
                                color: '#F5382C',
                                showTick: true
                            },
                            {
                                limit: 40,
                                color: '#F5382C',
                                showTick: true
                            },
                            {
                                limit: 60,
                                color: '#F5382C',
                                showTick: true
                            },
                            {
                                limit: 100,
                                color: '#F5382C',
                                showTick: true
                            },
                        ]
                    }}
                    labels={{
                        valueLabel: {
                            maxDecimalDigits: 2,
                            style: { fontSize: "15px", fill: "#000", textShadow: "none" },
                            matchColorWithArc: true
                        }
                    }}
                />
            </>
        }
    </Space>;
};

export default GaugeChart;
