import { Button, Flex, Form, Input, Modal, Spin } from 'antd'
import axios from 'axios';
import { useNotificationContext } from 'Config/NotificationsContext';
import React, { useEffect, useState } from 'react'
import { asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRecords, resetFormModeToNone } from 'Redux/MasterReducer/crudSlices';

const CloseSessionModal = ({ closeSessionModal, setCloseSessionModal, apiUrl, extraApiParamsState, searchParams, setDrawerComponentOpen }) => {

    const openNotification = useNotificationContext();
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const dispatch = useDispatch();

    const [confirmPasswordReqStatus, setConfirmPasswordReqStatus] = useState(null);
    const [fetchClosingBalanceReqStatus, setFetchClosingBalanceReqStatus] = useState(null);
    const [editRecordStatus, setEditRecordStatus] = useState(null);
    const [password, setPassword] = useState("");
    const [closingBalance, setClosingBalance] = useState(0);

    const [form] = Form.useForm();

    useEffect(()=>{
        const fetchClosingBalance = async () => {
            try {
                setFetchClosingBalanceReqStatus(asyncStatuses.LOADING);
                const response = await axios.get(`/theatre/rest/cashier_sessions/${currentEditViewFormId}/closing_balance/`);
                console.log(response?.data)
                if (response?.data?.closing_cash_balance) {
                    setClosingBalance(response?.data.closing_cash_balance);
                }
                setFetchClosingBalanceReqStatus(asyncStatuses.SUCCESS);
            } catch (err) {
                console.error(err);
                if (err?.response?.data?.error) {
                    openNotification("Failed to fetch closing balance", err.response.data.error, "error");
                }
                setFetchClosingBalanceReqStatus(asyncStatuses.FAILED);
            }
        }

        if (currentEditViewFormId) {
            fetchClosingBalance();
        }
    }, [currentEditViewFormId, openNotification]);

    const onConfirmPasswordFinish = async (val) => {
        setConfirmPasswordReqStatus(asyncStatuses.LOADING);
        try {
            const response = await axios.post('/user/confirm-password/', { password: password });
            console.log({ response });
            if (response.data?.message) {
                openNotification(response.data.message, null, "success");
                setConfirmPasswordReqStatus(asyncStatuses.SUCCESS);
                await closeSession();
            }
        } catch (err) {
            setConfirmPasswordReqStatus(asyncStatuses.FAILED);
            if (err.response?.data?.password) {
                openNotification("Incorrect Password", null, "error");
            }
            console.log(err);
        }
    }

    const closeSession = async () => {
        try {
            setEditRecordStatus(asyncStatuses.LOADING);
            const response = await axios.patch(`/theatre/rest/cashier_sessions/${currentEditViewFormId}/close_session/`);
            console.log(response?.data)
            if (response?.data?.message) {
                openNotification(response.data.message, null, "info");
            }
            setEditRecordStatus(asyncStatuses.SUCCESS);
            setCloseSessionModal(false);
            dispatch(resetFormModeToNone());
            form.resetFields();
            dispatch(fetchAllRecords({ apiUrl, extraApiParams: extraApiParamsState, searchParams, pageNumber: 1 }));
            if (typeof setDrawerComponentOpen === 'function') {
                setDrawerComponentOpen(false);
            }
        } catch (err) {
            console.error(err);
            if (err?.response?.data?.error) {
                openNotification("Failed to close the session", err.response.data.error, "error");
            }
            setEditRecordStatus(asyncStatuses.FAILED);
        }
    }

    return (
        <>
            <Modal
                title={"Enter password to close this session."}
                centered
                open={closeSessionModal}
                onOk={() => setCloseSessionModal(false)}
                onCancel={() => setCloseSessionModal(false)}
                footer={null}
            >
                <Flex gap={10}>
                    <div>Closing Balance: </div>
                    <div>MUR {fetchClosingBalanceReqStatus === asyncStatuses.LOADING ? <Spin size='small' /> : closingBalance?.toFixed(2)}</div>
                </Flex>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onConfirmPasswordFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    style={{
                        marginTop: 20
                    }}
                    form={form}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password value={password} onChange={({ target: { value } }) => setPassword(value)} placeholder='password'/>
                    </Form.Item>
                    <Flex align='center' justify='end' gap={20}>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                disabled={
                                    (
                                        confirmPasswordReqStatus === asyncStatuses.LOADING 
                                        || editRecordStatus === asyncStatuses.LOADING
                                        || fetchClosingBalanceReqStatus !== asyncStatuses.SUCCESS

                                    ) ? true : false}
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%" }}
                            >
                                {
                                    confirmPasswordReqStatus === asyncStatuses.LOADING ? <>
                                        <LoadingOutlined /> Verifying your password
                                    </> :
                                        confirmPasswordReqStatus === asyncStatuses.FAILED || confirmPasswordReqStatus === null ? <>
                                            Verify
                                        </> : editRecordStatus === asyncStatuses.LOADING ? "Updating the record now" : editRecordStatus === asyncStatuses.FAILED ? "Verify" : "Verify"
                                }
                            </Button>
                        </Form.Item>
                    </Flex>
                </Form>

            </Modal>
        </>
    )
}

export default CloseSessionModal