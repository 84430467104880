import CustomTable from "Components/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/POSSessions/AddEditForm";
import { useParams } from "react-router-dom";
import { Checkbox } from "antd";
import getPermittedColumns from "Utils/getPermittedColumns";
import moment from "moment-timezone";
import { getFormattedAmount } from "Utils/textFormat";

const apiUrl = "/theatre/rest/cashier_sessions";
const headerTitle = "POS Sessions";
const editPermission = "possessions:edit";
const deletePermission = Math.random().toString();
// const deletePermission = "possessions:delete";
// const addPermission = "possessions:add";
const addPermission = Math.random().toString();

const columns = [
    {
        title: 'Cashier',
        dataIndex: 'cashier',
        key: 'cashier',
        render: (text, row) => row?.cashier_data?.username || text,
    },
    {
        title: 'Start Device',
        dataIndex: 'start_device',
        key: 'start_device',
        render: (text, row) => row?.start_device_data?.device_name || text,
    },
    {
        title: 'Start Time',
        dataIndex: 'start_time',
        key: 'start_time',
        render: (text) => moment(text).format("DD MMM yyyy, hh:mm a"),
        align: "right"
    },
    {
        title: 'End Time',
        dataIndex: 'end_time',
        key: 'end_time',
        render: (text) => text ? moment(text).format("DD MMM yyyy, hh:mm a") : text,
        align: "right"
    },
    {
        title: 'End Device',
        dataIndex: 'end_device',
        key: 'end_device',
        render: (text, row) => row?.end_device_data?.device_name || text,
    },
    {
        title: 'Opening Bal',
        dataIndex: 'opening_balance',
        key: 'opening_balance',
        // render: (text) => <a>{text}</a>,
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'Closing Bal',
        dataIndex: 'closing_balance',
        key: 'closing_balance',
        // render: (text) => <a>{text}</a>,
        align: "right",
        render: (text) => getFormattedAmount(text),
    },
    {
        title: 'Session Check Required',
        dataIndex: 'session_check_required',
        key: 'session_check_required',
        render: (text, row) => {
            return <Checkbox disabled checked={row.session_check_required}></Checkbox>
        }
    },
    {
        title: 'Is Active?',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (text, row) => {
            return <Checkbox disabled checked={row.is_active}></Checkbox>
        }
    },
    {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        width: 150,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const POSSessionsPage = () => {

    const params = useParams();
    const { id } = params;

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);
    
    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            extraApiParams={{theatre: id}}
            headerTitle={headerTitle}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default POSSessionsPage;