import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Checkbox, Select, Flex, Col, Row, DatePicker, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import { resetProgramSlice } from 'Redux/ProgramReducer/programSlice';
import { validatePositiveInteger } from 'Utils/formValidators';
import AvailalbleLogos from './AvailalbleLogos';
import AvailableBackdrops from './AvailableBackdrops';
import PosterImage from './PosterImage';
import axios from 'axios';

export const ClientProgramApiUrl = `/program/rest/client-programs`;

export const TMDB_IMAGE_BASE_URL = "https://media.themoviedb.org/t/p/original";

const AddEditForm = ({ apiUrl = ClientProgramApiUrl, genresList, distributorOptions, certificationOptions }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    
    const [imageBase64, setImageBase64] = useState(null);
    const [newDefaultImage, setNewDefaultImage] = useState(null);
    const [releaseDate, setReleaseDate] = useState('');
    const [selectedGenres, setSelectedGenres] = useState(null);

    const [availableBackdrops, setAvailableBackdrops] = useState(null);
    const [selectedBackdropPath, setSelectedBackdropPath] = useState(null);

    const [availableLogos, setAvailableLogos] = useState(null);
    const [selectedLogoPath, setSelectedLogoPath] = useState(null);

    

    const modifyReleaseDate = (v) => {
        setReleaseDate(v);
    }

    useEffect(() => {
        if (currentRecordData.release_date) {
            setReleaseDate(dayjs(currentRecordData.release_date));
        }
    }, [currentRecordData.release_date]);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const validateTwoDecimalInteger = (rule, value) => {
        const numericValue = Number(value);
        if (isNaN(numericValue) || numericValue < 0) {
            return Promise.reject('It must be a non-negative numeric value.');
        }
        if (numericValue > 10) {
            return Promise.reject('It cannot be more than 10.');
        }

        const stringValue = String(value);
        const decimalIndex = stringValue.indexOf('.');
        const numberOfDigitsBeforeDecimal = decimalIndex === -1 ? stringValue.length : decimalIndex;

        if (numberOfDigitsBeforeDecimal > 2) {
            return Promise.reject('It can have at most 10 digits.');
        }

        const numberOfDecimalPoints = decimalIndex === -1 ? 0 : stringValue.length - decimalIndex - 1;
        if (numberOfDecimalPoints > 3) {
            return Promise.reject('It can have at most 3 decimal points.');
        }

        return Promise.resolve();
    };

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
            setImageBase64(null);
            setNewDefaultImage(null);
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            dispatch(resetProgramSlice());
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
            dispatch(resetProgramSlice());
        }
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let backdrops = currentRecordData?.all_images?.backdrops;
        if (!backdrops) {
            backdrops = currentRecordData?.program_data?.images?.backdrops;
        }
        console.log({ backdrops });
        if (Array.isArray(backdrops) && backdrops.length > 0) {
            setAvailableBackdrops(backdrops);
            let x = currentRecordData.backdrop_image;
            if (x) {
                let y = backdrops?.filter(r => x.includes(r.file_path));
                if (y.length > 0) {
                    let alreadySetBackdrop = y[0];
                    setSelectedBackdropPath(alreadySetBackdrop);
                    setAvailableBackdrops([...backdrops?.filter(r => x.includes(r.file_path)), ...backdrops?.filter(r => !x.includes(r.file_path))]);
                } else if (currentRecordData.backdrop_image) {
                    let bi = {
                        file_path: currentRecordData.backdrop_image.replace?.(`${axios.defaults.baseURL}`, ""),
                        is_uploaded: true
                    }
                    setAvailableBackdrops(prev => ([bi, ...backdrops]));
                    setSelectedBackdropPath(bi);
                } else {
                    setSelectedBackdropPath(backdrops[0]);
                }
            } else {
                setSelectedBackdropPath(backdrops[0]);
            }
        } else if (currentRecordData?.backdrop_image) {
            let bi = {
                file_path: currentRecordData.backdrop_image?.replace(`${axios.defaults.baseURL}`, ''),
                is_uploaded: true
            }
            setAvailableBackdrops([bi]);
            setSelectedBackdropPath(bi);
        } else {
            setAvailableBackdrops(null);
        }

        let logos = currentRecordData?.all_images?.logos;
        if (!logos) {
            logos = currentRecordData?.program_data?.images?.logos;
        }
        console.log({ logos });
        if (Array.isArray(logos) && logos.length > 0) {
            setAvailableLogos(logos);
            let x = currentRecordData.logo_image;
            if (x) {
                let y = logos?.filter(r => x.includes(r.file_path));
                if (y.length > 0) {
                    let alreadySetLogo = y[0];
                    setSelectedLogoPath(alreadySetLogo);
                    setAvailableLogos([...logos?.filter(r => x.includes(r.file_path)), ...logos?.filter(r => !x.includes(r.file_path))]);
                } else {
                    setSelectedLogoPath(logos[0]);
                }
            } else if (currentRecordData.logo_image) {
                let bi = {
                    file_path: currentRecordData.logo_image.replace?.(`${axios.defaults.baseURL}`, ""),
                    is_uploaded: true
                }
                setAvailableLogos(prev => ([bi, ...logos]));
                setSelectedBackdropPath(bi);
            } else {
                setSelectedLogoPath(logos[0]);
            }
        } else if (currentRecordData?.logo_image) {
            let lo = {
                file_path: currentRecordData.logo_image?.replace(`${axios.defaults.baseURL}`, ''),
                is_uploaded: true
            }
            setAvailableLogos([lo]);
            setSelectedLogoPath(lo);
        } else {
            setAvailableLogos(null);
        }
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            const genresAlreadySet = currentRecordData?.genres?.map?.(s => {
                // console.log({ s, genresList });
                let _s = {}
                let id = genresList?.filter?.(r => r.value === s.id);
                if (id?.length > 0) {
                    id = id[0];
                    _s["label"] = id.label;
                    _s["value"] = s.id;
                }
                // console.log({ _s, id });            
                return _s;
            });
            console.log({ genresAlreadySet });
            setSelectedGenres(genresAlreadySet);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            console.log({ "currentRecordData?.genres": currentRecordData?.genres, genresList });
            const genresAlreadySet = currentRecordData?.genres?.map?.(s => {
                console.log({ s, genresList });
                let _s = {}
                let id = genresList?.filter?.(r => r.value === s.tmdb_id);
                if (id?.length > 0) {
                    id = id[0];
                    _s["label"] = id.label;
                    _s["value"] = s.tmdb_id;
                }
                console.log({ _s, id });
                return _s;
            });
            console.log({ genresAlreadySet });
            setSelectedGenres(genresAlreadySet);
        } else {
            setSelectedGenres([]);
        }
    }, [genresList, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        // console.log({selectedBackdropPath})
        if (selectedBackdropPath?.is_uploaded) {
            delete val.backdrop_image;
            val.backdrop_path_string = axios.defaults.baseURL + selectedBackdropPath.file_path.replace("/mcine", "");
        } else if (selectedBackdropPath) {
            delete val.backdrop_image;
            val.backdrop_path_string = TMDB_IMAGE_BASE_URL + selectedBackdropPath?.file_path;
        }

        if (selectedLogoPath?.is_uploaded) {
            delete val.logo_image;
            val.logo_path_string = axios.defaults.baseURL + selectedLogoPath.file_path.replace("/mcine", "");
        } else if (selectedLogoPath) {
            delete val.logo_image;
            val.logo_path_string = TMDB_IMAGE_BASE_URL + selectedLogoPath?.file_path;
        }
        
        console.log({ val });
        val.release_date = releaseDate.format('YYYY-MM-DD');
        let headers = null;
        if (imageBase64) {
            val.poster_image = newDefaultImage;
            headers = {
                "Content-Type": "multipart/form-data"
            }
        } else if (currentRecordData.poster_image) {
            delete val.poster_image;
            val.poster_path_string = currentRecordData.poster_image;
        }

        val.genres = selectedGenres?.map?.(r => r.value);
        dispatch(addARecord({ apiUrl: apiUrl, data: val, headers }));
    }

    const edit = (val) => {
        // console.log(val);
        val.release_date = releaseDate.format('YYYY-MM-DD');
        let headers = null;
        if (imageBase64) {
            val.poster_image = newDefaultImage;
            headers = {
                "Content-Type": "multipart/form-data"
            }
        } else if (currentRecordData.poster_image) {
            delete val.poster_image;
            val.poster_path_string = currentRecordData.poster_image;
        }

        if (selectedBackdropPath?.file_path?.includes?.(currentRecordData?.backdrop_image)) {
            delete val.backdrop_image;
        } else {
            delete val.backdrop_image;
            console.log({ selectedBackdropPath })
            if (selectedBackdropPath?.is_uploaded) {
                val.backdrop_path_string = axios.defaults.baseURL + selectedBackdropPath?.file_path?.replace?.("/mcine", "");
            } else if (selectedBackdropPath?.file_path) {
                val.backdrop_path_string = TMDB_IMAGE_BASE_URL + selectedBackdropPath.file_path;
            } else {
                delete val.backdrop_path_string;
            }
        }

        if (selectedLogoPath?.file_path?.includes?.(currentRecordData?.logo_image)) {
            delete val.logo_image;
        } else {
            delete val.logo_image;
            if (selectedLogoPath?.is_uploaded) {
                val.logo_path_string = axios.defaults.baseURL + selectedLogoPath?.file_path?.replace?.("/mcine", "");
            } else if (selectedLogoPath?.file_path) {
                val.logo_path_string = TMDB_IMAGE_BASE_URL + selectedLogoPath.file_path;
            } else {
                delete val.logo_path_string;
            }
        }

        val.genres = selectedGenres?.map?.(r => r.value);
        dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val, headers }));
    }

    const changeSelectedBackdropPath = (img, e) => {
        console.log(e);
        if (e?.target?.getAttribute?.('data-click-select') === "selector") {
            setSelectedBackdropPath(img);
        }
    }

    const changeSelectedLogoPath = (img, e) => {
        console.log({ e, img })
        if (e?.target?.getAttribute?.('data-click-select') === "selector") {
            setSelectedLogoPath(img);
        }
    }

    const onFinish = async (val) => {
        val.show_certification = !!val.show_certification;
        val.show_rating = !!val.show_rating;
        console.log({val});
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }

    const onSelectGenresChange = function (value, option) {
        console.log({ value, option });
        setSelectedGenres(prev => {
            let r = JSON.parse(JSON.stringify(prev || []))
            if (Array.isArray(r)) {
                r.push(option);
            } else {
                r = []
                r.push(option);
            }
            return r;
        })
    }

    const onDeselectGenresChange = function (value, option) {
        console.log({ value, option, selectedGenres });
        let ss = selectedGenres && JSON.parse(JSON.stringify(selectedGenres));
        if (Array.isArray(ss)) {
            // 
        } else {
            ss = [];
        }
        ss = ss.filter(r => r.value !== value);
        setSelectedGenres(ss);
    }

    return (
        <Flex justify='center' style={{ width: "100%", marginBottom: 30 }} className='fade-enter fade-enter-active'>
            {contextHolder}
            <Form layout="vertical"
                requiredMark={true}
                onFinish={onFinish}
                autoComplete="off"
                style={{ width: "100%", padding: "0px 10px" }}
                form={form}
                disabled={currentFormMode === FormModesEnum.VIEW}
            >
                <Row gutter={15}>
                    <Col span={12}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter title',
                                },
                                {
                                    max: 255,
                                    message: "Max length should be 255"
                                }
                            ]}
                        >
                            <Input showCount placeholder="Please enter title" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="rating"
                            label="Rating"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter Rating',
                                },
                                { validator: validateTwoDecimalInteger },
                            ]}
                        >
                            <Input type='number' placeholder="max is 10" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="runtime"
                            label="Runtime (mins)"
                            rules={[
                                { required: true, message: 'Runtime is required.' },
                                { validator: validatePositiveInteger },
                            ]}
                        >
                            <Input type="number" placeholder='in minutes' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col span={12}>
                        <Form.Item
                            name="certification"
                            label="Certification"
                            rules={[
                                {
                                    required: true,
                                    message: "Certification is required"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                loading={Array.isArray(certificationOptions) ? false : true}
                                options={certificationOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="show_certification"
                            label="Show Certification?"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="show_rating"
                            label="Show Rating?"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={15}>
                    <Col span={12}>
                        <Form.Item
                            name="trailer_link"
                            label="Trailer Link"
                            rules={[
                                {
                                    required: false,
                                },
                                {
                                    type: 'url',
                                    message: "This is not a valid URL",
                                },
                            ]}
                        >
                            <Input placeholder="Link to the trailer" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Release Date"
                        >
                            <DatePicker style={{ width: "100%" }} value={releaseDate} onChange={modifyReleaseDate} format={'YYYY-MM-DD'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="distributor"
                            label="Distributor"
                            rules={[
                                {
                                    required: true,
                                    message: "Distributor is required"
                                }
                            ]}
                        >
                            <Select
                                showSearch
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                loading={Array.isArray(distributorOptions) ? false : true}
                                options={distributorOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Genres"
                        // name="genres"
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                value={selectedGenres}
                                placeholder="Choose genres"
                                options={genresList}
                                onSelect={onSelectGenresChange}
                                onDeselect={onDeselectGenresChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="overview"
                            label="Overview"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter Overview',
                                },
                            ]}
                        >
                            <Input.TextArea allowClear placeholder="A small Overview of the movie" rows={5} />
                        </Form.Item>
                        <Form.Item
                            name="tmdb_id"
                            hidden
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <PosterImage
                    imageBase64={imageBase64}
                    setImageBase64={setImageBase64}
                    setNewDefaultImage={setNewDefaultImage}
                />
                <AvailableBackdrops
                    availableBackdrops={availableBackdrops}
                    setAvailableBackdrops={setAvailableBackdrops}
                    selectedBackdropPath={selectedBackdropPath}
                    changeSelectedBackdropPath={changeSelectedBackdropPath}
                />
                <AvailalbleLogos
                    availableLogos={availableLogos}
                    setAvailableLogos={setAvailableLogos}
                    selectedLogoPath={selectedLogoPath}
                    changeSelectedLogoPath={changeSelectedLogoPath}
                />
                {
                    currentFormMode === FormModesEnum.ADD ? <Form.Item>
                        {
                            addRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled style={{ marginBottom: 20 }}>
                                    <LoadingOutlined />
                                    Submitting
                                </Button> :
                                <Button type="primary" htmlType="submit" style={{ marginBottom: 20 }}>
                                    Submit
                                </Button>
                        }
                    </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                        {
                            editRecordStatus === asyncStatuses.LOADING ?
                                <Button type="primary" htmlType="submit" disabled style={{ marginBottom: 20 }}>
                                    <LoadingOutlined />
                                    Updating
                                </Button> :
                                <Button type="primary" htmlType="submit" style={{ marginBottom: 20 }}>
                                    Update
                                </Button>
                        }
                    </Form.Item> : <></>
                }

            </Form >
        </Flex>
    );
};
export default AddEditForm;