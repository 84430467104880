export const getFormattedAmount = (amt) => {
    const formatted = Number(isNaN(amt) ? 0 : amt).toLocaleString("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 });
    // console.log({formatted});
    return "Rs " + formatted;
}

export const getFormattedNumber = (amt) => {
    const formatted = Number(isNaN(amt) ? 0 : amt).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    // console.log({formatted});
    return formatted;
}