import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Notifications/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { Image } from "antd";
import { useSelector } from "react-redux";
import allowedFiltersEnum from "Utils/allowedFiltersEnum";
import { landscapePlaceholder } from "Assets";

const apiUrl = "/notifications/get-notifications";
const headerTitle = "Notifications";
const editPermission = null;
const deletePermission = null;
const addPermission = "notifications:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: "100px",
        render: (text) => {
            console.log({text})
            return (
                <>
                    {
                        text && <Image.PreviewGroup
                            items={[
                                text
                            ]}
                        >
                            <Image
                                src={text}
                                width={100}
                                fallback={landscapePlaceholder}
                                style={{ objectFit: "contain", width: 85 }}
                            />
                        </Image.PreviewGroup>
                    }
                </>
            )
        },
    },
    {
        title: 'Type',
        dataIndex: 'notification_type',
        key: 'notification_type',
    },
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, row) => row?.user_data?.username || text,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: false, action: true })
]

const NotificationsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
        filters={[allowedFiltersEnum.USER.user, allowedFiltersEnum.NOTIFICATION.notification_type]}
    />;
}

export default NotificationsPage;