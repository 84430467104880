import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchClientList, fetchCityList, fetchStateList, fetchCountryList } from 'Api/commonApis';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [clientOptions, setClientOptions] = useState(null);

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    useEffect(() => {
        const fetchClientOptions = async () => {
            try {
                const list = await fetchClientList();
                if (Array.isArray(list)) {
                    if (list.length > 0) {
                        const options = list.map(l => ({ value: l.id, label: l.title }))
                        setClientOptions(options);
                    } else {
                        openNotification("No Clients Found", "Please enter clients first.", "error");
                    }
                } else {
                    throw new Error("Fetch Clients did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Client Options", err.message, "error");
                } else {
                    openNotification("Client Options", "Something went wrong while fetching client options", "error")
                }
            }
        }
        fetchClientOptions();
    }, []);

    const [cityOptions, setCityOptions] = useState(null);
    const [stateOptions, setStateOptions] = useState(null);
    const [countryOptions, setCountryOptions] = useState(null);

    const fetchCityOptions = async () => {
        try {
            const list = await fetchCityList();
            if (Array.isArray(list)) {
                const options = list.map(l => ({ value: l.id, label: l.title }))
                setCityOptions(options);
            } else {
                throw new Error("Fetch Cities did not return a list");
            }
        } catch (err) {
            console.log({ err })
            if (err.message) {
                openNotification("City Options", err.message, "error");
            } else {
                openNotification("City Options", "Something went wrong while fetching city options", "error")
            }
        }
    }

    const fetchStateOptions = async () => {
        try {
            const list = await fetchStateList();
            if (Array.isArray(list)) {
                const options = list.map(l => ({ value: l.id, label: l.title }))
                setStateOptions(options);
            } else {
                throw new Error("Fetch States did not return a list");
            }
        } catch (err) {
            console.log({ err })
            if (err.message) {
                openNotification("State Options", err.message, "error");
            } else {
                openNotification("State Options", "Something went wrong while fetching state options", "error")
            }
        }
    }

    const fetchCountryOptions = async () => {
        try {
            const list = await fetchCountryList();
            if (Array.isArray(list)) {
                const options = list.map(l => ({ value: l.id, label: l.title }))
                setCountryOptions(options);
            } else {
                throw new Error("Fetch Countries did not return a list");
            }
        } catch (err) {
            console.log({ err })
            if (err.message) {
                openNotification("Country Options", err.message, "error");
            } else {
                openNotification("Country Options", "Something went wrong while fetching country options", "error")
            }
        }
    }

    useEffect(() => {
        fetchCityOptions();
        fetchStateOptions();
        fetchCountryOptions();
    }, [])

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (editRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.SUCCESS) {
            form.resetFields();
        } else if (addRecordStatus === asyncStatuses.FAILED) {
            // 
        } else if (editRecordStatus === asyncStatuses.FAILED) {
            // 
        } else {
            form.resetFields();
        }
        return form.resetFields();
    }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        console.log({ currentRecordData });
        form.setFieldsValue(currentRecordData);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        // console.log(val);
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        let url = apiUrl?.split?.("?")[0];
        // console.log({ apiUrl: url, id: currentEditViewFormId, data: val });
        dispatch(editARecord({ apiUrl: url, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }
    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable
                thisModel="Theatres"
                requiredData={[
                    { data: cityOptions, field: "city" },
                    { data: stateOptions, field: "state" },
                    { data: countryOptions, field: "country" },
                    { data: clientOptions, field: "client" }]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW || !(Array.isArray(clientOptions) && clientOptions.length > 0)}
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter title',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter title" />
                    </Form.Item>
                    <Form.Item
                        name="host_url"
                        label="Host URL"
                        rules={[
                            {
                                required: false,
                                message: 'Please enter Host URL',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter Host URL" />
                    </Form.Item>
                    <Form.Item
                        name="client"
                        label="Client"
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(clientOptions) ? false : true}
                            options={clientOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Address',
                            },
                        ]}
                    >
                        <Input.TextArea allowClear placeholder="Please enter Address" rows={7} />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        label="City"
                        rules={[
                            {
                                required: true,
                                message: 'Please select city',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(cityOptions) ? false : true}
                            options={cityOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        label="State"
                        rules={[
                            {
                                required: true,
                                message: 'Please select state',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(stateOptions) ? false : true}
                            options={stateOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                            {
                                required: true,
                                message: 'Please select country',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(countryOptions) ? false : true}
                            options={countryOptions}
                        />
                    </Form.Item>

                    <Row gutter={8}>
                        <Col span={24}>
                            <h3>Contact Us Page Information</h3>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Support Email"
                                name="support_email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Invalid email format!',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Support Mobile"
                                name="support_mobile"
                                rules={[
                                    
                                ]}
                            >
                                <Input placeholder="Please enter mobile" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Google Maps Embed URL"
                                name="google_maps_embed_url"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: false,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form >
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;