import CustomTable from "Components/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RenderCreated, RenderUpdated, RenderActionButtons, getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Theatres/SeatType/AddEditForm";
import { Checkbox, Flex } from "antd";
import { useParams } from "react-router";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/theatre/rest/seat-type";
const headerTitle = "Theatre Seat Types";
const editPermission = "seat-type:edit";
const deletePermission = "seat-type:delete";
const addPermission = "seat-type:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        render: (text, row) => {
            return <Flex gap={5}>
                <div style={{ borderRadius: 5, width: 25, height: 25, backgroundColor: row.color }}></div>
                <span>{row.color}</span>
            </Flex>
        }
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const TheatreSeatTypesPage = () => {

    const params = useParams();
    const { id } = params;

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <>
        <CustomTable
            columns={_columns}
            apiUrl={apiUrl}
            headerTitle={headerTitle}
            extraApiParams={{ theatre: id }}
            MyFormComponent={AddEditForm}
            addPermissionNames={[addPermission]}
        />
    </>;
}

export default TheatreSeatTypesPage;