import ReportTitle from 'Components/Dashboard/ReportTitle';
import SelectDateForReports from 'Components/Dashboard/Reports/Filters/SelectDateForReports';
import TheatreFilterForReports from 'Components/Dashboard/Reports/Filters/TheatreFilterForReports';
import { useNotificationContext } from 'Config/NotificationsContext';
import { SELECTED_DATE_FORMAT } from 'Redux/ShowReducer/showSlices';
import { getFormattedAmount } from 'Utils/textFormat';
import { DatePicker, Flex, Table } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ExportXlsx from './ExportXlsx';
const { RangePicker } = DatePicker;

const API_URL = "/reports/user-onboarding-report/";
const initialDates = dayjs();

const tableColumns = [
    {
        title: '',
        dataIndex: 'particular',
        key: 'particular',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        align: "center",
    },
]

const UserOnboardingReport = () => {

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(initialDates);
    const [endDate, setEndDate] = useState(initialDates);
    const [loading, setLoading] = useState(false);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const payload = {}
                payload['start_date'] = startDate.format(SELECTED_DATE_FORMAT);
                payload['end_date'] = endDate.format(SELECTED_DATE_FORMAT);
                // console.log(payload)
                const response = await axios.post(API_URL, payload);
                // console.log({ response });
                if (response?.data?.data) setData(response.data?.data?.map((a, index) => ({ ...a, key: index })));
                else {
                    setData([]);
                    openNotification("Data Format Unrecognized", null, "error");
                }
                setLoading(false);
            } catch (e) {
                openNotification("Error occurred", `Failed to fetch User Onboarding Report data. ${e.response?.data?.message || ''}`, "error");
                console.error(e);
                setLoading(false);
            }
        }
        fetchData();
    }, [startDate, endDate]);

    return (
        <>
            <Flex style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
                position: "absolute",
                top: 10,
                right: 22,
                zIndex: 999
            }}>
                <ExportXlsx
                    apiUrl={`${API_URL}export/`}
                    payload={{
                        start_date: startDate.format(SELECTED_DATE_FORMAT),
                        end_date: endDate.format(SELECTED_DATE_FORMAT),
                    }}
                    reportName={'useronboarding_report'}
                    reportTitleForNotification={'User Onboarding Report'}
                />
                {/* <ExportPdf report_name='Movie Analysis Report' /> */}
            </Flex>
            <Flex align='start' vertical style={{ width: "100%", padding: 10 }} gap={10}>
                <Flex justify='space-between' style={{ width: "100%", marginTop: 20 }}>
                    <ReportTitle title={`User Onboarding Report`} />
                    <Flex gap={15}>
                        <SelectDateForReports startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    </Flex>
                </Flex>

                <Flex>
                    <Table
                        columns={tableColumns}
                        bordered
                        dataSource={data}
                        size='small'
                        scrollToFirstRowOnChange
                        stickyScrollBarBg={'rgba(0, 0, 0, 1)'}
                        loading={loading}
                        scroll={{
                            y: "65vh",
                            x: "100%"
                        }}
                        pagination={false}
                        summary={pageData => {
                            let _total = 0;
                            pageData.forEach(({ total }) => {
                                _total += total;
                            });

                            return (
                                <>
                                    <Table.Summary.Row >
                                        <Table.Summary.Cell className='table-summary-cell'>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell className='table-summary-cell' align='center'>
                                            <p style={{ margin: 0 }}>{_total}</p>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    />
                </Flex>
            </Flex>
        </>
    )
}

export default UserOnboardingReport