import CustomTable from "Components/CustomTable/CustomTable";
import {useSelector } from "react-redux";
import { getCommonColumns } from "Components/CustomTable/Commons";
import AddEditForm from "Components/Forms/Clients/Certifications/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";

const apiUrl = "/user/rest/certifications";
const headerTitle = "Certifications";
const editPermission = "certifications:edit";
const deletePermission = "certifications:delete";
const addPermission = "certifications:add";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        render: (_, row) => row?.client_data?.username || "-",
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const CetificationsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default CetificationsPage;