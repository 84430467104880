import React from "react";
import { Tag } from "antd";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { Flex, Image } from "antd";

const ShowDetailsVertical = ({
    title,
    image,
    language,
    theatre,
    showDate,
    startTime,
    formatType,
    screen,
}) => {

    const large = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <Flex vertical justify="start">
            <Image
                src={image}
                alt="movie image"
                className={`${large ? "w-75" : "w-25"} img-fluid mb-4 mb-md-0 mt-3 mt-md-0 rounded`}
                style={{ borderRadius: 10 }}
            />
            <Flex vertical justify="center" style={{marginRight: "0.75rem"}}>
                <div style={{marginBottom: "0.5rem"}}>
                    <h4 style={{marginTop: "0.5rem"}}>{title}</h4>
                    <Tag style={{padding: "0.5rem", marginRight: "0.5rem"}}>
                        {formatType}
                    </Tag>
                    <Tag style={{padding: "0.5rem", marginRight: "0.5rem"}}>
                        {language?.title}
                    </Tag>
                </div>
                <Flex vertical gap={5}>
                    <Flex align="center" className="d-flex flex-column flex-md-row align-items-md-center text-feather mb-2">
                        {theatre} Theatre
                    </Flex>
                    <Flex align="center" className="d-flex flex-column flex-md-row align-items-md-center text-feather mb-2">
                        Screen : {screen}
                    </Flex>
                    <Flex align="center" className="d-flex flex-column flex-md-row align-items-md-center text-feather mb-2">
                        {moment(showDate).format('ddd')} {moment(showDate).format("DD MMM")}, {moment(startTime, "HH:mm:ss").format('hh:mm a')}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ShowDetailsVertical;