import { Button, Col, Flex, Row, Spin, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { Input } from 'antd';
import SearchResultCard from './SearchResultCard';
import { useDispatch, useSelector } from 'react-redux';
import { performSearchAsync, resetProgramSlice, setSearchTerm } from 'Redux/ProgramReducer/programSlice';
import { asyncStatuses } from 'Redux/enums';
import responseCodeMessages from 'Utils/responseCodeMessages.enum';
const { Search } = Input;

const RESULTS_LIMIT = 9;

const getIncreasedNumberOfResults = (searchResultsMovies, numberOfResultsToDisplay) => {
    let increasedNumber = numberOfResultsToDisplay + RESULTS_LIMIT;
    if (searchResultsMovies?.length < increasedNumber) {
        increasedNumber = searchResultsMovies.length;
    }
    console.log(increasedNumber);
    return increasedNumber;
}

const SearchForm = () => {

    const [notificationApi, contextHolder] = notification.useNotification();
    const [currentPage, setCurrentPage] = useState(1);

    const searchStatus = useSelector(store => store.program.searchStatus);
    const loadingMoreStatus = useSelector(store => store.program.searchLoadingMoreStatus);
    const searchTerm = useSelector(store => store.program.searchTerm);
    const searchResultsMovies = useSelector(store => store.program.searchResults?.results);
    const searchTotalPages = useSelector(store => store.program.searchResults?.total_pages);
    const searchTotalResults = useSelector(store => store.program.searchResults?.total_results);
    const searchStatusErrorMessage = useSelector(store => store.program.searchStatusErrorMessage);

    const dispatch = useDispatch();

    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const onSearchClick = (value) => {
        if (!value) {
            return;
        }
        setCurrentPage(1);
        dispatch(performSearchAsync({ value, page: 1 }));
    }

    const changeSearchTerm = ({ target: { value } }) => {
        dispatch(setSearchTerm(value));
    }

    const loadMore = () => {
        const nextPage = currentPage + 1;
        if (nextPage <= searchTotalPages) {
            setCurrentPage(nextPage);
            dispatch(performSearchAsync({ value: searchTerm, page: nextPage }));
        }
    }

    useEffect(() => {
        // infinite loading
        const debouncedScroll = () => {
            let timer = new Date();

            return (e) => {
                let now = new Date();
                let diff = now - timer;
                if (diff >= 1000) {
                    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
                    console.log({ bottom });

                    const getMoreResults = () => {
                        timer = new Date();
                        loadMore();
                    }

                    if (bottom) {
                        if (loadingMoreStatus !== asyncStatuses.LOADING) {
                            getMoreResults();
                        }
                    }
                }
            };
        }
        const handleScroll = debouncedScroll();

        const searchResultsContainer = document.querySelector('#search-results');
        console.log(searchResultsContainer)
        if (searchResultsContainer) {
            searchResultsContainer.addEventListener('scroll', handleScroll);
        }

        return () => searchResultsContainer?.removeEventListener('scroll', handleScroll);
    }, [searchResultsMovies]);

    useEffect(() => {
        if (searchStatus === asyncStatuses.FAILED) {
            if (searchStatusErrorMessage && searchStatusErrorMessage in responseCodeMessages) {
                openNotification(responseCodeMessages[searchStatusErrorMessage].title, responseCodeMessages[searchStatusErrorMessage].message, "error")
            } else {
                openNotification("Error occurred", searchStatusErrorMessage, "error");
            }
        }
    }, [searchStatus, searchStatusErrorMessage]);

    return (
        <>
            {contextHolder}
            <Flex vertical style={{ width: "100%", height: "100%" }} className='fade-enter fade-enter-active'>
                <Search
                    placeholder="Type a movie name"
                    enterButton="Search"
                    size="large"
                    value={searchTerm}
                    loading={searchStatus === asyncStatuses.LOADING}
                    style={{ paddingTop: 30, paddingBottom: 15 }}
                    onChange={changeSearchTerm}
                    onSearch={onSearchClick}
                    allowClear
                />
                {
                    (!searchResultsMovies || searchResultsMovies?.length === 0) ?
                        <>
                            <Flex vertical align='center' justify='center' flex={1}>
                                {
                                    (searchStatus === asyncStatuses.LOADING) ?
                                        <Spin /> : (!searchTerm || !searchResultsMovies) ?
                                            <p style={{ color: "gray" }}>
                                                Results will appear here after you press search</p> :
                                            <p style={{ color: "gray" }}>No Results Found</p>
                                }

                            </Flex>
                        </> :
                        <>
                            <small style={{ color: "gray", marginTop: 7, marginBottom: 8 }}>
                                Showing{" "}
                                {searchResultsMovies?.length}
                                {" "}of {searchTotalResults}
                            </small>
                            <Row gutter={[10, 10]} justify='start' wrap='wrap' flex={1} style={{ overflow: "auto", padding: "10px 0px" }} id="search-results">
                                {
                                    searchResultsMovies.map(
                                        (result, index) => {
                                            return <Col span={8} key={result.id}>
                                                <SearchResultCard currentRecordData={result} />
                                            </Col>
                                        }
                                    )
                                }
                                {
                                    <Flex align='center' justify='center' style={{ width: "100%" }}>
                                        {
                                            loadingMoreStatus === asyncStatuses.LOADING ?
                                                <Spin /> :
                                                <Button
                                                    size='small'
                                                    type='default'
                                                    onClick={loadMore}
                                                >
                                                    Show More
                                                </Button>
                                        }
                                    </Flex>
                                }
                            </Row>
                        </>
                }

            </Flex>
        </>
    )
}

export default SearchForm;