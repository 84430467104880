import React, { useEffect, useState } from 'react'
import { Flex, Select } from 'antd';
import { useNotificationContext } from 'Config/NotificationsContext';
import axios from 'axios';
import { fetchPaymentMethodChoices } from 'Api/commonApis';

const { Option } = Select;

const PaymentMethodFilterForReports = ({ paymentMethodFilterVal, setPaymentMethodFilterVal }) => {

    const openNotification = useNotificationContext();
    const [filterValues, setFilterValues] = useState(null);
    

    useEffect(() => {
        const fetchPaymentMethodOptions = async () => {
            try {
                const obj = await fetchPaymentMethodChoices();
                if (Array.isArray(obj)) {
                    const options = [];
                    obj.forEach(o => {
                        options.push({
                            value: o[0],
                            label: o[1]
                        })
                    })
                    setFilterValues(options);
                    setPaymentMethodFilterVal("all");
                } else {
                    throw new Error("Fetch Inventory Status did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Inventory Status Options", err.message, "error");
                } else {
                    openNotification("Inventory Status Options", "Something went wrong while fetching convenienceFeeType options", "error")
                }
            }
        }
        fetchPaymentMethodOptions();
    }, []);

    const onFilterSelect = (v) => {
        setPaymentMethodFilterVal(v);
    }

    return (
        <>
            <Flex justify='flex-start' align='center' gap={5}>
                <label>Payment Method</label>
                <Select
                    style={{ width: "150px" }}
                    placeholder="Choose a Payment Method"
                    loading={Array.isArray(filterValues) ? false : true}
                    onSelect={onFilterSelect}
                    value={paymentMethodFilterVal}
                >
                    <Option key={"all"} value={"all"}>All</Option>
                    {
                        filterValues?.map?.((afv, ind) => {
                            return <Option key={afv.value} value={afv.value}>{afv?.label}</Option>
                        })
                    }
                </Select>
            </Flex>
        </>
    )
}

export default PaymentMethodFilterForReports;