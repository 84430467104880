import { useNotificationContext } from 'Config/NotificationsContext';
import { Button, Flex, Spin, Table } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import UpcomingShowCard from './UpcomingShowCard';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';


const tableColumns = [
    {
        title: 'Theatre',
        dataIndex: 'theatre',
        key: 'theatre',
        render: (text, show) => `${show?.hall_data?.theatre_data?.title}`,
        sorter: (a, b) => a.hall_data?.theatre_data?.title?.localeCompare?.(b.hall_data?.theatre_data?.title),
    },
    {
        title: 'Hall',
        dataIndex: 'hall_no',
        key: 'hall_no',
        render: (text, show) => show?.hall_data?.hall_no,
        sorter: (a, b) => a.hall_data?.hall_no?.localeCompare?.(b.hall_data?.hall_no),
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, show) => show?.date,
        sorter: (a, b) => a.date?.localeCompare?.(b.date),
    },
    {
        title: 'Start Time',
        dataIndex: 'start_time',
        key: 'start_time',
        render: (text, show) => show?.start_time?.substring?.(0, 5),
        sorter: (a, b) => a.start_time?.localeCompare?.(b.start_time),
    },
    {
        title: "Actions",
        render: (_, show) =>
            <Flex vertical gap={5}>
                <Link target='_blank' to={`/book-show/${show?.client_program_data?.id}/seats/${show?.id}`}>
                    <Button type="default" size="small">
                        Book Ticket
                    </Button>
                </Link>
                <Link target='_blank' to={`/shows/${show?.id}/pricelayout`}>
                    <Button type="default" size="small">
                        Price Layout
                    </Button>
                </Link>
            </Flex>
    }
]


const addFiltersToTableColumns = (data) => {
    try {
        // add filters to Theatre Column
        const theatres = {};

        data?.forEach?.(d => {
            let t = d?.hall_data?.theatre_data?.title;
            theatres[t] = 1;
        });

        const theatreFilterValues = Object.keys(theatres)?.map(r => ({ text: r, value: r }));

        const theatreIndex = tableColumns?.findIndex(r => r.key === "theatre");

        tableColumns[theatreIndex]['filters'] = theatreFilterValues;
        tableColumns[theatreIndex]['onFilter'] = (value, record) => record?.hall_data?.theatre_data?.title.indexOf(value) === 0;
    } catch (err) {
        console.log(err);
    }
}


const UpcomingShows = () => {

    const [upcomingShows, setUpcomingShows] = useState(null);
    const [upcomingShowsLoading, setUpcomingShowsLoading] = useState(false);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    const openNotification = useNotificationContext();

    useEffect(() => {
        const fetchUpcomingShows = async () => {
            try {
                setUpcomingShowsLoading(true);
                const response = await axios.post('/program/rest/shows/upcoming_shows_for_backoffice_only/', { program_id: currentRecordData?.id });
                console.log(response)
                if (response.data?.data) {
                    setUpcomingShows(response.data.data);
                    addFiltersToTableColumns(response.data.data);
                } else {
                    setUpcomingShows([]);
                }
            } catch (err) {
                console.error(err);
                setUpcomingShows([]);
                if (err?.response?.data?.error) {
                    openNotification('Could not fetch upcoming shows', err.response.data.error, "error");
                } else {
                    openNotification('Could not fetch upcoming shows', err?.message, "error");
                }
            } finally {
                setUpcomingShowsLoading(false);
            }
        }

        if (currentRecordData?.id) {
            fetchUpcomingShows();
        }
    }, [currentRecordData, openNotification]);

    return (
        <Flex vertical>
            <Title level={4}>Upcoming Shows</Title>
            <Flex style={{ width: "100%", minHeight: "10vh" }}>
                {
                    upcomingShowsLoading ? <Spin size='sm' /> : <>
                        {
                            upcomingShows?.length <= 0 || !upcomingShows ? <div>
                                <p style={{ color: "gray" }}><em>No shows scheduled.</em></p>
                            </div> : <div style={{ width: "100%" }}>
                                <>
                                    {/* {
                                        upcomingShows?.map?.(show => <UpcomingShowCard key={show.id} show={show} />)
                                    } */}
                                    <Table
                                        columns={tableColumns}
                                        dataSource={upcomingShows}
                                        pagination={{
                                            pageSize: 4,
                                        }}

                                    />
                                </>
                            </div>
                        }
                    </>
                }
            </Flex>
        </Flex>
    )
}

export default UpcomingShows