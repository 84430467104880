import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { asyncStatuses } from "Redux/enums";
import axios from "axios";

export const updateSingleSeat = createAsyncThunk("seat/single", async ({ id, data }, thunkAPI) => {
    console.log({ id, data });
    try {
        const response = await axios.patch(`hall/rest/seat/${id}/`, data);
        const responseData = response.data;
        return responseData;
    } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
    }
});

export const updateBulkSeats = createAsyncThunk("seat/bulk", async (data, thunkAPI) => {
    try {
        const response = await axios.post("hall/bulk-update-seats/", data);
        const responseData = response.data;
        return responseData;
    } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
    }
})

export const updateBulkSeatsPrice = async (data) => {
    try {
        const response = await axios.post("program/bulk-create-update-seat-price/", data);
        const responseData = response.data;
        return responseData;
    } catch (err) {
    }
}

const initialState = {
    updateSingleSeatStatus: null,
    updateSingleSeatErrorMessage: null,
    updateBulkSeatStatus: null,
    updateBulkSeatErrorMessage: null,
}

const hallLayoutSlice = createSlice({
    name: "hallLayout",
    initialState: initialState,
    reducers: {
        resetHallSlice: () => initialState,
        resetUpdateSingleStatus(state) {
            state.updateSingleSeatErrorMessage = null;
            state.updateSingleSeatStatus = null;
        },
        resetUpdateBulkStatus(state) {
            state.updateBulkSeatErrorMessage = null;
            state.updateBulkSeatStatus = null;
        },
    },
    extraReducers: (builder) => {
        // edit a single seat
        builder.addCase(updateSingleSeat.pending, (state) => {
            state.updateSingleSeatStatus = asyncStatuses.LOADING;
        });
        builder.addCase(updateSingleSeat.fulfilled, (state) => {
            state.updateSingleSeatStatus = asyncStatuses.SUCCESS;
        });
        builder.addCase(updateSingleSeat.rejected, (state, action) => {
            state.updateSingleSeatStatus = asyncStatuses.FAILED;
            state.updateSingleSeatErrorMessage = action.payload?.message || action.payload?.msg;
        });

        // edit bulk seats
        builder.addCase(updateBulkSeats.pending, (state) => {
            state.updateBulkSeatStatus = asyncStatuses.LOADING;
        });
        builder.addCase(updateBulkSeats.fulfilled, (state) => {
            state.updateBulkSeatStatus = asyncStatuses.SUCCESS;
        });
        builder.addCase(updateBulkSeats.rejected, (state, action) => {
            state.updateBulkSeatStatus = asyncStatuses.FAILED;
            state.updateSingleSeatErrorMessage = action.payload?.message;
        });
    }
});

export const { resetUpdateBulkStatus, resetUpdateSingleStatus, resetHallSlice } = hallLayoutSlice.actions;

export default hallLayoutSlice.reducer;