import { Checkbox, Flex } from 'antd';
import React from 'react';

export const processPermissions = (list) => {

  const map = new Map();

  for (let l of list) {
      let p = l.permission_name.split(":");
      let modifiedPermission = {
          ...l,
          model: p[0],
          permission: p[1]
      }

      if (map.has(modifiedPermission.model)) {
          map.get(modifiedPermission.model).push(modifiedPermission);
      } else {
          map.set(modifiedPermission.model, [modifiedPermission]);
      }
  }
  console.log(map);
  return map;
}

const PermissionSelectionView = ({ allPermissionsMap, selectedPermissions, onCheckPermission, text = "Permissions" }) => {
  // console.log(allPermissionsMap, Array.from(allPermissionsMap.keys()))
  return (
    <Flex vertical gap={2} style={{ marginBottom: 10 }}>
      <p style={{ marginBottom: 10 }}>{text}</p>
      {
        Array.from(allPermissionsMap.keys()).map((key, i) => {
          let permArray = allPermissionsMap.get(key);
          // console.log({ permArray })
          return <ModelPermission
            permArray={permArray}
            model={key}
            key={i}
            selectedPermissions={selectedPermissions}
            onCheckPermission={onCheckPermission}
          />
        })
      }
    </Flex>
  )
}

const ModelPermission = ({ permArray, model, selectedPermissions, onCheckPermission }) => {

  return (
    <Flex justify='space-between' align='center' style={{ width: "100%" }}>
      <em>{model}</em>
      <Flex gap={10}>
        {
          permArray.map(perm => {

            const isChecked = selectedPermissions.filter(r => r.id === perm.id).length > 0;
            // console.log({isChecked, p: perm.id, selectedPermissions})
            return (
              <Flex gap={4} key={perm.id}>
                <Checkbox checked={isChecked} onChange={() => { onCheckPermission(!isChecked, perm) }} />
                <span>{perm.permission}</span>
              </Flex>
            )
          })
        }
      </Flex>
    </Flex>
  )
}

export default PermissionSelectionView