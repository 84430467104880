import React, { useState, useRef, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { screen } from "Assets";
import { useParams } from "react-router";
import * as bookingApis from "../../../../Api/bookingApis"
import { IoIosArrowBack, IoIosCloseCircleOutline, IoMdAdd, IoMdRemove } from "react-icons/io";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ShowDetailsVertical from "Components/Order/ShowDetailsVertical";
import FoodCard from "Components/Order/FoodCard";
import LoginModal from "Components/Order/LoginModal";
import { Flex, Row, Col, Tag, Input, Button, Spin, notification, Image } from "antd";
import { useNotificationContext } from "Config/NotificationsContext";
import SeatDesign from "./SeatDesign";
import PaymentModal from "../PaymentModal";
import TicketMode from "./TicketMode";
import FoodAndCheckoutMode from "./FoodAndCheckoutMode";


export const PAYMENT_METHOD = {
    MIPS: "MIPS",
    MYT: "MYT",
    CASH: "CASH"
}


const SelectSeats = () => {

    const small = useMediaQuery({ query: "(min-width: 576px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    const [notificationApi, contextHolder] = notification.useNotification();
    const openNotification = (message, description, type) => {
        notificationApi[type]({
            message: message,
            description: description,
            placement: "top"
        });
    };

    const { movieId, showId } = useParams()
    const user = useSelector(store => store.auth?.user);
    const clientId = process.env.REACT_APP_CLIENT_ID
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [ticketMode, setTicketMode] = useState(true)

    const [seatNumberModal, setSeatNumberModal] = useState(true)
    const [timeLeft, setTimeLeft] = useState(0)
    const [paymentTimeLeft, setPaymentTimeLeft] = useState(0)

    const [movieData, setMovieData] = useState(null)
    const [showData, setShowData] = useState(null)
    const [hallSeats, setHallSeats] = useState([])
    const [seatTypes, setSeatTypes] = useState([])
    const [bookedSeats, setBookedSeats] = useState([])
    const [ticketVariety, setTicketVariety] = useState(null)
    const [totalPrice, setTotalPrice] = useState(0)
    const [maxSeatSelectionAllowed, setMaxSeatSelectionAllowed] = useState(10)
    const [totalSeatsSelected, setTotalSeatsSelected] = useState(1)
    const [ticketCoupon, setTicketCoupon] = useState(0)
    const [ticketCouponId, setTicketCouponId] = useState(null)
    const [convenienceFee, setConvenienceFee] = useState(null);

    const [thisTheatre, setThisTheatre] = useState(null)
    const [categories, setCategories] = useState([])
    const [foods, setFoods] = useState([])
    const [currentCategory, setCurrentCategory] = useState("")
    const [orderedFood, setOrderedFood] = useState([])
    const [orderedFoodPrice, setOrderedFoodPrice] = useState(0)
    const [maxAllowedFoodQuantity, setMaxAllowedFoodQuantity] = useState(10)
    const [totalFoodQuantity, setTotalFoodQuantity] = useState(0)
    const [foodCoupon, setFoodCoupon] = useState(0)
    const [foodCouponId, setFoodCouponId] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)
    const [seatLockingInProgress, setSeatLockingInProgress] = useState(false);

    const [seatCouponTitle, setSeatCouponTitle] = useState(null)
    const [foodCouponTitle, setFoodCouponTitle] = useState(null)

    const [allowCheckout, setAllowCheckout] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showForm, setShowForm] = useState("nologin")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneCode, setPhoneCode] = useState("")

    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(null)

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [order, setOrder] = useState(null)
    const [paymentId, setPaymentId] = useState(null)
    const [paymentInfoLoading, setPaymentInfoLoading] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState(null);
    // const [mytPaymentInfo, setMytPaymentInfo] = useState(null);
    // const [iframeContent, setIframeContent] = useState('');

    const [posSessions, setPosSessions] = useState([]);
    const [posSession, setPosSession] = useState(null);
    const [amountToPay, setAmountToPay] = useState(null);

    //   const indexWiseImages = [bycycle, bike, auto, hatchback, car, minivan, minivan, bus, bus, bus]

    const fetchMovie = async () => {
        const response = await bookingApis.fetchClientProgram(movieId, openNotification);
        if (response.status === 200) {
            setMovieData(response.data)
        }
    };

    useEffect(() => {
        if (movieId) {
            fetchMovie()
        }
    }, [movieId])

    const fetchShowData = async () => {
        const response = await bookingApis.fetchShow(showId, openNotification);
        if (response.status === 200) {
            setShowData(response.data)
        }
    };

    const fetchShowSeatsData = async () => {
        const response = await bookingApis.fetchShowSeats(showId, openNotification);
        if (response.status === 200) {
            setHallSeats(response.data)
        }
    };

    const fetchShowSeatTypesData = async () => {
        const response = await bookingApis.fetchShowSeatTypes(showId, openNotification);
        if (response.status === 200) {
            setSeatTypes([{ color: "#505050", title: "Booked" }, { color: "grey", title: "Locked" }, ...response.data])
        }
    };

    const fetchAllCategories = async () => {
        const response = await bookingApis.fetchCategories(showData?.theatre, openNotification);
        if (response.status === 200) {
            setCategories(response.data.results)
        }
    };

    const fetchFood = async () => {
        if (!showData?.theatre) return;
        const response = await bookingApis.fetchFoodOfCategory(showData?.theatre, currentCategory, openNotification);
        if (response.status === 200) {
            setFoods(response?.data)
        }
    };

    const fetchConvenienceFee = async () => {
        if (!showData?.theatre) return;
        const response = await bookingApis.fetchConvenienceFee(showData?.theatre, openNotification);
        setConvenienceFee(response);
    };

    const fetchTaxes = async () => {
        const response = await bookingApis.getTaxes(showData?.theatre, openNotification);
        if (response) {
            setTaxes(response)
        }
    }

    const fetchActivePOSSessions = async () => {
        const response = await bookingApis.fetchActivePOSSessions(showId, openNotification);
        if (response) {
            setPosSessions(response.data);
        }
    }

    useEffect(() => {
        if (showId) {
            fetchShowData()
            fetchShowSeatsData()
            fetchShowSeatTypesData()
            fetchActivePOSSessions();
        }
    }, [showId])

    useEffect(() => {
        if (movieData && showData) {
            setThisTheatre(movieData?.theatres?.filter(th => th.id === showData?.theatre)[0]?.title)
            fetchTaxes()
        }
    }, [movieData, showData])

    useEffect(() => {
        if (bookedSeats.length > 0) {
            let total_price = 0
            bookedSeats.map((bookedSeat) => total_price += parseFloat(bookedSeat.price))
            setTotalPrice(total_price)
            let ticket_variety = []
            bookedSeats.map((bookedSeat) => {
                let filtered_Seats = bookedSeats.filter((item) => item.seatType === bookedSeat.seatType)
                if (ticket_variety.filter(variety => variety.type === bookedSeat.seatType_data.title).length > 0) {
                } else {
                    console.log({ filtered_Seats })
                    ticket_variety.push({ "type": bookedSeat.seatType_data.title, "quantity": filtered_Seats.length, "color": bookedSeat.seatType_data.color, displayLabels: filtered_Seats.map(fs => fs.display_label).join(", ") })
                }
            })
            console.log(ticket_variety)
            setTicketVariety(ticket_variety)
        } else {
            setTotalPrice(0)
            setTicketVariety([])
        }
    }, [bookedSeats])

    useEffect(() => {
        let food_price = 0
        orderedFood.forEach((food) => {
            food_price += food.quantity * food.price
        })
        setOrderedFoodPrice(food_price)
    }, [orderedFood])

    useEffect(() => {
        if (!ticketMode) {
            fetchAllCategories()
            fetchFood();
            fetchConvenienceFee();
        }
    }, [ticketMode, showData])

    useEffect(() => {
        fetchFood();
    }, [currentCategory, showData])

    const lockSeats = async () => {
        const data = {
            "show": showId,
            "seats": bookedSeats.map(seat => { return seat.id }),
        }
        setSeatLockingInProgress(true);
        const response = await bookingApis.lockSeats(data);
        if (response) {
            setTicketMode(false)
        } else {
            setBookedSeats([])
            setHallSeats([])
            fetchShowSeatsData()
        }
        setSeatLockingInProgress(false);
    }

    const unlockSeats = async (with_reload = false) => {
        const data = {
            "show": showId,
            "seats": bookedSeats.map(seat => { return seat.id }),
        }
        const response = await bookingApis.unlockSeats(data);
        if (response) {
            setTicketMode(true)
            setBookedSeats([])
        } else {
            setHallSeats([])
            fetchShowSeatsData()
        }

        if (with_reload) window.location.reload();
    }

    const checkSeatCoupon = async (e) => {
        e.preventDefault();

        if (!seatCouponTitle) {
            openNotification("Coupon cannot be empty", null, "info");
            return;
        }

        let formData = {
            "theatre_id": showData?.theatre,
            "channel_code": "POS",
            "on_value": totalPrice,
            "no_of_tickets": totalSeatsSelected,
            "coupon": seatCouponTitle,
        }

        const response = await bookingApis.checkSeatCouponValidity(formData, openNotification);
        if (response?.data) {
            setTicketCoupon(response?.data.value)
            setTicketCouponId(response?.data.uuid)
        } else {
            setTicketCoupon(0)
            setTicketCouponId(null)
        }
    }

    const checkFoodCoupon = async (e) => {
        e.preventDefault()

        if (!foodCouponTitle) {
            openNotification("Coupon cannot be empty", null, "info");
            return;
        }

        let formData = {
            "theatre_id": showData?.theatre,
            "channel_code": "POS",
            "on_value": orderedFoodPrice,
            "coupon": foodCouponTitle,
        }

        const response = await bookingApis.checkFoodCouponValidity(formData, openNotification);
        if (response?.data) {
            setFoodCoupon(response?.data.value)
            setFoodCouponId(response?.data.uuid)
        } else {
            setFoodCoupon(0)
            setFoodCouponId(null)
        }
    }

    const handleCheckout = async () => {
        if (false && isAuthenticated === false && !(email && phone)) {
            console.log("There's still time!")
            setShowLoginModal(true)
            setAllowCheckout(false)
        } else {
            console.log("Let's do it!");
            setShowLoginModal(false)
            setIsSubmitting(true)
            const data_seats = bookedSeats.map(bs => bs.id)
            const data_foods = orderedFood.map(of => {
                return {
                    "id": of.food,
                    "ordered_quantity": of.quantity
                }
            })
            const data = {
                "show": showId,
                "theatre_id": showData?.theatre,
                "channel_code": "POS",
                "payment_method": "CASH",
                "seats": data_seats,
                "food_items": data_foods,
                "coupon": ticketCouponId,
                "food_coupon": foodCouponId,
                "user": user?.id,
                "created_by": user?.id,
                "send_to_email": email,
                "send_to_mobile": phone,
                "send_to_mobile_code": phoneCode,
            }

            if (phone) {
                data["send_to_mobile"] = phone;
            }
            if (phoneCode) {
                data["send_to_mobile_code"] = phoneCode;
            }
            if (email) {
                data["send_to_email"] = email;
            }

            const response = await bookingApis.createOrder(data, openNotification);
            console.log({ createOrder: response });
            if (response) {
                setOrder(response.order);
                setIsSubmitting(false);
                setPaymentId(response.payment["id"]);
                setPaymentTimeLeft(30 * 60);
                setShowPaymentModal(true);
                setAmountToPay(response.payment["total"]);
                // fetchPaymentDetails(response.payment["id"])
                // if ("redirect_url" in response) {
                //   navigate(response['redirect_url']);
                // }
            }
            setIsSubmitting(false)
        }
    }

    const seatContainerRef = useRef(null);

    useEffect(() => {
        if (hallSeats?.length > 0) {
            if (seatContainerRef.current) {
                const container = seatContainerRef.current;
                const contentWidth = container.scrollWidth;
                const containerWidth = container.clientWidth;
                container.scrollLeft = (contentWidth - containerWidth) / 2;
            }
        }
    }, [hallSeats]);

    const [taxes, setTaxes] = useState([])

    const [totalTicketTaxes, setTotalTicketTaxes] = useState([])
    const [totalFoodTaxes, setTotalFoodTaxes] = useState([])
    const [roundValue, setRoundValue] = useState(0.00)

    useEffect(() => {
        let ticketTaxes = 0
        let foodTaxes = 0
        if (totalPrice > 0) {
            let ticket_taxes_array = taxes.filter(tax => tax.type === "TICKET");
            for (let tax of ticket_taxes_array) {
                let priceToCalTaxOn = totalPrice.toFixed(2) - ticketCoupon.toFixed(2);
                let _tax = parseFloat((priceToCalTaxOn * (tax.value / 100)).toFixed(2));
                ticketTaxes += _tax;
            }
        }
        setTotalTicketTaxes(ticketTaxes)
        if (orderedFoodPrice > 0) {
            taxes.filter(tax => tax.type === "FOOD").forEach(tax => {
                foodTaxes += parseFloat(((orderedFoodPrice.toFixed(2) - foodCoupon.toFixed(2)) * tax.value / 100).toFixed(2))
            })
        }
        setTotalFoodTaxes(foodTaxes)
        console.log(totalPrice);
        let ceil_value = Math.ceil(totalPrice.toFixed(2) - ticketCoupon.toFixed(2) + orderedFoodPrice.toFixed(2) - foodCoupon.toFixed(2) + ticketTaxes + foodTaxes).toFixed(2)
        let total_value = (totalPrice.toFixed(2) - ticketCoupon.toFixed(2) + orderedFoodPrice.toFixed(2) - foodCoupon.toFixed(2) + ticketTaxes + foodTaxes).toFixed(2)
        if (ceil_value - total_value >= 0) {
            setRoundValue((ceil_value - total_value).toFixed(2))
        }
    }, [totalPrice, ticketCoupon, orderedFoodPrice, foodCoupon, taxes]);

    const fetchShowSeatsDataViaWS = async () => {
        const response = await bookingApis.fetchTheatreURLFromShowId(showId);
        let local_url = "";
        if (response.status === 200) {
            const result = response.data;
            console.log(result);
            local_url = `${result.host_url.replace('https://', 'wss://').replace('http://', 'ws://')}/ws/seat/WEB/${result.theatre_id}/${showId}/`;

        }
        const socket = new WebSocket(local_url);
        socket.onopen = () => {
            socket.onopen = () => {
                console.log('Connected to the WebSocket server');
            };

            socket.onmessage = (event) => {
                const response = event.data;
                // const { message } = JSON.parse(response);
                const jsonResponse = JSON.parse(response);
                console.log(jsonResponse);
                if ("message" in jsonResponse) {
                    setHallSeats(jsonResponse.message);
                }
                if ("changed_seats" in jsonResponse) {
                    const changed_seats = jsonResponse.changed_seats;
                    // alert("hello");
                    console.log(changed_seats);
                    setHallSeats((prevHallSeats) => {
                        let tempSeats = [...prevHallSeats];
                        for (let i = 0; i < changed_seats.length; i++) {
                            tempSeats[changed_seats[i].row_index][changed_seats[i].column_index] = changed_seats[i];
                            // console.log(tempSeats);
                        }
                        return tempSeats;
                    })
                }

            };

            socket.onclose = () => {
                console.log('Disconnected from the WebSocket server');
            };

            // setWs(socket);

            return () => {
                socket.close();
            };

        }
    }

    const fetchCashPaymentDetails = async (payment_id) => {
        setPaymentInfoLoading(true);
        try {
            console.log(order.id)
            const info = await bookingApis.getPaymentInfo(order.id, payment_id, showData?.theatre);
            console.log({ info });
            setPaymentInfo(info);
        } catch (e) {
            console.error(e);
            setPaymentInfo(`${e}`);
            openNotification(`${e}`, null, "error");
        } finally {
            setPaymentInfoLoading(false);
        }
    }

    useEffect(() => {
        const a = async () => {
            fetchShowSeatsDataViaWS();
        }
        a();
    }, []);

    useEffect(() => {
        if (allowCheckout) {
            handleCheckout()
        }
    }, [allowCheckout]);

    return (
        <>
            {contextHolder}
            <div>
                {ticketMode ?
                    <TicketMode
                        movieData={movieData}
                        showData={showData}
                        thisTheatre={thisTheatre}
                        small={small}
                        medium={medium}
                        large={large}
                        totalSeatsSelected={totalSeatsSelected}
                        bookedSeats={bookedSeats}
                        setBookedSeats={setBookedSeats}
                        setTotalSeatsSelected={setTotalSeatsSelected}
                        maxSeatSelectionAllowed={maxSeatSelectionAllowed}
                        seatContainerRef={seatContainerRef}
                        hallSeats={hallSeats}
                        seatTypes={seatTypes}
                        ticketVariety={ticketVariety}
                        totalPrice={totalPrice}
                        seatLockingInProgress={seatLockingInProgress}
                        lockSeats={lockSeats}
                        setTimeLeft={setTimeLeft}
                        seatNumberModal={seatNumberModal}
                        setSeatNumberModal={setSeatNumberModal}
                    />
                    :
                    <FoodAndCheckoutMode
                        timeLeft={timeLeft}
                        movieData={movieData}
                        showData={showData}
                        thisTheatre={thisTheatre}
                        large={large}
                        medium={medium}
                        small={small}
                        unlockSeats={unlockSeats}
                        currentCategory={currentCategory}
                        setCurrentCategory={setCurrentCategory}
                        categories={categories}
                        errorMessage={errorMessage}
                        foods={foods}
                        orderedFood={orderedFood}
                        setOrderedFood={setOrderedFood}
                        maxAllowedFoodQuantity={maxAllowedFoodQuantity}
                        totalFoodQuantity={totalFoodQuantity}
                        setErrorMessage={setErrorMessage}
                        setTotalFoodQuantity={setTotalFoodQuantity}
                        ticketVariety={ticketVariety}
                        bookedSeats={bookedSeats}
                        totalPrice={totalPrice}
                        ticketCoupon={ticketCoupon}
                        taxes={taxes}
                        seatCouponTitle={seatCouponTitle}
                        setSeatCouponTitle={setSeatCouponTitle}
                        setTicketCoupon={setTicketCoupon}
                        setTicketCouponId={setTicketCouponId}
                        checkSeatCoupon={checkSeatCoupon}
                        orderedFoodPrice={orderedFoodPrice}
                        foodCoupon={foodCoupon}
                        foodCouponTitle={foodCouponTitle}
                        setFoodCouponTitle={setFoodCouponTitle}
                        setFoodCoupon={setFoodCoupon}
                        setFoodCouponId={setFoodCouponId}
                        checkFoodCoupon={checkFoodCoupon}
                        totalTicketTaxes={totalTicketTaxes}
                        totalFoodTaxes={totalFoodTaxes}
                        isSubmitting={isSubmitting}
                        setAllowCheckout={setAllowCheckout}
                        showLoginModal={showLoginModal}
                        setShowLoginModal={setShowLoginModal}
                        showForm={showForm}
                        setShowForm={setShowForm}
                        email={email}
                        setEmail={setEmail}
                        phone={phone}
                        phoneCode={phoneCode}
                        setPhone={setPhone}
                        setPhoneCode={setPhoneCode}
                        showPaymentModal={showPaymentModal}
                        showId={showId}
                        order={order}
                        setShowPaymentModal={setShowPaymentModal}
                        paymentTimeLeft={paymentTimeLeft}
                        setPaymentTimeLeft={setPaymentTimeLeft}
                        paymentId={paymentId}
                        paymentInfoLoading={paymentInfoLoading}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        convenienceFee={convenienceFee}
                        fetchCashPaymentDetails={fetchCashPaymentDetails}
                        handleCheckout={handleCheckout}
                        posSession={posSession}
                        posSessions={posSessions}
                        setPosSession={setPosSession}
                    />
                }
            </div >
        </>
    );
};

export default SelectSeats;
