import { Button, Flex, Spin } from 'antd'
import axios from 'axios';
import React, { useState } from 'react'

const TempCyberSource = () => {

    const [loading, setLoading] = useState(false);
    const [userMsg, setUserMsg] = useState("not initiated");
    const [html, setHtml] = useState(null);

    const [form, setForm] = useState(null);

    const contactCyberSource = async (fields, signature, url) => {
        try {
            const formData = new FormData();

            for (let key in fields) {
                formData.set(key, fields[key])
            }

            formData.set('signature', signature);
            console.log({fields, signature, url})
            const response = await axios.post(url, formData);
            console.log(response);
        } catch (err) {
            console.error(err);
            setUserMsg("Error in contacting cybersource");
            setLoading(false);
        }
    }

    const initiateProcess = async () => {
        try {
            setLoading(true);
            setUserMsg("initiated");

            let data = {
                order_id: "43a7ce28-0122-4b09-86d6-ac28c8f2d0af"
            }

            const response = await axios.post('/payment/cyb_tra/', data);
            console.log({ response });

            if (response.data?.context) {
                let fields = response.data.context.fields;
                let signature = response.data.context.signature;
                let url = response.data.context.url;

                if (!fields || !signature || !url) {
                    throw new Error("Invalid data received");
                }
                
                if (response.data.json_response) {
                    setHtml(response.data.json_response)
                }

                // await contactCyberSource(fields, signature, url);
                setForm(response.data.context)
            } else {
                throw new Error("no data in response")
            }
        } catch (err) {
            console.error(err);
            setLoading(false);
            setUserMsg("Error")
        }
    }


    return (
        <Flex vertical align='center' justify='center' style={{ width: "100%", height: "100%" }}>
            <Button type='primary' onClick={initiateProcess}>Initiate</Button>
            <Flex style={{ padding: 10 }}>
                {
                    loading ? <Spin /> : <></>
                }
            </Flex>
            <pre>
                {userMsg}
            </pre>
            {
                form && form.fields && form.signature && form.url ? <>
                    <form method='post' action={form.url}>
                        {
                            Object.keys(form.fields).map((f, i) => {

                                return <input type='text' name={f} value={form.fields[f]} />
                            })
                        }
                        <input type="hidden" name="signature" id="" value={form.signature} />
                        <button type='submit'>submit</button>
                    </form>
                </> : <></>
            }
        </Flex>
    )
}

export default TempCyberSource