import { useSelector } from "react-redux";
import AddEditFormComponent from "./AddEditFormComponent";
import { FormModesEnum } from "Redux/enums";
import getHeaderTitle from "Utils/getHeaderTitle";
import { Drawer } from "antd";

const DrawerComponent = ({
    formCompononentStyle,
    nonViewFormCompononentStyle,
    resetFormMode,
    open,
    headerTitle,
    changeFormMode,
    MyFormComponent,
    ViewComponent,
    apiUrl,
    OtherFormComponent,
    extraApiParams,
    searchParams,
    setDrawerComponentOpen
}) => {

    const currentFormMode = useSelector(store => store.master?.currentFormMode);

    const getTitle = () => {
        let title = "";

        if (currentFormMode === FormModesEnum.ADD) {
            title += 'Create a new ';
        } else if (currentFormMode === FormModesEnum.EDIT) {
            title += 'Edit a '
        } else if (currentFormMode === FormModesEnum.VIEW) {
            title += 'Viewing a '
        }

        title += " ";
        title += getHeaderTitle(headerTitle);

        return title;
    }

    const getStyle = () => {
        let style = {}

        if (currentFormMode === FormModesEnum.VIEW && ViewComponent) {
            style = { width: "30vw", ...formCompononentStyle };
        } else {
            style = { width: "30vw", ...nonViewFormCompononentStyle }
        }

        return style;
    }

    return <Drawer
        title={getTitle()}
        width={"fit-content"}
        style={getStyle()}
        onClose={resetFormMode}
        open={open}
        styles={{
            body: {
                paddingBottom: 80,
            },
        }}
    >
        {
            AddEditFormComponent ?
                <AddEditFormComponent
                    ViewComponent={ViewComponent}
                    MyFormComponent={MyFormComponent}
                    changeFormMode={changeFormMode}
                    OtherFormComponent={OtherFormComponent}
                    apiUrl={apiUrl}
                    extraApiParams={extraApiParams}
                    searchParams={searchParams}
                    setDrawerComponentOpen={setDrawerComponentOpen}
                /> :
                <div>No Form Provided</div>
        }
    </Drawer>
}

export default DrawerComponent;